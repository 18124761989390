.divforfilternewscroll {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  width: calc(100% - 100px);
  display: flex;
  flex-direction: row;
  max-height: 92px;
  flex-wrap: nowrap;
  padding: 0px 0px 28px 26px;
  margin: 0px 0px -14px 0px;
  top: 0px;
  left: 0px;
  cursor: default;
  color: #deebf7;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  //transform: rotate(90deg);
}
.divforfilternewscrollwrapper {
  //transform: rotate(-90deg);
}
.filterSelectedLabel {
  color: #fff7cb;
  width: 100%;
  top: 13px;
  position: relative;
  text-align: left !important;
}
.filterOptionsButton {
  padding: 4px 10px 4px 10px;
  margin: 0px 0px 2px 0px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #1f2831;
    color: #fff7cb;
  }
}
.filterSelectedContainerButton {
  position: absolute;
  right: 4px;
  top: 6px;
}
.filterSelectedContainerWrapper {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-end;
  width: max-content;
  padding-top: 2px;
}
.filterSelectedContainer {
  background-color: #171c23;
  border: 2px solid #14181e;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  position: relative;
  right: 0px;
  border-radius: 10px;
  min-width: 200px;
  height: 40px;
  text-align: right;
  max-height: 40px;
  top: 13px;
  cursor: pointer;
  display: flex;
  width: max-content !important;
  padding: 19px 0px 18px 0px;
  margin: 0px 26px 0px 0px !important;
  &:hover {
    background-color: #1b2027;
  }
}
.filterSelectedContainerInsideX {
  position: absolute;
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: 200;
  font-size: 26px;
  top: 0px;
  right: -24px;
  transition: none;
}
.filterSelectedContainerInsideX:after {
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
  position: relative;
  transition: none;
  top: -17px;
  left: 0px !important;
  color: #7689a1;
  font-size: 1.2em;
  height: 10px;
  width: 10px;
  transition: none;
}
.filterSelectedContainerInsideX:hover:after {
  color: #deebf7;
  transition: none;
}
.filterSelectedContainerInsideXrel {
  position: relative;
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: 200;
  transition: none;
  font-size: 26px;
  top: 8px;
  right: -4px;
}
.filterSelectedContainerInsideXrel:after {
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
  position: relative;
  transition: none;
  top: -8px;
  transition: none;
  color: #7689a1;
  font-size: 1.2em;
  height: 10px;
  width: 10px;
}
.filterSelectedContainerInsideXrel:hover:after {
  color: #deebf7;
  transition: none;
}
.filterSelectedContainerInsideMultiWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-right: 44px;
}
.filterSelectedContainerInsideSingle {
  text-align: left;
  width: 100%;
  position: relative;
  margin-right: 80px;
  left: 16px;
  top: -12px;
  color: #fbd665;
}
.filterSelectedContainerInside {
  display: flex;
  flex-grow: 1; /* Allow the div to grow and take available space */
  position: relative;
  width: 100%;
  left: 0px;
}
.filterOptionsContainer {
  position: fixed;
  top: 138px;
  min-width: 100px;
  margin-right: 26px;
  max-height: 260px;
  padding: 3px 3px 1px 3px;
  background-color: #14181e;
  border-radius: 9px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.filterSelectedContainerInsideButton {
  width: fit-content;
  margin-top: -14px;
  color: #fbd665;
  margin-left: 4px;
  height: 28px;
  border-radius: 9px;
  line-height: 0px;
  padding: 8px 10px 6px 10px;
  background-color: #1f2831;
}
.filterOptionsContainer::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.filterOptionsContainer::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.filterOptionsContainer::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.filterOptionsContainer::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
@media screen and (max-width: 1680px), (max-height: 900px) {
  .filterOptionsContainer {
    top: 138px;
  }
  @media screen and (max-width: 481px) {
    .filtermenunewwrapper {
      position: relative;
      z-index: 11;
      height: 0px;
      display: none;
      left: 0px !important;
      width: calc(100%) !important;
      top: 0px;
      padding: 10px 0px 0px 6px;
      margin-bottom: 10px !important;
      overflow-y: visible;
      overflow-x: visible;
    }
  }
}
.filtermenudropdown {
  position: relative;
  width: fit-content;
  min-width: 200px;
  height: 50px;
  margin: 0px 26px 0px 0px !important;

  padding-bottom: 9px;
}
.filtermenudropdowninnerwrap > div {
  max-height: 32px !important;
}
.filtermenudropdowninnerwrap > div {
  max-height: 32px !important;
}

.filtermenudropdowninnerwrap {
  position: absolute;
  min-width: 200px;
  height: 30px;
  width: fit-content;
  margin: 0px 26px 0px 0px !important;
}
.filtermenudropdownfirst {
  position: relative;
  width: fit-content;
  margin: 0px 26px 0px 0px !important;

  min-width: 200px;
  height: 50px;
  margin-top: 20px;
  padding-bottom: 9px;
}
.filtermenudropdownlast {
  position: relative;
  height: 50px;
  min-width: 200px;
  height: 30px;
  margin: 0px 26px 0px 0px !important;

  width: fit-content;
  padding-bottom: 9px;
}
.divforfilternewscroll::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #14181e;
  background-color: #14181e;
}
.divforfilternewscroll::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.divforfilternewscroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.divforfilternewscroll::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.closefiltersbutton {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  position: relative;
  background-color: #fa798b;
  width: 60px;
  left: 20px;
  cursor: pointer;
  font-size: 0.9em;
  line-height: 24px;
  top: 16px;
  padding: 0px 0px 0px 12px;
  border-radius: 8px;
}
.filtermenunewwrapper {
  position: relative;
  z-index: 11;
  height: 0px;
  display: none;
  left: 50px;
  top: 0px;
  padding: 10px 0px 0px 6px;
  margin-bottom: 10px !important;
  overflow-y: visible;
  overflow-x: visible;
}
.filtermenunbuttonsmain {
  position: absolute;
  right: 0px;
  height: 98px;
  width: 100px;
  border-left: 1px solid #14181e;
  border-bottom: 1px solid #1f2831;
  top: 0px;
}
