.applyaccountbutton {
  position: absolute;
  font-size: 1.3em;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 14px;
  border: none;
  text-align: center;
  right: 40px;
  background-color: #fbd665;
  transition: all 0.25s;
  color: #1a2028;
  padding: 10px;
  z-index: 0 !important;
  cursor: pointer;
  width: 100px;
  bottom: 30px;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.usernameelementinnermorpheusemail {
  color: #007bff;
  font-weight: 500;
  margin-left: -170px;
  margin-top: 10px;
}
.newpricingtableToggleComponentMA {
  text-align: center;
  align-items: center;
  margin-left: -20px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.newpricingtablecardWrapperMainMA {
  margin-top: 100px;
  display: flex;
  font-family: "Roboto";
  flex-direction: row;
  margin-left: -10px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.featuredTierItem {
  position: absolute;
  background: linear-gradient(to bottom right, #ffe694, #6a5c34);
  border-radius: 30px;
  color: #14181e;
  font-weight: 400;
  font-size: 0.85em;
  line-height: 17px;
  top: 8px;
  left: 100px;
  padding: 0px 12px;
}
.deleteaccountcancelbutton {
  position: absolute;
  background-color: #bbbbbb;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  width: 110px;
  bottom: 14px;
  color: #14181e;
  padding: 8px 0px 8px 0px;
  right: 180px;
  margin: -22px 0px 4px 48px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.deleteaccountcancelbutton:hover {
  outline-style: none;
  border: none;
  background-color: #d6d6d6;
}
.text-input-boxes-accdel {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 1px solid #14181e;
  border-radius: 8px;
  height: 38px;
  color: #fa798b;
  background-color: #1a2028;
  outline-style: none;
  font-weight: 400;
  width: calc(100% - 40px);
}
.changePasswordNoneTExt {
  position: relative;
  left: 90px !important;
  color: #fff7cb;
  font-style: italic;
}
#settingswrapper[max-width~="481px"] .changePasswordbutton {
  position: relative;
  font-family: "Roboto";
  font-weight: bold;
  border-radius: 10px;
  border: none;
  text-align: center;
  left: 0px !important;
  margin-top: 0px;
  background-color: #fbd665;
  transition: all 0.25s;
  color: #1a2028;
  padding: 10px;
  z-index: 0 !important;
  cursor: pointer;
  width: 174px;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.changePasswordbutton {
  position: relative;
  font-family: "Roboto";
  font-weight: bold;
  border-radius: 10px;
  border: none;
  text-align: center;
  left: 90px !important;
  margin-top: -12px;
  background-color: #fbd665;
  color: #1a2028;
  padding: 10px;
  z-index: 0 !important;
  cursor: pointer;
  width: 174px;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.changePasswordbutton:disabled {
  background-color: #e4d5ae;
  color: #394453;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  cursor: not-allowed;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
.changePasswordbutton:hover {
  outline-style: none;
  border: none;
  color: #222a35;
  background-color: #ffe593;
}
.changepasswordconfirmtext {
  color: #55f8a6;
  position: relative;
  top: 40px;
  left: -80px;
}

.delaccwidth {
  width: 150px !important;
}
.delacccancelright {
  right: 210px !important;
}
.deleteaccountproceedbutton {
  position: absolute;
  background-color: #d41c35;
  outline: none;
  border: none;
  font-weight: bold;
  transition: all 0.25s;
  border-radius: 10px;
  width: 110px;
  bottom: 14px;
  color: #14181e;
  padding: 8px 0px 8px 0px;
  right: 36px;
  margin: -22px 0px 4px 48px;
  -webkit-filter: drop-shadow(0 0 6px #14181e);
  -moz-filter: drop-shadow(0 0 6px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 6px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 6px #14181e);
}
.deleteaccountproceedbutton:hover {
  outline-style: none;
  border: none;
  background-color: #d72b42;
  -webkit-filter: drop-shadow(0 0 8px #d72b42);
  -moz-filter: drop-shadow(0 0 8px #d72b42); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 8px #d72b42);
  filter: drop-shadow(0 0 8px #d72b42);
}
.deleteaccountproceedbutton:disabled {
  outline-style: none;
  border: none;
  cursor: default;
  background-color: #a34753;
  -webkit-filter: drop-shadow(0 0 3px #a34753);
  -moz-filter: drop-shadow(0 0 3px #a34753); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 3px #a34753);
  filter: drop-shadow(0 0 3px #a34753);
}
.featureItem {
  white-space: pre-wrap;
  height: auto;
  margin: 0px 0px -2px 0px;
  padding: 0px;
}
.featureItem:before {
  content: "✔ ";
  font-size: 1.3em;
  color: #fbd665;
}
.featureItemFree {
  height: 18px;
}
.featureItemFree:before {
  content: "✔ ";
  font-size: 1.3em;
  color: #deebf7;
}
.delacountinnermessagewrapper {
  padding: 0px 26px 16px 0px;
  margin: 24px 0px 0px -4px;
  font-size: 1.2em;
  color: #d41c35;
  font-weight: 400;
}
.modalSymbolHeader8 {
  z-index: 1000;
  width: 260px;
  margin-top: -39px;
  margin-left: -16px;
  margin-bottom: 20px;
  position: relative;
  color: #d41c35;
  font-size: 1.6em;
  padding-bottom: 4px;
  text-align: left;
  font-weight: 400;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.dangerzonewrapper {
  position: absolute;
  bottom: 20px;
  width: calc(100%);
  z-index: 100000000;
}
.criticalactionsheader {
  color: #d41c35;
  left: -6px;
  font-size: 1.3em;
  width: calc(100%);
  position: absolute;
  font-weight: 600;
  bottom: 180px;
  border-bottom: 1px solid #911324;
  text-align: left;
}
.deleteaccountbutton {
  position: absolute;
  background-color: #d41c35;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  color: #14181e;
  padding: 10px 0px 10px 0px;
  left: 310px;
  width: 150px;
  top: -10px;
  margin: 0px 34px 8px 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.deleteaccountbutton:hover {
  outline-style: none;
  border: none;
  background-color: #d72b42;
}
.deleteaccountbutton:focus {
  outline: none;
}
.deleteAccountModal {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  text-align: center;
  z-index: 100000001 !important;
  top: 50%;
  font-size: 1em;
  left: 50%;
  border-radius: 14px;
  color: #deebf7;
  background-color: #222a35;
  outline-style: none;
  border: none;
  display: flex;
  flex-flow: column;
  flex: auto;
  padding: 0px 0px 0px 40px;
  border-top: 50px solid #1a2028;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  height: 480px;
  width: 500px;
  margin: -296px 0px 0px -250px;
  text-align: left;
}
.applyaccountbutton:disabled {
  background-color: #e4d5ae;
  color: #394453;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  cursor: not-allowed;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
.applyaccountbutton:hover {
  outline-style: none;
  border: none;
  color: #222a35;
  background-color: #ffe593;
}
.applyaccountbutton:focus {
  outline-style: none;
  border: none;
}
.undoprofilechangesbutton {
  position: absolute;
  font-size: 1.3em;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 14px;
  border: none;
  text-align: center;
  right: 180px;
  background-color: #bbbbbb;
  color: #1a2028;
  padding: 10px;
  z-index: 0 !important;
  cursor: pointer;
  width: 176px;
  bottom: 30px;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  &:hover {
    outline-style: none;
    border: none;
    background-color: #d6d6d6;
  }
}
#manage-navigationbuttons-line {
  border-top: 1px solid #303a49;
  position: absolute;
  height: 10px;
  top: 50px;
  left: 0px;
  width: calc(100%);
  z-index: 100000000;
}
.currencysymbolsinnerheaderbilling {
  position: absolute;
  color: #98b5eb;
  font-size: 1.3em;
  display: flex;
  text-align: left;
  font-weight: 400;
  padding-bottom: 0px;
  height: 0px;
  width: 100%;
  top: 42px;
  left: -7px;
  z-index: 0;
  border-bottom: 1px solid #303a49;
}
.subscriptionsettings {
  top: 0px;
  left: 0px;
  position: absolute;
  width: calc(100% - 60px);
  height: calc(100% - 1px);
}
.billingsettings {
  top: 17px;
  left: 0px;
  position: absolute;
  width: calc(100% - 36px);
  height: calc(100% - 51px);
  overflow-y: scroll;
}
.billingsettings::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.billingsettings::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.billingsettings::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.billingsettings::-webkit-scrollbar-corner {
  background-color: #1f2831;
}
.manageaccountinnerheader3 {
  position: absolute;
  color: #fbd665;
  font-size: 1.3em;
  display: flex;
  text-align: left;
  font-weight: 400;
  padding-bottom: 0px;
  width: calc(100%);
  top: 42px;
  left: -7px;
  z-index: 0;
  border-bottom: 1px solid #72612e;
}
.select-manage {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-left: 0px;
  margin-right: 20px;
  left: 36px;
  top: 34px;
}
#manage-acc-comp {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border-top: 50px solid #28303c;
  position: absolute;
  width: calc(100% - 56px);
  text-align: center;
  z-index: 100000001;
  overflow: visible;
  font-size: 1em;
  height: calc(100% - 34px);
  border-radius: 10px;
  color: #deebf7;
  background-color: #1f2831;
  box-shadow: 0 0 4pt 2pt #171c23;
  outline-style: none;
  flex-flow: row wrap;
  flex: auto;
  align-items: center;
  justify-content: center;
  padding: 0px 24px 0px 24px;
}
#settingswrapper[min-width~="900px"] #manage-acc-comp {
  width: calc(100% - 56px);
}
#trialendsnumber {
  position: absolute;
  background-color: #ee6f21;
  color: #14181e;
  font-weight: 500;
  width: 180px;
  top: 30px;
  height: 32px;
  left: 0px;
  border-radius: 10px;
  padding: 6px 0px 0px 18px;
  line-height: 22px;
  margin: 0px 10px 0px 0px;
}
#settingswrapper[max-width~="1052px"] #pricing-tier-wrapper {
  margin: 0px 10px 36px 28px !important;
}
@media screen and (max-width: 454px) {
  #manage-navigationbuttons-line {
    border-top: 1px solid #303a49;
    position: absolute;
    height: 10px;
    top: 45px;
    left: 0px;
    width: calc(100%);
    z-index: 100000000;
  }
}
#promocodeelementmaintext {
  margin-top: 17px;
  white-space: nowrap;
  margin-left: 6px;
  position: absolute;
}
#promocodeelementmaintextold {
  margin-top: 12px;
  white-space: nowrap;
}
#promocodeelementsmalltext {
  font-size: 12px;
  margin-top: -2px;
  margin-left: 6px;

  position: absolute;
}
#promocodeelementsmalltext2 {
  font-size: 10px;
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  width: fit-content;
  right: 6px;
  top: 4px;
  height: 10px;
  line-height: 10px;
  position: absolute;
}
.upgradebuttonpositionsing {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: relative !important;
  color: #14181e !important;
  left: 20px !important;
  top: 5px !important;
  cursor: pointer;
}
#upgradebuttonpositionsing::before {
  animation: none !important; /* adjust the animation duration */
}
#promocodeelement22 {
  -webkit-text-fill-color: #14181e !important;
  font-style: normal !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 86px;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
  color: #14181e;
  text-align: center;
  vertical-align: middle;
  padding: 0px 2px 0px 4px;
  border-radius: 10px;
  line-height: 8px;
  cursor: pointer;
  top: -28px;
  left: 124px;
  transition: 0.25s all ease-in-out !important;
  overflow: hidden; /* hide overflow */
  background: linear-gradient(to bottom right, #ffe694, #6a5c34);
  &:hover {
    margin-top: -3px;
  }
}
.TrialExpiredModalX {
  top: -46px;
}
.TrialExpiredModalICON {
  border-radius: 100px;
  padding: 14px 0px 0px 0px;
  width: 50px;
  height: 50px;
  position: absolute;
  left: calc(50% - 25px);
  color: #ee6f21;
  background-color: #171c23;
  top: 102px;
  -webkit-filter: drop-shadow(0 0 4px #556171);
  -moz-filter: drop-shadow(0 0 4px #556171); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 4px #556171);
  filter: drop-shadow(0 0 4px #556171);
}
.TrialExpiredModalHeader {
  width: 100%;
  position: absolute;
  left: 0px;
  top: -40px;
  color: #fff7cb;
  font-weight: 500;
  font-size: 20px;
}
.TrialExpiredModalHeader2 {
  position: absolute;
  font-weight: 500;
  font-size: 20px;
  height: 100px;
  top: 40px;
  width: 100%;
  left: 0px;
}
.TrialExpiredModalHeadermessage {
  font-weight: 300;
  font-size: 18px;
  position: absolute;
  bottom: 80px;
  left: 0px;
  padding: 30px;
}
.tooptip-i-style44rbrb2wrapper {
  position: relative;
  top: -28px;
}
.tooptip-i-style44rbrb2 {
  position: relative;
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  color: #fbd665;
  height: 12px;
  width: 12px;
  padding: 1px 6px 1px 6px;
  font-size: 0.8em;
  left: 8px;
  top: 27px;
  border-radius: 4px;
  margin-bottom: -20px;
  background-color: #161b21;
  z-index: 100000000;
  font-weight: 400;
  cursor: default;
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: #101317;
    color: #fbd665;
    cursor: pointer;
  }
}
.tooptip-i-style44rbrb {
  position: relative;
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  color: #fbd665;
  height: 0px;
  width: 12px;
  top: 27px;
  margin: 0px 0px -20px 0px;
  padding: 1px 6px 1px 6px;
  left: 10px;
  border-radius: 4px;
  font-size: 0.8em;
  background-color: #161b21;
  z-index: 100000000;
  font-weight: 400;
  cursor: default;
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: #101317;
    color: #fbd665;
    cursor: pointer;
  }
}
#promocodeelement3 {
  -webkit-text-fill-color: #14181e !important;
  font-style: normal !important;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 156px;
  height: 44px;
  font-size: 20px;
  font-weight: 500;
  color: #14181e;
  text-align: center;
  vertical-align: middle;
  padding: 0px 2px 0px 4px;
  cursor: pointer;
  border-radius: 12px;
  line-height: 20px;
  bottom: 26px;
  right: calc(50% - 78px);
  transition: 0.25s all ease-in-out !important;
  overflow: hidden; /* hide overflow */
  background: linear-gradient(to bottom right, #ffe694, #6a5c34);
  &:hover {
    margin-top: -3px;
  }
}
/* Apply the animation */
#promocodeelement3::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 276px;
  height: 22px;
  background-color: #ffefbc;
  transform: skew(-20deg) rotate(-16.26deg) translateY(-120%); /* adjust the transform property */
  opacity: 0.5;
  filter: blur(12px);
  animation: somestripe 6s linear infinite; /* adjust the animation duration */
}
#promocodeelement2 {
  -webkit-text-fill-color: #14181e !important;
  font-style: normal !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 86px;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
  color: #14181e;
  text-align: center;
  vertical-align: middle;
  padding: 0px 2px 0px 4px;
  border-radius: 10px;
  line-height: 8px;
  top: -28px;
  left: 124px;
  transition: 0.25s all ease-in-out !important;
  overflow: hidden; /* hide overflow */
  background: linear-gradient(to bottom right, #ffe694, #6a5c34);
  &:hover {
    margin-top: -3px;
  }
}
/* Apply the animation */
#promocodeelement2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 222px;
  height: 18px;
  background-color: #ffefbc;
  transform: skew(-20deg) rotate(-16.26deg) translateY(-120%); /* adjust the transform property */
  opacity: 0.5;
  filter: blur(12px);
  animation: somestripe 6s linear infinite; /* adjust the animation duration */
}
#promocodeelement {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 230px;
  height: 44px;
  font-size: 16px;
  font-weight: bold;
  color: #14181e;
  text-align: center;
  vertical-align: middle;
  padding: 0px 2px 0px 4px;
  border-radius: 12px;
  line-height: 28px;
  top: -37px;
  z-index: 100000;
  left: 300px;
  transition: 0.25s all ease-in-out !important;
  overflow: hidden; /* hide overflow */
  background: linear-gradient(to bottom right, #ffe694, #6a5c34);
  /*   &:hover {
    cursor: pointer;
  } */
}
/* Apply the animation */
#promocodeelement::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 222px;
  height: 18px;
  background-color: #ffefbc;
  transform: skew(-20deg) rotate(-16.26deg) translateY(-120%); /* adjust the transform property */
  opacity: 0.8;
  filter: blur(12px);
  animation: somestripe 6s linear infinite; /* adjust the animation duration */
}

@keyframes somestripe {
  0% {
    transform: skew(-20deg) rotate(-16.26deg) translateY(-62px)
      translateX(-100%); /* adjust the transform property */
  }
  52% {
    transform: skew(-20deg) rotate(-16.26deg) translateY(-62px)
      translateX(-100%); /* adjust the transform property */
  }
  76% {
    transform: skew(-20deg) rotate(-16.26deg) translateY(110px) translateX(100%);
  }
  100% {
    transform: skew(-20deg) rotate(-16.26deg) translateY(110px) translateX(100%);
  }
}

.stripetablewrapper {
  border-radius: 10px !important;
  padding: 28px 0px 0px 0px;
  overflow-y: hidden;

  margin: 48px 0px 26px -32px;
  height: calc(100% - 152px);
  zoom: 0.95;
  width: calc(100% - 26px);
  display: flex;
  flex-flow: row wrap;
  overflow-y: scroll;
  -webkit-filter: drop-shadow(0 0 8px #14181e);
  -moz-filter: drop-shadow(0 0 8px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 8px #14181e);
  filter: drop-shadow(0 0 8px #14181e);
}
.stripetablewrapper::-webkit-scrollbar {
  border-radius: 10px;
  height: 10px;
  width: 10px;
  color: #14181e;
  background-color: #14181e;
}
.stripetablewrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.stripetablewrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.stripetablewrapper::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
#pricing-tier-featurehader {
  top: 40px;
  position: relative;
  color: #98b5eb;
  font-size: 1.2em;
  font-weight: 400;
}
#pricing-tier-wrapper {
  margin: 0px 10px 0px 28px;
  font-size: 0.9em;
  width: 274px;
  height: 622px;
  top: 0px;
  overflow: visible;
  position: relative;
  padding: 22px 0px 0px 0px;
  -moz-box-shadow: 0 0 14px #14181e;
  -webkit-box-shadow: 0 0 14px #14181e;
  box-shadow: 0 0 14px #14181e;
  border-radius: 10px;
  z-index: 1000000000;
  background-color: #1f2831;
  justify-content: center;
  align-items: center;
}
.radio-label-buy22 {
  position: absolute;
  top: 0px;
  left: 30px;
  z-index: 9;
}
#pricing-tier-highlighted-text {
  color: #14181e;
  padding: 2px 0px 0px 0px;
  z-index: -100000000000;
  position: relative;
  font-weight: 500;
  width: 120px;
  left: calc(50% - 60px);
  top: -29px;
  margin: -18px 0px -7px 0px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  -moz-box-shadow: 0 0 1px #55f8a6;
  -webkit-box-shadow: 0 0 1px #55f8a6;
  box-shadow: 0 0 1px #55f8a6;
  background-image: linear-gradient(to bottom right, #55f8a6, #3da873);
}
.pricing-tier-wrapper-highlighted {
  margin: 26px 52px 26px 26px;
  width: 274px;
  font-size: 0.9em;
  height: 622px;
  top: -3.2vh;
  overflow: visible;
  position: relative;
  padding: 20px 0px 0px 0px;
  border-radius: 10px;
  z-index: 1000000000;
  background-color: #1f2831;
  border: 1px solid #55f8a6;
  -moz-box-shadow: inset 0 0 4px #55f8a6 !important;
  -webkit-box-shadow: inset 0 0 4px #55f8a6 !important;
  box-shadow: inset 0 0 4px #55f8a6 !important;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  justify-content: center;
  align-items: center;
}
#pricing-tier-superwrapper-highlighted {
  width: 35%;
  min-width: 250px;
  height: 54vh;
}
#pricing-tier-feature-item-ultimate {
  color: #fbd665;
  font-size: 1.15em;
  font-weight: 300;
  padding: 4px 0px 0px 0px;
}
#pricing-tier-feature-item-starter {
  color: #fff7cb;
  font-size: 1.15em;
  font-weight: 300;
  padding: 4px 0px 0px 0px;
}
#pricing-tier-feature-item-pro {
  color: #fff7cb;
  font-size: 1.15em;
  font-weight: 300;
  padding: 4px 0px 0px 0px;
}
#pricing-tier-feature-item-free {
  color: #deebf7;
  font-size: 1.15em;
  padding: 4px 0px 0px 0px;
  font-weight: 300;
}
#pricing-tier-features-wrapper {
  text-align: left;
  position: absolute;
  margin-top: 0px;
  bottom: 0px;
  height: 63%;
  padding: 16px 26px 26px 42px;
}
#pricing-tier-features-wrapper-master {
  text-align: left;
  position: absolute;
  margin-top: 0px;
  bottom: 0px;
  height: 63%;
  padding: 16px 26px 26px 42px;
}

#pricing-tier-features-wrapper-free {
  text-align: left;
  position: absolute;
  margin-top: 0px;
  height: 63%;
  padding: 40px 26px 26px 42px;
}
.freecomponentheader {
  margin-top: 30px;
}
#pricing-tier-header-free {
  color: #deebf7;
  font-size: 1.5em;
  font-weight: 500;
}
#pricing-tier-header-starter {
  color: #fff3ca;
  font-size: 1.5em;
  font-weight: 500;
}
#pricing-tier-header-master {
  color: #fbd665;
  font-size: 1.5em;
  font-weight: 600;
  @import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
  background: linear-gradient(to bottom right, #ffdd77, #9e8846);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */
}
#pricing-tier-header-ultimate {
  color: #fbd665;
  font-size: 1.5em;
  font-weight: 600;
  @import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
  background: linear-gradient(to bottom right, #9e8846, #9e8846);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */
}
#pricing-tier-header-pro {
  color: #ffeba8;
  font-size: 1.5em;
  font-weight: 500;
}
#pricing-tier-subheader-free {
  color: #deebf7;
  font-size: 1em;

  font-weight: 300;
}
#pricing-tier-subheader-pro {
  color: #deebf7;
  font-size: 1em;
  font-weight: 300;
}
#pricing-tier-subheader-master {
  color: #deebf7;
  font-size: 1em;
  font-weight: 300;
}
#pricing-tier-monthly-price {
  color: #ffeba8;
  font-size: 1.5em;
  font-weight: 400;
  margin-top: 20px;
}
#pricing-tier-master-price {
  font-weight: 550;
  background: linear-gradient(to bottom right, #ffdd77, #9e8846);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */
  font-size: 1.5em;
  margin-top: 20px;
}
#pricing-tier-ultimate-price {
  font-weight: 550;
  background: linear-gradient(to bottom right, #ffdd77, #9e8846);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */
  font-size: 1.5em;
  margin-top: 20px;
}
#pricing-tier-free-price {
  color: #deebf7;
  font-size: 1.5em;
  font-weight: 400;
  margin-top: 20px;
}

#pricing-tier-button-starter {
  position: relative;
  font-size: 1.3em;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 12px;
  border: none;
  text-align: center;
  background-image: linear-gradient(to bottom right, #fff3ca, #fff3ca);
  color: #1a2028;
  padding: 12px;
  top: 20px;
  z-index: 0 !important;
  cursor: pointer;
  width: 200px;
  bottom: 0px;
  overflow: hidden; /* hide overflow */

  transition: background-image 0.25s;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  &:hover {
    outline-style: none;
    border: none;
    color: #222a35;
    outline: 2px solid #927c39;
  }
}

#pricing-tier-button-pro {
  position: relative;
  font-size: 1.3em;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 12px;
  border: none;
  text-align: center;
  background-image: linear-gradient(to bottom right, #ffeba8, #fbd665);
  color: #1a2028;
  padding: 12px;
  top: 20px;
  z-index: 0 !important;
  cursor: pointer;
  width: 200px;
  bottom: 0px;
  overflow: hidden; /* hide overflow */

  transition: background-image 0.25s;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  &:hover {
    outline-style: none;
    border: none;
    color: #222a35;
    outline: 2px solid #927c39;
  }
}
/* #pricing-tier-button-pro::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 550px;
  height: 40px;
  background-color: #fff7cb;
  transform: skew(-14.622deg) rotate(-14.622deg) translateY(-120%);
  opacity: 0.7;
  filter: blur(20px);
  animation: somestripe2 6s linear infinite;
} */
.ultimatebuttonwrapper {
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
}
#pricing-tier-button-ultimate {
  position: relative;
  font-size: 1.3em;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 12px;
  border: 2px solid #6b5922;
  outline: none;
  margin-bottom: -2px;
  text-align: center;
  background: linear-gradient(to bottom right, #14181e, #000000);
  transition: background-image 0.25s;
  padding: 12px;
  top: 20px;
  z-index: 0 !important;
  cursor: pointer;
  width: 200px;
  bottom: 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  /*   animation: shadowstuff2 7s linear infinite; 
  animation-delay: 2s;
  overflow: hidden; */
  overflow: hidden; /* hide overflow */

  &:hover {
    color: #222a35;
    border: 2px solid #9e8846;
    outline: none;
    .ultimatebuttonwrapper {
      background: linear-gradient(to bottom right, #8e8769, #ffe694);
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 500;
    }
  }
}
#pricing-tier-button-ultimate::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 550px;
  height: 40px;
  background-color: #fff7cb;
  transform: skew(-14.622deg) rotate(-14.622deg) translateY(-120%); /* adjust the transform property */
  opacity: 0.3;
  filter: blur(20px);
  animation: somestripe2 6s linear infinite; /* adjust the animation duration */
}
#pricing-tier-button-master {
  position: relative;
  font-size: 1.3em;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 12px;
  border: none;
  text-align: center;
  background: linear-gradient(to bottom right, #ffe694, #6a5c34);
  transition: background-image 0.25s;
  color: #1a2028;
  padding: 12px;
  top: 20px;
  z-index: 0 !important;
  cursor: pointer;
  width: 200px;
  bottom: 0px;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  /*   animation: shadowstuff2 7s linear infinite; 
  animation-delay: 2s;
  overflow: hidden; */
  overflow: hidden; /* hide overflow */

  &:hover {
    border: none;
    color: #222a35;
    outline: 2px solid #6b5922;
  }
}

/* Apply the animation */
#pricing-tier-button-master::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 550px;
  height: 40px;
  background-color: #fff7cb;
  transform: skew(-14.622deg) rotate(-14.622deg) translateY(-120%); /* adjust the transform property */
  opacity: 0.7;
  filter: blur(20px);
  animation: somestripe2 6s linear infinite; /* adjust the animation duration */
}
@keyframes somestripe2 {
  0% {
    transform: skew(-20deg) rotate(-16.26deg) translateY(-152px)
      translateX(-100%); /* adjust the transform property */
  }
  52% {
    transform: skew(-20deg) rotate(-16.26deg) translateY(-152px)
      translateX(-100%); /* adjust the transform property */
  }
  76% {
    transform: skew(-20deg) rotate(-16.26deg) translateY(156px) translateX(100%);
  }
  100% {
    transform: skew(-20deg) rotate(-16.26deg) translateY(106px) translateX(100%);
  }
}

@keyframes shadowstuff2 {
  0% {
    -webkit-filter: drop-shadow(0 0 10px #14181e);
    -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 10px #14181e);
  }
  82% {
    -webkit-filter: drop-shadow(0 0 10px #14181e);
    -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 10px #14181e);
  }
  91% {
    -webkit-filter: drop-shadow(0 0 3px #ffeba8);
    -moz-filter: drop-shadow(0 0 3px #ffeba8); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 3px #ffeba8);
    filter: drop-shadow(0 0 3px #ffeba8);
  }
  100% {
    -webkit-filter: drop-shadow(0 0 10px #14181e);
    -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 10px #14181e);
  }
}

#pricing-tier-button-free {
  position: relative;
  font-size: 1.3em;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 12px;
  border: none;
  height: 0px;
  text-align: center;
  background-color: transparent;
  transition: all 0.25s;
  color: #55f8a6;
  top: 31px;
  z-index: 0 !important;
  cursor: default;
  width: 200px;
  bottom: 0px;
  outline-style: none;
}
.buy-container2 {
  display: block !important;
  width: 200px !important;
  position: relative;
  padding: 0px 6px 0px 6px;
  margin: 0px 0px 32px 0px;
}
.buy-containerfirst {
  display: block !important;
  width: 120px !important;
  position: relative;
  padding: 0px 6px 0px 6px;
  margin: 0px 0px 32px 0px;
}
.buy-container2nd {
  display: block !important;
  width: 210px !important;
  position: relative;
  padding: 0px 6px 0px 6px;
  margin: 0px 0px 32px 0px;
}
.checksygf8 {
  width: 90px;
  left: 0px !important;
}
.checkssspv2 {
  position: relative;
  padding-right: 0;
  left: 0px;
  top: 2px;
  width: 155px;
  height: 20px;
  word-wrap: none;
  white-space: nowrap;
}
.checksygf82 {
  width: 174px;
  left: 0px !important;
}
.checksygf83 {
  width: 156px;
  left: 0px !important;
}
.checksygf84 {
  width: 140px;
  left: 0px !important;
}
.select-billing-type-inner {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  left: 0px;
  top: 40px;
  width: fit-content;
}
#settingswrapper[max-width~="811px"] .select-billing-type-inner {
  display: flex !important;
  flex-flow: row wrap !important;
  position: relative !important;
  left: 0px;
  top: 40px;
  width: calc(100% - 72px) !important;
}
#settingswrapper[max-width~="811px"] #trialendsnumber {
  //margin: 10px 10px 0px 0px;
}
#settingswrapper[max-width~="811px"] .stripetablewrapper {
  border-radius: 10px !important;
  padding: 28px 0px 0px 0px;
  overflow-y: hidden;
  margin: 48px 0px 26px -32px;
  height: calc(100% - 196px);
  zoom: 0.95;
  width: calc(100% - 26px);
  display: flex;
  flex-flow: row wrap;
  overflow-y: scroll;
  -moz-filter: drop-shadow(0 0 8px #14181e);
  -ms-filter: drop-shadow(0 0 8px #14181e);
  filter: drop-shadow(0 0 8px #14181e);
}
#manage-acc-comp[max-width~="608px"] .usernameelementwrapper {
  display: flex;
  flex-direction: column !important;
  text-align: left;
  width: calc(100% - 10px);
  max-width: 700px;
  position: absolute;
  top: 161px;
}
#manage-acc-comp[max-width~="608px"] .usernameelementinner {
  margin-left: 0px !important;
  margin-top: 0px !important;
  width: 100%;
}
#manage-acc-comp[max-width~="608px"] .select-profile-type-inner {
  margin-bottom: 4px !important;
}
#manage-acc-comp[max-width~="608px"] .select-profile2-type-inner {
  margin-bottom: 10px !important;
}
#manage-acc-comp[max-width~="608px"] .select-profile3-type-inner {
  margin-bottom: 4px !important;
}
#manage-acc-comp[max-width~="608px"] .select-critical-type-inner {
  display: block;
  position: absolute;
  left: 0px;
  bottom: 320px;
  width: 100%;
}
#manage-acc-comp[max-width~="608px"] .changePasswordNoneTExt {
  position: relative;
  left: 0px !important;
  color: #fff7cb;
  font-style: italic;
}
#settingswrapper[max-width~="497px"] .stripetablewrapper {
  height: calc(100% - 230px) !important;
}
#settingswrapper[max-width~="457px"] .stripetablewrapper {
  height: calc(100% - 264px) !important;
}
@media screen and (max-width: 1680px), (max-height: 900px) {
  #settingswrapper[max-width~="615px"] .stripetablewrapper {
    border-radius: 10px !important;
    padding: 28px 0px 0px 0px;
    overflow-y: hidden;
    margin: 48px 0px 26px -32px;
    height: calc(100% - 154px);
    zoom: 0.95;
    width: calc(100% - 26px);
    display: flex;
    flex-flow: row wrap;
    overflow-y: scroll;
    -moz-filter: drop-shadow(0 0 8px #14181e);
    -ms-filter: drop-shadow(0 0 8px #14181e);
    filter: drop-shadow(0 0 8px #14181e);
  }
  #settingswrapper[max-width~="392px"] .stripetablewrapper {
    border-radius: 10px !important;
    padding: 28px 0px 0px 0px;
    overflow-y: hidden;
    margin: 48px 0px 26px -32px;
    height: calc(100% - 256px) !important;
    zoom: 0.95;
    width: calc(100% - 26px);
    display: flex;
    flex-flow: row wrap;
    overflow-y: scroll;
    -moz-filter: drop-shadow(0 0 8px #14181e);
    -ms-filter: drop-shadow(0 0 8px #14181e);
    filter: drop-shadow(0 0 8px #14181e);
  }
  #settingswrapper[max-width~="838px"] #pricing-tier-wrapper {
    margin: 0px 10px 36px 28px !important;
  }
  #settingswrapper[max-width~="647px"] .select-billing-type-inner {
    display: flex !important;
    flex-flow: row wrap !important;
    position: relative !important;
    left: 0px;
    top: 40px;
    width: calc(100% - 72px) !important;
  }
  #settingswrapper[max-width~="647px"] #trialendsnumber {
    //margin: 10px 10px 0px 0px;
  }
  #settingswrapper[max-width~="494px"] .select-billing-type-inner {
    display: flex !important;
    flex-flow: row wrap !important;
    position: relative !important;
    left: 0px;
    top: 40px;
    width: calc(100% - 72px) !important;
  }
  .pricing-tier-wrapper {
    min-height: 750px;
  }
}
.select-critical-type-inner {
  display: block;
  position: absolute;
  left: 0px;
  bottom: 330px;
  width: 100%;
}
.select-profile-type-inner {
  display: block;
  position: relative;
  left: 0px;
  top: -64px;
  width: 100%;
}
.select-profile2-type-inner {
  display: block;
  position: relative;
  left: 0px;
  top: 10px;
  width: 100%;
}
.select-profile2-type-inner > div > div {
  width: calc(73px);
}
.select-profile3-type-inner {
  display: block;
  position: relative;
  left: 0px;
  top: 106px;
  width: 100%;
}
#discountednumber {
  color: #fbd665;
  font-size: 1em;
  font-weight: 400;
}
.usernameelementinner {
  margin-left: 144px;
  margin-top: -10px;
  width: 100%;
}
.usernameelementwrapper {
  display: flex;
  flex: row nowrap;
  width: calc(100% - 10px);
  max-width: 700px;
  position: absolute;
  top: 181px;
}
.deleteaccountelementwrapper {
  display: flex;
  flex: row nowrap;
  width: calc(100% - 10px);
  max-width: 700px;
  position: absolute;
  top: 181px;
}
#settingswrapper[max-width~="473px"] .deleteaccountbutton {
  position: absolute;
  background-color: #d41c35;
  outline: none;
  border: none;
  font-weight: bold;
  transition: all 0.25s;
  border-radius: 10px;
  color: #14181e;
  padding: 10px 0px 10px 0px;
  right: -28px !important;
  left: auto !important;
  width: 150px;
  top: -10px;
  margin: 0px 34px 8px 0px;
  -moz-filter: drop-shadow(0 0 10px #14181e);
  -ms-filter: drop-shadow(0 0 10px #14181e);
  filter: drop-shadow(0 0 10px #14181e);
}
