.loader-container {
  position: fixed;
  z-index: 100000001;
  width: 7vw;
  height: 7vh;
  min-width: 75px;
  min-height: 75px;
  left: 50%;
  top: 50%;
  margin: -3.5vh 0 0 -3.5vw;
  display: flex;
  text-align: center;
  justify-content: center;
  //background-color: rgba(222, 235, 247, 0.22);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.loader-container-b {
  position: relative;
  margin: -3px 0px 0px 18px;
  z-index: 100000001;
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  display: flex;
  text-align: center;
  justify-content: center;
  //background-color: rgba(222, 235, 247, 0.22);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.loader-container-b > div > img{
  width: 8px;
  height: 8px;
  min-width: 24px;
  min-height: 24px;
}
@media screen and (max-width: 840px) {
  #loadertext {
    position: absolute !important;
    width: 300px !important;
    overflow-wrap: break-word !important;
    white-space: pre-wrap !important;
  }
}
@media screen and (max-width: 481px) {
  .loader-container {
    left: calc(50% + 12px);
    top: calc(50% - 21px);
    margin: -3vh 0 0 -3vw;
    min-width: 0px;
    min-height: 0px;
    width: 0vw;
    height: 0vh;
  }
  #loadertext {
    margin-top: calc(125% + 100px) !important;
  }
}
#loadertext {
  color: #909090;
  height: 20px;
  z-index: 100000000;
  position: relative;
  white-space: nowrap;
  margin-top: 125%;
  font-size: 1.1em;
}
.loader3b,
.loader2b,
.loader1b {
  z-index: inherit;
  position: absolute;
  image-rendering: smooth;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.loader1b {
  animation-name: loading1b;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}
.loader2b {
  animation-name: loading2b;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}
.loader3b {
  animation-name: loading3b;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}
@keyframes loading3b {
  0% {
    -webkit-filter: none;
    opacity: 1;
  }
  40% {
    -webkit-filter: drop-shadow(0 -2px 8px #deebf7);
    filter: drop-shadow(0 -2px 8px #deebf7);
    opacity: 1;
  }
  100% {
    -webkit-filter: none;
    opacity: 1;
  }
}
@keyframes loading2b {
  0% {
    -webkit-filter: none;
    opacity: 0;
  }
  20% {
    -webkit-filter: none;
    opacity: 1;
  }
  21% {
    -webkit-filter: none;
    opacity: 1;
  }
  40% {
    -webkit-filter: none;
    opacity: 1;
  }
  41% {
    -webkit-filter: none;
    opacity: 0;
  }
  100% {
    -webkit-filter: none;
    opacity: 0;
  }
}
@keyframes loading1b {
  0% {
    -webkit-filter: none;
    opacity: 1;
  }
  20% {
    -webkit-filter: none;
    opacity: 1;
  }
  21% {
    -webkit-filter: none;
    opacity: 0;
  }
  40% {
    -webkit-filter: none;
    opacity: 0;
  }
  41% {
    -webkit-filter: none;
    opacity: 0;
  }
  100% {
    -webkit-filter: none;
    opacity: 0;
  }
}
.loader3b img {
  height: 100%;
  width: 100%;
  z-index: 1;
  min-width: 75px;
  min-height: 75px;
}
.loader2b img {
  height: 100%;
  width: 100%;
  z-index: 2;
  min-width: 75px;
  min-height: 75px;
}
.loader1b img {
  height: 100%;
  width: 100%;
  z-index: 3;
  min-width: 75px;
  min-height: 75px;
}
#overlayMAIN {
  /*   display: none;  Hidden by default */
  position: fixed; /* Stay in place */
  transition: all 0.5s;
  z-index: 100000000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}
#noclickMAIN {
  /*   display: none;  Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100000001; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: transparent;
}
