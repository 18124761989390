.ANT-heading,
.ANT-heading-trade,
.ANT-heading-conc {
  position: relative;
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */
  font-size: 1.6em;
  padding-bottom: 4px;
  text-align: left;
  margin-left: 3px;
  top: -64px;
  width: fit-content;
  font-weight: 400;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.ANT-heading-comments {
  position: relative;
  color: #fbd665;
  font-size: 1.6em;
  width: fit-content;

  padding-bottom: 4px;
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */

  text-align: left;
  top: -69px;
  font-weight: 400;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.ANT-heading-image {
  position: relative;
  margin: 0;
  color: #fbd665;
  width: fit-content;

  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */

  font-size: 1.6em;
  padding-bottom: 4px;
  text-align: left;
  top: -44px;
  font-weight: 400;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.ANTcardwrapper {
  display: flex;
  flex-flow: row;
  width: 100%;
  padding-left: 15px;
  padding-right: 14px;
  margin-bottom: 34px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.addtradeshowtradepictureswrappernon,
.addtradeshowtradepictureswrapper {
  position: absolute;
  width: calc(100% - 50px);
  height: 232px;
  display: flex;
  justify-content: left;
  border: 1px solid #14181e;
  border-radius: 8px;
  flex: row wrap;
  background-color: #1a2028;
  margin: 0px 280px 24px -4px;
  padding-right: 32px;
  overflow-x: scroll;
  &:active {
    .nopics {
      color: #deebf7;
    }
  }
}
.addtradeshowtradepictureswrapper::-webkit-scrollbar {
  border-radius: 10px;
  height: 9px;
  width: 9px;
  color: #1f2831;
  background-color: #1f2831;
}
.addtradeshowtradepictureswrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.addtradeshowtradepictureswrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #1a2028;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.addtradeshowtradepictureswrapper::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.addtradeshowtradepictureswrappernon::-webkit-scrollbar {
  border-radius: 10px;
  height: 9px;
  width: 9px;
  color: #1f2831;
  background-color: #1f2831;
}
.addtradeshowtradepictureswrappernon::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.addtradeshowtradepictureswrappernon::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #1a2028;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.addtradeshowtradepictureswrappernon::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}

.addtradeshowtradepictureswrapper {
  cursor: pointer;
  &:active {
    background-color: #1d242e;
  }
}
.addimage {
  margin: -10px 28px 0px 29px;
  border-top: 50px solid #28303c;
  position: relative;
  border-radius: 10px;
  width: 100%;
  background-color: #1f2831;
  padding: 0 25px 10px 25px;
  z-index: 0;
  display: block;
  height: 326px;
  right: 0px;
  box-shadow: 0 0 4pt 2pt #171c23;
}
.addimageinner {
  margin-top: -20px;
  height: 100%;
  margin-left: 4px;
}
.ANTcardwrapper[max-width~="815px"] {
  top: 0px;
  display: flex;
  flex-flow: row wrap;
  padding-left: 15px;
  padding-right: 14px;
  margin-bottom: 34px;
}
div[role="dialog"] {
  z-index: 2147483647 !important;
}
div[role="presentation"] {
  z-index: 2147483647 !important;
}
.ANTcardwrapper[min-width~="815px"] .addimage {
  margin: -10px 30px 0px 31px;
}
.MuiTypography-root {
  color: #deebf7 !important;
}
.MuiDateTimePickerToolbar-timeContainer > button {
  background-color: transparent !important;
}
.MuiDateTimePickerToolbar-dateContainer > button {
  background-color: transparent !important;
}
/* .MuiClockPicker-arrowSwitcher {
  display: none !important;
} */
.MuiPickersPopper-root {
  z-index: 2147483647 !important;
  position: absolute;
}
.MuiPaper-root {
  color: #deebf7 !important;
  background-color: #1a2028 !important;
  -webkit-filter: drop-shadow(0 0 8px #1a2028);
  -moz-filter: drop-shadow(0 0 8px #1a2028); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 8px #1a2028);
  filter: drop-shadow(0 0 8px #1a2028);
}
.MuiPickersArrowSwitcher-root {
  right: 6px !important;
  width: 80px;
}
.css-4f0ona-MuiClock-clock {
  background-color: #161b21 !important;
}
.MuiClockNumber-root {
  color: #deebf7 !important;
}
.MuiButtonBase-root {
  color: #deebf7 !important;
  background-color: #14181e !important;
}
.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium {
  background-color: transparent !important;
}
.MuiInputBase-input {
  border-radius: 60px !important;
}
label.Mui-focused {
  border-color: #deebf7 !important;
}
.MuiOutlinedInput-root > .Mui-focused {
  border-color: #deebf7 !important;
}
.MuiOutlinedInput-root {
  border-color: none !important;
  border-radius: 8px !important;
  &:hover {
    border-color: none !important;
  }
  .Mui-focused {
    border-color: #deebf7 !important;
  }
}
.MuiYearPicker-root::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #14181e;
  background-color: #14181e;
  border-radius: 10px;
}
.MuiYearPicker-root::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.MuiYearPicker-root::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.MuiYearPicker-root::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.MuiPickersDay-today:not(.Mui-selected) {
  border: 1px #fbd665 solid !important;
}
.MuiInput-underline:after {
  border-bottom-color: "#deebf7";
}
.MuiOutlinedInput-input {
  color: #fbd665 !important;
  height: 5px !important;
}
.MuiOutlinedInput-input::placeholder {
  color: #fa798b !important; /* Replace #yourColorHere with your desired color */
  opacity: 1 !important; /* CSS standard requires setting the opacity for the color to take full effect */
}
.column1PD {
  position: relative;
  top: -46px;
  width: 250px;
  left: 1.1vw;
}
.column2PD {
  position: relative;
  top: -58px;
  width: 250px;
  left: 1.1vw;
}

.inner-concluding {
  position: relative;
  top: -52px;
  left: 1.1vw;
  width: 250px;
}

.addtradeformwrapper {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 0px 0px;
  height: fit-content;
  right: 0;
  width: 100%;
}
.ANT-italics {
  position: relative;
  left: 10px;
  font-style: italic;
  font-size: 0.58em;
}
.optionsOptionals {
  position: relative;
  left: 356px;
  top: -214px;
  width: 50%;
}
.optionsOptionalsexit {
  position: relative;
  left: 356px;
  top: -244px;
  width: 50%;
}
.exitcommentsbox {
  top: 0px !important;
  position: relative;
  height: 156px !important;
  width: calc(100% - 36px) !important;
  margin: 0px 0px 0px 0px !important;
  overflow-y: auto !important;
  overflow-x: none !important;
  text-align: center !important;
  justify-content: center !important;
  padding: 6px 0px 0px 0px !important;
  background-color: #171c23 !important;
  border: 1px solid #14181e !important;
}

.ANTselectionfieldsDT {
  width: 100%;
  padding-right: 38px;
  color: #deebf7;
  z-index: 2147483647 !important;
}
.ANTselectionfields {
  width: 100%;
  padding-right: 38px;
  color: #deebf7;
}
.ANTselectionfields > div > input::placeholder {
  color: #fbd665;
  opacity: 1;
}

.ANTselectionfields
  > div:nth-of-type(1)
  > div:nth-of-type(1)
  > div:nth-of-type(1) {
  overflow-x: hidden;
  overflow-y: hidden;
  word-wrap: none;
  flex-flow: row wrap;
  align-items: left !important;
  justify-content: left !important;
}
.ANTselectionfields > .required::placeholder {
  color: #fa798b !important; /* Replace #yourColorHere with your desired color */
  opacity: 1 !important; /* CSS standard requires setting the opacity for the color to take full effect */
}
.ANTselectionfieldsRequiredOPTION {
  width: 50%;
  padding-right: 38px;
}
.ANTselectionfieldsNOTRequiredOPTION {
  width: 100%;
  padding-right: 38px;
}
.ANTselectionfieldsNOTRequiredOPTION > div > div > input::placeholder {
  color: #808080;
}
.ANTselectionfieldsNOTRequiredOPTION > div > input::placeholder {
  color: #808080;
}
.ANTselectionfieldsRequiredOPTION > div > input::placeholder {
  color: #fbd665;
  opacity: 1;
}
.ANTselectionfieldsRequiredOPTION > .required::placeholder {
  color: #fa798b !important; /* Replace #yourColorHere with your desired color */
  opacity: 1 !important; /* CSS standard requires setting the opacity for the color to take full effect */
}
.ANTselectionfieldsOPTION {
  width: 100%;
  padding-right: 38px;
}
.ANTselectionfieldsOPTION > div > input::placeholder {
  color: #fa798b;
  opacity: 1;
}
.ANTselectionfieldsOPTION > .required::placeholder {
  color: #fa798b !important; /* Replace #yourColorHere with your desired color */
  opacity: 1 !important; /* CSS standard requires setting the opacity for the color to take full effect */

}
.requiredordertype {
  color: #fa798b;
  opacity: 1;
}
.notrequired {
  color: #808080;
}
.requiredlegtype {
  color: #fa798b;
  opacity: 1;
}
.requiredlegtype2 {
  color: #fa798b;
  opacity: 1;
}
.requiredassetclass {
  color: #fa798b;
  opacity: 1;
}
.rdt {
  width: 100%;
}
.text-input-boxes {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 1px solid #14181e;
  border-radius: 8px !important;
  height: 38px;
  color: #fbd665;
  background-color: #1a2028;
  outline-style: none;
  font-weight: 400;
  width: 100%;
}
.text-input-boxes:focus {
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: all 0.1s;
  border-radius: 8px;
}
::-webkit-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
  color: #808080;
}
:-moz-placeholder {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
  color: #808080;
}
::-moz-placeholder {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
  color: #808080;
}
:-ms-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
  color: #808080;
}
.checkbox-style {
  background-color: #1a2028;
  border-color: #14181e;
}
.logtradebuttonwrapper {
  position: relative;
  margin-left: auto;
  margin-right: 280px;
  pointer-events: all;
}
#tooltipwujiaUdiuw {
  position: absolute !important;
}

.logtradebuttonwrapperinner {
  position: fixed;
  font-size: 1.7em;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 14px;
  border: none;
  text-align: center;
  background-color: #fbd665;
  transition: all 0.25s;
  color: #1a2028;
  padding: 15px;
  bottom: 66px;
  margin-top: -12px;
  z-index: 2147483647 !important;
  cursor: pointer;
  width: 195px;
}
.logtradebutton:disabled {
  background-color: #e4d5ae;
  color: #394453;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  display: inline-block;
  cursor: not-allowed;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
.logtradebutton {
  position: fixed;
  font-size: 1.7em;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 14px;
  border: none;
  text-align: center;
  background-color: #fbd665;
  color: #1a2028;
  padding: 15px;
  bottom: 66px;
  margin-top: -12px;
  z-index: 2147 !important;
  cursor: pointer;
  width: 195px;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  -webkit-animation: fadeindown2 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeindown2 1s; /* Firefox < 16 */
  animation: fadeindown2 1s;
  @keyframes fadeindown2 {
    from {
      opacity: 0;
      transform: translate(0px, 0px);
    }
    to {
      opacity: 1;
      transform: translate(0px, 10px);
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadeindown2 {
    from {
      opacity: 0;
      transform: translate(0px, 0px);
    }
    to {
      opacity: 1;
      transform: translate(0px, 10px);
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadeindown2 {
    from {
      opacity: 0;
      transform: translate(0px, 0px);
    }
    to {
      opacity: 1;
      transform: translate(0px, 10px);
    }
  }
}
.logtradebutton:hover {
  outline-style: none;
  border: none;
  color: #222a35;
  background-color: #ffe593;
}
.logtradebutton:focus {
  outline-style: none;
  border: none;
}
.main-labels {
  margin-top: 10px;
  margin-bottom: 8px;
}
.comment-label {
  padding: 25px;
  border-top: 50px solid #28303c;
  position: relative;
  top: 0px;
  border-radius: 10px;
  background-color: #1f2831;
  height: fit-content;
  min-height: 254px;
  width: 100%;
  z-index: 0;
  margin: 28px 28px 14px 28px;
  box-shadow: 0 0 4pt 2pt #171c23;
}
.comment-label {
  padding: 25px;
  border-top: 50px solid #28303c;
  position: relative;
  top: 0px;
  border-radius: 10px;
  background-color: #1f2831;
  height: fit-content;
  min-height: 254px;
  width: 100%;
  z-index: 0;
  margin: 28px 28px 12px 30px;
  box-shadow: 0 0 4pt 2pt #171c23;
}
.showmultiExecsBox {
  height: 156px !important;
  width: calc(100% - 36px) !important;
  margin: 0px 0px 0px 0px !important;
  overflow-y: auto !important;
  overflow-x: none !important;
  text-align: center !important;
  justify-content: center !important;
  padding: 6px 0px 0px 0px !important;
  background-color: #171c23 !important;
  border: 1px solid #14181e !important;
}
.commentsbox {
  word-break: break-all;
  border: 1px solid #14181e;
  border-radius: 8px;
  color: #fbd665;
  background-color: #1a2028;
  outline-style: none;
  position: relative;
  height: 156px;
  top: 0px;
  margin: -44px 0px 46px 0px;
  width: 100%;
  left: 0px;
  padding: 6px 10px 0px 10px;
}
.commentsbox::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.commentsbox::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.commentsbox::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #1a2028;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.commentsbox::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.commentsbox:focus {
  word-break: break-all;
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: 0.1s;
  border-radius: 8px;
}
.react-select__menu-list::-webkit-scrollbar {
  height: 10px !important;
  width: 10px !important;
  color: #1f2831 !important;
  background-color: #1f2831 !important;
}
.react-select__menu::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.react-select__menu::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #1a2028;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.react-select__menu::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.addimagewrapper {
  width: 100%;
  margin-right: 60px;
  -webkit-filter: drop-shadow(0 0 1px #171c23);
  -moz-filter: drop-shadow(0 0 1px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 1px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 1px #171c23);
}
//------ Custom Checkbox and radio button------
.container {
  z-index: 10;
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  left: -116px;
  cursor: pointer;
  width: 51px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.containerdis {
  z-index: 10;
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  left: -116px;
  cursor: default;
  width: 51px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checksyg {
  width: 53px;
}
.checksygBC {
  width: 93px;
}
.checksygBC2 {
  width: 91px;
}
.checksygBCC {
  width: 77px;
}
.checksygBP {
  width: 87px;
}
.checksygSCC {
  width: 138px;
}
.checksygSCP {
  width: 136px;
}
.checksygSt {
  width: 150px;
}
.container input {
  position: relative;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.containerdis input {
  position: relative;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox-label-noEntryS {
  position: absolute;
  top: 598px;
  left: 26px;
}
.checkbox-label-noExitS {
  position: absolute;
  top: 605px;
  left: 26px;
}
.order-type-style {
  padding-bottom: 0px;
}
.checkmark {
  position: absolute;
  left: 0px;
  margin-top: -2px;
  border-radius: 4px;
  height: 20px;
  width: 20px;
  background-color: #1a2028;
  border: 1px solid #14181e;
}
.checkmark-col1-1 {
  top: 1px;
}
.checkmark-col2 {
  position: absolute;
  left: 0px;
  top: 32px;
}

.checkmark-col1-2 {
  position: absolute;
  left: 0px;
  top: 4px;
}
.container:hover input ~ .checkmark {
  background-color: #2e3a48;
}
.container:hover input:disabled ~ .checkmark {
  background-color: #1a2028;
  cursor: default;
}
.container input:checked ~ .checkmark {
  -webkit-filter: drop-shadow(0 0 3px #171c23);
  -moz-filter: drop-shadow(0 0 3px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 3px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 3px #171c23);
  background-color: #2e3a48;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 5px;
  top: 0px;
  width: 7px;
  height: 14px;
  border: solid #fbd665;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.radiocircle {
  position: absolute;
  top: 1px;
  left: 0px;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  background-color: #1a2028;
  border: 2px solid #14181e;
  border-radius: 100%;
}
.radiocircledis {
  position: absolute;
  top: 1px;
  left: 0px;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  background-color: #1f2831;
  border: 2px solid #1a2028;
  border-radius: 100%;
}
.main-labels > label > div > div {
  border-radius: 8px !important;
}
.portaddinputs-linkbroker > div > div {
  border-radius: 8px !important;
}
.portaddinputs-linkbroker332 > div > div {
  border-radius: 8px !important;
}
.select-currency-symbol-inner > div:nth-child(2) > div {
  border-radius: 8px !important;
}
.select-currency-symbol-inner > div:nth-child(2) {
  border-radius: 8px !important;
}
.select-currency-symbol-inner > div:nth-child(1) {
  border-radius: 8px !important;
}
.currencySymbolSelectbuff > div > div {
  border-radius: 8px !important;
}
.move-container {
  position: relative;
  margin-top: 5px;
  padding-bottom: 25px;
  left: 116px;
  display: flex;
  width: 100%;
  flex-flow: row wrap;
}
#optionsselectwrapper {
  position: relative;
  left: 116px;
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: row wrap;
  padding: 0px 10px 10px 0px;
  margin: -22px -200000000px 26px 0px;
}
#optionsselectwrapper-upper {
  position: relative;
  left: 116px;
  display: flex;
  height: auto;
  width: calc(100% - 116px);
  flex-flow: row wrap;
  padding: 0px 10px 0px 0px;
  margin: -22px -200000000px 28px 0px;
}
#addassetClassButton {
  outline: none;
  background-color: transparent;
  border: none;
  color: #98b5eb;
  margin-top: 4px;
  height: 0;
  font-style: italic;
  transition: all 0.2s ease-in-out;
  text-decoration: underline;
}
.addassetClassButton2 {
  outline: none;
  background-color: transparent;
  border: none;
  color: #98b5eb;
  margin-top: 4px;
  height: 0;
  line-height: 0px;
  position: relative;
  font-style: italic;
  transition: all 0.2s ease-in-out;
  text-decoration: underline;
}
#allotherselectwrapper {
  position: relative;
  left: 0px;
  display: flex;
  width: auto;
  height: 100%;
  flex-flow: row wrap;
  padding: 10px 10px 10px 0px;
  margin: -34px 0px 19px 0px;
}
.buy-container {
  display: block !important;
  width: auto;
  position: relative;
  padding: 0px 0px 0px 0px;
  margin: 26px -36px 6px 116px;
}
.buy-call-container {
  display: block !important;
  width: 104px;
  position: relative;
  padding: 0px 6px 0px 6px;
  margin: 26px 0px 6px 0px;
}
.buy-put-container {
  display: block !important;
  width: 100px;
  position: relative;
  padding: 0px 6px 0px 6px;
  margin: 26px 0px 6px 0px;
}
.sell-container {
  display: block !important;
  position: relative;
  width: auto;
  padding: 0px 0px 0px 0px;
  margin: 26px 0px 6px 116px;
}
.optionsellcall-container {
  display: block !important;
  position: relative;
  width: 74px;
  padding: 0px 6px 0px 6px;
  margin: 26px 26px 6px 0px;
}
.optionstrategy-container {
  display: block !important;
  position: relative;
  width: 120px;
  padding: 0px 6px 0px 6px;
  margin: 26px 0px 6px 0px;
}
.optionsellcall-container-upper {
  display: block !important;
  position: relative;
  width: 80px;
  padding: 0px 6px 0px 6px;
  margin: 26px 26px 6px 0px;
}
.optionsellput-container {
  display: block !important;
  position: relative;
  width: 74px;
  padding: 0px 6px 0px 6px;
  margin: 26px 26px 6px 0px;
}
.exercised-super-container {
  margin: 4px 0px 0px 116px;
  height: 32px;
  padding: 0px;
  position: relative;
  display: flex;
  width: 320px;
}
.exercised-container {
  display: block !important;
  width: 110px;
  position: relative;
  padding: 0px 6px 0px 6px;
  margin: 0px 0px 6px 0px;
}
.expired-container {
  display: block !important;
  width: 110px;
  position: relative;
  padding: 0px 6px 0px 6px;
  margin: 0px 0px 6px 8px;
}
.assigned-container {
  display: block !important;
  width: 100px;
  position: relative;
  padding: 0px 6px 0px 6px;
  margin: 0px 0px 6px 0px;
}
.radio-label-buy,
.radio-label-sell {
  position: absolute;
  top: 0px;
  left: -90px;
  z-index: 9;
}
.radio-label-buy-dis,
.radio-label-sell-dis {
  position: absolute;
  top: 0px;
  left: -90px;
  z-index: 9;
  color: #808080;
}
.container:hover input ~ .radiocircle {
  background-color: #2e3a48;
}
.container:hover input:disabled ~ .radiocircle {
  background-color: #1a2028;
}
.containerdis:hover input ~ .radiocircle {
  background-color: none !important;
}
.container input:checked ~ .radiocircle {
  -webkit-filter: drop-shadow(0 0 3px #171c23);
  filter: drop-shadow(0 0 3px #171c23);
  background-color: #2e3a48;
}

.radiocircle:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .radiocircle:after {
  display: block;
}

.container .radiocircle:after {
  left: 5px;
  top: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fbd665;
}

.checksss {
  position: relative;
  padding-right: 0;
  left: 0px;
  top: -8px;
  width: 155px;
  word-wrap: none;
  white-space: nowrap;
}
.checksports {
  position: relative;
  padding-right: 0;
  left: 0px;
  top: -8px;
  margin-right: 6px;
  width: fit-content;
  max-width: 157px;
  word-wrap: none;
  white-space: nowrap;
}
.checkssspvt {
  position: relative;
  padding-right: 0;
  left: 0px;
  top: -4px;
  width: 155px;
  word-wrap: none;
  white-space: nowrap;
}
.checkssspv {
  position: relative;
  padding-right: 0;
  left: 0px;
  top: -14px;
  width: 155px;
  height: 20px;
  word-wrap: none;
  white-space: nowrap;
}
.checksssg {
  position: relative;
  padding-right: 0;
  margin-left: auto;
  top: -30px;
  margin-right: -112px;
  width: 120px;
  word-wrap: none;
}
.checksssg1 {
  position: relative;
  padding-right: 0;
  left: 26px;
  top: 0px;
  margin: 0px 216px 0px auto;
  width: 120px;
  word-wrap: none;
}
.checksssg2 {
  position: relative;
  padding-right: 0;
  margin-left: auto;
  left: 26px;

  top: 0px;
  margin-right: 216px;
  width: 120px;
  word-wrap: none;
}
.checksssg3 {
  position: relative;
  padding-right: 0;
  margin-left: auto;
  top: -52px;
  left: 26px;
  margin-right: 0px;
  width: 120px;
  word-wrap: none;
}
.checksssg4 {
  position: relative;
  padding-right: 0;
  margin-left: auto;
  top: -104px;
  margin-right: 0px;
  left: 26px;

  width: 120px;
  word-wrap: none;
}
.checksssg5 {
  position: relative;
  padding-right: 0;
  margin-left: auto;
  top: 0px;
  left: 26px;

  margin-right: 216px;
  width: 120px;
  word-wrap: none;
}
.checksssg6 {
  position: relative;
  padding-right: 0;
  margin-left: auto;
  left: 26px;

  top: -78px;
  margin-right: 0px;
  width: 120px;
  word-wrap: none;
}
#portshowalltext {
  position: absolute;
  color: #deebf7;
  margin: 0;
  padding: 0;
  display: block;
  top: 0px;
  left: 30px;
}
.porttext {
  position: absolute;
  color: #deebf7;
  margin: 0;
  padding: 0;
  display: block;
  top: 0px;
  left: 30px;
}
.porttext44 {
  position: absolute;
  color: #fff7cb;
  margin: 0;
  padding: 0;
  display: block;
  top: 0px;
  left: 30px;
}

#porttext445 {
  position: absolute;
  color: #98b5eb;
  width: 400px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  top: 270px;
}
#porttext445a {
  position: absolute;
  color: #98b5eb;
  margin: 0;
  padding: 0;
  display: block;
  top: 256px;
  padding: 0px 20px 0px 20px;
  text-align: center;
}
#porttext445b {
  position: absolute;
  color: #98b5eb;
  margin: 0;
  padding: 0;
  display: block;
  top: 272px;
  padding: 0px 20px 0px 2px;
  text-align: center;
}
.checksst {
  position: relative;
  padding: 0;
  left: 0px;
  top: -14px;
  width: 144px;
  margin: 0;
  word-wrap: none;
  white-space: nowrap;
}

.mandatory {
  color: #fa798b;
  font-size: 14px;
  position: absolute;
  right: 40px;
}
.mandatory1 {
  color: #fa798b;
  font-size: 14px;
  margin-left: 10px;
}
.checkheader {
  position: relative;
  padding-right: 0;
  left: 7px;
  top: -1px;
  width: 32px;
  word-wrap: none;
  margin: 4px;
}
.checkheader-2 {
  position: relative;
  padding-right: 0;
  left: 7px;
  top: -1px;
  width: 32px;
  word-wrap: none;
  cursor: pointer;
  margin: 4px;
}
textarea {
  resize: none;
}
.usesltpforwexitwrapper {
  display: flex;
  position: relative;
  height: 0px;
  top: -50px;
  left: 216px;
}
.usesltpforwexitwrappero {
  display: flex;
  position: relative;
  height: 0px;
  top: -50px;
  left: 236px;
}
/*--------------------start tooltip styles--------------------*/
.toolTipTextDT {
  opacity: 0;
  flex-wrap: wrap;
}
#exitExecutionModal {
  padding: 4px 0px 0px 36px !important;
}

#exitExecutionModal,
#multiExecutionModal {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap%22");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  transition: none;
  display: block;
  width: 746px;
  z-index: 2147483647 !important;
  padding: 4px 0px 0px 36px !important;
  margin: -311px 0px 0px -373px;
  overflow: visible;
  top: 50%;
  font-size: 1em;
  left: 50%;
  height: 622px;
  border-radius: 14px;
  color: #deebf7;
  background-color: #222a35;
  outline-style: none;
  border: none;
  flex-flow: row wrap;
  flex: auto;
  padding: 0px 0px 0px 36px;
  border-top: 50px solid #1a2028;
}

.multiExecutionModalOPTIONS {
  margin: -382px 0px 0px -375px !important;
  top: 50% !important;
  left: 50% !important;
  height: 764px !important;
}
.toolTipIcon {
  position: relative;
  width: 20px;
  margin-left: 10px;
}
.toolTipWrap {
  position: relative;
  height: 22px;
  display: flex;
  width: 100%;
}
.iconwrapper {
  display: flex;
}
.toolTipWrap {
  &:hover {
    .toolTipIcon {
      color: #fbd665;
    }
    .toolTipTextDT {
      opacity: 1;
    }
  }
}

/*--------------------end tooltip styles--------------------*/
.executionbutton2 {
  position: relative;
  width: 160px !important;
  left: 230px !important;
  top: 12px;
  font-weight: 600 !important;
  border-radius: 10px !important;
  background-color: #fbd665;
}
#centerit {
  text-align: center;
}
.executionButton {
  position: relative;
  width: 160px !important;
  left: 0px !important;
  top: 10px;
  font-weight: 600 !important;
  border-radius: 10px !important;
  background-color: #fbd665;
  color: #1a2028;
  font-family: "Roboto";
  font-weight: 400;
  border: none;
  text-align: center;
  z-index: 200 !important;
  cursor: pointer;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  font-size: 1em;
  font-weight: 500;
  border-radius: 8px;
  padding-top: 2px;
  bottom: 66px;
  margin-top: 12px;
  margin-right: auto !important;
  margin-left: 24px;
  height: 40px;
  width: calc(100% - 36px);
  transition: 0.25s ease-in-out;
  right: 0px;
}
.executionButton:disabled {
  background-color: #e4d5ae;
  color: #394453;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}

.executionButtonInModals {
  background-color: #fbd665;
  color: #1a2028;
  font-family: "Roboto";
  font-weight: 400;
  border: none;
  text-align: center;
  z-index: 200 !important;
  cursor: pointer;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  font-size: 1em;
  font-weight: 500;
  border-radius: 10px;
  padding-top: 2px;
  bottom: 66px;
  margin-top: 24px;
  margin-bottom: 20px;
  margin-right: auto !important;
  height: 40px;
  width: calc(100% - 36px);
  right: 0px;
}
.executionButtonInModals:disabled {
  background-color: #e4d5ae;
  color: #394453;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}

.ANTselectionfieldsinner {
  display: flex;
  flex-flow: row nowrap;
  margin-right: 132px;
}
.ANTcardwrapper[max-width~="1337px"] .ANTselectionfieldsinner {
  margin-right: 0px;
}
.select-symbol {
  z-index: 100000000;
}

.clearExecutionButton {
  background-color: #bbbbbb;
  color: #1a2028;
  font-family: "Roboto";
  font-weight: 400;
  border: none;
  text-align: center;
  z-index: 200 !important;
  cursor: pointer;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  font-size: 1em;
  font-weight: 400;
  border-radius: 10px;
  padding-top: 2px;
  bottom: 66px;
  position: relative;
  width: 100px !important;
  left: 0px !important;
  top: 10px;
  font-weight: 600 !important;
  bottom: 0px;
  height: 40px;
  width: calc(100% - 36px);
}
.clearExecutionButton:disabled {
  background-color: #d6d6d6;
  color: #394453;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  &:hover {
    background-color: #d6d6d6;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}

.clearExecutionButton:hover {
  outline-style: none;
  border: none;
  color: #222a35;
  background-color: #d6d6d6;
}
/* .executionButton {
  font-size: 1em;
  font-weight: 400;
  border-radius: 10px;
  transition: all 0.25s;
  padding-top: 2px;
  bottom: 66px;
  margin-top: 5px;
  margin-right: 10px;
  height: 40px;
  width: 150px;
} */

.viewExerciseColor {
  background-image: linear-gradient(
    to bottom right,
    #c7b8ff,
    #9a7eff
  ) !important;
  &:hover {
    background-image: linear-gradient(
      to bottom right,
      #c7b8ff,
      #8464fa
    ) !important;
  }
}
.expiredcolor {
  background-image: linear-gradient(
    to bottom right,
    #6e7c8d,
    #556171
  ) !important;
  &:hover {
    background-image: linear-gradient(
      to bottom right,
      #6e7c8d,
      #47515e
    ) !important;
  }
}
.assignedcolor {
  background-image: linear-gradient(
    to bottom right,
    #ff8080,
    #ce5f5f
  ) !important;
  &:hover {
    background-image: linear-gradient(
      to bottom right,
      #ff8080,
      #a14b4b
    ) !important;
  }
}
.exercisedcolor {
  background-image: linear-gradient(
    to bottom right,
    #55f8a6,
    #37a870
  ) !important;
  &:hover {
    background-image: linear-gradient(
      to bottom right,
      #55f8a6,
      #379164
    ) !important;
  }
}
.editExecutionButton {
  background-image: linear-gradient(to bottom right, #ffe593, #8f783e);
  color: #1a2028;
  font-family: "Roboto";
  border: none;
  text-align: center;
  font-size: 1em;
  font-weight: 400;
  border-radius: 8px;
  bottom: 66px;
  margin-top: 5px;
  height: 26px;
  max-width: 270px;
  min-width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 2px 8px 1px 8px;
  letter-spacing: 0.05px;
}

.executionButton:hover,
.executionButtonInModals:hover {
  outline-style: none;
  border: none;
  color: #222a35;
  background-color: #ffe593;
}

.editExecutionButton:hover {
  outline-style: none;
  border: none;
  color: #222a35;
  background-color: #ffe593;
  background-image: linear-gradient(to bottom right, #fbd665, #8f783e);
}
// SAVE EXECUTION BUTTON
.saveExecutionButton {
  background-color: #fbd665;
  color: #1a2028;
  font-family: "Roboto";
  font-weight: 400;
  border: none;
  text-align: center;
  z-index: 200 !important;
  cursor: pointer;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  font-size: 1em;
  font-weight: 400;
  border-radius: 10px;
  padding-top: 2px;
  bottom: 66px;
  position: absolute;
  width: 90px !important;
  right: 36px !important;
  bottom: 18px;
  font-weight: 600 !important;
  height: 40px;
  width: calc(100% - 36px);
  margin-left: 24px;
}
.saveExecutionButton:disabled {
  background-color: #e4d5ae;
  color: #394453;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
.saveExecutionButton:hover {
  outline-style: none;
  border: none;
  background-color: #ffe593;
}
// -------------

// CANCEL EXECUTION BUTTON
.cancelExecutionButton {
  background-color: #bbbbbb;
  color: #1a2028;
  font-family: "Roboto";
  font-weight: 400;
  border: none;
  text-align: center;
  z-index: 200 !important;
  cursor: pointer;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  font-size: 1em;
  font-weight: 400;
  border-radius: 10px;
  padding-top: 2px;
  bottom: 66px;
  position: absolute;
  width: 96px !important;
  right: 150px !important;
  bottom: 18px;
  font-weight: 600 !important;
  height: 40px;
  width: calc(100% - 36px);
}
.cancelExecutionButton:hover {
  outline-style: none;
  border: none;
  background-color: #d6d6d6;
}
.checksantcurretntime {
  position: relative;
  z-index: 0;
  padding-right: 0;
  left: 166px;
  right: 116px;
  top: -26px;
  width: max-content;
  height: 0px;
  word-wrap: none;
  white-space: nowrap;
}
.checksantcurretntimeexp {
  position: relative;
  z-index: 0;
  padding-right: 0;
  left: 148px;
  right: 132px;
  top: -26px;
  width: max-content;
  height: 0px;
  word-wrap: none;
  white-space: nowrap;
}
// -------------
.editExecutionModalInputs {
  text-align: left !important;
  height: 322px;
}
.lotSizeErrorMsg {
  position: relative;
  left: 0px;
  margin-left: auto;
  float: right;
}
.pointValueErrorMsg {
  position: relative;
  left: 0px;
  margin-left: auto;
  float: right;
}
.modalSymbolHeader {
  z-index: 1000;
  width: auto;
  max-width: 300px;
  margin-top: -44px;
  margin-left: -8px;
  margin-bottom: 20px;
  position: relative;
  color: #fbd665;
  font-size: 1.6em;
  padding-bottom: 4px;
  text-align: left;
  font-weight: 300;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.modalSymbolHeader3 {
  z-index: 1000;
  width: 340px;
  margin-top: -39px;
  margin-left: -12px;
  margin-bottom: 20px;
  position: relative;
  color: #fbd665;
  font-size: 1.6em;
  padding-bottom: 4px;
  text-align: left;
  font-weight: 300;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.modalSymbolHeader2 {
  z-index: 1000;
  margin-top: -46px;
  margin-left: 20px;
  margin-bottom: 16px;
  left: 230px;
  position: absolute;
}
.deleteExecutionButton {
  position: absolute;
  color: rgb(238, 73, 73);
  outline: none;
  font-weight: bold;
  border-radius: 8px;
  transition: all 0.25s;
  height: 28px;
  width: 20px;
  margin-left: 10px;
  font-size: 24px;
  transform: rotate(45deg);
  margin-top: 1px;
  cursor: pointer;
  padding: 2px 0px 1px 0px;
}
.deleteExecutionButton:hover {
  outline-style: none;
  border: none;
  -webkit-filter: drop-shadow(0 0 3px #fcc6c5);
  -moz-filter: drop-shadow(0 0 3px #fcc6c5); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 3px #fcc6c5);
  filter: drop-shadow(0 0 3px #fcc6c5);
}
.editExecutionButtonText {
  padding-right: 35px;
}
#assetClassButton_mte {
  padding: 6px 10px 6px 10px;
  display: block !important;
  margin: 2px 10px 10px 0px;
  border: 1px solid #fbd665;
  border-radius: 8px;
  outline: none;
  color: #deebf7;
  font-weight: 300;
  background-color: #1a2028;
  -moz-box-shadow: inset 0 0 4px #ffe593;
  -webkit-box-shadow: inset 0 0 4px #ffe593;
  box-shadow: inset 0 0 4px #ffe593;
  &:hover {
    cursor: pointer;
    outline-style: none;
    background-color: #ffe593;
    border: 1px solid #ffe593;
    color: #14181e;
  }
}
#assetClassButton_mteselected {
  padding: 6px 10px 6px 10px;
  display: block !important;
  margin: 2px 10px 10px 0px;
  border: 1px solid #fbd665;
  border-radius: 8px;
  outline: none;
  color: #14181e;
  font-weight: 300;
  background-color: #ffe593;
  &:hover {
    cursor: pointer;
    outline-style: none;
    background-color: #ffe593;
    border: 1px solid #ffe593;
  }
}
#assetClassButton_mteonly {
  padding: 6px 10px 6px 10px;
  display: block !important;
  margin: 2px 10px 10px 0px;
  border: 1px solid #ffe593;
  border-radius: 8px;
  outline: none;
  color: #14181e;
  font-weight: 300;
  background-color: #ffe593;
  transition: all 0.1s ease-in-out;
  cursor: default;
}
#assetClassButtonWrapper_mte {
  width: 90%;
  margin-right: -200000000px;
  display: flex;
  flex-flow: row wrap;
}

// Overlay and popover css

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after,
.bs-popover-start > .popover-arrow::after {
  border-left-color: #14181e;
}
.popover {
  z-index: 107000000000000;
}

// ------------------ REPONSIVE DESIGN ------------------
@media screen and (max-width: 1440px) and (max-height: 800px) {
  .addtradeformwrapper {
    //font-size: 0.85em; //makes everything this size, important!!
  }
  .addtradeformwrapper[max-width~="529px"] .ANT-heading {
    padding-bottom: 4px;
    margin-left: 1px;
    top: -62px;
  }
  .addtradeformwrapper[max-width~="529px"] .ANT-heading-trade {
    padding-bottom: 4px;
    margin-left: 1px;
    top: -62px;
  }
  .addtradeformwrapper[max-width~="529px"] .ANT-heading-image {
    padding-bottom: 4px;
    margin-left: -3px;
    top: -42px;
  }
  .addtradeformwrapper[max-width~="529px"] .ANT-heading-conc {
    padding-bottom: 4px;
    margin-left: 1px;
    top: -62px;
  }
  .addtradeformwrapper[max-width~="529px"] .ANT-heading-comments {
    padding-bottom: 4px;
    margin-left: -3px;
    top: -66px;
  }
}

@media screen and (max-width: 646px) {
  .editExecutionModalInputs {
    //left: 20px;
    width: calc(100% + 12px) !important;
  }
  .exitcommentsbox {
    width: calc(100% - 26px) !important;
  }
  .showmultiExecsBox {
    width: calc(100% - 26px) !important;
  }
  .optionsOptionals {
    position: relative;
    right: calc(-50% + 2px) !important;
    left: auto !important;
    top: -214px;
    width: 50%;
  }
  .optionsOptionalsexit {
    position: relative;
    right: calc(-50% + 2px) !important;
    left: auto !important;
    top: -214px;
    width: 50%;
  }
  #exitExecutionModal,
  #multiExecutionModal {
    @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap%22");
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    position: absolute;
    transition: none;
    display: block;
    width: calc(100% - 64px);
    z-index: 2147483647 !important;
    padding: 4px 0px 0px 26px !important;
    margin: -311px 0px 0px calc(-50% + 32px) !important;
    overflow: visible;
    top: 50%;
    left: 50% !important;
    height: 622px;
    border-radius: 14px;
    color: #deebf7;
    background-color: #222a35;
    outline-style: none;
    border: none;
    flex-flow: row wrap;
    flex: auto;
    padding: 0px 0px 0px 36px;
    border-top: 50px solid #1a2028;
  }
}

@media screen and (max-width: 515px) {
  .exitcommentsbox {
    height: 168px !important;
  }
  .showmultiExecsBox {
    height: 168px !important;
  }
  .optionsOptionals {
    position: relative;
    right: calc(0%) !important;
    left: auto !important;
    top: 0px;
    width: calc(100% + 30px);
  }
  .optionsOptionalsexit {
    position: relative;
    right: calc(0%) !important;
    left: auto !important;
    top: 0px;
    width: calc(100% + 30px);
  }
  .editExecutionModalInputs {
    overflow-y: scroll;
    overflow-x: hidden;
    width: calc(100% - 26px) !important;
    padding: 0px;
  }
  .editExecutionModalInputs::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    color: #1f2831;
    background-color: #1f2831;
    border-radius: 8px;
  }
  .editExecutionModalInputs::-webkit-scrollbar-track {
    border-radius: 8px;
    border: #a4a9b1;
    color: #1f2831;
    background-color: #1f2831;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .editExecutionModalInputs::-webkit-scrollbar-thumb {
    border-radius: 8px;
    color: #14181e;
    background-color: #14181e;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  .editExecutionModalInputs::-webkit-scrollbar-corner {
    background-color: #1f2831;
    border-bottom-right-radius: 5px;
  }
  .ANTselectionfieldsRequiredOPTION515 {
    width: calc(100% + 30px);
    padding-right: 38px;
  }
  .ANTselectionfieldsDT515 {
    width: calc(100% + 30px);
    padding-right: 38px;
  }
  .ANTselectionfields515 {
    width: calc(100% + 30px);
    padding-right: 38px;
  }
  .ANTselectionfieldsNOTRequiredOPTION515 {
    width: calc(100% + 30px);
    padding-right: 38px;
  }
  .saveExecutionButton {
    font-size: 0.85em;
    width: 60px !important;
    right: 26px !important;
    bottom: 16px;
    font-weight: 600 !important;
    height: 30px;
    transition: 0.25s ease-in-out;
    margin-left: 24px;
  }
  .executionButton {
    position: absolute;
    width: 120px !important;
    left: auto !important;
    font-size: 0.85em;
    bottom: 16px !important;
    top: auto;
    margin-top: 12px;
    margin-right: auto !important;
    margin-left: 24px;
    height: 30px;
    left: 80px !important;
  }
  .cancelExecutionButton {
    font-size: 0.85em;
    font-weight: 400;
    border-radius: 10px;
    padding-top: 2px;
    bottom: 66px;
    position: absolute;
    width: 70px !important;
    right: 104px !important;
    bottom: 16px;
    font-weight: 600 !important;
    height: 30px;
  }
  .clearExecutionButton {
    font-size: 0.85em;
    font-weight: 400;
    border-radius: 10px;
    padding-top: 2px;
    bottom: 66px;
    position: absolute;
    width: 60px !important;
    left: 26px !important;
    top: auto !important;
    font-weight: 600 !important;
    bottom: 16px !important;
    height: 30px;
  }
}
@media screen and (max-width: 385px) {
  .cancelExecutionButton {
    display: none;
  }
}
@media screen and (max-width: 481px) {
  .checksssg3 {
    position: relative;
    padding-right: 0;
    margin-left: auto;
    top: -76px !important;
    left: 26px;
    margin-right: 0px;
    width: 120px;
    word-wrap: none;
  }
  .checksssg4 {
    position: relative;
    padding-right: 0;
    margin-left: auto;
    top: -76px !important;
    margin-right: 0px;
    left: 26px;
    width: 120px;
    word-wrap: none;
  }
  .checksssg6 {
    position: relative;
    padding-right: 0;
    margin-left: auto;
    left: 26px;
    top: -76px !important;
    margin-right: 0px;
    width: 120px;
    word-wrap: none;
  }
}
.addtradeformwrapper[max-width~="529px"] .multiExecutionModal {
  //font-size: 0.85em !important;
}

.addtradeformwrapper[max-width~="529px"] {
  //font-size: 0.85em !important;
}
.addtradeformwrapper[max-width~="529px"] .ANT-heading {
  padding-bottom: 4px;
  margin-left: 10px;
  top: -62px;
}
.addtradeformwrapper[max-width~="529px"] .ANT-heading-trade {
  padding-bottom: 4px;
  margin-left: 10px;
  top: -62px;
}
.addtradeformwrapper[max-width~="529px"] .ANT-heading-image {
  padding-bottom: 4px;
  margin-left: -4px;
  top: -47px;
}
.addtradeformwrapper[max-width~="529px"] .ANT-heading-conc {
  padding-bottom: 4px;
  margin-left: 10px;
  top: -62px;
}
.addtradeformwrapper[max-width~="529px"] .ANT-heading-comments {
  padding-bottom: 4px;
  margin-left: -4px;
  top: -75px;
}
.addtradeformwrapper[max-width~="529px"] .ANTcardwrapper {
  top: 0px;
  display: flex;
  flex-flow: row wrap;
  padding-left: 11px;
  padding-right: 11px;
  margin-top: -1px;
  margin-bottom: 34px;
}
.addtradeformwrapper[max-width~="529px"] .comment-label {
  padding: 34px 25px 25px 25px;
  top: 0px;
  width: 100%;
  margin: 28px 24px 32px 25px;
}
.addtradeformwrapper[max-width~="529px"] .input-card-styles {
  padding: 21px 20px 0px 10px !important;
}
.addtradeformwrapper[max-width~="529px"] .addimage {
  margin: -12px 24px 0px 25px;
  width: 100%;
  padding: 6px 25px 10px 25px;
  height: 326px;
  right: 0px;
}
.addtradeformwrapper[max-width~="529px"] .addimageinner {
  //font-size: 0.85em;
  margin-top: -24px !important;
}
.addtradeformwrapper[max-width~="529px"] .commentsbox {
  //font-size: 0.85em;
  margin-top: -50px !important;
}
.input-card-styles {
  max-width: calc(33% - 22px);
}
.ANTcardwrapper[max-width~="815px"] .input-card-styles {
  max-width: 100%;
}
.ANTcardwrapper[max-width~="379px"] .input-card-styles {
  max-width: 100%;
  width: calc(100% - 10px) !important;
}
