.history-table::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
  border-radius: 10px;
}
.history-table::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.history-table::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.history-table::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.history-table > table > tbody > tr {
  padding: 8px 22px 8px 6px;
}
.history-table > table > tbody > tr > td {
  color: #deebf7;
  border-bottom: 1px solid #44546a;
  overflow: hidden;
  white-space: nowrap;
  padding: 8px 22px 8px 6px;
}
.history-table > table > thead > tr > th:nth-child(2),
.history-table > table > tbody > tr > td:nth-child(2) {
  display: none;
  width: -1;
}
.history-table > table > thead > tr > th:first-child,
.history-table > table > tbody > tr > td:first-child {
  margin-right: 0px;
  padding-right: 0px;
}
.history-table > table > thead > tr > th:nth-child(3),
.history-table > table > tbody > tr > td:nth-child(3) {
  margin-left: 0px;
  padding-left: 0px;
}

.history-table > table > thead > tr {
  color: #fff7cb;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.history-table > table > thead > tr > th {
  white-space: nowrap;
  padding: 11px 22px 11px 6px;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0px;
  z-index: 10000;
  background-color: #1a2028;
  font-weight: 400;
  &:hover {
    color: #fbd665;
    cursor: pointer;
  }
}

.history-table > table > tbody > tr:first-child > td {
  color: #deebf7;
  border-top: 1px solid #44546a;
  z-index: -1;
  overflow: hidden;
  white-space: nowrap;
  padding: 8px 22px 8px 6px;
}
.confidence-veryhigh {
  color: #2dff49;
  filter: drop-shadow(0 0 1px #2dff49);
  -webkit-filter: drop-shadow(0 0 1px #2dff49);
}
.confidence-high {
  color: #78ff2a;
  filter: drop-shadow(0 0 1px #78ff2a);
  -webkit-filter: drop-shadow(0 0 1px #78ff2a);
}
.confidence-medium {
  color: #fff241;
  filter: drop-shadow(0 0 1px #fff241);
  -webkit-filter: drop-shadow(0 0 1px #fff241);
}
.confidence-low {
  color: #ff9735;
  filter: drop-shadow(0 0 1px #ff9735);
  -webkit-filter: drop-shadow(0 0 1px #ff9735);
}
.confidence-verylow {
  color: #ff2222;
  filter: drop-shadow(0 0 1px #ff2222);
  -webkit-filter: drop-shadow(0 0 1px #ff2222);
}

.commissionscolstyles {
  color: #fa798b;
}
.symbolcolstyles {
  color: #98b5eb;
  filter: drop-shadow(0 0 1px #98b5eb);
  -webkit-filter: drop-shadow(0 0 1px #98b5eb);
}
.strategyscolstyles {
  color: #ffe593;
  filter: drop-shadow(0 0 2px #ffe593);
  -webkit-filter: drop-shadow(0 0 2px #ffe593);
  border: 1px solid #ffe593;
  padding: 5px 6px 5px 6px;
  border-radius: 9px;
  margin: 0px 4px 0px 4px;
}
.timeframescolstyles {
  color: #deebf7;
  filter: drop-shadow(0 0 2px #deebf7);
  -webkit-filter: drop-shadow(0 0 2px #deebf7);
  border: 1px solid #deebf7;
  padding: 5px 6px 5px 6px;
  border-radius: 9px;
}
.emotionsscolstyles {
  color: #98b5eb;
  filter: drop-shadow(0 0 2px #98b5eb);
  -webkit-filter: drop-shadow(0 0 2px #98b5eb);
  border: 1px solid #98b5eb;
  padding: 5px 6px 5px 6px;
  border-radius: 9px;
  margin: 0px 4px 0px 4px;
}
.physicalscolstyles {
  color: #d898eb;
  filter: drop-shadow(0 0 2px #d898eb);
  -webkit-filter: drop-shadow(0 0 2px #d898eb);
  border: 1px solid #d898eb;
  padding: 5px 6px 5px 6px;
  border-radius: 9px;
  margin: 0px 4px 0px 4px;
}
.marketcolstyles {
  color: #c7b8ff;
  filter: drop-shadow(0 0 2px #c7b8ff);
  -webkit-filter: drop-shadow(0 0 2px #c7b8ff);
  border: 1px solid #c7b8ff;
  padding: 5px 6px 5px 6px;
  border-radius: 9px;
}
.mistakescolstyles {
  color: #fa798b;
  filter: drop-shadow(0 0 2px #fa798b);
  -webkit-filter: drop-shadow(0 0 2px #fa798b);
  border: 1px solid #fa798b;
  padding: 5px 6px 5px 6px;
  border-radius: 9px;
  margin: 0px 4px 0px 4px;
}
.tagscolstyles {
  color: #55f8a6;
  filter: drop-shadow(0 0 1px #55f8a6);
  -webkit-filter: drop-shadow(0 0 2px #55f8a6);
  border: 1px solid #55f8a6;
  padding: 5px 6px 5px 6px;
  border-radius: 9px;
  margin: 0px 4px 0px 4px;
}
.history-table > table > tbody > tr:hover {
  box-shadow: 0px 0px 8px 3px #556171;
  background-color: #1a2028;
  cursor: pointer;
}

.view-history-label {
  position: absolute;
  display: block;
  white-space: nowrap;
  margin: 0px;
  margin-bottom: -40px;
  padding: 0;
  color: #fbd665;
  font-size: 1.6em;
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */
  top: 31px;
  left: 52px;
  text-align: left;
  justify-content: left;
  font-weight: 400;
  z-index: 10000;
}
.upgrade-history-label {
  position: absolute;
  display: block;
  white-space: nowrap;
  margin: 0px;
  margin-bottom: -40px;
  padding: 0;
  color: #fff7cb;
  font-size: 1em;
  top: 31px;
  left: 52px;
  text-align: left;
  justify-content: left;
  font-weight: 300;
  z-index: 10000;
}
.historywrappa[max-width~="529px"] .containviewhist {
  margin: 0px 24px 0px -2px;
}
// open trade --------------

.open-trade {
  color: #fbd665;
  filter: drop-shadow(0 0 2px #55f8a6);
  -webkit-filter: drop-shadow(0 0 2px #55f8a6);
  border: 1px solid #fbd665;
  padding: 5px 6px 5px 6px;
  border-radius: 8px;
  font-weight: 300;
}
.open-trade2 {
  color: #fbd665;
  filter: drop-shadow(0 0 2px #55f8a6);
  -webkit-filter: drop-shadow(0 0 2px #55f8a6);
  border: 1px solid #fbd665;
  padding: 5px 6px 5px 6px;
  border-radius: 8px;
  font-weight: 300;
  margin-left: 27.5%;
}
//end open trade ----------------

.orderType-cell-component {
  padding: 5px 6px 5px 6px;
  border-radius: 9px;
  font-weight: 300;
}
.orderType-cell-component-multi {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0px 6px 0px 6px;
  border-radius: 9px;
  font-weight: 300;
  margin: -2px 0px -2px 0px;
}
.orderType-cell-component-text {
  font-size: 0.75em;
  margin: -4px 0px 0px 0px;
}

.orderType-Long {
  color: #55f8a6;
  filter: drop-shadow(0 0 2px #55f8a6);
  -webkit-filter: drop-shadow(0 0 2px #55f8a6);
  border: 1px solid #55f8a6;
}
.orderType-Long-multi {
  color: #55f8a6;
  filter: drop-shadow(0 0 2px #55f8a6);
  -webkit-filter: drop-shadow(0 0 2px #55f8a6);
  border: 1px solid #55f8a6;
  &:hover {
    .radio-label-buy-tooltip {
      z-index: 10000000000 !important;
      height: auto;
      width: 56px;
      text-transform: none !important;
      margin-left: 0px;
      position: absolute;
      color: #deebf7;
      display: block;
      background-color: #14181e;
      border-radius: 8px;
      padding: 0px 10px 3px 11px;
      top: 46px;
      font-size: 17px;
      font-weight: 300;
    }
    .radio-label-buy-tooltip-tip {
      left: 22px;
    }
  }
}
.orderType-Short {
  color: #fa798b;
  filter: drop-shadow(0 0 2px #fa798b);
  -webkit-filter: drop-shadow(0 0 2px #fa798b);
  border: 1px solid #fa798b;
}
.orderOutcome-Win {
  color: #55f8a6;
  font-weight: 420;
}
.orderOutcome-Loss {
  color: #fa798b;
  font-weight: 420;
}
.orderOutcome-BE {
  color: #818181;
  font-weight: 420;
}
.orderOutcome-Win-notbold {
  color: #55f8a6;
  font-weight: 300;
}
.orderOutcome-Loss-notbold {
  color: #fa798b;
  font-weight: 300;
}
.orderOutcome-BE-notbold {
  color: #818181;
  font-weight: 300;
}
.orderType-Deposit {
  color: #55f8a6;
  filter: drop-shadow(0 0 2px #c1cad4);
  -webkit-filter: drop-shadow(0 0 2px #c1cad4);
  border: 1px solid #c1cad4;
}
.orderType-Withdrawal {
  color: #fa798b;
  filter: drop-shadow(0 0 2px #c1cad4);
  -webkit-filter: drop-shadow(0 0 2px #c1cad4);
  border: 1px solid #c1cad4;
}
.orderType-FundingPayment {
  color: #98b5eb;
  filter: drop-shadow(0 0 2px #4c5867);
  -webkit-filter: drop-shadow(0 0 2px #4c5867);
  border: 1px solid #4c5867;
}
.orderType-Approval {
  color: #98b5eb;
  filter: drop-shadow(0 0 2px #4c5867);
  -webkit-filter: drop-shadow(0 0 2px #4c5867);
  border: 1px solid #4c5867;
}
.orderType-Commit {
  color: #55f8a6;
  filter: drop-shadow(0 0 2px #4c5867);
  -webkit-filter: drop-shadow(0 0 2px #4c5867);
  border: 1px solid #4c5867;
}
.orderType-NFT {
  color: #fbd665;
  filter: drop-shadow(0 0 2px #98b5eb);
  -webkit-filter: drop-shadow(0 0 2px #98b5eb);
  border: 1px solid #98b5eb;
}
.orderType-Self {
  color: #808080;
  filter: drop-shadow(0 0 2px #4c5867);
  -webkit-filter: drop-shadow(0 0 2px #4c5867);
  border: 1px solid #4c5867;
}
.checkmark-col2 {
  position: absolute;
  left: 0px;
  top: 3px;
}
.deleteTradeButton {
  transition: all 0.1s ease-in-out;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  margin-bottom: -40px;
  padding: 5px 10px 0px 10px;
  border-radius: 100px;
  color: #fa798b;
  font-size: 1.4em;
  top: 29px;
  width: 40px;
  height: 40px;
  left: 170px;
  z-index: 10000;
  cursor: pointer;
}
.deleteTradeButton:hover {
  transition: all 0.1s ease-in-out;
  background-color: #1c2229;
}
.editTradeButton {
  transition: all 0.1s ease-in-out;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  margin-bottom: -40px;
  padding: 3px 10px 0px 10px;
  border-radius: 100px;
  color: #deebf7;
  font-size: 1.4em;
  top: 34px;
  height: 40px;
  width: 40px;
  left: 234px;
  z-index: 10000;
  cursor: pointer;
}
.editTradeButton:hover {
  transition: all 0.1s ease-in-out;
  background-color: #1c2229;
}
.tagTradeButton {
  transition: all 0.1s ease-in-out;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  margin-bottom: -40px;
  padding: 5px 10px 0px 10px;
  border-radius: 100px;
  color: #fff7cb;
  font-size: 1.4em;
  top: 29px;
  height: 40px;
  width: 40px;
  left: 224px;
  z-index: 10000;
  cursor: pointer;
}
.tagTradeButton:hover {
  transition: all 0.1s ease-in-out;
  background-color: #1c2229;
}
.shareTradeButton {
  transition: all 0.1s ease-in-out;
  position: relative;
  top: -40;
  color: #deebf7;
  font-size: 1.4em;
  height: 10px;
  width: 10px;
  cursor: pointer;
}
.shareTradeButton:hover {
  transition: all 0.1s ease-in-out;
  background-color: #1c2229;
}
.upgradelogbookbutton {
  transition: all 0.1s ease-in-out;
  position: absolute;
  margin-bottom: -40px;
  padding: 5px 10px 0px 10px;
  border-radius: 100px;
  color: #98b5eb;
  font-size: 1em;
  font-style: italic;
  top: 34px;
  height: 40px;
  width: fit-content;
  left: 266px;
  z-index: 10000;
  cursor: pointer;
}
.tagselectionfields2 {
  width: calc(100% - 28px);
  padding-right: 38px;
  margin: 0px 0px 0px 36px;
  position: absolute;
  top: 20px;
  text-align: left;
  color: #fff7cb;
  z-index: 2;
}
.tagselectionfields22 {
  width: calc(100% - 28px);
  padding-right: 38px;
  margin: 0px 0px 0px 36px;
  position: absolute;
  top: 104px;
  text-align: left;
  color: #fff7cb;
  z-index: 1;
}
.zindex1 {
  z-index: 1 !important;
}
#tag-trades-class {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  display: none;
  width: 350px;
  text-align: center;
  z-index: 100000001;
  margin: -180px 0px 0px -175px;
  overflow: visible;
  top: 50%;
  font-size: 1em;
  left: 50%;
  height: 360px;
  border-radius: 14px;
  color: #deebf7;
  background-color: #222a35;
  outline-style: none;
  border: none;
  flex-flow: row wrap;
  flex: auto;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: 50px solid #1a2028;
}
#delete-trades-class {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  display: none;
  width: 350px;
  text-align: center;
  z-index: 100000001;
  margin: -95px 0px 0px -175px;
  overflow: visible;
  top: 50%;
  font-size: 1em;
  left: 50%;
  height: 190px;
  border-radius: 14px;
  color: #deebf7;
  background-color: #222a35;
  outline-style: none;
  border: none;
  flex-flow: row wrap;
  flex: auto;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: 50px solid #1a2028;
}
.choose-columnsclosebutton {
  position: absolute;
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: 200;
  font-size: 26px;
  border-radius: 8px;
  color: rgb(238, 73, 73);
  padding: 3px 9px 2px 10px;
  top: -46px;
  right: 8px;
  transition: all 0.25s;
}
.choose-columnsclosebutton:hover {
  outline-style: none;
  border: none;
  -webkit-filter: drop-shadow(0 0 4px #fcc6c5);
  -moz-filter: drop-shadow(0 0 4px #fcc6c5); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 4px #fcc6c5);
  filter: drop-shadow(0 0 4px #fcc6c5);
}
.choose-columnsclosebutton:focus {
  outline: none;
}
.deletetradesclosebutton {
  position: absolute;
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: 300;
  font-size: 26px;
  border-radius: 10px;
  color: rgb(238, 73, 73);
  padding: 3px 10px 2px 10px;
  top: -46px;
  right: 8px;
  transition: all 0.25s;
}
.deletetradesclosebutton:hover {
  outline-style: none;
  border: none;
  -webkit-filter: drop-shadow(0 0 4px #fcc6c5);
  -moz-filter: drop-shadow(0 0 4px #fcc6c5); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 4px #fcc6c5);
  filter: drop-shadow(0 0 4px #fcc6c5);
}
.deletetradesclosebutton:focus {
  outline: none;
}
.deletetradesheading {
  position: absolute;
  color: #fbd665;
  font-size: 1.6em;
  display: flex;
  text-align: left;
  top: -44px;
  font-weight: 300;
  z-index: 0;
}
.checkBoxContainWrapper {
  padding: 40px 24px 0px 24px;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}
@media screen and (max-width: 613px) {
}

.tagtradesubmitbutton {
  position: relative;
  background-color: #fbd665;
  outline: none;
  border: none;
  font-weight: bold;
  z-index: 0;
  position: absolute;
  border-radius: 10px;
  color: #14181e;
  padding: 8px 20px 8px 20px;
  right: 4px;
  width: 126px;
  bottom: 14px;
  margin: -22px 32px 8px 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.tagtradesubmitbutton:hover {
  outline-style: none;
  border: none;
  background-color: #ffe593;
}
.tagtradesubmitbutton:focus {
  outline: none;
}
.tagtradesubmitbutton:disabled {
  transition: none;
  background-color: #e4d5ae;
  color: #394453;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
.deletetradesubmitbutton {
  position: relative;
  background-color: #ff8080;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  color: #14181e;
  padding: 8px 40px 8px 32px;
  left: 32px;
  width: 110px;
  top: 5px;
  margin: -22px 32px 8px 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.deletetradesubmitbutton:hover {
  outline-style: none;
  border: none;
  background-color: #ffa09f;
}
.deletetradesubmitbutton:focus {
  outline: none;
}
.tagtradenobutton {
  top: auto !important;
  position: absolute !important;
  bottom: 14px !important;
  left: 40px;
  z-index: 0 !important;
}
.tagtradenobutton:hover {
  outline-style: none;
  border: none;
  background-color: #d6d6d6;
}
.tagtradenobutton:focus {
  outline: none;
}
.deletetradenobutton,
.tagtradenobutton {
  position: relative;
  background-color: #bbbbbb;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  color: #14181e;
  width: 110px;
  top: 5px;
  padding: 8px 0px 8px 0px;
  right: 0px;
  margin: -22px 0px 8px 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.deletetradenobutton:hover {
  outline-style: none;
  border: none;
  background-color: #d6d6d6;
}
.deletetradenobutton:focus {
  outline: none;
}

.confirmedit {
  width: 160px;
}
.addpicturebutton {
  height: 80%;
  transition: height 0.25s;
  display: inline-block;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 20px 0px 0px 20px;
  padding: 0px;
  border: 3px solid #44546a;
  border-radius: 10px;
  z-index: 200000000;
  cursor: pointer;
  &:active {
    background-color: #1d242e;
  }
  &:hover {
    border: 3px solid #5c708d;
  }
}
.addpicturebuttoninnerplus {
  color: #deebf7;
  cursor: pointer;
  margin-top: 38px;
  margin-left: 66px;
}
.addpicturebuttoninner {
  width: 168px;
  height: 0px;
  font-size: 60px;
  cursor: pointer;
}
.tablemodalbuttonwrapper {
  display: flex;
  flex-flow: row wrap;
  position: absolute;
  bottom: 14px;
  right: 4px;
  z-index: -1;
}
.canceltradehistroytablebutton {
  outline: none;
  position: relative;
  border: none;
  z-index: 0;
  background-color: #bbbbbb;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  color: #14181e;
  width: 130px;
  padding: 10px 0px 10px 0px;
  margin: 0px 28px 5px 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.canceltradehistroytablebutton:hover {
  outline-style: none;
  border: none;
  background-color: #d6d6d6;
}
.canceltradehistroytablebutton:focus {
  outline: none;
}
.paddingSL {
  margin-left: 6px;
  top: -8px !important;
}

#choose-columns-class {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  display: none;
  width: 700px;
  text-align: center;
  z-index: 100000001;
  margin: -255px 0px 0px -350px;
  top: 50%;
  font-size: 1em;
  left: 50%;
  height: 510px;
  border-radius: 14px;
  color: #deebf7;
  background-color: #222a35;
  outline-style: none;
  border: none;
  flex-flow: row wrap;
  flex: auto;
  align-items: center;
  justify-content: center;
  border-top: 50px solid #1a2028;
}
.historywrappa {
  width: 100%;
  display: flex;
}

.nopics {
  font-size: 2em;
  margin: auto;
  font-style: italic;
  color: #a4a9b1;
}
.checkboxlonatiner {
  position: relative;
  margin: 10px;
  padding: 0;
  display: flex;
  width: 150px;
  white-space: nowrap;
  left: -50px;
}
.exitpicbutton {
  transition: height 0.25s;
  background-color: #1a2028;
  position: absolute;
  padding: 3px 0 0px 0px;
  margin: -10px auto auto auto;
  height: 42px;
  width: 42px;
  border-radius: 30px;
  z-index: 1000000001;
  color: #deebf7;
  font-weight: 200;
  font-size: 2.2em;
  right: calc(50%);
  border: 1px solid #3a424d;
  display: inline-block;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  cursor: pointer;
}
.exitpicbutton:after {
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
  position: absolute;
  transition: none;
  top: -12px;
  left: 11px !important;
  color: #7689a1;
  font-size: 36px;
  height: 10px;
  width: 10px;
}
.exitpicbutton:hover:after {
  color: #94aac7;
}
.innerpicturewrapper {
  display: flex;
  align-items: right;
  justify-content: right;
}

.deletepicbutton {
  transition: height 0.25s;
  background-color: #1a2028;
  position: relative;
  padding: 0px 0px 0px 10px;
  height: 30px;
  width: 30px;
  right: 0px;
  margin: 10px 0px 0px -22px;
  border-radius: 30px;
  border: 1px solid #3a424d;
  z-index: 1000000001;
  color: #deebf7;
  font-weight: 200;
  font-size: 1.6em;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  cursor: pointer;
}
.deletepicbutton:after {
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
  position: absolute;
  transition: none;
  top: -11px;
  left: 0.24em !important;
  color: #7689a1;
  font-size: 1.1em;
  height: 10px;
  width: 10px;
}
.deletepicbutton:hover:after {
  color: #94aac7;
}
.showtradedatamegawrapper {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  display: flex;
  z-index: -2;
  flex-flow: row wrap;
  justify-content: center;
}

.changeexecutions {
  position: relative;
  width: 100%;
  background-color: #1a2028;
  height: 70%;
  margin: 0px 32px 0px 0px;
  border-radius: 8px;
  -moz-box-shadow: 0 0 14px #14181e;
  -webkit-box-shadow: 0 0 14px #14181e;
  box-shadow: 0 0 14px #14181e;
}
.sortingcaret {
  margin-left: 8px;
  font-size: 1.3em;
  color: #a4a9b1;
}
.showtradedatacol1 {
  display: block;
  flex-grow: 1;
  font-size: 1.2em;
  height: 100%;
  justify-content: left;
  align-items: left;
  max-width: 50%;
}
.stackeddata {
  display: flex;
  padding: 4px;
  width: calc(100% - 20px);
  padding: 20px 4px 14px 14px;
  border-radius: 10px;
  margin: 6px 50px 6px 0px;
  border: 1px solid #1a2028;
}
.stackeddata3 {
  display: flex;
  position: relative;
  height: max-content;
  padding: 20px 4px 14px 14px;
  border-radius: 10px;
  margin: 6px 20px 6px 0px;
  width: calc(100% - 20px);
  border: 1px solid #1a2028;
}
.stackeddataPLdoll {
  display: flex;
  padding: 4px 4px 100px 4px;
  width: 100%;
}
.showtradedatacol3 {
  display: block;
  flex-grow: 1;
  flex: column wrap;
  font-size: 1.2em;
  height: 100%;
  padding-top: 1px;
  justify-content: left;
  align-items: left;
  max-width: 600px;
}
.datatypecolor {
  color: #deebf7;
  font-weight: 300;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  white-space: nowrap;
}
.selectedelems {
  position: relative;
  color: #fbd665;
  padding: 4px 10px 4px 10px;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #232932, #14181e);
  -webkit-filter: drop-shadow(0 0 4px #14181e);
  -moz-filter: drop-shadow(0 0 4px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 4px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 4px #14181e);
  background-color: #1a2028;
  margin: 0px 0px 6px 8px;
}
.datatypecolorB {
  color: #deebf7;
  font-weight: 600;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  white-space: nowrap;
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in;
  -webkit-transition: opacity 200ms ease-in;
  -moz-transition: opacity 200ms ease-in;
  -ms-transition: opacity 200ms ease-in;
  -o-transition: opacity 200ms ease-in;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.flushrighttextinner {
  display: flex;
  flex-flow: row wrap;
  margin: 0px 0px -14px auto;
  text-align: right;
  color: #fbd665;
  justify-content: right;
}
.flushrighttextinnermul {
  display: flex;
  flex-flow: row wrap;
  margin: -4px 0px -14px auto;
  text-align: right;
  color: #fbd665;
  justify-content: right;
}
.flushrighttext {
  display: block;
  text-align: right;
  padding-right: 50px;
  margin-left: 20px;
  font-weight: 400;
  width: 100%;
  height: max-content;
  min-height: 36px;
  color: #fbd665;
  white-space: nowrap;
  cursor: text;
}
::-webkit-file-upload-button {
  cursor: pointer;
  height: auto;
  width: auto;
}

.addpictureinput {
  width: 176px;
  height: 176px;
  position: absolute;
  top: 0px;
  left: 0px;
  color: transparent;
  background-color: transparent;
  opacity: 0;
  cursor: pointer;
}
.addpictureinputlarge {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: transparent;
  background-color: transparent;
  opacity: 0;
  cursor: pointer;
}
.OverlayModal {
  position: fixed; /* Stay in place */
  transition: all 0.5s;
  z-index: 2147483647; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}
.OverlayModal {
  position: fixed; /* Stay in place */
  transition: all 0.5s;
  z-index: 2147483647; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}
.OverlayModalBlurred {
  position: fixed; /* Stay in place */
  transition: all 0.5s;
  z-index: 2147483647; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  backdrop-filter: blur(7px);
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}
#choose-modal-class {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  display: none;
  width: 725px;
  text-align: center;
  z-index: 100000001;
  margin: -363px 0px 0px -363px;
  top: 50%;
  font-size: 1em;
  left: 50%;
  height: 725px;
  border-radius: 8px;
  color: #deebf7;
  background-color: #222a35;
  -webkit-filter: drop-shadow(0 0 5px #556171);
  -moz-filter: drop-shadow(0 0 5px #556171); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 5px #556171); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 5px #556171);
  outline-style: none;
  border: none;
  flex-flow: row wrap;
  flex: auto;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: 50px solid #1a2028;
  overflow: none;
}

.checkheaderseuss {
  position: relative;
  padding-right: 0;
  left: 11px;
  top: -1px;
  width: 10px;
  word-wrap: none;
}
.checkBoxContain1 {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  height: 370px;
  width: 100px;
  top: -28px;
  left: 160px;
}
.tooltip {
  z-index: 2147483647 !important;
  border-radius: 10px !important;
}
.checkBoxContain2 {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  height: 370px;
  width: 100px;
  top: -28px;
  left: 322px;
}
.checkBoxContain3 {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  height: 370px;
  width: 100px;
  top: -28px;
  left: 470px;
}
.choosecolumnsubmitbutton {
  position: relative;
  background-color: #fbd665;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  color: #14181e;
  padding: 8px 0px 8px 0px;
  left: 104px;
  width: 110px;
  top: -60px;
  margin: 0px 28px 8px 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.choosecolumnsubmitbutton:hover {
  outline-style: none;
  border: none;
  background-color: #ffe593;
}
.choosecolumnsubmitbutton:focus {
  outline: none;
}
.choosecolumnsubmitbutton:disabled {
  background-color: #e4d5ae;
  color: #394453;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
.choosecolumnnobutton {
  position: relative;
  background-color: #bbbbbb;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  color: #14181e;
  width: 110px;
  top: -60px;
  padding: 8px 0px 8px 0px;
  left: 78px;
  margin: 0px 0px 8px 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.choosecolumnnobutton:hover {
  outline-style: none;
  border: none;
  background-color: #d6d6d6;
}
.choosecolumnnobutton:focus {
  outline: none;
}
.choosecolumnALLbutton {
  position: relative;
  background-color: #fff7cb;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  color: #14181e;
  width: 122px;
  top: -60px;
  padding: 8.5px 0px 9px 0px;
  left: -50px;
  margin: 0px 8px 8px 0px;
  -webkit-filter: drop-shadow(0 0 3px #14181e);
  -moz-filter: drop-shadow(0 0 3px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 3px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 3px #14181e);
}
.choosecolumnALLbutton:hover {
  outline-style: none;
  border: none;
  background-color: #fff9e0;
}
.choosecolumnALLbutton:focus {
  outline: none;
}
.choosecolumnresetbutton {
  position: relative;
  background-color: #fff7cb;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  color: #14181e;
  width: 110px;
  top: -60px;
  padding: 8px 0px 8px 0px;
  left: -76px;
  margin: 0px 0px 8px 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.choosecolumnresetbutton:hover {
  outline-style: none;
  border: none;
  background-color: #fff9e0;
}
.choosecolumnresetbutton:focus {
  outline: none;
}
.historycolumnchoosewrapper {
  margin-left: 86px;
  margin-top: 1px;
}
.historycolumnchoosewrapper:hover {
  .radio-label-buy-tooltip {
    z-index: 10000000000 !important;
    height: auto;
    width: 150px;
    text-transform: none !important;
    margin: 19px 0px 0px -100px;
    position: fixed;
    color: #deebf7;
    animation: none;
    display: block;
    background-color: #14181e;
    border-radius: 8px;
    padding: 0px 10px 3px 11px;
    font-size: 17px;
    font-weight: 300;
    transition: 0.25s;
  }
  .radio-label-buy-tooltip-tip {
    left: 92px;
  }
}
.pagination {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  display: flex;
  flex-flow: row;
  z-index: 300000000;
  position: relative;
  margin: 0px;
  margin-left: auto;
  top: 35px;
  margin-right: 7px;
  width: 700px;
  height: 34px;
}
.containviewhist[max-width~="1130px"] .pagination {
  position: absolute;
  display: flex;
  margin-left: 0;
  left: 534px;
  top: 35px;
}

.containviewhist[max-width~="1130px"] .history-table {
  top: -4px;
}
@media screen and (max-width: 1440px) and (max-height: 800px) {
  .containviewhist {
    //font-size: 0.9em;
  }
  .pageindexwrapper {
    margin-top: 4px !important;
  }
  .view-history-label {
    position: absolute;
    display: block;
    white-space: nowrap;
    margin: 0px;
    margin-bottom: -40px;
    padding: 0;
    color: #fbd665;
    font-size: 1.6em;
    top: 32px;
    left: 52px;
    text-align: left;
    justify-content: left;
    font-weight: 300;
    z-index: 10000;
  }
  .paginationbuttons2 {
    padding: 8px 10px 8px 17px !important;
  }
  .paginationbuttonslg {
    padding: 8px 10px 8px 12px !important;
  }
  .paginationbuttonslg2 {
    padding: 8px 10px 8px 13px !important;
  }
  .paginationbuttons1 {
    padding: 8px 10px 8px 15px !important;
  }
  .containviewhist[max-width~="1130px"] .pageindexwrapper {
    width: 112px;
    margin: 6px 17px 0px 0px;
    white-space: nowrap;
  }
}

@media screen and (max-width: 950px) and (max-height: 800px) {
  .containviewhist {
    //font-size: 0.9em;
  }
  .history-table > table > tbody > tr > td {
    padding: 8px 18px 8px 4px;
  }
  .history-table > table > thead > tr > th {
    padding: 12px 18px 12px 4px;
  }
  .checkheader-2 {
    width: 26px !important;
  }
  .history-table > table > tbody > tr:first-child > td {
    padding: 12px 18px 12px 4px;
  }
}

.containviewhist {
  overflow: hidden;
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  display: flex;
  justify-content: right;
  flex-flow: row wrap;
  flex: auto;
  margin: 0px 28px 0px 0px;
  width: 100%;
  overflow: hidden;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.pageindexwrapper {
  width: 125px;
  margin: 3px 17px 0px 0px;
  white-space: nowrap;
}
.viewtablecols {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: 28px 0px -14px 28px;
  border-top: 50px solid #28303c;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  position: relative;
  padding: 0px 0px 25px 0px;
  border-radius: 10px;
  background-color: #1f2831;
  align-items: center;
  justify-content: center;
  border-collapse: separate;
  border-spacing: 0px;
  box-shadow: 0 0 4pt 2pt #171c23;
  color: #deebf7;
  top: -38px;
  width: 100%;
  height: calc(100% - 32px);
}
.paginationbuttons1,
.paginationbuttons2 {
  transition: all 0.1s ease-in-out;
  position: relative;
  margin-bottom: -40px;
  margin-right: 4px;
  margin-left: 4px;
  border-radius: 100px;
  color: #deebf7;
  font-size: 1.3em;
  width: 40px;
  top: -6px;
  height: 40px;
  z-index: 10000;
  cursor: pointer;
}
.paginationbuttons1 {
  padding: 4px 6px 4px 14px;
}
.paginationbuttons2 {
  padding: 4px 5px 4px 15px;
}
.paginationbuttons1:hover,
.paginationbuttons2:hover {
  transition: all 0.1s ease-in-out;
  background-color: #1c2229;
}
.paginationbuttonslg {
  transition: all 0.1s ease-in-out;
  position: relative;
  margin-right: 4px;
  margin-left: 4px;
  margin-bottom: -40px;
  padding: 4px 10px 4px 10px;
  border-radius: 100px;
  color: #deebf7;
  font-size: 1.3em;
  width: 40px;
  height: 40px;
  top: -6px;
  z-index: 10000;
  cursor: pointer;
}
.paginationbuttonslg:hover {
  transition: all 0.1s ease-in-out;
  background-color: #1c2229;
}
.paginationbuttonslg2 {
  padding: 4px 8px 4px 12px;
  margin-right: 24px;
}
.checkmarkvh {
  position: absolute;
  left: 0px;
  border-radius: 4px;
  height: 21px;
  margin-top: 2px;
  z-index: -10;
  width: 21px;
  cursor: pointer;
  background-color: #1a2028;
  border: 2px solid #14181e;
}

.container:hover input ~ .checkmarkvh {
  background-color: #2e3a48;
  cursor: pointer;
}
.checkmark-col1-3 {
  position: absolute;
  left: 0px;
  top: 0px;
}
.container input:checked ~ .checkmarkvh {
  -webkit-filter: drop-shadow(0 0 3px #171c23);
  -moz-filter: drop-shadow(0 0 3px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 3px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 3px #171c23);
  background-color: #2e3a48;
}

.checkmarkvh:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmarkvh:after {
  display: block;
}

.container .checkmarkvh:after {
  left: 5px;
  top: 0px;
  cursor: pointer;
  width: 7px;
  height: 14px;
  border: solid #fbd665;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkheader {
  position: relative;
  padding-right: 0;
  left: 11px;
  top: -1px;
  width: 10px;
  word-wrap: none;
}
.checkheader-2 {
  position: relative;
  padding-right: 0;
  left: 7px;
  top: -1px;
  width: 32px;
  word-wrap: none;
  margin: 4px;
}
@media print {
  body * {
    visibility: hidden;
  }
  .containviewhist * {
    visibility: visible;
  }
  .containviewhist {
    position: absolute;
    display: table;
    width: 100%;
    margin-right: auto;
    left: 0;
    top: 0;
    height: 2000px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }
  .history-table > table > thead > tr > th:nth-child(2),
  .history-table > table > tbody > tr > td:nth-child(2) {
    display: none;
    width: -1;
  }
  .history-table > table > thead > tr > th:first-child,
  .history-table > table > tbody > tr > td:first-child {
    margin-right: 0px;
    padding-right: 0px;
    width: -1;
    display: none;
  }
  .history-table > table > tbody > tr > td {
    overflow: hidden;
    align-items: center !important;
    justify-content: center !important;
  }
  .pagination {
    display: none;
  }
  .view-history-label {
    top: 6px;
  }
}
.printButton {
  transition: all 0.2s ease-in-out;
  position: relative;
  color: #deebf7;
  font-size: 1.5em;
  margin: 3px 0px 2px -12px;
  z-index: 10000;
  cursor: pointer;
}
.pagination {
  position: relative;
  left: 110px;
}
.printButton:hover {
  -webkit-filter: drop-shadow(0 0 5px #d9e7fa);
  -moz-filter: drop-shadow(0 0 5px #d9e7fa); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 5px #d9e7fa);
  filter: drop-shadow(0 0 5px #d9e7fa);
}
.printButtonWrapper {
  width: 0px;
  position: relative;
  left: 43px;
}
.chooseColumnsButton {
  transition: all 0.2s ease-in-out;
  position: relative;
  color: #deebf7;
  font-size: 1.5em;
  margin: 1px 0px 0px 0px;
  padding: 0;
  z-index: 10000;
  cursor: pointer;
  display: block;
  right: 0px;
  height: 26px;
}
.chooseColumnsButton:hover {
  -webkit-filter: drop-shadow(0 0 5px #d9e7fa);
  -moz-filter: drop-shadow(0 0 5px #d9e7fa); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 5px #d9e7fa);
  filter: drop-shadow(0 0 5px #d9e7fa);
  /*   -webkit-animation: spin 1s ease-in-out;
  -moz-animation: spin 1s ease-in-out;
  animation: spin 1s ease-in-out;
  animation-iteration-count: 1; */
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(20deg);
  }
  20% {
    -webkit-transform: rotate(-16deg);
  }
  30% {
    -webkit-transform: rotate(12deg);
  }
  40% {
    -webkit-transform: rotate(-9deg);
  }
  50% {
    -webkit-transform: rotate(6deg);
  }
  60% {
    -webkit-transform: rotate(-4deg);
  }
  70% {
    -webkit-transform: rotate(2deg);
  }
  85% {
    -webkit-transform: rotate(-2deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
/* #logBookEfficiencyBarOuter{
  width: 200;
  display: block;
  text-align: left;
} */
#logBookEfficiencyBar {
  box-shadow: inset 0px 0px 6px #14181e;
  height: 9px;
  border-radius: 10px;
  display: flex;
  width: 100%;
  margin: 0px 0px 0px 0px;
}
#logBookEfficiencyBarInner {
  box-shadow: inset 0px 0px 3px #14181e;
  margin-top: 0.03em;
  height: 8px;
  border-radius: 10px;
  color: #00b82e;
}
