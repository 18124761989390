.navbar[max-width~="481px"] .contentLB {
  padding: 0px 0px 0px 32px;
}
.navbar[max-width~="481px"] .contentLB2 {
  padding: 0px 0px 0px 32px;
}
$heightToAdjust: 900px;
$widthToAdjust: 1680px;

@media screen and (max-width: $widthToAdjust), (max-height: $heightToAdjust) {
  html,
  body,
  body > #root,
  .appgodwrapper,
  .appvicegodwrapper {
    height: 100%;
    overflow: hidden;
  }
  /*     html,
  body,
  body > div,
  body > div > div,
  body > div > div > div {
    height: 100%;
    overflow: hidden;
  } */
}

html,
body {
  overflow: hidden;
}
.tooltip {
  scale: 1;
}
// App super container
.appsupercontainer {
  overflow: hidden;
  height: calc(100%);
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}
.appvicegodwrapperedit {
  height: 100%;
  overflow: hidden;
  width: calc(100% - 340px);
}
.contentLB {
  color: #deebf7;
  z-index: 10;
  overflow: scroll;
  display: flex;
  flex-flow: row;
  flex: auto;
  padding: 0px 0px 16px 32px;
  top: 0px;
  width: 100%;
  //height: calc(100vh + 140px);
  -webkit-animation: fadeindown 1s linear 0s normal !important; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeindown 1s linear 0s normal !important; /* Firefox < 16 */
  animation: fadeindown 1s linear 0s normal !important;

  @keyframes fadeindown {
    0% {
      opacity: 0;
      transform: translate(0px, -15px);
    }
    100% {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadeindown {
    0% {
      opacity: 0;
      transform: translate(0px, -15px);
    }
    100% {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadeindown {
    0% {
      opacity: 0;
      transform: translate(0px, -15px);
    }
    100% {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }
}
.contentLB::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.contentLB::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.contentLB::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.contentLB::-webkit-scrollbar-corner {
  background-color: #1f2831;
}
.thumb-vertical-PT {
  background-color: #14181e;
  border-radius: 10px;
  z-index: 100000;
}
.track-vertical-PT {
  background-color: #1a2028;
  right: 0px;
  top: 0px;
  height: 100vh;
}

.tooptip-i-style5,
.tooptip-i-style4,
.tooptip-i-style44,
.tooptip-i-style3,
.tooptip-i-style2,
.tooptip-i-style {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  color: #fbd665;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background-color: #161b21;
  z-index: 100000000;
  font-weight: 400;
  cursor: default;
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: #101317;
    color: #fbd665;
    cursor: pointer;
  }
}
.tooptip-i-style77 {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  color: #fbd665;
  height: 16px;
  width: 16px;
  padding: 1px 6px 1px 6px;
  line-height: 15px;
  border-radius: 4px;
  background-color: #161b21;
  z-index: 100000000;
  font-weight: 400;
  cursor: default;
  transition: all 0.1s ease-in-out;
  font-size: 0.46em;
  top: 13px;
  position: relative;
  margin-left: 10px;
  margin-right: 4px;
  -webkit-background-clip: none !important;
  -moz-background-clip: none !important; /* Old versions of Firefox */
  -ms-background-clip: none !important; /* Internet Explorer/Edge */
  background-clip: none !important;
  -webkit-text-fill-color: #fbd665 !important;
  -moz-text-fill-color: #fbd665 !important; /* Old versions of Firefox */
  -ms-text-fill-color: #fbd665 !important; /* Internet Explorer/Edge */

  &:hover {
    background-color: #101317;
    color: #fbd665;
    cursor: pointer;
  }
}
.tooptip-i-style80 {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  color: #fbd665 !important;
  height: 14px;
  width: 14px;
  padding: 0px 5px 0px 5px;
  border-radius: 4px;
  background-color: #1a2028;
  z-index: 0;
  font-weight: 400;
  cursor: default;
  transition: all 0.1s ease-in-out;
  font-size: 0.75em;
  &:hover {
    background-color: #1f2831;
    color: #fbd665;
    cursor: pointer;
  }
  top: -1px;
  position: relative;
  margin-left: 6px;

  -webkit-text-fill-color: #fbd665;
  -moz-text-fill-color: #fbd665; /* Old versions of Firefox */
  -ms-text-fill-color: #fbd665; /* Internet Explorer/Edge */
  margin-right: 4px;
}
.tooptip-i-style79 {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  color: #fbd665 !important;
  height: 14px;
  width: 14px;
  padding: 0px 5px 0px 5px;
  border-radius: 4px;
  background-color: #14181e;
  z-index: 0;
  font-weight: 400;
  cursor: default;
  transition: all 0.1s ease-in-out;
  font-size: 0.72em;
  &:hover {
    background-color: #101317;
    color: #fbd665;
    cursor: pointer;
  }
  top: -1px;
  position: relative;
  margin-left: 6px;

  -webkit-text-fill-color: #fbd665;
  -moz-text-fill-color: #fbd665; /* Old versions of Firefox */
  -ms-text-fill-color: #fbd665; /* Internet Explorer/Edge */
  margin-right: 4px;
}
.tooptip-i-style7 {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  color: #fbd665 !important;
  height: 16px;
  width: 16px;
  padding: 1px 6px 1px 6px;
  border-radius: 4px;
  background-color: #14181e;
  z-index: 0;
  font-weight: 400;
  cursor: default;
  transition: all 0.1s ease-in-out;
  font-size: 0.75em;
  &:hover {
    background-color: #101317;
    color: #fbd665;
    cursor: pointer;
  }
  top: -1px;
  position: relative;
  margin-left: 6px;

  -webkit-text-fill-color: #fbd665;
  -moz-text-fill-color: #fbd665; /* Old versions of Firefox */
  -ms-text-fill-color: #fbd665; /* Internet Explorer/Edge */
  margin-right: 4px;
}
.tooptip-i-style6 {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  color: #fbd665;
  height: 16px;
  width: 16px;
  padding: 1px 6px 1px 6px;
  border-radius: 4px;
  background-color: #161b21;
  z-index: 100000000;
  font-weight: 400;
  cursor: default;
  transition: all 0.1s ease-in-out;
  font-size: 0.75em;
  &:hover {
    background-color: #101317;
    color: #fbd665;
    cursor: pointer;
  }
}

.tooptip-i-style5 {
  padding: 0px 6px 0px 6px;
  font-size: 0.56em;
  margin-top: 10px;
  margin-left: 6px;
  margin-right: 4px;
}
.tooptip-i-style44 {
  padding: 0px 6px 0px 7px;
  font-size: 0.62em;
  margin-top: 6px;
  margin-left: 6px;
  margin-right: 4px;
}
.tooptip-i-style4 {
  padding: 0px 6px 0px 6px;
  font-size: 0.62em;
  margin-top: 6px;
  margin-left: 6px;
  margin-right: 4px;
}
.tooptip-i-style3 {
  padding: 2px 6px 0px 6px;
  font-size: 0.68em;
  margin-top: -1px;
  margin-left: 6px;
  margin-right: 4px;
}
.tooptip-i-style {
  padding: 0px 6px 0px 6px;
  font-size: 0.72em;
  margin-top: 3px;
  margin-left: 6px;
  margin-right: 4px;
}
.tooptip-i-style2 {
  padding: 0px 0px 0px 0px;
  font-size: 0.72em;
  margin-top: -3px;
  margin-left: 6px;
  margin-right: 4px;
}
.linktooltiphover {
  color: #98b5eb;
  height: 0;
  &:hover {
    color: #deebf7;
    cursor: pointer;
  }
}

#overlay {
  /*   display: none;  Hidden by default */
  position: fixed; /* Stay in place */
  transition: all 0.5s;
  z-index: 100000000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}
#super-container {
  overflow: hidden;
  height: 100vh;
}
.selectPortfoliobuttonmove {
  left: 130px !important;
  top: 50px !important;
}
#selectPortfoliobutton {
  position: relative;
  cursor: pointer;
  z-index: 100000002; /* Sit on top */
  background-color: #fbd665;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  color: #14181e;
  padding: 8px 40px 8px 32px;
  left: 48px;
  width: 230px;
  top: 46px;
  margin: -22px 32px 8px 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
#selectPortfoliobutton:hover {
  outline-style: none;
  border: none;
  background-color: #ffe593;
}
#selectPortfoliobutton:focus {
  outline: none;
}
#messageBox {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  width: 350px;
  text-align: center;
  z-index: 100000001;
  margin: -95px 0px 0px -175px;
  overflow: visible;
  display: none;
  top: 50%;
  font-size: 1.2em;
  left: 50%;
  height: 170px;
  border-radius: 10px;
  color: #deebf7;
  background-color: #222a35;
  -webkit-filter: drop-shadow(0 0 5px #556171);
  -moz-filter: drop-shadow(0 0 5px #556171); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 5px #556171); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 5px #556171);
  outline-style: none;
  border: none;
  align-items: center;
  justify-content: center;
  padding: 20px 14px 0px 14px;
}
#noclick {
  /*   display: none;  Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100000001; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: transparent;
}
.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

.confirmedactionbutton:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-left-color: #222a35;
  border-right: 0;
  margin-top: -15px;
  margin-right: -15px;
}
@keyframes showConfirmed {
  0% {
    opacity: 1;
    right: -100px;
  }
  20% {
    opacity: 1;
    right: 50px;
  }
  30% {
    opacity: 1;
    right: 50px;
  }
  100% {
    right: 50px;
    opacity: 0;
  }
}
.confirmedactionbuttonani {
  display: inline-block !important;
  animation-name: showConfirmed;
  animation-duration: 5s;
  animation-iteration-count: 1;
}
.confirmedactionbutton {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  position: fixed;
  opacity: 0;
  font-size: 1.5em;
  font-family: "Roboto";
  font-weight: 300;
  border-radius: 14px;
  border: none;
  text-align: center;
  background-color: #222a35;
  transition: all 0.25s;
  color: #fffde0;
  padding: 10px;
  z-index: 2147483647 !important;
  width: 150px;
  top: 90px;
  display: none;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 8px #14181e);
  -moz-filter: drop-shadow(0 0 8px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 8px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 8px #14181e);
}
.confirmedactionwrapper {
  float: right;
  position: relative;
  margin-left: auto;
  margin-right: 220px;
}
#portfolioModal {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  display: none;
  width: 520px;
  text-align: center;
  z-index: 100000001;
  margin: -150px 0px 0px -260px;
  overflow: visible;
  top: 50%;
  font-size: 1em;
  left: 50%;
  height: 336px;
  border-radius: 8px;
  color: #deebf7;
  background-color: #222a35;
  -webkit-filter: drop-shadow(0 0 5px #556171);
  -moz-filter: drop-shadow(0 0 5px #556171); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 5px #556171); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 5px #556171);
  outline-style: none;
  border: none;
  flex-flow: row wrap;
  flex: auto;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: 50px solid #1a2028;
  transition: height 0.5s ease-in-out, margin 0.5s ease-in-out;
}
.portfoliotopline {
  width: 100%;
  display: block;
  padding-top: 18px;
  z-index: 10000000;
}
.portfolioAddNewSection {
  display: block;
  height: 200px;
  z-index: -10;
  transition: all 0.5s ease-in-out;
  margin-right: 34px;
  margin-left: 34px;
  overflow: hidden;
  border-top: none;
}
.portfoliosheading {
  position: absolute;
  color: #fbd665;
  font-size: 1.6em;
  display: flex;
  text-align: left;
  top: -44px;
  left: 186px;
  font-weight: 400;
  z-index: 0;
}
.portfolioselectionfield {
  width: 100%;
  padding-right: 144px;
  padding-left: 34px;
  text-align: left;
  display: block;
  z-index: 5;
  position: relative;
}
.portfolioselectionfield2 {
  width: 100%;
  text-align: left;
  padding-top: 6px;
  display: none;
}
#portfolioselect > div:nth-of-type(1) {
  max-height: 58px;
}
#portfolioselect > div:nth-of-type(1) > div:nth-of-type(1)::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  color: #1f2831;
  background-color: #1f2831;
}
#portfolioselect
  > div:nth-of-type(1)
  > div:nth-of-type(1)::-webkit-scrollbar-track {
  border-radius: 8px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #2e3c49;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
#portfolioselect
  > div:nth-of-type(1)
  > div:nth-of-type(1)::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
#portfolioselect
  > div:nth-of-type(1)
  > div:nth-of-type(1)::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
#portfolioselect > div:nth-of-type(1) > div:nth-of-type(1) {
  overflow-x: scroll;
  overflow-y: hidden;
  max-height: 58px;
  word-wrap: none;
  flex-flow: row nowrap;
  align-items: left !important;
  justify-content: left !important;
}

#portfolioselect > div:nth-of-type(1) > div:nth-of-type(1) > div {
  width: fit-content;
  flex: none;
}
#portfolioselect > div:nth-of-type(1) > div:nth-of-type(1) > div:first-child {
  margin-left: -2px;
}

.portfoliosubmitbutton {
  z-index: 3;
  position: relative;
  background-color: #fbd665;
  outline: none;
  border: none;
  font-weight: bold;
  transition: none;
  border-radius: 8px;
  color: #14181e;
  padding: 8px 38px 8px 35px;
  right: 0px;
  width: 110px;
  left: 102px;
  top: -74px;
  margin: -40px 0px 8px 28px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.portfoliosubmitbutton:hover {
  outline-style: none;
  border: none;
  background-color: #ffe593;
}
.portfoliosubmitbutton:focus {
  outline: none;
}
.portfoliosubmitbutton:disabled {
  background-color: #e4d5ae;
  outline: none;
  border: none;
  font-weight: bold;
  transition: none;
  border-radius: 8px;
  color: #394453;
  padding: 8px 38px 8px 35px;
  right: 0px;
  width: 110px;
  left: 102px;
  top: -74px;
  z-index: 3;
  position: relative;
  margin: -22px 0px 8px 28px;
  transition: top 0.5s ease-in-out, filter 0.25s ease-in-out,
    background-color 0.25s ease-in-out;
  -webkit-filter: drop-shadow(0 0 10px #1a2028);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
  }
}
.portfolionobutton {
  background-color: #bbbbbb;
  outline: none;
  border: none;
  font-weight: bold;
  transition: none;
  border-radius: 8px;
  color: #14181e;
  width: 110px;
  top: -74px;
  padding: 8px 0px 8px 0px;
  right: 0px;
  margin: -22px 0px 8px 0px;
  left: 98px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  z-index: 3;
  position: relative;
}
.portfolionobutton:hover {
  outline-style: none;
  border: none;
  background-color: #d6d6d6;
}
.portfolionobutton:focus {
  outline: none;
}
.mandatory3port {
  color: #fa798b;
  font-weight: bold;
  font-size: 15px;
  position: absolute;
  right: 94px;
  margin-top: -90px;
  display: none;
}
.mandatory3port2 {
  color: #fa798b;
  font-weight: bold;
  font-size: 15px;
  position: absolute;
  right: 76px;
  margin-top: -90px;
  display: none;
}
.portfoliotypecontainersuper {
  width: 100%;
  display: flex;
  flex-flow: row;
}
.portfoliotypecontainersuperinner {
  width: 50%;
  display: flex;
  flex-flow: column;
}
// portfolios checkbox

.loaddemodatacheckboxtop {
  display: block !important;
  position: absolute !important;
  top: 248px !important;
  left: 26px !important;
  width: 150px !important;
}
@media screen and (max-width: 481px) {
  .loaddemodatacheckboxtop {
    display: block !important;
    position: absolute !important;
    top: 228px !important;
    left: 26px !important;
    width: 150px !important;
  }
  .portfoliotypecontain {
    display: block;
    text-align: left;
    z-index: 0;
    position: absolute;
    left: 0px;
    width: 100%;
    margin-top: 0px;
  }
}
.containerSA {
  z-index: 10;
  display: block;
  margin: 0;
  padding: 0;
  left: 26px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  padding-right: 0;
  top: -30px;
  margin: 2px 0px 0px 0px;
  width: 120px;
  word-wrap: none;
}
.containerSA input {
  position: relative;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmarkSA {
  position: absolute;
  border-radius: 4px;
  height: 20px;
  width: 20px;
  background-color: #1a2028;
  border: 1px solid #14181e;
  left: 0px;
  top: 0px;
  margin-top: -2px;
}
.containerSA:hover > input ~ .checkmarkSA {
  background-color: #2e3a48;
}
.containerSA input:checked ~ .checkmarkSA {
  -webkit-filter: drop-shadow(0 0 3px #171c23);
  -moz-filter: drop-shadow(0 0 3px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 3px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 3px #171c23);
  background-color: #2e3a48;
}
.checkmarkSA:after {
  content: "";
  position: absolute;
  display: none;
}
.containerSA input:checked ~ .checkmarkSA:after {
  display: block;
}
.containerSA .checkmarkSA:after {
  left: 6px;
  top: 0px;
  width: 7px;
  height: 14px;
  border: solid #fbd665;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
#boxShowAllPortfolios:disabled + span {
  &:hover {
    background-color: #252c33;
  }
  background-color: #252c33;
}
#boxChangePortfolios:disabled + span {
  &:hover {
    background-color: #252c33;
  }
  background-color: #252c33;
}

// show all portfolios
.containerSAS {
  z-index: 10;
  display: block;
  margin: 0;
  padding: 0;
  left: -116px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  padding-right: 0;
  margin-left: auto;
  top: -30px;
  margin-right: -112px;
  width: 120px;
  word-wrap: none;
}
.containerSAS input {
  position: relative;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmarkSAS {
  position: absolute;
  border-radius: 2px;
  height: 17px;
  width: 17px;
  background-color: #1a2028;
  border: 2px solid #14181e;
  left: 0px;
  top: 3px;
}
.containerSAS:hover > input ~ .checkmarkSAS {
  background-color: #2e3a48;
}
.containerSAS input:checked ~ .checkmarkSAS {
  -webkit-filter: drop-shadow(0 0 3px #171c23);
  -moz-filter: drop-shadow(0 0 3px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 3px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 3px #171c23);
  background-color: #2e3a48;
}
.checkmarkSAS:after {
  content: "";
  position: absolute;
  display: none;
}
.containerSAS input:checked ~ .checkmarkSAS:after {
  display: block;
}
.containerSAS .checkmarkSAS:after {
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid #fbd665;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// For MFA code modal
#CODEConfirmationModal {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  width: 350px;
  text-align: center;
  z-index: 100000001 !important;
  margin: -240px 0px 0px -190px;
  top: 50%;
  left: 50%;
  height: 480px;
  border-radius: 14px;
  color: #fbd665;
  transition: all 0.5s;
  background-color: #222a35;
  outline-style: none;
  border: none;
  display: flex;
  flex-flow: column;
  flex: auto;
  padding: 0px 0px 0px 36px;
  border-top: 50px solid #1a2028;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.modalSymbolHeaderData {
  z-index: 1000;
  width: 100%;
  text-align: center;
  margin-top: -43px;
  margin-left: -18px;
  margin-bottom: 20px;
  position: relative;
  color: #fbd665;
  font-size: 1.6em;
  padding-bottom: 4px;
  font-weight: 300;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.mfcode-text-input-boxes-wrapper {
  display: block;
  text-align: left;
  padding-right: 0px;
  padding-left: 2px;
  color: #fff7cb;
  width: calc(100% + 46px);
  margin: -4px 0px 20px 0px !important;
}
.submitmfabutton {
  position: absolute;
  background-color: #fbd665;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  color: #14181e;
  padding: 10px 0px 10px 0px;
  right: 0px;
  width: 100px;
  bottom: 16px;
  margin: 0px 34px 8px 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.submitmfabutton:hover {
  outline-style: none;
  border: none;
  background-color: #ffe593;
}
.submitmfabutton:focus {
  outline: none;
}
.clearmfaButton {
  background-color: #bbbbbb;
  position: absolute;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  color: #14181e;
  padding: 10px 0px 10px 0px;
  right: 130px;
  width: 140px;
  bottom: 16px;
  margin: 0px 34px 8px 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}

.clearmfaButton:hover {
  outline-style: none;
  border: none;
  color: #222a35;
  background-color: #d6d6d6;
}
.mfcode-text-input-boxes-superwrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 180px;
  width: calc(100% - 12px);
}
.mfcode-text-input-boxes-superwrapper::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.mfcode-text-input-boxes-superwrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.mfcode-text-input-boxes-superwrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.mfcode-text-input-boxes-superwrapper::-webkit-scrollbar-corner {
  background-color: #1f2831;
}
.finalloadtext {
  font-weight: 400;
  color: #deebf7;
}
.finalerrortext {
  font-weight: 400;
  color: #fa798b;
}
.retrybuttonerror {
  color: #98b5eb;
  margin-left: 8px;
  height: 0;
  &:hover {
    color: #deebf7;
    cursor: pointer;
  }
}
.notfinalloadtext {
  font-weight: 500;
  color: #fbd665;
}
