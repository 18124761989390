#feedback-class {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  display: none;
  width: 800px;
  text-align: center;
  z-index: 100000001;
  margin: -250px 0px 0px -400px;
  overflow: visible;
  top: 50%;
  font-size: 1em;
  left: 50%;
  height: 510px;
  border-radius: 8px;
  color: #deebf7;
  background-color: #222a35;
  -webkit-filter: drop-shadow(0 0 5px #556171);
  -moz-filter: drop-shadow(0 0 5px #556171); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 5px #556171); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 5px #556171);
  outline-style: none;
  border: none;
  flex-flow: row wrap;
  flex: auto;
  align-items: center;
  justify-content: center;
  padding: 0;
  padding-left: 28px;
  padding-right: 28px;
  border-top: 50px solid #1a2028;
}
.feedbackheading {
  position: absolute;
  color: #fbd665;
  font-size: 1.6em;
  display: flex;
  text-align: center;
  top: -44px;
  margin-left: 280px;
  font-weight: 400;
  z-index: 0;
}
.feedbacksubmitbutton {
  position: absolute;
  background-color: #fbd665;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 8px;
  color: #14181e;
  padding: 8px 20px 8px 20px;
  width: 110px;
  margin-bottom: 8px;
  bottom: 16px;
  margin-left: auto;
  right: 32px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.feedbacksubmitbutton:hover {
  outline-style: none;
  border: none;
  background-color: #ffe593;
}
.feedbacksubmitbutton:focus {
  outline: none;
}
.feedbacknobutton {
  position: absolute;
  background-color: #bbbbbb;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 8px;
  color: #14181e;
  width: 110px;
  padding: 8px 0px 8px 0px;
  margin-bottom: 8px;
  bottom: 16px;
  margin-left: auto;
  right: 174px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.feedbacknobutton:hover {
  outline-style: none;
  border: none;
  background-color: #d6d6d6;
}
.feedbacknobutton:focus {
  outline: none;
}
.feedbackbox {
  word-break: break-all;
  border: 1px solid #14181e;
  border-radius: 8px;
  color: #fbd665;
  background-color: #1a2028;
  outline-style: none;
  position: relative;
  height: 126px;
  top: 216px;
  margin: -46px 0px 46px 0px;
  width: 100%;
  left: 0px;
  padding: 6px 10px 0px 10px;
}
.feedbackbox::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.feedbackbox::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.feedbackbox::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #1a2028;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.feedbackbox::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}


#ratingwrapper {
  display: flex;
  position: relative;
  top: 110px;
}
#ratingwrapper2 {
  display: flex;
  position: absolute;
  bottom: 54px;
  width: 170px;
}
.containerFB {
  z-index: 10;
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  left: 0px;
  cursor: pointer;
  width: 240px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.containerFB1{
  width: 284px;
}
.containerFB2{
  width: 230px;
}
.containerFB3{
  width: 190px;
}
.containerFB4{
  width: 200px;
}
.containerFB5{
  width: 244px;
}

.containerFB input {
  position: relative;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.radiocircleFB {
  position: absolute;
  top: 3px;
  left: 0px;
  border-radius: 50px;
  height: 16.9px;
  width: 17px;
  background-color: #1a2028;
  border: 2px solid #14181e;
  border-radius: 100%;
}
.containerFB:hover input ~ .radiocircleFB {
  background-color: #2e3a48;
}
.containerFB input:checked ~ .radiocircleFB {
  -webkit-filter: drop-shadow(0 0 3px #171c23);
  filter: drop-shadow(0 0 3px #171c23);
  background-color: #2e3a48;
}
.radiocircleFB:after {
  content: "";
  position: absolute;
  display: none;
}
.containerFB input:checked ~ .radiocircleFB:after {
  display: block;
}
.containerFB .radiocircleFB:after {
  left: 4.2px;
  top: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fbd665;
}
.containerFB1x{
  width: 260px;
}
.containerFB2x{
  width: 260px;
}
