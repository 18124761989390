#premiumTierComponent {
  position: relative;
  display: flex;
  width: 50px;
  height: 14px;
  font-size: 10px;
  font-weight: bold;
  color: #14181e;
  padding: 0px 2px 0px 4px;
  background-color: #fff8b9;
  border-radius: 5px;
  line-height: 14px;
  margin-top: 0px;
  cursor: pointer;
  top: 4px;
  left: 6px;
  transition: 0.25s all ease-in-out;
  &:hover {
    -webkit-filter: drop-shadow(0 0 2px #deebf7);
    -moz-filter: drop-shadow(0 0 2px #deebf7); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 2px #deebf7); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 2px #deebf7);
    top: 0px;
  }
}

#proTierComponent {
  position: relative;
  display: flex;
  width: 36px;
  height: 14px;
  font-size: 10px;
  font-weight: bold;
  color: #14181e;
  padding: 0px 2px 0px 10px;
  cursor: pointer;
  border-radius: 5px;
  line-height: 14px;
  top: 12px;
  left: 10px;
  transition: 0.25s all ease-in-out;
  background: linear-gradient(to bottom right, #ffeba8, #fbd665);
  &:hover {
    top: 9px;
  }
}
#UltimateTierComponent {
  position: relative;
  display: flex;
  width: 47px;
  height: 14px;
  font-size: 9px;
  font-weight: 500;
  color: #d0b153;
  -moz-box-shadow: inset 0 0 2px #28303c;
  -webkit-box-shadow: inset 0 0 2px #28303c;
  box-shadow: inset 0 0 2px #28303c;
  padding: 0px 2px 0px 6px;
  outline: 1px solid #453e1f;
  cursor: pointer;
  border-radius: 5px;
  line-height: 14px;
  top: 12px;
  left: 10px;
  transition: 0.25s all ease-in-out !important;
  overflow: hidden; /* hide overflow */
  background: linear-gradient(to bottom right, #14181e, #000000);
  &:hover {
    top: 9px;
  }
}
#UltimateTierComponentInner {
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -ms-text-fill-color: transparent;
}
/* Apply the animation */
#UltimateTierComponent::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 52px;
  height: 14px;
  background-color: #ffefbc;
  transform: skew(-20deg) rotate(-16.26deg) translateY(-120%); /* adjust the transform property */
  opacity: 0.28;
  filter: blur(8px);
  animation: stripe 6s linear infinite; /* adjust the animation duration */
}
.masterTierComponent {
  position: relative;
  display: flex;
  width: 48px;
  height: 14px;
  font-size: 10px;
  font-weight: bold;
  color: #14181e;
  padding: 0px 2px 0px 8px;
  cursor: pointer;
  border-radius: 5px;
  line-height: 14px;
  top: 12px;
  left: 10px;
  transition: 0.25s all ease-in-out !important;
  overflow: hidden; /* hide overflow */
  background: linear-gradient(to bottom right, #ffe694, #6a5c34);
  animation: shadowstuff 6s linear infinite; /* adjust the animation duration */
  &:hover {
    top: 9px;
  }
}
/* Apply the animation */
.masterTierComponent::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 52px;
  height: 14px;
  background-color: #ffefbc;
  transform: skew(-20deg) rotate(-16.26deg) translateY(-120%); /* adjust the transform property */
  opacity: 0.8;
  filter: blur(8px);
  animation: stripe 6s linear infinite; /* adjust the animation duration */
}
.trialTierComponent {
  width: 38px !important;
  padding-top: 0px !important;
}
.upgradeTierComponent {
  width: 37px !important;
  height: 14px;
  background: linear-gradient(to bottom right, #deebf7, #3d3d3d);
  animation: none !important; /* adjust the animation duration */
}
.upgradeTierComponent::before {
  animation: none !important; /* adjust the animation duration */
}
/* Define the animation */
@keyframes stripe {
  0% {
    transform: skew(-20deg) rotate(-16.26deg) translateY(-52px)
      translateX(-100%); /* adjust the transform property */
  }
  52% {
    transform: skew(-20deg) rotate(-16.26deg) translateY(-52px)
      translateX(-100%); /* adjust the transform property */
  }
  76% {
    transform: skew(-20deg) rotate(-16.26deg) translateY(52px) translateX(100%);
  }
  100% {
    transform: skew(-20deg) rotate(-16.26deg) translateY(52px) translateX(100%);
  }
}

@keyframes shadowstuff {
  0% {
    -webkit-filter: none;
    -moz-filter: none; /* Old versions of Firefox */
    -ms-filter: none;
    filter: none;
  }
  52% {
    -webkit-filter: none;
    -moz-filter: none; /* Old versions of Firefox */
    -ms-filter: none;
    filter: none;
  }
  64% {
    -webkit-filter: drop-shadow(0 0 1px #ffefbc);
    -moz-filter: drop-shadow(0 0 1px #ffefbc); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 1px #ffefbc);
    filter: drop-shadow(0 0 1px #ffefbc);
  }
  76% {
    -webkit-filter: none;
    -moz-filter: none; /* Old versions of Firefox */
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-filter: none;
    -moz-filter: none; /* Old versions of Firefox */
    -ms-filter: none;
    filter: none;
  }
}
