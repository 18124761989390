#settingscompmain {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  width: calc(100% - 52px);
  text-align: center;
  z-index: 100000001;
  overflow: visible;
  font-size: 1em;
  height: calc(100% - 34px);
  border-radius: 10px;
  color: #deebf7;
  background-color: #1f2831;
  box-shadow: 0 0 4pt 2pt #171c23;
  outline-style: none;
  border: none;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  border-top: 50px solid #28303c;
  padding: 0px 32px 0px 32px;
}
#headColorSuperWrapper2 {
  margin-left: 170px;
  margin-top: -22px;
}
#headColorWrapper2 {
  width: 50px;
  -webkit-appearance: none;
  height: 26px;
  border-radius: 4px;
  padding: 2px;
  margin-top: -80px;
  outline: 1px solid #14181e;
  border: transparent;
  cursor: pointer;
  background-color: #14181e;
  color: #14181e;
  &:hover {
    outline: 1px solid #1a2028;
    background-color: #1a2028;
  }
}
#headColorWrapper2::-webkit-color-swatch {
  border: none;
  border-radius: 4px;
  padding: 0;
}
#headColorWrapper2::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 4px;
  padding: 0;
}

.nocolormovesettings {
  left: 170px;
  margin-top: 1px;
}
.adjustdemdatabutton2 {
  margin-left: -106px;
  top: -4px;
}
.settingsColorWrapper {
  z-index: 1000000000;
  top: 181px;
  width: 400px;
  left: 0px;
  position: absolute;
}
.checkssettings {
  position: absolute;
  z-index: 0;
  padding-right: 0;
  left: 146px;
  right: 116px;
  top: 56px;
  width: max-content;
  height: 0px;
  word-wrap: none;
  white-space: nowrap;
}
.checksIBsettings {
  position: absolute;
  z-index: 0;
  padding-right: 0;
  left: 146px;
  right: 116px;
  top: 160px;
  width: max-content;
  height: 0px;
  word-wrap: none;
  white-space: nowrap;
}
#settingswrapper {
  padding: 26px 26px 0px 26px;
  width: 100%;
  overflow: hidden;
}
#settings-navigationbuttons-line {
  border-top: 1px solid #303a49;
  position: absolute;
  height: 10px;
  top: 50px;
  width: calc(100%);
  z-index: 100000000;
}
.settings-navigationbuttons-selected {
  border-bottom: 1px solid #fbd665 !important;
  color: #fbd665 !important;
  &:hover {
    border-bottom: 1px solid #fbd665 !important;
    z-index: 10000000000 !important;
  }
}
#settings-navigationbuttons-wrapper {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  left: 17px;
  display: flex;
  flex-flow: row;
  top: 3px;
  position: absolute;
  height: 50px;
  width: 0px;
}

#settings-navigationbuttons-button {
  padding: 11px 10px 0px 10px;
  margin: 0px 10px 4px 10px;
  border: none;
  background-color: transparent;
  color: #deebf7;
  outline: none;
  z-index: 10000000000;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: max-content;
  height: 48px;
  &:hover {
    border-bottom: 1px solid #556171;
    z-index: 10000000000;
  }
}
.startingbalsetting {
  display: flex;
  flex: row nowrap;
  width: 500px;
  position: absolute;
  top: 250px;
}
.startingbalsettinginner {
  position: absolute;
  left: 261px;
  margin-top: -10px;
  width: calc(100% - 162px);
}
.translateXneg1 {
  transform: scaleX(-1) !important;
  right: -62px !important;
  top: -16px !important;
}
#settingslabelsbuttonwrapper {
  position: absolute;
  top: -2px;
  right: -30px;
  height: 26px;
  width: 26px;
  color: #fbd665;
  background-color: transparent;
  margin: 0px 0px 0px 0px;
  transform: scaleX(1);
  font-size: 1.3em;
  border: none;
  border-radius: 100px;
}
#settingslabelswrapper {
  color: #fff7cb;
  font-weight: 400;
  margin: 0px 0px 8px 0px;
}

.currencysymbolsheading {
  position: absolute;
  color: #fbd665;
  font-size: 1.6em;
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  font-weight: 400;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */
  display: flex;
  text-align: left;
  top: -44px;
  left: 24px;
  font-weight: 400;
  z-index: 0;
}
.currencysymbolsinnerheader,
.currencysymbolsinnerheader2,
.currencysymbolsinnerheader3,
.generalsettinginnerheader {
  position: absolute;
  color: #fbd665;
  font-size: 1.6em;
  display: flex;
  text-align: left;
  font-weight: 300;
  padding-bottom: 6px;
  width: calc(100% - 32px);
  z-index: 0;
  border-bottom: 1px solid #72612e;
}
.currencysymbolsinnerheader {
  top: -80px;
  left: 0px;
}
.generalsettinginnerheader {
  top: -80px;
  left: -7px;
}
.currencysymbolsinnerheader3 {
  top: 90px;
  left: -7px;
}
.filtersettings {
  top: 0px;
  left: 0px;
  position: absolute;
  width: calc(100% - 60px);
}
.importsettings {
  top: -14px;
  position: absolute;
  display: flex;
  height: 100%;
  flex-flow: column;
  width: calc(100% - 60px);
}
.integrationsettings {
  top: 0px;
  left: 0px;
  position: absolute;
  display: flex;
  height: 100%;
  flex-flow: column;
  width: calc(100% - 60px);
}
.generalsettings {
  top: 0px;
  left: 0px;
  position: absolute;
  width: calc(100% - 60px);
}
.blockchainsettings {
  top: -543px;
  left: 326px;
  position: absolute;
  width: 50%;
}
.currencysymbolsinnerheader2 {
  top: 42px;
  left: -7px;
}
.currencySymbolSelectbuff {
  position: absolute;
}
.skynetPortalSelectbuff {
  position: absolute;
}

.select-currency-symbol {
  display: flex;
  flex: row nowrap;
  width: 400px;
  margin-top: 0px;
  top: 316px;
  position: relative;
}
.select-datefilter-type {
  display: flex;
  flex: row nowrap;
  width: 440px;
  position: absolute;
  top: 0px;
}
.select-datefilter-type2 {
  display: flex;
  flex: row nowrap;
  width: 420px;
  position: absolute;
  top: 50px;
}
.select-datefilter-type3 {
  display: flex;
  flex: row nowrap;
  width: 400px;
  position: absolute;
  top: 214px;
}
.select-rewards-type {
  display: flex;
  flex: row nowrap;
  width: 400px;
  position: relative;
  top: 162px;
}
.portfoliotypecontain-settings-inner {
  display: block;
  text-align: left;
  z-index: 0;
  position: absolute;
  left: 235px;
  width: 264px;
  top: 0px;
}
.portfoliotypecontain-settings {
  display: block;
  text-align: left;
  z-index: 0;
  position: absolute;
  left: 0px;
  width: 264px;
  top: 61px;
}
.portfolionamesettinginner {
  position: relative;
  left: 261px;
  margin-top: -10px;
  width: calc(100% - 162px);
}

.portfolioname-setting {
  display: flex;
  flex: row nowrap;
  width: 500px;
  position: absolute;
  top: 0px;
}
.select-calculation-type {
  display: flex;
  flex: row nowrap;
  width: 400px;
  position: absolute;
  top: 380px;
}
.select-calculation-type2 {
  display: flex;
  flex: row nowrap;
  width: 400px;
  position: absolute;
  top: 382px;
}
.select-calculation-type-inner2 {
  position: relative;
  left: 83px;
}
.select-calculation-type-inner {
  position: relative;
  left: 263px;
}
.select-datefilter-type-inner {
  position: relative;
  left: 260px;
}
.select-datefilter-type-inner2 {
  position: relative;
  left: 50px;
}
.select-datefilter-type-isolated {
  &:hover {
    .radio-label-buy-tooltip {
      z-index: 10000000000 !important;
      height: auto;
      width: 190px;
      position: absolute;
      left: 150px;
      display: block;
      background-color: #14181e;
      border-radius: 8px;
      padding: 0px 10px 10px 10px;
      top: 30px;
    }
  }
}
.select-datefilter-type-cummulative {
  &:hover {
    .radio-label-buy-tooltip {
      z-index: 10000000000 !important;
      height: auto;
      width: 190px;
      position: absolute;
      display: block;
      background-color: #14181e;
      border-radius: 8px;
      padding: 0px 10px 10px 10px;
      top: 30px;
    }
  }
}
.select-datefilter-type-inner3 {
  position: relative;
  left: 261px;
}
.select-rewards-type-inner {
  position: relative;
  left: 56px;
}
.select-settings {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-left: 0px;
  margin-right: 20px;
  left: 36px;
  top: 90px;
}
.currency-symbol-notreallyinner {
  width: 400px;
  position: absolute;
  height: 20px;
}
.select-currency-symbol-inner {
  position: relative;
  margin: -6px 20px 20px 20px;
  position: absolute;
  left: 241px;
  width: 217px;
  top: 0px;
}
.select-currency-symbol-inner > div:nth-child(2) > div > div > div > div {
  align-items: left;
  justify-content: left;
  justify-items: left;
  text-align: left;
}

.applysettingsbuttonwrapper {
  float: right;
  position: relative;
  margin-left: auto;
  margin-right: 310px;
}
.undosettingschangesbutton {
  position: absolute;
  font-size: 1.3em;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 14px;
  border: none;
  text-align: center;
  right: 248px;
  background-color: #bbbbbb;
  color: #1a2028;
  padding: 10px;
  z-index: 0 !important;
  cursor: pointer;
  width: 176px;
  bottom: 30px;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  &:hover {
    outline-style: none;
    border: none;
    background-color: #d6d6d6;
  }
}
.applysettingsbutton {
  position: absolute;
  font-size: 1.3em;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 14px;
  border: none;
  text-align: center;
  right: 40px;
  background-color: #fbd665;
  color: #1a2028;
  padding: 10px;
  z-index: 0 !important;
  cursor: pointer;
  width: 170px;
  bottom: 30px;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.applysettingsbutton:disabled {
  background-color: #e4d5ae;
  color: #394453;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  cursor: not-allowed;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
#togglebutton[disabled] {
  pointer-events: none;
}
.applysettingsbutton:hover {
  outline-style: none;
  border: none;
  color: #222a35;
  background-color: #ffe593;
}
.applysettingsbutton:focus {
  outline-style: none;
  border: none;
}
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  margin: 0px 8px 0px 8px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.defaultSettingName {
  font-weight: 400;
  color: #fbd665;
  text-align: left;
  font-size: 1.2em;
  margin: 5px 0px 0px 0px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #14181e;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: #fbd665;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #14181e;
}

input:focus + .slider {
  box-shadow: 0 0 2px #556171;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}
.slider.round2:before {
  display: block;
}
.slider.round3:before {
  display: none;
}
.AUTOIMPORTselectionfields {
  width: calc(100% - 60px);
  text-align: left;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: -10px 0px 32px 0px;
  z-index: 10000000;
}
.AUTOIMPORTselectionfields > div > input::placeholder {
  color: #fbd665;
  opacity: 0.7;
}
.AUTOIMPORTselectionfields > .required::placeholder {
  color: #fa798b;
  opacity: 1;
}
.AUTOIMPORTlabel {
  width: calc(100% - 60px);
  text-align: left;
  font-weight: 400;
  position: relative;
  height: 50px;
  margin: 8px 0px 0px 24px;
  padding: 0px 0px 0px 0px;
  z-index: 1000000;
  color: #fbd665;
}
.linklogowrapperimgmain {
  margin: -10px 0px 0px 30px;
  width: 70px;
}
.linklogowrapperimgmainsmall {
  margin: -24px 0px -18px 30px;
  width: 50px;
}
.linklogowrapperimgmainrh {
  margin: -28px 0px -23px 30px;
  width: 140px;
}
.manageaccountinnerheader32 {
  position: absolute;
  color: #fbd665;
  font-size: 1.3em;
  display: flex;
  text-align: left;
  font-weight: 400;
  padding-bottom: 0px;
  width: calc(100%);
  top: 430px;
  left: -7px;
  z-index: 0;
  border-bottom: 1px solid #72612e;
}
#settingsactionbuttonwrapper {
  position: absolute;
  width: 200px;
  top: 246px;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: right;
}
#autoimportconnectwalletbutton {
  position: relative;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  text-align: center;
  float: right;
  background-color: transparent;
  border: 1px solid #fbd665;
  transition: all 0.25s;
  color: #fff7cb;
  padding: 8px 0px 8px 0px;
  margin: 0px 30px 0px 0px;
  font-size: 1em;
  z-index: 20000;
  font-weight: 500;
  cursor: pointer;
  width: calc(100% - 60px);
  left: 0px;
  top: 50px;
  -moz-box-shadow: inset 0 0 4px #ffe593;
  -webkit-box-shadow: inset 0 0 4px #ffe593;
  box-shadow: inset 0 0 4px #ffe593;
}
#autoimportconnectwalletbutton:disabled {
  position: relative;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  text-align: center;
  float: right;
  background-color: transparent;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  border: 2px solid #e4d5ae;
  color: #e4d5ae;
  padding: 8px 0px 8px 0px;
  margin: 0px 30px 0px 0px;
  font-size: 1em;
  z-index: 20000;
  width: calc(100% - 60px);
  left: 0px;
  top: 50px;
  font-weight: 500;

  -webkit-filter: drop-shadow(0 0 4px #e4d5ae);
  -moz-filter: drop-shadow(0 0 4px #e4d5ae); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 4px #e4d5ae); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 4px #e4d5ae);
  &:hover {
    border: 2px solid #e4d5ae;
    cursor: not-allowed;

    background-color: transparent;
    color: #e4d5ae;
    -webkit-filter: drop-shadow(0 0 4px #e4d5ae);
    -moz-filter: drop-shadow(0 0 4px #e4d5ae); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 4px #e4d5ae); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 4px #e4d5ae);
  }
}
#autoimportconnectwalletbutton:hover {
  cursor: pointer;
  outline-style: none;
  background-color: #ffe593;
  border: 1px solid #ffe593;
  color: #14181e;

}
.linklogowrappertext {
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: -12px;
  color: #fff7cb;
}
.linklogowrapperimgmt4 {
  height: 33px;
  width: 128px;
  margin-top: 14px;
  margin-left: -2px;
}
.linklogowrapperimgdydx {
  height: 30px;
  width: 70px;
  margin-top: 12px;
  margin-left: -2px;
}
.linklogowrapperimgkraken {
  height: 22px;
  width: auto;
  margin-top: 20px;
  margin-left: -2px;
}
.linklogowrapperimgrweb3 {
  height: 36px;
  margin: -1px 10px 0px 4px;
}
.linklogowrapperimgrh {
  height: 30px;
  margin-top: 16px;
  margin-left: 2px;
}
.linklogowrapperimgapollox {
  width: 158px;
  height: 40px;
  margin-top: 10px;
  margin-left: -12px;
}
.linklogowrapper {
  position: absolute;
  bottom: 34px;
  left: 36px;
  height: 50px;
  text-align: center;
}
#autoimportlinkbrokerbutton {
  position: relative;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  text-align: center;
  float: right;
  background-color: #fbd665;
  color: #1a2028;
  font-weight: 500;
  padding: 8px 0px 8px 0px;
  margin: 0px 30px 36px 0px;
  font-size: 1em;
  z-index: 20000;
  cursor: pointer;
  width: calc(100% - 60px);
  left: 0px;
  top: 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}

#autoimportlinkbrokerbutton:hover {
  color: #222a35;
  background-color: #ffe593;
}
#autoimportlinkbrokerbutton:disabled {
  background-color: #e4d5ae;
  color: #394453;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}

#autoimportor {
  position: relative;
  top: 70px;
  width: 0px;
  left: calc(50% - 14px);
  color: #fff7cb;
}
.keep-entries-container {
  display: block !important;
  width: 140px;
  position: relative;
  padding: 0px 6px 0px 6px;
  margin: 26px 0px 6px 0px;
}
.checksati1 {
  width: 114px;
}
.checksati2 {
  width: 144px;
}

#autoimportmodalwrapper {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: relative;
  display: flex;
  width: calc(100% + 36px);
  z-index: 10000000001 !important;
  margin: 0px 0px 0px -36px;
  overflow: visible;
  font-size: 1em;
  height: 100%;
  border-radius: 8px;
  color: #deebf7;
  outline-style: none;
  border: none;
  flex-flow: column wrap;
  flex: auto;
  align-items: center;
  vertical-align: top;
  justify-content: center;
  padding: 0px 0px 0px 8px;
}

#autoimportunlinkmodalwrapper {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  display: flex;
  width: 400px;
  z-index: 10000000001 !important;
  margin: -110px 0px 0px -200px;
  overflow: visible;
  top: 50%;
  font-size: 1em;
  left: 50%;
  height: 200px;
  border-radius: 8px;
  color: #deebf7;
  outline-style: none;
  border: none;
  flex-flow: column wrap;
  flex: auto;
  align-items: center;
  justify-content: center;
  padding: 0px 28px 0px 28px;
}
.autoimportmodalheading {
  position: absolute;
  color: #fbd665;
  font-size: 1.6em;
  width: 100%;
  text-align: center;
  top: -44px;
  font-weight: 300;
  z-index: 0;
}
.autoimportmodalunlinkheading {
  position: absolute;
  color: #fa798b;
  font-size: 1.6em;
  display: flex;
  text-align: center;
  top: -19px;
  font-weight: 400;
  z-index: 0;
  -webkit-filter: none !important;
  -moz-filter: none !important; /* Old versions of Firefox */
  -ms-filter: none !important; /* Internet Explorer/Edge */
  filter: none !important;
}
#selectPortfolioHeaderWrapper {
  margin-top: -12px;
}
#selectPortfolioHeaderText {
  margin-top: 40px;
}
#autoimportlinkbrokerwrapper {
  width: 100%;
  max-width: 577px;
  top: -48px;
  overflow: visible;
  position: relative;
  padding: 0px 0px 0px 0px;
  border-radius: 10px;
  z-index: 1000000000;
}
#autoimportlinkbrokerwrapperinner {
  height: 100%;
}
#autoimportconnectwalletbuttonwrapper {
  position: relative;
  height: 200px;
  display: block;
}
#autoimportlinkbrokerbuttontext {
  color: #deebf7;
  font-weight: 500;
  white-space: nowrap;
  top: 0px;
  text-align: center;
}
.select-autogroup-trades {
  display: flex;
  flex: row nowrap;
  width: 350px;
  position: relative;
  top: 114px;
}
#autoimportlinkbrokermastertierwrapper {
  position: absolute;
  top: 20px;
  right: 80px;
}
.select-datefilter-type-inner3auto {
  position: relative;
  left: 120px;
}
.subscription-setting-text {
  position: relative;
  left: 0px;
  top: 120px;
}
#existingtradestext {
  position: relative;
  display: block;
  width: calc(100% - 26px);
  color: #deebf7;
  margin: 24px 30px 42px 0px;
  padding-bottom: 10px;
  left: 0px;
  top: 0px;
}
#existingtradestextempty {
  position: relative;
  display: block;
  width: calc(100% - 60px);
  margin: 16px 30px 0px 0px;
  padding-bottom: 10px;
  left: 30px;
  top: 0px;
}
.text-input-boxes-linkbroker {
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 1px solid #14181e;
  border-radius: 8px;
  height: 38px;
  color: #fbd665;
  background-color: #1a2028;
  outline-style: none;
  font-weight: 400;
  width: 100%;
  margin: 0px 0px 12px 0px;
}
.text-input-boxes-linkbroker:focus {
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: all 0.1s;
  border-radius: 8px;
}
.text-input-boxes-linkbroker2 {
  position: relative;
  top: 0px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 1px solid #14181e;
  border-radius: 8px;
  height: 38px;
  color: #fbd665;
  background-color: #1a2028;
  outline-style: none;
  font-weight: 400;
  width: 100%;
  margin: 0px 0px 0px 0px;
}
.text-input-boxes-linkbroker2:focus {
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: all 0.1s;
  border-radius: 8px;
}
#settingstoplabel {
  position: relative;
  max-width: 190px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#settingstoplabellabel {
  position: relative;
  max-width: 190px;
  display: inline-block;
  top: -7px;
  left: 4px;
}
.portaddinputs-linkbroker {
  width: 100%;
  padding-right: 38px;
  text-align: left;
}
.portaddinputs-linkbroker4 {
  width: calc(100% - 46px);
  text-align: right;
  padding-right: 0px;
  margin-top: 4px;
  margin-right: -20px !important;
  font-size: 0.9em;
}
.portaddinputs-linkbroker2 {
  width: 100%;
  padding-right: 38px;
  margin: 4px 0px 0px 0px;
  text-align: left;
}
.portaddinputs-linkbroker33 {
  width: 100%;
  padding-right: 38px;
  margin: 14px 0px 0px 0px;
  text-align: left;
}
.portaddinputs-linkbroker332 {
  width: 100%;
  padding-right: 38px;
  margin: 16px 0px 0px 0px;
  text-align: left;
}
.portaddinputs-linkbroker3 {
  width: 100%;
  padding-right: 38px;
  margin: 18px 0px 0px 0px;
  text-align: left;
}
.linkBrokerActualButton {
  background-color: #fbd665;
  color: #1a2028;
  font-family: "Roboto";
  font-weight: 400;
  border: none;
  text-align: center;
  z-index: 200 !important;
  cursor: pointer;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  font-size: 1em;
  font-weight: 400;
  border-radius: 10px;
  padding-top: 2px;
  bottom: 66px;
  position: relative;
  width: 90px !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
  height: 40px;
  width: calc(100% - 36px);
  margin-left: 24px;
}
.linkBrokerActualButton:disabled {
  background-color: #e4d5ae;
  color: #394453;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
.linkBrokerActualButton:hover {
  outline-style: none;
  border: none;
  background-color: #ffe593;
}
// CANCEL EXECUTION BUTTON
.cancelBrokerActualButton {
  background-color: #bbbbbb;
  color: #1a2028;
  font-family: "Roboto";
  font-weight: 400;
  border: none;
  text-align: center;
  z-index: 200 !important;
  cursor: pointer;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  font-size: 1em;
  padding-top: 2px;
  bottom: 66px;
  position: relative;
  width: 96px !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
  height: 40px;
  margin-left: 6px;
}
.cancelBrokerActualButton:hover {
  outline-style: none;
  border: none;
  background-color: #d6d6d6;
}
.linkbuttonwrapper {
  position: absolute;
  bottom: -44px;
  right: 38px;
}
.linkBrokerActualUNLINKButton {
  background-color: #ff8080;
  color: #1a2028;
  font-family: "Roboto";
  font-weight: 400;
  border: none;
  text-align: center;
  z-index: 200 !important;
  cursor: pointer;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  font-size: 1em;
  font-weight: 400;
  padding-top: 2px;
  bottom: 66px;
  position: relative;
  width: 90px !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
  height: 40px;
  width: calc(100% - 36px);
  margin-left: 24px;
}

.linkBrokerActualUNLINKButton:hover {
  outline-style: none;
  border: none;
  background-color: #ffa09f;
}
#unlinkbrokertext {
  margin: -44px 0px 0px 0px;
  text-align: center;
}
.unlinkbuttonwrapper {
  position: absolute;
  bottom: -44px;
}
#howtogetdydxcredentialslink {
  color: #98b5eb;
  &:hover {
    text-decoration: underline;
  }
}
.autoimporterrormsg {
  color: #fa798b;
  display: none;
  position: absolute;
  height: 10px;
  top: -20px;
  font-size: 0.9em;
}
#linkweb3brokerbutton {
  padding: 7px 13px 10px 13px;
  display: inline-block !important;
  margin: -3px 12px 10px 0px;
  width: 360px;
  border: 1px solid #1f2831;
  border-radius: 10px;
  outline: none;
  height: 48px;
  color: #deebf7;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 400;
  text-overflow: ellipsis;
  font-size: 1em;
  background-color: transparent;
  background-color: #1a2028;
  -moz-box-shadow: inset 0 0 4px #556171;
  -webkit-box-shadow: inset 0 0 4px #556171;
  box-shadow: inset 0 0 4px #556171;
  &:hover {
    cursor: pointer;
    outline-style: none;
    background-color: #556171;
    border: 1px solid #556171;
    font-weight: 500;
    color: #14181e;
  }
}
@media screen and (min-width: 1680px) and (min-height: 900px) {
  #settingswrapper[max-width~="720px"] .generalsettings {
    width: 100%;
  }
  #settingswrapper[max-width~="720px"] .generalsettings > * {
    display: flex;
    flex-flow: column;
    position: relative;
    justify-content: left !important;
    align-items: left !important;
  }
  #settingswrapper[max-width~="720px"] .filtersettings {
    width: 100%;
  }
  #settingswrapper[max-width~="720px"] .filtersettings > * {
    display: flex;
    flex-flow: column;
    position: relative;
    justify-content: left !important;
    align-items: left !important;
  }
  #settingswrapper[max-width~="720px"] .portfolioname-setting {
    display: flex;
    flex-direction: column;
    width: calc(100%);
    position: relative;
    top: 0px;
  }
  #settingswrapper[max-width~="720px"] .portfoliotypecontain-settings {
    top: 45px;
  }
  #settingswrapper[max-width~="720px"] .portfolionamesettinginner {
    margin-left: 0px;
    width: calc(356px);
    height: 20px;
    margin-top: 0px;
    left: 0px !important;
  }
  #settingswrapper[max-width~="720px"] .select-calculation-type {
    top: 0px;
    margin-top: 8px;
    width: calc(100% - 153px);
  }
  #settingswrapper[max-width~="720px"] .select-currency-symbol-inner {
    position: relative;
    margin: 0px 20px 20px 0px;
    left: 0px;
    width: 217px;
    top: 0px;
  }
  #settingswrapper[max-width~="720px"] .startingbalsettinginner {
    margin-left: 0px;
    margin-top: 0px;
    width: calc(356px);
    left: 0px;
    position: relative;
  }
  #settingswrapper[max-width~="720px"] .select-calculation-type-inner {
    position: relative;
    left: 0px;
  }
  #settingswrapper[max-width~="720px"] .select-currency-symbol {
    margin-top: 28px !important;
    top: 0px;
  }
  #settingswrapper[max-width~="720px"] .startingbalsetting {
    margin-top: -10px !important;
    top: 0px;
    width: calc(100%);
  }
  #settingswrapper[max-width~="720px"] #settingscompmain {
    text-align: left !important;
  }
  #settingswrapper[max-width~="720px"] #settingslabelswrapper {
    text-align: center !important;
  }
  #settingswrapper[max-width~="720px"] .portfoliotypecontain-settings > * {
    position: relative;
  }
  #settingswrapper[max-width~="720px"] .portfoliotypecontain-settings-inner {
    left: 0;
  }
  #settingswrapper[max-width~="720px"] .generalsettinginnerheader {
    width: 100%;
  }
  #settingswrapper[max-width~="720px"] .select-settings {
    position: absolute;
    width: calc(100% - 20px);
    height: 100%;
    margin-left: 0px;
    margin-right: 20px;
    left: 36px;
    top: 90px;
  }
  #settingswrapper[max-width~="720px"] .select-datefilter-type-inner {
    position: relative;
    left: 0px;
    height: 6px;
  }
  #settingswrapper[max-width~="720px"] .select-datefilter-type-inner3 {
    position: relative;
    left: 0px;
    height: 6px;
  }
  #settingswrapper[max-width~="720px"] .AUTOIMPORTselectionfields {
    width: calc(100% - 60px);
    text-align: left;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: -10px 0px 32px 30px;
    z-index: 10000000;
  }
  #settingswrapper[max-width~="720px"] .select-datefilter-type-inner3 {
    position: relative;
    left: 0px;
    height: 6px;
  }
  #settingswrapper[max-width~="484px"] .portfolionamesettinginner {
    width: calc(100% - 56px);
  }
  #settingswrapper[max-width~="484px"] .startingbalsettinginner {
    width: calc(100% - 56px);
  }
}
// For screen sizes smaller than 1680w or 900h
@media screen and (max-width: 1680px), (max-height: 900px) {
  #settingswrapper[max-width~="611px"] .adjustdemdatabutton2 {
    margin-left: 0px;
    left: 0px;
    position: absolute;
  }
  #settingswrapper[max-width~="577px"] .generalsettings {
    width: 100% !important;
  }
  #settingswrapper[max-width~="577px"] .generalsettings > * {
    display: flex !important;
    flex-flow: column !important;
    position: relative !important;
    justify-content: left !important;
    align-items: left !important;
  }
  #settingswrapper[max-width~="577px"] .filtersettings {
    width: 100% !important;
    display: flex;
    flex-flow: column;
  }
  #settingswrapper[max-width~="577px"] .select-datefilter-type-inner {
    position: relative;
    left: 0px;
    height: 6px;
  }
  #settingswrapper[max-width~="577px"] .select-datefilter-type-inner3 {
    position: relative;
    left: 0px;
    height: 6px;
  }
  #settingswrapper[max-width~="577px"] .settingsColorWrapper {
    z-index: 1000000000;
    top: 0px;
    width: 400px;
    left: 0px;
    position: absolute;
  }
  #settingswrapper[max-width~="577px"] .nocolormovesettings {
    left: 70px;
  }
  #settingswrapper[max-width~="577px"] .filtersettings > * {
    display: flex !important;
    flex-flow: column !important;
    position: relative !important;
    justify-content: left !important;
    align-items: left !important;
  }
  #settingswrapper[max-width~="577px"] .portfolioname-setting {
    display: flex !important;
    flex-direction: column nowrap !important;
    height: 44px !important;
    width: calc(100%) !important;
    position: relative !important;
    top: 0px !important;
  }
  #settingswrapper[max-width~="577px"] .portfoliotypecontain-settings {
    top: 45px !important;
  }
  #settingswrapper[max-width~="577px"] .AUTOIMPORTselectionfields {
    width: calc(100% - 60px);
    text-align: left;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: -10px 0px 32px 30px;
    z-index: 10000000;
  }
  #settingswrapper[max-width~="577px"] .portfolionamesettinginner {
    margin-left: 0px !important;
    width: calc(356px) !important;
    margin-top: 0px !important;
    left: 0px !important;
  }
  #settingswrapper[max-width~="577px"] #settingsactionbuttonwrapper {
    position: absolute;
    width: 100%;
    top: -160px;
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: right;
  }
  #settingswrapper[max-width~="577px"] .manageaccountinnerheader32 {
    position: absolute;
    color: #fbd665;
    font-size: 1.3em;
    display: flex;
    text-align: left;
    font-weight: 400;
    padding-bottom: 0px;
    width: calc(100% - 36px);
    top: 24px;
    left: -7px;
    z-index: 0;
    border-bottom: 1px solid #72612e;
  }
  #settingswrapper[max-width~="577px"] #autoimportlinkbrokerbuttontext {
    color: #deebf7;
    font-weight: 500;
    white-space: nowrap;
    top: 0px;
    text-align: center;
  }
  #settingswrapper[max-width~="577px"] .select-calculation-type {
    top: 0px !important;
    margin-top: 8px !important;
    width: calc(100% - 153px) !important;
  }
  #settingswrapper[max-width~="577px"] .select-currency-symbol-inner {
    position: relative !important;
    margin: 0px 20px 20px 0px !important;
    left: 0px !important;
    width: 217px !important;
    top: 0px !important;
  }
  #settingswrapper[max-width~="577px"] .startingbalsettinginner {
    margin-left: 0px !important;
    margin-top: 0px !important;
    width: calc(356px) !important;
    left: 0px !important;
    position: relative !important;
  }
  #settingswrapper[max-width~="577px"] .select-calculation-type-inner {
    position: relative !important;
    left: 0px !important;
  }
  #settingswrapper[max-width~="577px"] .select-currency-symbol {
    margin-top: 28px !important;
    top: 0px !important;
  }
  #settingswrapper[max-width~="577px"] .startingbalsetting {
    margin-top: -10px !important;
    top: 0px !important;
    width: calc(100%) !important;
  }
  #settingswrapper[max-width~="577px"] #settingscompmain {
    text-align: left !important;
  }
  #settingswrapper[max-width~="577px"] #settingslabelswrapper {
    text-align: center !important;
  }
  #settingswrapper[max-width~="577px"] .portfoliotypecontain-settings > * {
    position: relative !important;
  }
  #settingswrapper[max-width~="577px"] .portfoliotypecontain-settings-inner {
    left: -26px !important;
  }
  #settingswrapper[max-width~="577px"] .generalsettinginnerheader {
    width: 100% !important;
  }
  #settingswrapper[max-width~="577px"] .select-settings {
    position: absolute;
    width: calc(100% - 20px) !important;
    height: 100%;
    margin-left: 0px;
    margin-right: 20px;
    left: 36px;
    top: 72px;
  }
  #settingswrapper[max-width~="410px"] .undosettingschangesbutton {
    right: 192px;
    font-size: 1em !important;
    padding: 6px 12px 6px 12px;
    width: 132px !important;
    border-radius: 12px;
  }
  #settingswrapper[max-width~="410px"] .applysettingsbutton {
    font-size: 1em !important;
    padding: 6px 12px 6px 12px;
    width: 124px !important;
    border-radius: 12px;
  }
  #settingswrapper[max-width~="386px"] .portfolionamesettinginner {
    width: calc(100% - 56px) !important;
  }
  #settingswrapper[max-width~="386px"] .startingbalsettinginner {
    width: calc(100% - 56px) !important;
  }
}

@media screen and (max-width: 454px) {
  #autoimportmodalwrapper {
    font-size: 0.92em;
    width: calc(100% + 36px);
    height: 634px;
    margin: 0px 0px 0px -36px;
  }
  .IntegrationImportModal {
    width: calc(100% - 36px);
    margin: -310px 0px 0px calc(-50% + 18px);
    height: 620px;
  }
  .IntegrationImportModal2 {
    width: calc(100% - 36px);
    margin: -168px 0px 0px calc(-50% + 18px);
  }
  #settings-navigationbuttons-wrapper {
    transform: scale(0.9);
    top: -1px;
  }
  #settings-navigationbuttons-line {
    border-top: 1px solid #303a49;
    position: absolute;
    height: 10px;
    top: 44px;
    width: 100%;
    z-index: 100000000;
    color: #ff5d73;
  }
}
