.icon-caret-insights {
  display: none;
}
.icon-caret-adddata {
  display: none;
}
.icon-caret-tools {
  display: none;
}
.icon-caret-import {
  display: none;
}
.icon-caret-insights:hover {
  transition: all 0.15s ease-in;
  opacity: 1;
  visibility: visible;
}
.icon-caret-adddata:hover {
  transition: all 0.15s ease-in;
  opacity: 1;
  visibility: visible;
}
.icon-caret-tools:hover {
  transition: all 0.15s ease-in;
  opacity: 1;
  visibility: visible;
}
.icon-caret-import:hover {
  transition: all 0.15s ease-in;
  opacity: 1;
  visibility: visible;
}
#ANTicon {
  position: absolute;
  padding: 0;
  margin: 14px 16px 14px 16px;
  font-size: 1.3em;
  top: -5px;
}
.Dashicon {
  position: absolute;
  padding: 0;
  margin: 14px 16px 14px 16px;
  font-size: 1.3em;
  top: -6px;
  left: -0.13em;
}
.AIicon > img {
  position: absolute;
  padding: 0;
  font-size: 1.7em;
  top: 12px;
  left: 12px;
  width: 26px !important;
  height: 26px !important;
}
.LogBookicon {
  position: absolute;
  padding: 0;
  margin: 14px 16px 14px 16px;
  font-size: 1.3em;
  top: -5px;
}
.Blockchainicon {
  position: absolute;
  padding: 0;
  margin: 14px 16px 14px 14px;
  font-size: 1.3em;
  top: -5px;
}
.Homeicon {
  position: absolute;
  padding: 0;
  margin: 14px 16px 14px 14px;
  font-size: 1.3em;
  top: -5px;
}
.Supporticon {
  position: absolute;
  padding: 0;
  margin: 4px 16px 14px 0px;
  font-size: 1.3em;
  top: -3px;
}
.Forumicon {
  position: absolute;
  padding: 0;
  margin: 14px 16px 14px 12px;
  font-size: 1.3em;
  top: -5px;
}
.dataanalysisicon {
  position: absolute;
  width: 22px;
  top: 12px;
  left: 14px;
  display: block;
}
.sidenavstyledmain {
  padding: 0;
  position: absolute;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  background-color: #14181e;
  border-radius: 8px;
  height: 48px;
  left: 0;
  margin: 4px 3px 0px 3px;
}
.sidenavstyledmain:hover {
  background-color: #222a35;
}
.sidenavicon {
  padding: 0px 0px 0px 0px;
  margin: 14px 16px 14px 15px;
  font-size: 1.3em;
}
.sidenavtext {
  position: absolute;
  left: 55px;
  top: 0px;
  padding: 0;
  margin: 12px 16px 14px 0px;
}
