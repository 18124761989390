.showtradedatasuperwrapper {
  width: calc(100% - 54px);
  margin: 0px 28px 0px 28px;
  padding: 0px;
}
.showtradeinfowrapperupper {
  width: calc(100% - 54px);
  margin: 0px 28px 0px 28px;
  padding: 0px;
}
.TradingViewWidgetwrapper {
  width: calc(100% - 54px);
  margin-left: 27px;
  margin-top: 24px;
  border-radius: 10px;
  border: 1px solid #14181e;
  margin-bottom: 26px;
  height: 520px;
  overflow: hidden;
  -moz-box-shadow: 0 0 14px #14181e;
  -webkit-box-shadow: 0 0 14px #14181e;
  box-shadow: 0 0 14px #14181e;
}
.showtradepictureswrappernon,
.showtradepictureswrapper {
  position: absolute;
  width: calc(100% - 54px);
  height: 232px;
  display: flex;
  justify-content: left;
  border: 1px solid #14181e;
  border-radius: 10px;
  flex: row wrap;
  background-color: #1a2028;
  margin: 0px 280px 24px -4px;
  padding-right: 32px;
  overflow: scroll;
  &:active {
    .nopics {
      color: #deebf7;
    }
  }
}
.showtradepictureswrapper::-webkit-scrollbar {
  border-radius: 10px;
  height: 9px;
  width: 9px;
  color: #1f2831;
  background-color: #1f2831;
}
.showtradepictureswrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.showtradepictureswrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #1a2028;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.showtradepictureswrapper::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.showtradepictureswrappernon::-webkit-scrollbar {
  border-radius: 10px;
  height: 9px;
  width: 9px;
  color: #1f2831;
  background-color: #1f2831;
}
.showtradepictureswrappernon::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.showtradepictureswrappernon::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #1a2028;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.showtradepictureswrappernon::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.innerpictures {
  display: inline-block;
  position: relative;
  z-index: 10000000;
  align-items: center;
  justify-content: center;
  margin: 20px 0px 0px 20px;
  padding: 0;
  border: 3px solid #44546a;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
}
.innerpicturesBG {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 0px 0px;
  padding: 0;
  border: 10px solid #44546a;
  border-radius: 10px;
}
.showtradepictureswrapper {
  cursor: pointer;
  &:active {
    background-color: #1d242e;
  }
}

.logbook-text-input-boxes {
  border: 1px solid #14181e;
  height: 48px;
}
.showtradedatacol2 {
  display: block;
  flex: column wrap;
  flex-grow: 1;
  font-size: 1.2em;
  height: 100%;
  justify-content: left;
  align-items: left;
  max-width: 50%;
}
.logbook-text-input-boxes,
.logbook-select-input-boxes {
  font-family: "Roboto", sans-serif;
  position: relative;
  border-radius: 10px;
  color: #fbd665;
  background-color: #1a2028;
  outline-style: none;
  font-weight: 400;
  width: calc(100% - 60px);
  margin: -6px 14px -6px auto;
}
.logbook-text-input-boxes-wrapper {
  display: block;
  text-align: right;
  padding-right: 0px;
  width: 100%;
  margin: -4px 0px 4px 0px !important;
}
.logbook-select-input-boxes-wrapper {
  display: block;
  text-align: right;
  padding-right: 0px;
  width: 100%;
  max-width: 100%;
  margin: 0px 0px -4px 0px !important;
}
.logbook-select-input-boxes > div {
  border-radius: 8px;
}
#logbook-select-input-boxes {
  margin: -10px 14px 0px auto;
}
#logbook-select-input-boxes > div:nth-of-type(1) {
  min-height: 50px;
}
#logbook-select-input-boxes
  > div:nth-of-type(1)
  > div:nth-of-type(1)::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  color: #1f2831;
  background-color: #1f2831;
}
#logbook-select-input-boxes
  > div:nth-of-type(1)
  > div:nth-of-type(1)::-webkit-scrollbar-track {
  border-radius: 8px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #2e3c49;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
#logbook-select-input-boxes
  > div:nth-of-type(1)
  > div:nth-of-type(1)::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
#logbook-select-input-boxes
  > div:nth-of-type(1)
  > div:nth-of-type(1)::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
#logbook-select-input-boxes > div:nth-of-type(1) > div:nth-of-type(1) {
  overflow-x: hidden;
  overflow-y: hidden;
  word-wrap: none;
  flex-flow: row wrap;
  align-items: left !important;
  justify-content: left !important;
}

#logbook-select-input-boxes > div:nth-of-type(1) > div:nth-of-type(1) > div {
  flex: none;
}
.OverlayModalPic {
  position: fixed; /* Stay in place */
  transition: all 0.5s;
  z-index: 2147483647; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}
.PictureModal {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  text-align: center;
  z-index: 100000001 !important;
  margin: -300px 0px 0px -37.5%;
  top: 50%;
  font-size: 1.6vh;
  left: 50%;
  height: 600px;
  width: 75%;
  border-radius: 14px;
  color: #fbd665;
  transition: all 0.5s;
  outline: none;
  border: none;
  display: flex;
  flex-flow: column;
  flex: auto;
  padding: 0px 0px 0px 0px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.resetExecutionButton {
  position: absolute;
  outline: none;
  left: 32px;
  bottom: 13px;
  border: none;
  z-index: 0;
  background-color: #ff8080;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 8px;
  color: #14181e;
  width: 100px;
  padding: 10px 0px 10px 0px;
  margin: 0px 28px 5px 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.resetExecutionButton:hover {
  outline-style: none;
  border: none;
  background-color: #ffa09f;
}
.resetExecutionButton:focus {
  outline: none;
}
#logbook-select-input-boxes
  > div:nth-of-type(1)
  > div:nth-of-type(1)
  > div:first-child {
  margin-left: -2px;
}
.logbook-text-input-boxes:focus {
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: all 0.1s;
  border-radius: 8px;
}
.logbook-select-input-boxes:focus {
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: all 0.1s;
  border-radius: 8px;
}
.tradecalculatedinfo_Eff {
  position: absolute;
  display: flex;
  flex-flow: row;
  top: 22px;
  left: 30px;
  width: 90%;
}
.tradecalculatedinfo_RRR {
  position: absolute;
  display: flex;
  flex-flow: row;
  top: 186px;
  left: 30px;
  width: calc(100% - 10px);
}
.tradecalculatedinfo_RM {
  position: absolute;
  display: flex;
  flex-flow: row;
  top: 244px;
  left: 30px;
  width: calc(100% - 10px);
}
.tradecalculatedinfo_symbol {
  position: relative;
  display: inline-flex;
  white-space: nowrap;
  margin: 0px 10px 0px 10px;
  padding: 0px 0px 0px 0px;
  font-size: 1.6em;
  text-align: left;
  justify-content: left;
  font-weight: 400;
  z-index: 10000;
  top: 17px;
}
.tradecalculatedinfo_ordertypedisplay {
  margin-top: -3px;
}
.tradecalculatedinfo_ordertypelong,
.tradecalculatedinfo_ordertypeshort,
.tradecalculatedinfo_ordertypeopen,
.tradecalculatedinfo_ordertypefp {
  position: relative;
  display: flex;
  white-space: nowrap;
  margin: 0px 10px 0px 10px;
  padding: 4px 8px 0px 10px;
  font-size: 1.3em;
  text-align: left;
  justify-content: left;
  font-weight: 400;
  z-index: 10000;
  border-radius: 8px;
  vertical-align: top;
  height: 34px;
  top: 18px;
}
.tradecalculatedinfo_ordertypelong {
  background-color: #3f8a65;
}
.tradecalculatedinfo_ordertypeshort {
  background-color: #91404b;
}
.tradecalculatedinfo_ordertypeopen {
  background-color: #9e8a4e;
}
.tradecalculatedinfo_ordertypefp {
  background-color: #5d6f8f;
}
.justneedmargintop {
  margin-top: -13px;
}
.verifiedtradetooltipbody {
  margin-top: -12px;
}
.tradecalculatedinfo_ordertypelongml,
.tradecalculatedinfo_ordertypeshortml,
.tradecalculatedinfo_ordertypeopenml {
  position: relative;
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  margin: 0px 10px 0px 10px;
  padding: 0px 8px 0px 10px;
  font-size: 1.2em;
  text-align: center;
  justify-content: center;
  font-weight: 400;
  z-index: 10000;
  border-radius: 8px;
  vertical-align: top;
  height: 40px;
  width: auto;
  top: 16px;
}
.tradecalculatedinfo_ordertypelongml {
  background-color: #3f8a65;
}
.tradecalculatedinfo_ordertypeshortml {
  background-color: #91404b;
}
.tradecalculatedinfo_ordertypeopenml {
  background-color: #9e8a4e;
}

.tradecalculatedinfo_typestrategyopen,
.tradecalculatedinfo_typestrategylong,
.tradecalculatedinfo_typestrategyshort {
  position: relative !important;
  display: block;
  white-space: nowrap;
  margin: 0px 0px 0px 0px !important;
  padding: 4px 0px 0px 0px !important;
  border-radius: 8px;
  height: 0px !important;
  font-size: 0.8em !important;
  text-align: center !important;
  justify-content: center !important;
  font-weight: 400;
  z-index: 10000;
  top: -12px !important;
}
.tradecalculatedinfo_typestrategyopen {
  color: #fdf0a7;
  background-color: transparent;
}
.tradecalculatedinfo_typestrategylong {
  color: #8ddcb4;
  background-color: transparent;
}
.tradecalculatedinfo_typestrategyshort {
  color: #ffa09f;
  background-color: transparent;
}

.tradecalculatedinfo_opendisplay {
  position: absolute;
  top: 0px;
  left: 5px;
  font-size: 0.9em;
  font-weight: 400;
}
.flushrighttext-top {
  display: block;
  text-align: right;
  padding-right: 50px;
  margin-left: 20px;
  font-weight: 400;
  width: 0px;
  height: 0px;
  min-height: 0px;
  color: #fbd665;
  white-space: nowrap;
  cursor: text;
}
.tradecalculatedinfo_orderNumber {
  text-transform: none;
  font-size: 1.2em;
  font-weight: 300;
  color: #deebf7;
  margin: 22px 10px 0px 50px;
  right: 20px;
  position: absolute;
  z-index: 1001;
}

.tradecalculatedinfo_toprowshort {
  display: flex;
  position: absolute;
  justify-content: left;
  align-items: left;
  color: #fa798b;
  width: 40%;
  height: 70px;
  left: 20px;
  padding: 0px 0px 0px 14px;
  top: 20px;
  background-image: linear-gradient(to bottom right, #91404b, #222a35);
  border-radius: 14px;
}
.tradecalculatedinfo_toprowlong {
  display: flex;
  position: absolute;
  justify-content: left;
  align-items: left;
  color: #55f8a6;
  width: 40%;
  height: 70px;
  left: 20px;
  padding: 0px 0px 0px 14px;
  top: 20px;
  background-image: linear-gradient(to bottom right, #3f8a65, #222a35);
  border-radius: 14px;
}
.stackeddatadwwrapper {
  display: flex;
  flex-flow: column;
  width: 40%;
}
.text-input-boxesDT {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 1px solid #14181e;
  border-radius: 8px;
  height: 48px;
  color: #fbd665;
  background-color: #1a2028;
  outline-style: none;
  font-weight: 400;
  width: calc(100% - 68px);
  right: 14px;
  top: -4px;
  border-radius: 8px;
}
.text-input-boxesDT > div {
  height: 48px;
  border-radius: 8px;
  font-size: 1em;
}
.percentMetricsWrapper {
  display: flex;
  flex-direction: row nowrap;
  margin-left: 32px;
  margin-top: -10px;
  width: 40%;
}
.text-input-boxesDT:focus {
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: all 0.1s;
  border-radius: 8px;
}
.stackeddatafeestop {
  font-size: 1.2em;
  display: flex;
  width: 100%;
  padding: 18px 4px 14px 14px;
  border-radius: 10px;
  margin: 22px 0px 6px 70px;
  border: 1px solid #1a2028;
  height: 68px;
}
.tradecalculatedinfo_toprowopen {
  display: flex;
  position: absolute;
  justify-content: left;
  align-items: left;
  width: 40%;
  color: #fbd665;
  height: 70px;
  left: 20px;
  padding: 0px 0px 0px 14px;
  top: 20px;
  background-image: linear-gradient(to bottom right, #9e8a4e, #222a35);
  border-radius: 14px;
}
.tradecalculatedinfo_toprowfp {
  display: flex;
  position: absolute;
  justify-content: left;
  align-items: left;
  width: 40%;
  color: #98b5eb;
  height: 70px;
  left: 20px;
  padding: 0px 0px 0px 10px;
  top: 20px;
  background-image: linear-gradient(to bottom right, #5d6f8f, #222a35);
  border-radius: 14px;
}
.tradecalculatedinfo_ME {
  position: relative;
  display: flex;
  justify-content: right;
  align-items: right;
  text-align: right;
  flex-flow: column;
  flex: 1;
  margin-right: 14px;
  margin-top: 6px;
}
.tradecalculatedinfo_slip {
  position: relative;
  display: flex;
  justify-content: right;
  align-items: right;
  text-align: right;
  flex-flow: column;
  flex: 2;
  margin-right: 14px;
  margin-top: 30px;
}
.tradecalculatedinfo_PL {
  display: flex;
  flex: 1;
  margin-top: 14px;
  margin-left: 8%;
  flex-flow: column;
  position: relative;
  justify-content: center;
  align-items: center;
}
.riskgraphmetricvaluewrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.tradecalculatedinfo_ratioswrapper {
  display: block;
  position: absolute;
  font-weight: 400;
  width: calc(40%);
  margin-left: calc(42% + 24px);
}

.tradecalculatedinfo_verticalline {
  display: none;
  position: relative;
  background-color: #414958;
  height: calc(100% - 48px);
  z-index: 100000000000;
  margin-left: 50px;
  margin-right: -10px;
  margin-top: 24px;
  width: 1px;
}
.singlepinmetricmetric {
  position: relative;
  font-size: 1.1em;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 51px;
  left: 4px;
  font-weight: 500;
}
.singlepinneddivtextwrapper {
  width: 100%;
  height: 30%;
  margin-top: -10%;
  position: absolute;
  align-items: center;
  justify-content: center;
}
#viewTradeEfficiencyBarOuter {
  width: 100%;
  display: block;
  text-align: center;
  position: absolute;
  padding-left: 106px;
}
#viewTradeimportantmetricgraphwraper,
#viewTradeimportantmetricgraphwraper2,
#viewTradeimportantmetricgraphwraper3 {
  z-index: 1000000;
  top: 50%;
  display: flex;
  flex: row nowrap;
  position: relative;
  margin: -2px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  width: 100%;
}
#viewTradeimportantmetricgraphwraper3 {
  z-index: 1000000;
  top: 50%;
  display: flex;
  flex: row nowrap;
  position: relative;
  margin: -3px 0px 0px 2px;
  padding: 0px 0px 0px 0px;
  width: calc(100% - 4px);
}
.singlepinnedMetricsWrapper {
  display: flex;
  flex: 1;
  min-width: 100px;
}
.singlepinmetricactual {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  padding: 0;
  margin: 0;
  top: -20px;
}
.singlepinmetricheader {
  color: #8193b3;
  font-size: 1.1em;
  font-weight: 300;
  margin-top: -4px;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  display: flex;
}
.riskmetriclabel {
  position: fixed;
  font-size: 1.1em;
  font-weight: 400;
  bottom: 10px;
  width: 100%;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  display: flex;
}
.singlepinmetric {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  display: block;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 16px 0px 0px 0px;
  margin: 16px 0px 0px 40%;
  border-radius: 10px;
  width: 100%;
  height: calc(100% - 48px);
  max-width: 106px;
}
.tradecalculatedinfo_Efftitle {
  display: flex;
  flex-direction: row;
  color: #8193b3;
  font-weight: 300;
  font-size: 1.2em;
  margin: 12px 40px 0px -20px;
  white-space: nowrap;
}
.tradecalculatedinfo_PLtitle {
  color: #8193b3;
  font-weight: 300;
  font-size: 1.2em;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  text-align: right;
  justify-content: right;
}
.tradecalculatedinfo_PLvalue {
  font-size: 1.4em;
  font-weight: 400;
  color: #fff7cb;
  white-space: nowrap;
}
.tradecalculatedinfo_PLvaluemain {
  font-size: 1.65em;
  font-weight: 400;
  color: #fff7cb;
  white-space: nowrap;
}
.tradecalculatedinfo {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  width: calc(100% - 58px);
  background-color: #1f2831;
  height: 310px;
  margin: 24px 28px 28px 28px;
  padding: 0px;
  border-radius: 10px;
  -moz-box-shadow: 0 0 14px #14181e;
  -webkit-box-shadow: 0 0 14px #14181e;
  box-shadow: 0 0 14px #14181e;
}
.viewtradetradeformwrapper {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  display: inline-block;
  vertical-align: top;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 0px 0px;
  right: 0;
  width: 100%;
  padding: 0px 0px 32px 0px;
}
.addimage2 {
  margin: 0px 28px 28px 29px;
  border-top: 50px solid #28303c;
  position: relative;
  border-radius: 10px;
  width: calc(100% - 54px);
  background-color: #1f2831;
  padding: 0 22px 10px 25px;
  z-index: 0;
  display: block;
  height: 326px;
  right: 0px;
  box-shadow: 0 0 4pt 2pt #171c23;
}
.addimage2 > div:last-child > div {
  width: calc(100% - 49px);
}
.showtradecommentswrappersuper2 {
  width: calc(100% - 56px);
  margin: 0px 0px 20px 0px;
  padding: 0px 0px 18px 0px;
}
.showtradecommentswrappersuper {
  width: calc(100% - 58px);
  margin: 0px 0px 20px 2px;
  padding: 0px 0px 18px 0px;
}
.showimagedatasuperwrapper {
  width: calc(100% - 54px);
  margin: 0px 32px 0px 32px;
  padding: 0px 0px 30px 0px;
  height: 260px;
}
.nocomments {
  font-size: 1em;
  position: relative;
  left: -20px;
  top: 50px;
  margin: auto;
  font-style: italic;
  color: #a4a9b1;
  cursor: pointer;
}
.cursorpointer {
  cursor: pointer;
}
.pointValuewrapper {
  position: relative;
  margin: 14px 0px 0px 0px;
  padding: 0px;
  z-index: 0;
}
.clearEditExecutionButton,
.clearEditExecutionButton2 {
  background-color: #bbbbbb;
  color: #1a2028;
  font-family: "Roboto";
  font-weight: 400;
  border: none;
  text-align: center;
  z-index: 200 !important;
  cursor: pointer;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  font-size: 1em;
  font-weight: 400;
  border-radius: 10px;
  padding-top: 2px;
  position: absolute;
  width: 100px !important;
  left: 36px !important;
  bottom: 18px;
  font-weight: 600 !important;
  height: 40px;
  width: calc(100% - 36px);
}

.clearEditExecutionButton:hover {
  outline-style: none;
  border: none;
  color: #222a35;
  background-color: #d6d6d6;
}
.edittradehistroytablebutton {
  position: absolute;
  background-color: #ff8080;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  color: #14181e;
  padding: 10px 0px 10px 0px;
  right: 0px;
  width: 100px;
  top: 24px;
  margin: 0px 34px 8px 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.edittradehistroytablebutton:hover {
  outline-style: none;
  border: none;
  background-color: #ffa09f;
}
.edittradehistroytablebutton:focus {
  outline: none;
}

.edittradehistroytablebutton2 {
  position: absolute;
  background-color: #c7b8ff;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 8px;
  color: #14181e;
  padding: 10px 0px 10px 0px;
  right: 0px;
  width: 100px;
  top: 24px;
  margin: 0px 34px 8px 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.edittradehistroytablebutton2:hover {
  outline-style: none;
  border: none;
  background-color: #d4c9fd;
}
.edittradehistroytablebutton2:focus {
  outline: none;
}

.showEDITExecsBox {
  height: 156px !important;
  width: 100% !important;
  margin: 0px 20px 0px 0px !important;
  overflow-y: auto !important;
  overflow-x: none !important;
  text-align: center !important;
  justify-content: center !important;
  padding: 6px 2px 0px 2px !important;
  background-color: #171c23 !important;
  border: 1px solid #14181e !important;
}
.showEDITExecsBoxwrapper {
  width: 100% !important;
  margin: 0px 20px 6px 0px !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}
.tradecalculatedinfo_typestrategyopen,
.tradecalculatedinfo_typestrategylong,
.tradecalculatedinfo_typestrategyshort,
.tradecalculatedinfo_typestrategyfp {
  position: absolute;
  display: block;
  white-space: nowrap;
  margin: 28px 10px 0px 6px;
  padding: 4px 8px 0px 8px;
  border-radius: 8px;
  height: 0px !important;
  font-size: 0.9em;
  text-align: left;
  justify-content: left;
  font-weight: 400;
  z-index: 10000;
  top: 18px;
}
.tradecalculatedinfo_typestrategyopen {
  color: #fdf0a7;
  background-color: transparent;
}
.tradecalculatedinfo_typestrategylong {
  color: #8ddcb4;
  background-color: transparent;
}
.tradecalculatedinfo_typestrategyshort {
  color: #ffa09f;
  background-color: transparent;
}
.tradecalculatedinfo_typestrategyfp {
  color: #fdf0a7;
  background-color: transparent;
}
.optionsOptionals2 {
  position: relative;
  left: 332px;
  top: -214px;
  width: 50%;
}
.optionsOptionals23 {
  position: relative;
  left: 332px;
  top: -214px;
  width: 50%;
}
.VTPselectionfieldsDT {
  width: 100%;
  padding-right: 38px;
  color: #deebf7;
  z-index: 2147483647 !important;
}
.VTPselectionfields {
  width: 100%;
  padding-right: 38px;
  color: #deebf7;
}
.VTPselectionfields > div > input::placeholder {
  color: #fbd665;
  opacity: 0.7;
}
.VTPselectionfields > .required::placeholder {
  color: #fa798b !important; /* Replace #yourColorHere with your desired color */
  opacity: 1 !important; /* CSS standard requires setting the opacity for the color to take full effect */
}
.VTPselectionfields
  > div:nth-of-type(1)
  > div:nth-of-type(1)
  > div:nth-of-type(1) {
  overflow-x: hidden;
  overflow-y: hidden;
  word-wrap: none;
  flex-flow: row wrap;
  align-items: left !important;
  justify-content: left !important;
}
.VTPselectionfieldsNOTRequiredOPTION {
  width: 100%;
  padding-right: 38px;
}
.VTPselectionfieldsNOTRequiredOPTION > div > div > input::placeholder {
  color: #808080;
}
.VTPselectionfieldsNOTRequiredOPTION > div > input::placeholder {
  color: #808080;
}
.VTPselectionfieldsNOTRequiredOPTIONf {
  width: 100%;
  padding-right: 38px;
}
.VTPselectionfieldsNOTRequiredOPTIONf > div > div > input::placeholder {
  color: #808080;
}
.VTPselectionfieldsNOTRequiredOPTIONf > div > input::placeholder {
  color: #808080;
}
.viewtradepageverifiedimage {
  width: 26px;
  height: 26px;
  margin-top: -2px;
}
.viewtradepageverifiedimage2 {
  margin-top: -4px !important;
}
.portfolioVTPshow {
  border-radius: 10px;
  width: fit-content;
  height: 30px;
  cursor: default;
  bottom: 20px;
  right: 26px;
  position: absolute;
  line-height: 30px;
  padding: 1px 10px 0px 10px;
}
.exercised-container-vtp {
  display: block !important;
  width: 110px;
  position: relative;
  padding: 0px 6px 0px 6px;
  margin: 0px 0px 6px 0px;
}
.verifiedimagewrapper {
  position: absolute;
  cursor: default;
  top: 86px;
  right: 26px;
}
#verifiedimagetext {
  position: relative;
  top: 2px;
  font-weight: 500;
  background: linear-gradient(to bottom right, #ffeba8, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -ms-text-fill-color: transparent;
}
.TableModal {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  width: 500px;
  text-align: center;
  z-index: 100000001 !important;
  margin: -227px 0px 0px -250px;
  top: 50%;
  font-size: 1em;
  left: 50%;
  height: 454px;
  border-radius: 14px;
  color: #deebf7;
  transition: all 0.5s;
  background-color: #222a35;
  outline-style: none;
  border: none;
  display: flex;
  flex-flow: column;
  flex: auto;
  padding: 0px 0px 0px 36px;
  border-top: 50px solid #1a2028;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.topadjustoptionoptional2 {
  margin-top: -28px;
}
@media screen and (max-width: 610px) {
  .TableModalOptions {
    width: calc(100% - 60px) !important;
    margin: -223px 0px 0px calc(-50% + 30px) !important;
  }
  .optionsOptionals2 {
    position: absolute;
    left: auto !important;
    right: 0px !important;
    top: 80px;
    width: calc(50% - 18px) !important;
  }
  .optionsOptionals23 {
    position: absolute;
    left: auto !important;
    right: 0px !important;
    top: 18px;
    width: calc(50% - 18px) !important;
  }
  .topadjustoptionoptional2 {
    margin-top: -18px;
    width: calc(50%) !important;
  }
}
@media screen and (max-width: 515px) {
  .TableModalOptions {
    width: calc(100% - 60px) !important;
    margin: -238px 0px 0px calc(-50% + 30px) !important;
    height: 476px !important;
  }
  .clearEditExecutionButton {
    font-size: 0.85em;
    font-weight: 400;
    position: absolute;
    width: 64px !important;
    height: 30px !important;
  }
  .optionsOptionals2 {
    position: relative;
    left: auto !important;
    right: 0px !important;
    top: 4px;
    width: calc(100% + 26px) !important;
  }
  .optionsOptionals23 {
    position: relative;
    left: auto !important;
    right: 0px !important;
    top: 4px;
    width: calc(100% + 26px) !important;
  }
  .topadjustoptionoptional2 {
    margin-top: 0px;
  }
  .editExecutionModalInputs2 {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    width: calc(100% - 16px) !important;
    margin-left: -8px;
    height: 326px !important;
  }
  .editExecutionModalInputs2::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    color: #1f2831;
    background-color: #1f2831;
    border-radius: 8px;
  }
  .editExecutionModalInputs2::-webkit-scrollbar-track {
    border-radius: 8px;
    border: #a4a9b1;
    color: #1f2831;
    background-color: #1f2831;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .editExecutionModalInputs2::-webkit-scrollbar-thumb {
    border-radius: 8px;
    color: #14181e;
    background-color: #14181e;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  .editExecutionModalInputs2::-webkit-scrollbar-corner {
    background-color: #1f2831;
    border-bottom-right-radius: 5px;
  }
  .editExecutionModalInputs3 {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    width: calc(100% - 16px) !important;
    margin-left: -8px;
    height: 326px !important;
    top: 0px !important;
  }
  .editExecutionModalInputs3::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    color: #1f2831;
    background-color: #1f2831;
    border-radius: 8px;
  }
  .editExecutionModalInputs3::-webkit-scrollbar-track {
    border-radius: 8px;
    border: #a4a9b1;
    color: #1f2831;
    background-color: #1f2831;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .editExecutionModalInputs3::-webkit-scrollbar-thumb {
    border-radius: 8px;
    color: #14181e;
    background-color: #14181e;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  .editExecutionModalInputs3::-webkit-scrollbar-corner {
    background-color: #1f2831;
    border-bottom-right-radius: 5px;
  }
  .ANTselectionfieldsRequiredOPTION {
    width: calc(100% + 26px);
    padding-right: 38px;
  }
  .VTPselectionfieldsDT {
    width: calc(100% + 26px);
    padding-right: 38px;
  }
  .VTPselectionfields {
    width: calc(100% + 26px);
    padding-right: 38px;
  }
  .VTPselectionfieldsNOTRequiredOPTION {
    width: calc(100% + 26px);
    padding-right: 38px;
  }
  .VTPselectionfieldsNOTRequiredOPTIONf {
    width: calc(100%);
    padding-right: 38px;
  }
}
@media screen and (max-width: 451px) {
  .TableModal {
    position: absolute;
    width: calc(100% - 62px);
    text-align: center;
    z-index: 100000001 !important;
    margin: -224px 0px 0px calc(-50% + 31px);
    top: 50%;
    font-size: 1em;
    left: 50%;
    height: 448px;
    border-radius: 8px;
    color: #deebf7;
    display: flex;
    flex-flow: column;
    flex: auto;
    padding: 0px 0px 0px 36px;
    border-top: 50px solid #1a2028;
  }
}
.modalSymbolHeader5 {
  z-index: 1000;
  width: 340px;
  margin-top: -39px;
  margin-left: -11px;
  margin-bottom: 20px;
  position: relative;
  color: #fbd665;
  font-size: 1.6em;
  padding-bottom: 4px;
  text-align: left;
  font-weight: 300;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.commentsboxVT {
  word-break: break-all;
  border: 1px solid #14181e;
  border-radius: 8px;
  color: #fbd665;
  background-color: #1a2028;
  outline-style: none;
  position: relative;
  height: 156px;
  top: 0px;
  margin: -44px 0px 46px 0px;
  width: 100%;
  left: 0px;
  padding: 6px 10px 0px 10px;
}
.commentsboxVT::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.commentsboxVT::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.commentsboxVT::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #1a2028;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.commentsboxVT::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.commentsboxVT:focus {
  word-break: break-all;
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: 0.1s;
  border-radius: 8px;
}
.editExecutionModalInputs2 {
  text-align: left !important;
  padding-top: 2px;
  margin-bottom: -20px !important;
}
.editExecutionModalInputs3 {
  text-align: left !important;
  height: 322px;
  position: relative;
  padding-top: 0px;
  top: 3px;
}

.editraderedobutton {
  position: absolute;
  outline: none;
  border: none;
  z-index: 1;
  right: 196px;
  top: 0px;
  height: 62px;
  outline: none;
  border: none;
  font-weight: bold;
  font-size: 38px;
  transform: scaleX(-1);
  transition: all 0.25s;
  border-radius: 8px;
  color: #a6afc2;
  bottom: 14px;
  padding: 10px 0px 10px 0px;
  margin: 0px 0px 5px 0px;
  background-color: transparent;
  cursor: pointer;
}
.editraderedobutton:hover {
  outline-style: none;
  border: none;
  -webkit-filter: drop-shadow(0 0 3px #a6afc2);
  -moz-filter: drop-shadow(0 0 3px #a6afc2); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 3px #a6afc2);
  filter: drop-shadow(0 0 3px #a6afc2);
}
.moveleftmore {
  left: 30px;
}
.resetbuttontext {
  position: relative;
  top: -4px;
}
.editraderedobuttonmove1 {
  right: 228px;
}
.edittradedeletebutton {
  position: absolute;
  color: #ff8080;
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: bold;
  transition: all 0.25s;
  border-radius: 8px;
  padding: 0px 0px 0px 0px;
  right: 18px;
  font-size: 36px;
  width: fit-content;
  height: fit-content;
  top: 10px;
  margin: 0px 0px 8px 0px;
}
.edittradepencil {
  color: #556171;
  &:hover {
    color: #deebf7;
    cursor: pointer;
  }
}
.edittradedeletebutton:hover {
  outline-style: none;
  border: none;
  -webkit-filter: drop-shadow(0 0 3px #ff8080);
  -moz-filter: drop-shadow(0 0 3px #ff8080); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 3px #ff8080);
  filter: drop-shadow(0 0 3px #ff8080);
  .radio-label-buy-tooltip {
    z-index: 10000000000 !important;
    height: auto;
    width: 72px;
    position: absolute;
    color: #ff8080;
    display: block;
    background-color: #14181e;
    border-radius: 8px;
    padding: 0px 10px 1px 11px;
    top: 70px;
    font-size: 17px;
    font-weight: 300;
  }
}
.edittradedeletebutton:focus {
  outline: none;
}
.sharetradehistroytablebuttongreen {
  color: #55f8a6;
  &:hover {
    -webkit-filter: drop-shadow(0 0 3px #55f8a6);
    -moz-filter: drop-shadow(0 0 3px #55f8a6); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 3px #55f8a6); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 3px #55f8a6);
    .radio-label-buy-tooltip {
      color: #55f8a6;
    }
  }
}
.sharetradehistroytablebuttonred {
  color: #fa798b;
  &:hover {
    -webkit-filter: drop-shadow(0 0 3px #fa798b);
    -moz-filter: drop-shadow(0 0 3px #fa798b); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 3px #fa798b); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 3px #fa798b);
    .radio-label-buy-tooltip {
      color: #fa798b;
    }
  }
}
.sharetradehistroytablebutton {
  position: absolute;
  outline: none;
  border: none;
  z-index: 1;
  right: -10px;
  top: 12px;
  height: 60px;
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: bold;
  transition: all 0.25s;
  border-radius: 8px;
  width: 100px;
  color: #98b5eb;
  font-size: 36px;
  bottom: 0px;
  padding: 10px 0px 10px 0px;
  margin: 46px 0px 5px 0px;
  &:hover {
    -webkit-filter: drop-shadow(0 0 3px #98b5eb);
    -moz-filter: drop-shadow(0 0 3px #98b5eb); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 3px #98b5eb); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 3px #98b5eb);
  }
}
.sharetradehistroytablebutton:hover {
  outline-style: none;
  border: none;
}
.sharetradehistroytablebutton:focus {
  outline: none;
}
.togglenexttradesbutton1 {
  position: absolute;
  outline: none;
  border: none;
  z-index: 0;
  top: 8px;
  right: 80px;
  transform: rotate(180deg);
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: bold;
  transition: all 0.25s, top 0s;
  border-radius: 8px;
  color: #a6afc2;
  font-size: 36px;
  width: fit-content;
  height: fit-content;
  bottom: 0px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 5px 0px;
  &:hover {
    -webkit-filter: drop-shadow(0 0 3px #deebf7);
    -moz-filter: drop-shadow(0 0 3px #deebf7); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 3px #deebf7); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 3px #deebf7);
  }
}
.togglenexttradesbutton2 {
  position: absolute;
  outline: none;
  border: none;
  z-index: 0;
  top: 10px;
  right: 18px;
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: bold;
  transition: all 0.25s, top 0s;
  border-radius: 8px;
  color: #a6afc2;
  width: fit-content;
  height: fit-content;
  font-size: 36px;
  bottom: 0px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 5px 0px;
  &:hover {
    -webkit-filter: drop-shadow(0 0 3px #deebf7);
    -moz-filter: drop-shadow(0 0 3px #deebf7); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 3px #deebf7); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 3px #deebf7);
  }
}
.togglenexttradesbuttonWrapper {
  position: absolute;
  outline: none;
  border: none;
  z-index: 0;
  right: 58px;
  top: 0px;
}
.TableModalOptions {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  width: 700px;
  text-align: center;
  z-index: 100000001 !important;
  margin: -223px 0px 0px -350px;
  top: 50%;
  font-size: 1em;
  left: 50%;
  height: 446px;
  border-radius: 14px;
  color: #deebf7;
  transition: all 0.5s;
  background-color: #222a35;
  outline-style: none;
  border: none;
  display: flex;
  flex-flow: column;
  flex: auto;
  padding: 0px 0px 0px 36px;
  border-top: 50px solid #1a2028;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.movedeletebutton {
  top: 80px !important;
  right: 28px;
}
.movecancelbutton {
  left: 60px !important;
  height: 44px;
  top: 80px;
}
.deletemodaltext {
  font-size: 18px;
  color: #deebf7;
  margin-left: -28px;
  padding: 0px 16px 24px 16px;
}
.modalSymbolHeader4 {
  z-index: 1000;
  width: calc(100% + 33px);
  margin-top: -39px;
  margin-left: -34px;
  margin-bottom: 20px;
  position: relative;
  color: #fbd665;
  font-size: 1.6em;
  padding-bottom: 4px;
  text-align: center;
  font-weight: 300;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.ConfirmationModal {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  width: 350px;
  text-align: center;
  z-index: 100000001 !important;
  margin: -95px 0px 0px -190px;
  top: 50%;
  left: 50%;
  height: 190px;
  border-radius: 14px;
  color: #fbd665;
  transition: all 0.5s;
  background-color: #222a35;
  outline-style: none;
  border: none;
  display: flex;
  flex-flow: column;
  flex: auto;
  padding: 0px 0px 0px 36px;
  border-top: 50px solid #1a2028;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.exitsharebutton {
  transition: height 0.25s;
  position: absolute;
  padding: 3px 0 0px 0px;
  margin: -10px auto auto auto;
  height: 26px;
  width: 26px;
  top: -35px;
  border-radius: 30px;
  z-index: 1000000001;
  color: #deebf7;
  font-weight: 200;
  font-size: 2.2em;
  right: 18px;
  display: inline-block;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  cursor: pointer;
}
.exitsharebutton:after {
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
  position: absolute;
  transition: none;
  top: -12px;
  left: 11px !important;
  color: #7689a1;
  font-size: 36px;
  height: 10px;
  width: 10px;
}
.exitsharebutton:hover:after {
  color: #94aac7;
}
.ShareModal {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  width: 420px;
  text-align: center;
  z-index: 100000001 !important;
  margin: -280px 0px 0px -210px;
  top: 50%;
  left: 50%;
  height: 560px;
  border-radius: 14px;
  color: #fbd665;
  transition: all 0.5s;
  background-color: #222a35;
  outline-style: none;
  border: none;
  display: flex;
  flex-flow: column;
  flex: auto;
  padding: 0px 0px 0px 36px;
  border-top: 50px solid #1a2028;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.showtradecommentswrapper {
  position: relative;
  font-size: 1.1em;
  z-index: 1;
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: left;
  padding: 0px 0px 100px 18px;
  margin: 0px 32px 0px 90px;
  border: 1px solid #14181e;
  border-radius: 10px;
  flex: row wrap;
  background-color: #1a2028;
  color: #fbd665;
  overflow-y: scroll;
  word-break: break-all;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  overflow-x: hidden;
  &:focus {
    outline: 1px solid #deebf7;
    border: 1px solid #deebf7;
  }
}
.showtradecommentswrapper::-webkit-scrollbar {
  height: 9px;
  width: 9px;
  color: #1f2831;
  background-color: #1f2831;
  border-radius: 10px;
}
.showtradecommentswrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.showtradecommentswrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #1a2028;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.showtradecommentswrapper::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.viewExerciseStockOrderbutton {
  background-image: linear-gradient(to bottom right, #ffe593, #8f783e);
  color: #1a2028;
  font-family: "Roboto";
  font-weight: 400;
  border: none;
  text-align: center;
  font-size: 1em;
  font-weight: 400;
  border-radius: 9px;
  transition: all 0.15s;
  bottom: 66px;
  margin-top: 5px;
  margin-right: 36px;
  height: 26px;
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 1px 10px 1px 10px;
}
.viewtradetradeformwrapper[max-width~="792px"] .showtradedatawrapper {
  flex-flow: column;
}
.viewtradetradeformwrapper[max-width~="792px"] .showtradedatawrapperupper {
  flex-flow: column;
  padding-right: 0px !important;
}
.viewtradetradeformwrapper[max-width~="792px"] .showtradedatacol1 {
  max-width: 100% !important;
}
.viewtradetradeformwrapper[max-width~="792px"] .showtradedatacol2 {
  max-width: 100% !important;
}
.viewtradetradeformwrapper[max-width~="792px"] .showEDITExecsBoxwrapper {
  width: calc(100% - 24px) !important;
  margin: 0px 20px 6px 0px !important;
}
.showEDITExecsBoxprice {
  color: #fff7cb;
  display: flex;
  margin-left: 6px;
  margin-bottom: 7px;
  font-size: 1em;
}
.showEDITExecsBoxpriceinner {
  color: #fff7cb;
  display: inline-block;
  font-weight: 400;
  margin-left: 10px;
}
.showEDITExecsBoxpriceinner2 {
  color: #fff7cb;
  margin: 0px 0px 0px 34px;
}
.showEDITExecsBoxtext {
  color: #fbd665;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}
.showEDITExecsBoxtext:before,
.showEDITExecsBoxtext:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
.showEDITExecsBoxtext:before {
  margin-right: 14px;
}
.showEDITExecsBoxtext:after {
  margin-left: 14px;
}
.executionwrapper {
  margin-left: 60px;
  position: relative;
  left: -40px;
  display: inline-block;
}
.addexecedittrade {
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: 400;
  color: #fbd665;
  border-radius: 4px;
  padding: 1px 6px 1px 6px;
  font-size: 24px;
  top: 4px;
  position: relative;
  margin: 0px 6px -4px 0px;
  &:hover {
    -webkit-filter: drop-shadow(0 0 2px #fbd665);
    -moz-filter: drop-shadow(0 0 2px #fbd665); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 2px #fbd665);
    filter: drop-shadow(0 0 2px #fbd665);
    .radio-label-buy-tooltip {
      z-index: 10000000000 !important;
      height: auto;
      width: 132px;
      position: absolute;
      color: #fbd665;
      display: block;
      background-color: #14181e;
      border-radius: 8px;
      padding: 0px 10px 1px 11px;
      top: 46px;
      left: -26px;
      font-size: 17px;
      font-weight: 300;
    }
  }
}
.clearExitExecutionButton {
  background-color: #bbbbbb;
  color: #1a2028;
  font-family: "Roboto";
  font-weight: 400;
  border: none;
  text-align: center;
  z-index: 200 !important;
  cursor: pointer;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  font-size: 1em;
  font-weight: 400;
  border-radius: 10px;
  bottom: 18px;
  position: absolute;
  width: 100px !important;
  left: 36px !important;
  font-weight: 600 !important;
  height: 40px;
  width: calc(100% - 36px);
}
.clearExitExecutionButton:hover {
  outline-style: none;
  border: none;
  color: #222a35;
  background-color: #d6d6d6;
}
.addexecedittradeerror {
  color: #ff8080;
  margin-top: 20px;
  transition: all 0.25s ease-in-out;
}
.addexecedittradeerror2 {
  display: none;
  color: #ff8080;
  margin-top: 0px;
  top: 0px;
  position: absolute;
  font-size: 0.9em;
  transition: all 0.25s ease-in-out;
}
@media screen and (max-width: 1680px), (max-height: 900px) {
  .viewtradetradeformwrapper {
    //font-size: 0.85em !important;
  }
  .viewtradetradeformwrapper[max-width~="1120px"] .percentMetricsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 80px 60px;
    margin-left: -0px;
    position: absolute;
    right: 140px !important;
    top: 138px;
    margin-top: -8px;
    width: 31% !important;
  }
  .viewtradetradeformwrapper[max-width~="720px"] .percentMetricsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 80px 12.5% !important;
    margin-left: -10px;
    position: absolute;
    right: 13% !important;
    top: 138px;
    margin-top: -8px;
    width: 40% !important;
  }
  .viewtradetradeformwrapper[max-width~="543px"] .percentMetricsWrapper {
    right: -29% !important;
    top: 48px !important;
    margin-top: -8px;
    gap: 80px 5% !important;
    width: calc(100% - 60px) !important;
  }
  .viewtradetradeformwrapper[max-width~="510px"] .percentMetricsWrapper {
    right: -28.5% !important;
    top: 48px !important;
    margin-top: -8px;
    gap: 80px 2% !important;
    width: calc(100% - 60px) !important;
  }
  .viewtradetradeformwrapper[max-width~="463px"] .percentMetricsWrapper {
    right: calc(17%) !important;
    top: 34px !important;
    margin-top: -8px;
    gap: 80px 12% !important;

    width: calc(66%) !important;
  }
  .viewtradetradeformwrapper[max-width~="463px"] .edittradedeletebutton {
    font-size: 26px !important;
    top: 0px;
    right: 16px;
  }
  .viewtradetradeformwrapper[max-width~="463px"] .portfolioVTPshow {
    border-radius: 10px;
    width: -moz-fit-content;
    width: fit-content;
    height: 26px !important;
    cursor: default;
    top: 17px !important;
    right: 178px !important;
    font-size: 1em;
    position: absolute;
    line-height: 26px !important;
    padding: 1px 10px 0px 10px;
    color: rgb(0, 0, 0);
  }
  .viewtradetradeformwrapper[max-width~="463px"]
    .tradecalculatedinfo_ordertypelong,
  .viewtradetradeformwrapper[max-width~="463px"]
    .tradecalculatedinfo_ordertypeshort,
  .viewtradetradeformwrapper[max-width~="463px"]
    .tradecalculatedinfo_ordertypeopen,
  .viewtradetradeformwrapper[max-width~="463px"]
    .tradecalculatedinfo_ordertypefp {
    position: relative;
    display: flex;
    white-space: nowrap;
    margin: 0px 10px 0px 10px;
    padding: 4px 8px 0px 10px;
    font-size: 1.3em;
    text-align: left;
    justify-content: left;
    font-weight: 400;
    z-index: 10000;
    border-radius: 8px;
    vertical-align: top;
    height: 30px;
    top: 20px;
  }
  .viewtradetradeformwrapper[max-width~="463px"] .togglenexttradesbutton2 {
    font-size: 26px !important;
    top: -34px;
    right: 0px !important;
  }
  .viewtradetradeformwrapper[max-width~="463px"] .togglenexttradesbutton1 {
    font-size: 26px !important;
    top: -26px !important;
    right: 44px !important;
  }
  .viewtradetradeformwrapper[max-width~="463px"] .singlepinnedMetricsWrapper {
    max-width: 50% !important;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 586px;
    right: calc(0px) !important;
  }
  .viewtradetradeformwrapper[max-width~="463px"] .singlepinmetric {
    @import url("https: //fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
    font-family: "Roboto", sans-serif;
    display: block;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 16px 0px 0px 0px;
    margin: 16px 0px 0px 0px;
    border-radius: 10px;
    width: 100%;
    height: calc(100% - 48px);
    max-width: 106px;
  }
  .viewtradetradeformwrapper[max-width~="463px"] .tradecalculatedinfo {
    flex-flow: column;
    height: 1000px !important;
  }
}
// Below 1120px
.viewtradetradeformwrapper[max-width~="1120px"] .showtradedatawrapperupper {
  flex-flow: column;
  padding-right: 24px;
}
.viewtradetradeformwrapper[max-width~="1120px"] .tradecalculatedinfo {
  flex-flow: column;
  height: 420px;
}
.viewtradetradeformwrapper[max-width~="1120px"] .tradecalculatedinfo_leftSide {
  top: 130px;
  left: 0px;
  width: calc(100% - 368px);
}
.viewtradetradeformwrapper[max-width~="1120px"]
  .tradecalculatedinfo_verticalline {
  display: none;
}
.viewtradetradeformwrapper[max-width~="1120px"]
  .tradecalculatedinfo_toprowshort {
  width: calc(100% - 368px);
}

.viewtradetradeformwrapper[max-width~="1120px"]
  .tradecalculatedinfo_toprowlong {
  width: calc(100% - 368px);
}
.viewtradetradeformwrapper[max-width~="1120px"]
  .tradecalculatedinfo_toprowopen {
  width: calc(100% - 368px);
}
.viewtradetradeformwrapper[max-width~="1120px"] .tradecalculatedinfo_toprowfp {
  width: calc(100% - 368px);
}

.viewtradetradeformwrapper[max-width~="1120px"]
  .tradecalculatedinfo_ratioswrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  position: absolute;
  justify-content: center;
  width: 72%;
  top: 188px;
  margin-left: 8px;
  left: 0px;
}
.viewtradetradeformwrapper[max-width~="1094px"]
  .tradecalculatedinfo_ratioswrapper {
  width: 67%;
}
.viewtradetradeformwrapper[max-width~="994px"]
  .tradecalculatedinfo_ratioswrapper {
  width: 62%;
}
.viewtradetradeformwrapper[max-width~="894px"]
  .tradecalculatedinfo_ratioswrapper {
  width: 57%;
}
.viewtradetradeformwrapper[max-width~="1120px"] .singlepinnedMetricsWrapper {
  width: 50%;
  max-width: 130px;

  display: flex;
  right: 0px;
  flex-direction: column;
  position: relative;
}
.viewtradetradeformwrapper[max-width~="1120px"] .stackeddatadwwrapper {
  display: flex;
  flex-flow: column;
  width: calc(100% - 82px);
  margin: 150px 0px 0px -30px;
}
// Below 1120px
.viewtradetradeformwrapper[max-width~="1120px"] .tradecalculatedinfo {
  flex-flow: column;
  height: 630px;
}
.viewtradetradeformwrapper[max-width~="1120px"] .verifiedimagewrapper {
  position: absolute;
  top: 6px;
  right: auto;
  left: 6px;
}

.viewtradetradeformwrapper[max-width~="1120px"] .tradecalculatedinfo_leftSide {
  flex-flow: column;
  width: calc(50% - 80px);
  margin-top: 40px;
}
.viewtradetradeformwrapper[max-width~="1120px"]
  .tradecalculatedinfo_ratioswrapper {
  width: calc(100% - 28px);
  top: 400px;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  position: absolute;
  justify-content: center;
  margin-left: 26px;
  left: 0px;
}
.viewtradetradeformwrapper[max-width~="1120px"]
  .tradecalculatedinfo_verticalline {
  display: none;
}
.viewtradetradeformwrapper[max-width~="1120px"]
  .tradecalculatedinfo_toprowshort {
  margin-top: 46px;
  width: calc(100% - 40px);
}
.viewtradetradeformwrapper[max-width~="1120px"] .percentMetricsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 80px 60px;
  margin-left: -0px;
  position: absolute;
  right: 110px;
  top: 138px;
  margin-top: -8px;
}
.viewtradetradeformwrapper[max-width~="1310px"] .editraderedobutton {
  right: 22px;
  top: 66px;
}

.viewtradetradeformwrapper[max-width~="1120px"]
  .tradecalculatedinfo_toprowlong {
  margin-top: 46px;
  width: calc(100% - 40px);
}
.viewtradetradeformwrapper[max-width~="1120px"]
  .tradecalculatedinfo_toprowopen {
  margin-top: 46px;
  width: calc(100% - 40px);
}
.viewtradetradeformwrapper[max-width~="1120px"] .tradecalculatedinfo_toprowfp {
  margin-top: 46px;
  width: calc(100% - 40px);
}
.viewtradetradeformwrapper[max-width~="1120px"] .edittradedeletebutton {
  font-size: 32px;
  top: 10px;
}
.viewtradetradeformwrapper[max-width~="1120px"] .togglenexttradesbuttonWrapper {
  right: 58px;
  position: absolute;
  top: 34px;
}
.viewtradetradeformwrapper[max-width~="1120px"] .togglenexttradesbutton1 {
  font-size: 32px;
  top: -26px;
  right: 76px;
}
.viewtradetradeformwrapper[max-width~="1120px"] .togglenexttradesbutton2 {
  font-size: 32px;
  top: -24px;
  right: 16px;
}
.viewtradetradeformwrapper[max-width~="1120px"] .editraderedobutton {
  font-size: 34px;
  top: 0px;
  right: 188px;
  top: 0px;
}
.viewtradetradeformwrapper[max-width~="463px"] .editraderedobutton {
  font-size: 27px;
  top: 0px;
  right: 142px;
  top: 0px;
}
.viewtradetradeformwrapper[max-width~="1120px"] .singlepinnedMetricsWrapper {
  width: 130px;
}
.viewtradetradeformwrapper[max-width~="1120px"] .portfolioVTPshow {
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  height: 30px;
  cursor: default;
  top: 20px;
  right: 240px;
  position: absolute;
  line-height: 30px;
  padding: 1px 10px 0px 10px;
  color: rgb(0, 0, 0);
}
.calculatedPLValue {
  display: block;
  text-align: right;
  padding-right: 50px;
  margin-left: 20px;
  font-weight: 400;
  width: 100%;
  height: max-content;
  min-height: 36px;
  color: #fbd665;
  white-space: nowrap;
  cursor: text;
}
.viewtradetradeformwrapper[max-width~="1120px"] .tradecalculatedinfo_PL {
  display: block;
  margin: 6px 0px 28px 18px;
  flex-flow: column;
  position: relative;
  justify-content: right !important;
  align-items: right !important;
  text-align: right !important;
  width: 100%;
}

.viewtradetradeformwrapper[max-width~="1120px"] .calculatedPLValue {
  display: block;
  text-align: right;
  padding-right: 0px;
  margin-left: 0px;
  font-weight: 400;
  width: 100%;
  height: max-content;
  min-height: 36px;
  color: #fbd665;
  white-space: nowrap;
  cursor: text;
}
.viewtradetradeformwrapper[max-width~="543px"]
  .tradecalculatedinfo_ratioswrapper {
  width: calc(100% - 28px);
  top: 380px;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  position: absolute;
  justify-content: center;
  margin-left: 26px;
  left: 0px;
}
.viewtradetradeformwrapper[max-width~="720px"] .percentMetricsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 80px 20px;
  margin-left: -10px;
  position: absolute;
  right: 130px;
  top: 130px;
  margin-top: -8px;
  width: 50%;
}
.viewtradetradeformwrapper[max-width~="720px"] .tradecalculatedinfo {
  font-size: 0.9em;
}
//Below 543
.viewtradetradeformwrapper[max-width~="543px"] .tradecalculatedinfo {
  flex-flow: column;
  height: 830px;
}
@media screen and (min-width: 1680px) and (min-height: 900px) {
}
@media screen and (max-width: 1680px), (max-height: 900px) {
}

.viewtradetradeformwrapper[max-width~="543px"] .tradecalculatedinfo_leftSide {
  flex-flow: column;
  width: calc(100% - 40px);
  margin-top: 40px;
  text-align: center !important;
}
.viewtradetradeformwrapper[max-width~="543px"] .tradecalculatedinfo_PLtitle {
  text-align: center;
  justify-content: center;
}
.viewtradetradeformwrapper[max-width~="543px"]
  .tradecalculatedinfo_PLvaluemain {
  font-size: 1.65em;
  font-weight: 400;
  color: #fff7cb;
  white-space: nowrap;
  text-align: center;
}
.viewtradetradeformwrapper[max-width~="543px"] .calculatedPLValue {
  text-align: center;
  justify-content: center;
}
.viewtradetradeformwrapper[max-width~="543px"]
  .tradecalculatedinfo_leftSideinner {
  display: flex;
  flex-flow: column nowrap;
  height: 0px;
  margin-top: 0px;
  margin-left: 44px !important;
}
.viewtradetradeformwrapper[max-width~="543px"] .tradecalculatedinfo_PL {
  display: block;
  margin: 6px 0px 28px 22px;
  flex-flow: column;
  position: relative;
  justify-content: right !important;
  align-items: right !important;
  text-align: right !important;
  width: 100%;
}
.viewtradetradeformwrapper[max-width~="543px"] .tradecalculatedinfo_PLvalue {
  text-align: center;
  justify-content: center;
}
.viewtradetradeformwrapper[max-width~="543px"] .singlepinnedMetricsWrapper {
  width: 130px;
  position: relative;
  top: 586px;
  right: calc(50% - 16px);
}
.viewtradetradeformwrapper[max-width~="543px"] .tradecalculatedinfo_RRR {
  position: absolute;
  display: flex;
  flex-flow: column !important;
  top: 90px;
  left: 30px;
  width: 85%;
}
.viewtradetradeformwrapper[max-width~="321px"] .percentMetricsWrapper {
  width: 85% !important;
  right: 20px !important;
}
.viewtradetradeformwrapper[max-width~="253px"] .percentMetricsWrapper {
  width: 50% !important;
  justify-content: center !important;
  right: 25% !important;
}
.viewtradetradeformwrapper[max-width~="253px"] .tradecalculatedinfo {
  flex-flow: column;
  height: 1320px !important;
}
.showtradedatawrapperupper {
  display: flex;
  flex-flow: row;
  width: calc(100% - 4px);
  padding: 12px 0px 12px 24px;
  margin: 0px -34px 28px 0px;
  position: relative;
  right: 0px;
  border-radius: 10px;
  background-color: #1f2831;
  border-top: 50px solid #28303c;
  height: auto;
  -moz-box-shadow: 0 0 14px #14181e;
  -webkit-box-shadow: 0 0 14px #14181e;
  box-shadow: 0 0 14px #14181e;
}
.showtradedatawrapper {
  display: flex;
  flex-flow: row;
  width: calc(100% - 4px);
  padding: 12px 0px 12px 24px;
  margin: 0px -34px 28px 0px;
  position: relative;
  right: 0px;
  border-radius: 10px;
  background-color: #1f2831;
  border-top: 50px solid #28303c;
  height: auto;
  -moz-box-shadow: 0 0 14px #14181e;
  -webkit-box-shadow: 0 0 14px #14181e;
  box-shadow: 0 0 14px #14181e;
}
.view-trade-label {
  position: absolute;
  display: block;
  white-space: nowrap;
  margin: 0px;
  margin-bottom: -20px;
  margin-top: -44px;
  padding: 0;
  color: #fbd665;
  font-size: 1.6em;
  top: 0px;
  left: 24px;
  text-align: left;
  justify-content: left;
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */

  font-weight: 400;
  z-index: 10000;
}
.tradecalculatedinfowrapper {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: calc(40% - 32px);
  height: calc(100% + 6px);
  margin: 0px 32px 0px 0px;
  border-radius: 8px;
}
.tradecalculatedinfo_leftSideinner {
  display: flex;
  flex-flow: column nowrap;
  height: 0px;
  margin-top: 80px;
  margin-left: -20px;
  width: calc(50% - 32px);
}
.viewtradetradeformwrapper[max-width~="1120px"]
  .tradecalculatedinfo_leftSideinner {
  display: flex;
  flex-flow: column nowrap;
  height: 0px;
  margin-top: 0px;
  margin-left: -20px;
  width: calc(100% - 32px);
}
.viewtradetradeformwrapper[max-width~="1120px"]
  .tradecalculatedinfo_leftSideinner {
  display: flex;
  flex-flow: column nowrap;
  height: 0px;
  margin-top: 0px;
  margin-left: 60px;
}

.viewtradetradeformwrapper[max-width~="1120px"] .tradecalculatedinfo_ME {
  position: relative;
  display: block;
  justify-content: right !important;
  align-items: right !important;
  flex-flow: column;
  flex: 1 1;
  margin-right: 10px;
  margin-top: 2px;
  margin-bottom: 16px;
}
.viewtradetradeformwrapper[max-width~="1120px"] .tradecalculatedinfo_slip {
  position: relative;
  display: block;
  justify-content: right !important;
  align-items: right !important;
  flex-flow: column;
  flex: 1 1;
  margin-right: 10px;
  margin-top: 16px;
}
.viewtradetradeformwrapper[max-width~="1120px"] .tradecalculatedinfo_RRR {
  position: absolute;
  display: flex;
  flex-flow: row;
  top: 90px;
  left: 30px;
  width: 85%;
}
.viewtradetradeformwrapper[max-width~="1120px"] .tradecalculatedinfo_RM {
  position: absolute;
  display: flex;
  flex-flow: row;
  top: 162px;
  left: 30px;
  width: 85%;
}
.tradecalculatedinfo_leftSide {
  display: flex;
  flex-flow: row;
  position: relative;
  justify-content: center;
  width: 40%;
  top: 36px;
  font-size: 0.95em;
  margin-left: 0px;
}
.tradecalculatedinfo[max-width~="484px"] .tradecalculatedinfo_orderNumber {
  display: none;
}
