.underwatercurve-label {
  position: absolute;
  display: block;
  padding: 0;
  color: #fa798b;
  font-size: 1.6em;
  left: 39%;
  top: 6px;
  font-weight: 400;
  z-index: 10000;
  height: 40px;
  width: auto;
  background: linear-gradient(to bottom right, #a9505c, #fa798b);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */
}
.runupcurve-label {
  position: absolute;
  display: block;
  padding: 0;
  color: #55f8a6;
  background: linear-gradient(to bottom right, #4ca177, #55f8a6);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */

  font-size: 1.6em;
  left: 39%;
  top: 6px;
  font-weight: 400;
  z-index: 10000;
  height: 40px;
  width: auto;
}

.drawdown-metrics {
  position: relative;
  top: 0px;
}
.drawdown-metrics-toggle {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  position: relative;
  border-radius: 8px;
  background-color: #1f2831;
  align-items: center;
  border-spacing: 0px;
  box-shadow: 0 0 4pt 2pt #171c23;
  display: flex;
  justify-content: center;
  color: #fff7cb;
  top: -2px;
  height: 10%;
  width: 100%;
  margin: 26px 32px 0px 0px;
  font-size: 1.4em;
}
/* .dashboardwrapper[max-width~="529px"] .maingraphsdashboardwrapper {
  display: flex;
  flex-flow: row wrap;
  height: 134%;
  gap: 12px;
  margin: 0px 0px -24px 0px !important;
  padding: 8px 12px 4648px 12px !important;
} */
.drawdown-metrics-table {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border-top: 50px solid #28303c;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  position: relative;
  padding: 14px;
  border-radius: 8px;
  background-color: #1f2831;
  align-items: center;
  border-spacing: 0px;
  box-shadow: 0 0 4pt 2pt #171c23;
  display: block;
  justify-content: left;
  color: #deebf7;
  top: -2px;
  height: calc(100% - 28px - 10%);
  width: 100%;
  margin: 26px 32px 0px 0px;
}
.select-calculation-type-drawdown {
  position: absolute;
  z-index: 2147483647;
  height: 20px !important;
  width: 120px !important;
  right: -20px;
  margin-top: -50px;
}
.toggleadjust {
  top: 4px !important;
  margin: 0px 16px 0px 16px !important;
}
.select-toggler-drawdown {
  position: absolute;
  z-index: 2147483647;
  margin-top: 0px;
}
.drawdown-metrics-label {
  position: absolute;
  margin-top: -43px;
  padding: 0;
  width: 300px;
  font-size: 1.6em;
  top: 0px;
  left: 20px;
  font-weight: 400;
  z-index: 10000;
  height: 20px;
  background: linear-gradient(to bottom right, #a9505c, #fa798b);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */
}
.runup-metrics-label {
  position: absolute;
  margin-top: -43px;
  padding: 0;
  width: 300px;
  font-size: 1.6em;
  top: 0px;
  left: 20px;
  font-weight: 400;
  z-index: 10000;
  height: 20px;
  background: linear-gradient(to bottom right, #4ca177, #55f8a6);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */
}
.drawdown-metrics-table > div > div {
  width: 100%;
  height: 14.5%;
  display: flex;
  border-bottom: 1px solid #28303c;
}
.drawdown-metrics-table > div > div > div {
  width: 100%;
}
.drawdown-metrics-table > div {
  width: 100%;
  height: 100%;
  display: table;
  justify-content: center;
}
.drawdown-metrics-table > div > div:last-child {
  border-bottom: none;
}

.drawdowncomponentwrapper {
  //font-size: 0.85em;
}

.main-labels-Drawdown {
  margin-top: 10px;
  margin-bottom: 8px;
}

.tiqgreen {
  color: #55f8a6;
}
.tiqwhite {
  color: #deebf7;
}
.tiqred {
  color: #fa798b;
}
.tiqgrey {
  color: #808080;
}
.drawdownreportgraphwrapper {
  display: flex;
  transition: none;
  width: 100%;
  height: 100%;
  margin: 30px 0px -30px -6px;
  padding-bottom: 40px;
}
.drawdownreportgraphactual {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  background-color: #06080a;
}
.drawdownreportgraph {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 2px solid #14181e;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  border-radius: 8px;
  min-width: 240px;
  background-color: #1f2831;
  box-shadow: 0 0 4pt 2pt #171c23;
  padding: 8px 28px 0px 20px;
  margin: 24px 28px 0px 26px;
  display: flex;
  height: calc(100% + 10px);
  width: calc(100% - 52px);
  top: -2px;
  position: relative;
}
#randomChanceTool {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border-top: 50px solid #28303c;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  position: relative;
  padding: 8px 14px 7px 14px;
  border-radius: 8px;
  background-color: #1f2831;
  align-items: center;
  border-spacing: 0px;
  box-shadow: 0 0 4pt 2pt #171c23;
  display: flex;
  flex-flow: row;
  justify-content: left;
  color: #deebf7;
  top: 22px;
  height: calc(100% + 12px);
  width: 100%;
  margin: 26px 32px 26px 26px;
}
.drawdowncomponentTOPINNERwrapper {
  padding: 0;
  width: 100%;
  min-height: 100%;
}
.drawdowncomponentBOTTOMINNERwrapper {
  margin: 10px 0px 0px 2px;
  padding: 0;
  width: 100%;
  display: flex;
  flex-flow: row;
  height: 400px;
  min-height: 300px;
  max-height: 400px;
}
.drawdowncomponentwrapper[max-width~="595px"]
  .drawdowncomponentBOTTOMINNERwrapper {
  margin: 10px 0px 0px 2px;
  padding: 0px 0px 80px 0px;
  width: 100%;
  display: flex;
  flex-flow: row;
  height: 800px;
  min-height: 560px;
  max-height: 800px;
}

.drawdowncomponentwrapper[max-width~="595px"] {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  gap: 12px;
  margin: 0px 0px -24px 0px !important;
  padding: 0px 12px 1360px 12px !important;
}

@media screen and (max-height: 870px) {
  .drawdowncomponentRIGHTINNERwrapper {
    height: calc(100%);
    min-height: calc(748px);
    width: 30%;
    padding-right: 0px;
    padding-bottom: 48px !important;
  }
  .drawdowncomponentLEFTINNERwrapper {
    height: calc(100% - 350px);
    width: 70%;
    min-height: 400px;
    padding-bottom: 48px !important;
    display: flex;
    flex-direction: column;
  }
}

/* @media screen and (max-height: 870px) {
  .drawdowncomponentRIGHTINNERwrapper {
    height: calc(100%);
    min-height: calc(748px);
    width: 30%;
    padding-right: 0px;
  }
  .drawdowncomponentLEFTINNERwrapper {
    height: calc(100% - 350px);
    width: 70%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
  }
} */

.drawdowncomponentRIGHTINNERwrapper {
  height: calc(100%);
  min-height: calc(748px);
  width: 30%;
  padding-right: 0px;
}
.drawdowncomponentLEFTINNERwrapper {
  height: calc(100% - 350px);
  width: 70%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.gradient-animation-green {
  border: 2px solid;
  border-image: linear-gradient(to right, red, rgb(57, 206, 211)) 1;
  animation: sweep 1s linear forwards;
}

@keyframes sweep {
  from {
    border-image-slice: 1;
  }
  to {
    border-image-slice: 100;
  }
}

.drawdowncomponentwrapper {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 0px;
  height: calc(100% - 29px);
  width: calc(100% - 2px);
  min-width: 200px;
  padding: 0px 26px 0px 0px;
  margin-bottom: 29px;
}
@media screen and (max-width: 1440px) and (max-height: 800px) {
}
.drawdowncomponentwrapper[max-width~="465px"] {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  gap: 12px;
  margin: 0px 0px -24px 0px !important;
  padding: 0px 12px 1322px 12px !important;
}
.drawdowncomponentwrapper[max-width~="416px"] .randomprobtool-label {
  position: absolute;
  display: block;
  padding: 0;
  font-size: 1.2em !important;
  left: 19px;
  top: -32px !important;
  font-weight: 300;
  z-index: 10000;
  height: 40px;
  width: auto;
}

.drawdowncomponentwrapper[max-width~="416px"] .drawdown-metrics-table {
  border-top: 44px solid #28303c;
}

.drawdowncomponentwrapper[max-width~="416px"]
  .select-calculation-type-drawdown {
  position: absolute;
  z-index: 2147483647;
  height: 20px !important;
  width: 120px !important;
  right: -10px;
  margin-top: -46px;
}
.drawdowncomponentwrapper[max-width~="416px"] .switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  margin: -4px 8px 0px 8px;
  top: -2px;
}
.drawdowncomponentwrapper[max-width~="416px"] .drawdown-metrics-label {
  margin-top: -38px;
}
@media screen and (max-height: 864px) and (max-width: 470px) {
  .drawdowncomponentwrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    height: calc(100% - 29px);
    width: calc(100% - 2px);
    min-width: 200px;
    padding: 0px 0px 776px 26px;
  }
}
@media screen and (max-height: 864px) and (max-width: 470px) {
  .drawdowncomponentwrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    height: calc(100% - 29px);
    width: calc(100% - 2px);
    min-width: 200px;
    padding: 0px 0px 776px 26px;
  }
}
.drawdowncomponentwrapper[max-width~="1190px"] {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  gap: 12px;
  margin: 0px 0px -24px 0px !important;
  padding: 0px 24px 1326px 24px !important;
}
.drawdowncomponentwrapper[max-width~="1190px"]
  .drawdowncomponentLEFTINNERwrapper {
  height: calc(100% - 350px);
  width: 100%;
  min-height: 400px;
  margin: -228px 0px 0px 0px;
  display: flex;
  flex-direction: column;
}

.drawdowncomponentwrapper[max-width~="1190px"]
  .drawdowncomponentRIGHTINNERwrapper {
  height: auto;
  width: 100%;
  padding-right: 0px;
}
.drawdowncomponentwrapper[max-width~="416px"] {
  //font-size: 0.85em;
}
.drawdowncomponentwrapper[max-width~="416px"] .outermetriccell {
  font-size: 13px;
}
.drawdowncomponentwrapper[max-width~="1190px"] #randomChanceTool {
  margin: 24px 4px 26px 2px;
}
.drawdowncomponentwrapper[max-width~="1190px"] .drawdownreportgraph {
  padding: 8px 28px 0px 20px;
  margin: 24px 0px 0px 0px;
  display: flex;
  height: calc(100% + 10px);
  width: calc(100%);
  top: -2px;
  position: relative;
}
.drawdowncomponentwrapper[max-width~="1190px"] .drawdown-metrics-table {
  height: calc(100%);
  max-height: 400px;
}
.drawdowncomponentwrapper[max-width~="1190px"] .drawdown-metrics-toggle {
  height: 80px;
}
