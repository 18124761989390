.total-metrics-label {
  position: relative;
  margin: 0;
  padding: 0;
  color: #fbd665;
  font-size: 1.6em;
  top: 29px;
  width: 70px;
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */

  left: 50px;
  font-weight: 400;
  z-index: 10000;
}
.avg-metrics-label {
  position: relative;
  margin-bottom: -32px;
  padding: 0;
  color: #fbd665;
  font-size: 1.6em;
  top: -4px;
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */

  width: 200px;
  left: 50px;
  font-weight: 400;
  z-index: 10000;
}
.misc-metrics-label {
  position: relative;
  margin-bottom: -29px;
  padding: 0;
  color: #fbd665;
  width: 200px;
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */

  font-size: 1.6em;
  top: 2px;
  left: 27px;
  margin-left: -4px;
  font-weight: 400;
  z-index: 10000;
}
.overallreportwrapper[min-width~="1127px"] .misc-metrics-label {
  left: 27px;
}
.overallreportwrapper[max-width~="1127px"] .misc-metrics-label {
  top: 3px;
}
.equitycurve-label {
  position: absolute;
  display: block;
  padding: 0;
  color: #fbd665;
  font-size: 1.6em;
  left: 0px;
  align-items: center;
  text-align: center;
  top: 0px;
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */

  font-weight: 400;
  z-index: 10000;
  height: 40px;
  width: 100%;
}
.overallreportgraph {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 2px solid #14181e;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  border-radius: 10px;
  background-color: #1f2831;
  box-shadow: 0 0 4pt 2pt #171c23;
  padding: 8px 28px 0px 20px;
  margin: -6px 26px 0px 1px;
  min-width: 240px;
  min-height: 340px;
  display: flex;
  height: calc(65.1% - 32px);
  top: -2px;
  position: relative;
}
.overallreportwrapper[min-width~="1127px"] .overallreportgraph {
  position: relative;
  height: calc(100% - 14px);
  width: 65%;
  margin-top: 24px;
  margin-bottom: 0px;
  margin-right: 0;
  margin-left: -2px;
}
.overallreportwrapper[max-width~="900px"] .overallreportgraph {
  margin: -6px 26px 0 4px;
  height: 460px;
}
.miscmetricomponentwrapper {
  position: relative;
  margin-top: 24px;
  margin-bottom: 20px;
  margin-left: 2px;
  width: calc(100% - 52px);
  height: 40%;
}
.overallreportwrapper[min-width~="1127px"] .miscmetricomponentwrapper {
  margin-bottom: 0px;
  margin-top: 16px;
  margin-right: 2px;
  margin-left: 26px;
  height: calc(100% + 2px);
  width: calc(65% - 44px);
}
.ovallreportgraphactual {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  background-color: #06080a;
}
.overallreportgraphwrapper {
  display: flex;
  transition: none;
  width: 100%;
  height: 100%;
  margin: 30px 0px -100px -6px;
  padding-bottom: 40px;
}
.avg-metrics-table > div > div:nth-child(2) {
  display: none; // PIPS!!!! - this will be toggleable
}
.total-metrics-table > div > div:nth-child(2) {
  display: none; // PIPS!!!! - this will be toggleable
}
.avg-metrics-table > div {
  width: 100%;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 6px;
  justify-content: center;
}
.overallreportwrapper[max-width~="529px"] #metric-component {
  margin: 20px 24px 18px 24px;
}
.overallreportwrapper[max-width~="529px"] .total-metrics-label {
  top: 20px;
  left: 42px;
}
.overallreportwrapper[max-width~="529px"] .avg-metrics-label {
  top: -12px;
  left: 42px;
}
#metric-component {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: 24px 28px 20px 28px;
  border-top: 50px solid #28303c;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  position: relative;
  padding: 0px 14px 0px 14px;
  border-radius: 10px;
  background-color: #1f2831;
  align-items: center;
  border-collapse: separate;
  border-spacing: 0px;
  box-shadow: 0 0 4pt 2pt #171c23;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  color: #deebf7;
  top: -38px;
}
.total-metrics-table > div {
  width: 100%;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 6px;
  justify-content: center;
  overflow: hidden;
}
.misc-metrics-table > div {
  width: 100%;
  height: 100%;
  display: table;
  justify-content: center;
}
.avg-metrics-table > div > div {
  width: 100%;
  height: 25%;
  display: flex;
  border-bottom: 1px solid #28303c;
}
.avg-metrics-table > div > div:last-child {
  border-bottom: none;
}
.total-metrics-table > div > div {
  width: 100%;
  height: 10%;
  border-bottom: 1px solid #28303c;
  display: flex;
}
.total-metrics-table > div > div:last-child {
  border-bottom: none;
}
.total-metrics-table > div > div > div:nth-child(1) {
  width: 100%;
}
.avg-metrics-table > div > div > div:nth-child(1) {
  width: 100%;
}
.misc-metrics-table > div > div > div:nth-child(1) {
  width: 100%;
}
.outermetriccell {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: #deebf7;
  font-size: 17px;
}

.innermetriccell1 {
  font-weight: 300;
  text-align: left;
  padding-left: 10px;
  cursor: default;
  background-color: transparent;
  color: #deebf7;
  border: none;
}
.innermetriccell {
  text-align: right;
  vertical-align: middle;
  display: table-cell;
  padding-right: 10px;
  font-weight: 500;
  color: #98b5eb;
}
.misc-metrics-table > div > div {
  width: 100%;
  height: 25%;
  display: flex;
  border-bottom: 1px solid #28303c;
}
.misc-metrics-table > div > div:last-child {
  border-bottom: none;
}
.overallreportwrapper {
  padding: 0px 0px 0px 0px;
  margin: 14px -14px 0px 0px;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}
.secondoverallreportwrapper {
  padding: 0px 0px 12px 0px;
  display: flex;
  flex-flow: column;
  width: calc(100% - 26px);
  height: 100%;
  margin: 40px -26px 0px 26px;
}
.firstoverallreportwrapper {
  padding: 0;
  width: 100%;
  display: flex;
  flex-flow: row;
  height: 54%;
  margin-top: -12px;
  min-height: 430px;
}

.overallreportwrapper[max-width~="900px"] .firstoverallreportwrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
  height: auto;
  margin-top: 6px;
  min-height: auto;
}
@media screen and (max-height: 800px) {
}
@media screen and (max-height: 800px) {
  .secondoverallreportwrapper {
    padding: 0px 0px 12px 0px;
    display: flex;
    flex-flow: column;
    width: calc(100% - 26px);
    height: 100%;
    margin: 32px -26px 6px 26px;
  }
  .misc-metrics-table {
    //margin-top: 36px !important;
  }

  .overallreportwrapper[min-width~="1127px"] .avgmetricomponentwrapper {
    position: relative;
    height: calc(100% + 24px) !important;
    margin-top: 22px !important;
    margin-left: 0px;
    margin-bottom: -50px !important;
  }
  .overallreportwrapper[min-width~="1127px"] .totalmetricomponentwrapper {
    position: relative;
    margin-top: 0px;
    //height: calc(100% + 2px) !important;
    min-height: 350px;
    margin-bottom: 0px;
  }
  .miscmetricomponentwrapper {
    height: calc(100%) !important;
    margin-top: 18px;
  }
}
.overallreportwrapper[max-width~="900px"] .secondoverallreportwrapper {
  padding: 0px 0px 0px 0px;
  display: flex;
  flex-flow: column;
  margin-bottom: -20px !important;
  width: calc(100% - 22px);
  height: fit-content !important;
  margin-top: 14px !important;
  margin-left: 22px;
  margin-right: -22px;
}
.overallreportwrapper[min-width~="1127px"] .secondoverallreportwrapper {
  padding: 0;
  display: flex;
  flex-flow: row;
  width: calc(100% - 26px);
  height: 64% !important;
  margin-top: 0px;
  margin-bottom: 20px;
}
.overallreportwrapper[max-width~="1127px"] .secondoverallreportwrapper {
  margin-top: 40px;
  margin-bottom: -16px;
}
.overallreportwrapper[max-width~="1127px"] .firstoverallreportwrapper {
  margin-top: -16px;
}
.overallreportwrapper[min-width~="1127px"] .firstoverallreportwrapper {
  width: 100%;
  display: flex;
  flex-flow: row;
  height: 36%;
  margin-top: 0px;
  margin-bottom: 30px;
  min-height: 0px;
}
.overallreportwrapper[min-width~="1127px"] {
  padding: 0px 0px 0px 0px;
  margin: 0px -14px 0px 0px;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}
.overallreportwrapper[max-width~="900px"] {
  padding: 0px 0px 0px 0px;
  margin: 16px -14px 0px 0px;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1440px) and (max-height: 800px) {
  .overallreportwrapper[max-width~="900px"] .overallreportgraph {
    margin: -10px 26px 0 4px;
  }
  .overallreportwrapper {
    padding: 18px 0px 0px 0px;
    margin: 0px -14px 0px 0px;
    //font-size: 0.85em !important;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
  }
  .outermetriccell {
    font-size: 1em !important;
  }
  .innermetriccell {
    font-size: 1em !important;
  }
  .innermetriccell1PM {
    font-size: 1em;
  }
  .miscmetricomponentwrapper {
    height: 100%;
    margin-top: 18px !important;
    padding: 0px 0px 0px 0px !important;
  }
  .overallreportwrapper[max-width~="1127px"] .miscmetricomponentwrapper {
    height: 100%;
    margin-top: 26px !important;
    padding: 0px 0px 4px 0px !important;
  }
  .overallreportwrapper[max-width~="900px"] .miscmetricomponentwrapper {
    height: 100%;
    margin-top: 26px !important;
    padding: 0px 0px 4px 0px !important;
  }
  .overallreportwrapper[max-height~="591px"] .miscmetricomponentwrapper {
    height: 100%;
    margin-top: 18px !important;
    padding: 0px 0px 32px 0px !important;
  }
  .overallreportwrapper[max-width~="529px"] .misc-metrics-label {
    margin-bottom: -28px;
    top: 6px !important;
    left: 24px;
  }
  #metric-component {
    border-top: 44px solid #28303c;
  }
  .misc-metrics-table {
    border-top: 44px solid #28303c !important;
  }
  .misc-metrics-label {
    margin-bottom: -27px;
  }
  .total-metrics-label {
    top: 25px;
    left: 48px;
  }
  .avg-metrics-label {
    top: -8px;
    left: 50px;
  }
}

.overallreportwrapper[max-width~="529px"] #metric-component {
  border-top: 44px solid #28303c;
}
.overallreportwrapper[max-width~="529px"] .misc-metrics-table {
  border-top: 44px solid #28303c;
}
.overallreportwrapper[max-width~="529px"] .misc-metrics-label {
  margin-bottom: -28px;
  top: 2px;
  left: 24px;
}
/* @media screen and (max-width: 950px) and (max-height: 900px) {
  .miscmetricomponentwrapper {
    position: relative;
    margin-top: 24px;
    margin-bottom: 16px;
    margin-left: 6px !important;
    padding: 0px 0px 0px 4px;
    width: calc(100% - 56px) !important;
  }
} */
.overallreportwrapper[min-width~="1127px"] .totalmetricomponentwrapper {
  position: relative;
  height: calc(100% - 1px);
  margin-bottom: 0px;
  width: calc(35%);
}
.overallreportwrapper[max-width~="900px"] .totalmetricomponentwrapper {
  position: relative;
  height: calc(70%);
  margin-top: 0px;
  margin-bottom: 14px;
  min-height: 430px;
}
.overallreportwrapper[min-width~="1127px"] .avgmetricomponentwrapper {
  position: relative;
  height: calc(100% + 25px);
  width: calc(35% - 10px);
  margin-top: 22px;
  margin-left: 24px;
  margin-bottom: 0px;
}
.overallreportwrapper[max-width~="900px"] .avgmetricomponentwrapper {
  position: relative;
  margin: 28px 0px -4px 0px;
  height: 100%;
  width: 100%;
}

.misc-metrics-table {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border-top: 50px solid #28303c;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  position: relative;
  padding: 8px 14px 7px 14px;
  border-radius: 10px;
  background-color: #1f2831;
  align-items: center;
  border-spacing: 0px;
  box-shadow: 0 0 4pt 2pt #171c23;
  display: flex;
  flex-flow: row;
  justify-content: left;
  color: #deebf7;
  top: -40px;
  height: calc(100% + 6px);
  margin: 30px -22px 0px 0px;
}
.overallreportwrapper[min-width~="1127px"] .misc-metrics-table {
  margin: 28px -24px 0px 0px;
}
.overallreportwrapper[max-width~="900px"] .misc-metrics-table {
  flex-flow: column;
  margin-bottom: -28px;
  height: calc(100%);
  min-height: 400px;
}
.overallreportwrapper[max-width~="529px"] {
  padding: 18px 0px 0px 0px;
  margin: 0px -14px 0px 0px;
  //font-size: 0.85em !important;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}
.overallreportwrapper[max-width~="529px"] .overallreportgraph {
  margin: -6px 24px 0px 1px;
}
.overallreportwrapper[max-width~="529px"] .avgmetricomponentwrapper {
  position: relative;
  margin: 26px 0px -6px 0px;
  height: 100%;
  width: 100%;
}

.overallreportwrapper[max-width~="900px"] .miscmetricomponentwrapper {
  position: relative;
  margin-top: 24px;
  margin-bottom: 16px;
  margin-left: 6px;
  width: calc(100% - 56px);
}
.overallreportwrapper[max-width~="529px"] .miscmetricomponentwrapper {
  position: relative;
  margin-top: 24px;
  margin-bottom: 16px;
  margin-left: 4px;
  width: calc(100% - 52px);
}
.overallreportwrapper[max-width~="900px"] .misc-metrics-table > div > div {
  width: 100%;
  height: 25%;
  display: flex;
  border-bottom: 1px solid #28303c;
}
.overallreportwrapper[max-width~="532px"] .outermetriccell {
  font-size: 1em;
}
.overallreportwrapper[max-width~="532px"] {
  padding: 0px 0px 0px 0px;
  margin: 16px -14px 0px 0px;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}
.overallreportwrapper[max-width~="446px"] .outermetriccell {
  font-size: 1em;
}
.overallreportwrapper[max-width~="446px"] {
  padding: 0px 0px 0px 0px;
  margin: 16px -14px 0px 0px;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}
.avg-metrics-table {
  height: 100%;
}
.total-metrics-table {
  height: 100%;
}
.totalmetricomponentwrapper {
  position: relative;
  height: 100%;
  margin-bottom: 0px;
  padding-bottom: 18px;
  width: 100%;
}
.avgmetricomponentwrapper {
  position: relative;
  margin-top: 32px;
  margin-left: -30px;
  padding-bottom: 18px;
  margin-bottom: 18px;

  height: 100%;
  width: 100%;
}
.overallreportwrapper[max-width~="900px"] .miscmetricol1 {
  height: 50%;
}
.overallreportwrapper[max-width~="900px"] .miscmetricol2 {
  height: 50%;
}
