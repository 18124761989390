.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 8px;
  border: 1px solid #14181e;
  display: flex;
  height: fit-content;
  overflow: visible;
  justify-content: flex-start;
  flex-direction: row;
  background: #1a2028 !important;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  user-select: none;
}
.calcustomobjwrappernoteinner
  > .container-root
  > div
  > div.rdw-editor-toolbar.toolbarClassName
  > div.rdw-fontsize-wrapper {
  display: none;
}
.rdw-dropdown-optionwrapper {
  position: absolute;
  background: #14181e !important;
  border-radius: 8px;
  padding: 6px 6px 6px 6px;
  margin-top: 10px;
  z-index: 100000000;
  list-style-type: none;
}
.container-root {
  font-size: 16px;
}
.rdw-emoji-wrapper > .rdw-option-wrapper {
  padding: 4px 0px 0px 12px;
}
.rdw-emoji-wrapper > .rdw-option-wrapper:hover {
  padding: 6px 0px 0px 14px;
}
.rdw-option-wrapper:hover {
  background: #1a2028 !important;
  border: none !important;
  outline: 2px solid #14181e !important;
  box-shadow: none !important;
  padding: 6px 0px 0px 16px;
}

.rdw-option-wrapper {
  background: #14181e !important;
  border-radius: 6px;
  color: #deebf7;
  &:hover {
    cursor: pointer;
  }
  border: 2px solid #14181e !important;
  padding: 4px 0px 0px 14px;
  margin: 0px 4px 5px 2px;
  height: 36px;
  width: 44px;
}
.rdw-option-wrapper > img {
  filter: invert(85%) sepia(31%) saturate(276%) hue-rotate(347deg)
    brightness(106%) contrast(106%);
}
.rdw-option-wrapper:hover > img {
  filter: invert(92%) sepia(11%) saturate(2102%) hue-rotate(340deg)
    brightness(96%) contrast(105%);
}

.rdw-dropdown-selectedtext {
  border-radius: 6px;
  height: 36px;
  width: fit-content;
  padding: 4px 0px 0px 0px;
  margin: 6px 0px 0px -2px !important;
  top: 6px !important;
}

.rdw-block-wrapper {
  margin: 6px 0px 0px 0px !important;
  display: none;
}
.rdw-fontsize-wrapper {
  margin: 6px 0px 0px 0px !important;
}
.rdw-fontfamily-wrapper {
  margin: 6px 0px 0px 0px !important;
  display: none;
}
.rdw-dropdownoption-default {
  cursor: pointer;
  border-radius: 6px;
  padding: 3px 8px 3px 8px;
  &:hover {
    background-color: #1a2028;
  }
}
.rdw-dropdown-selectedtext > span {
  background: #14181e !important;
  border-radius: 6px;
  height: 36px;
  width: fit-content;
  color: #fff7cb;
  padding: 9px 16px 9px 16px;
  margin: 6px 4px 0px 4px !important;
  cursor: pointer;
  &:hover {
    background: #1a2028 !important;
    color: #fbd665;
    outline: 2px solid #14181e !important;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    box-shadow: none !important;
  }
}
.rdw-option-active {
  box-shadow: none !important;
  background: #1a2028 !important;
  outline: none !important;
  border: 2px solid #14181e !important;
}
.wrapperClassName {
  margin-top: -40px;
}
.editorClassName {
  background: #1a2028 !important;
  font-size: 16px;
  border: 1px solid #14181e;
  border-radius: 10px;
  min-height: 200px;
  max-height: 200px;
  cursor: text !important;
  overflow-y: scroll;
  padding: 6px 6px 0px 10px;
  color: #fbd665;
}
.editorClassName::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.editorClassName::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.editorClassName::-webkit-scrollbar-thumb {
  border-radius: 10px;
  color: #14181e;
  background-color: #1a2028;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.editorClassName::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.editorClassNameFocused {
  background: #1a2028 !important;
  border: 2px solid #deebf7;
  border-radius: 10px;
  min-height: 200px;
  max-height: 200px;
  cursor: text !important;
  padding: 6px 6px 0px 10px;
  color: #fbd665;
  overflow-y: scroll;
}
.editorClassNameFocused::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
  border-radius: 10px;
}
.editorClassNameFocused::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.editorClassNameFocused::-webkit-scrollbar-thumb {
  border-radius: 10px;
  color: #14181e;
  background-color: #1a2028;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.editorClassNameFocused::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.rdw-inline-wrapper {
  display: flex;
  flex-direction: row;
}
.rdw-list-wrapper {
  display: flex;
  flex-direction: row;
}
.rdw-list-wrapper {
  display: flex;
  flex-direction: row;
}
.rdw-list-wrapper > div:nth-child(3) {
  display: none;
}
.rdw-list-wrapper > div:nth-child(4) {
  display: none;
}
.rdw-text-align-wrapper {
  display: none;
}
.rdw-colorpicker-wrapper {
  display: none;
}

.rdw-history-wrapper {
  display: flex;
  flex-direction: row;
  display: none;
}
.rdw-image-wrapper {
  display: none;
}
.rdw-embedded-wrapper {
  display: none;
}
.rdw-link-wrapper {
  //display: flex;
}
.rdw-remove-wrapper {
  //display: none;
}
.rdw-emoji-wrapper {
  display: none;
}
