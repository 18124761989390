.dropdown-items {
  font-size: 1.3em;
  padding: 11px 0 0 20px;
  margin: 0;
  width: 200px;
  height: 43px;
}
.optin-link {
  border: none;
  position: absolute;
  white-space: nowrap;
  font-size: 0.8em;
  margin: 2px 0px 0px 10px;
  outline: none;
  color: #fff8b9;
  background-color: transparent;
  &:hover {
    text-decoration: underline;
  }
}
#portfolio-linkinner-name {
  margin-top: -23px;
  margin-left: 34px;
  position: relative;
  overflow: hidden;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
  max-width: 138px;
}
.dropdown-text {
  padding: 0;
  margin: 0 0 0 4px;
  left: -15.5px;
  border-radius: 8px;
  width: 200px;
  height: 43px;
  font-size: 1em;
  display: flex;
}

.dropdown-text:hover {
  background-color: #2e3a48;
  cursor: pointer;
}
.dropdown-list-tools {
  font-size: 1em;
  border-radius: 8px !important;
  border: none !important;
  width: 208px;
  top: 325px;
  height: 180px;
  padding: 4px 0px !important;
}
.dropdown-list-community {
  font-size: 1em;
  border: none !important;
  width: 208px;
  top: 224px;
  height: 137px;
}
.accountDropdownerrMsg {
  width: auto;
  height: 10px;
  position: absolute;
  top: 14px;
  margin-right: -17px;
}
.dropdown-list-import {
  width: 208px;
  top: 376px;
  //height: 180px; if it has 4
  height: 137px;
  border-radius: 8px !important;

  border: none !important;
}
.portfoliotypecontain {
  display: block;
  text-align: left;
  z-index: 0;
  position: absolute;
  left: 0px;
  width: 100%;
  margin-top: -14px;
}
.portfoliotypecontaintext {
  display: block;
  text-align: left;
  z-index: 1;
  position: absolute;
  left: 26px;
  margin-top: -52px;
  color: #fff7cb;
}
// ----- Mega Menu CSS -----

.item-megamenu {
  width: 100%;
  margin: 0;
  padding: 14px 0 4px 0;
}
.item-megamenu-outcome {
  margin-top: 57px;
}
.megamenulist {
  margin: 0;
  padding: 0px;
}
.megamenu-heading {
  font-family: "Roboto", sans-serif;
  justify-content: left;
  text-align: left;
  font-weight: 400;
  font-size: 1.6em;
  padding-left: 23px;
  color: #fbd665;
}

// -------- Account Dropdown ----------
.notexttooltip {
  z-index: 10000000000;
  background: #14181e;
  color: #deebf7;
  font-weight: 300;
  padding: 6px 12px 6px 12px;
  font-size: 16px;
  border-radius: 8px;
  position: absolute;
  display: none;
  -webkit-filter: drop-shadow(0 0 2px #deebf7);
  -moz-filter: drop-shadow(0 0 2px #deebf7); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #deebf7); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #deebf7);
}

.notexttooltip[data-show] {
  display: block;
}

#notexttooltiparrow,
#notexttooltiparrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#notexttooltiparrow {
  visibility: hidden;
}

#notexttooltiparrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

.notexttooltip[data-popper-placement^="top"] > #notexttooltiparrow {
  bottom: -4px;
}

.notexttooltip[data-popper-placement^="bottom"] > #notexttooltiparrow {
  top: -4px;
}

.notexttooltip[data-popper-placement^="left"] > #notexttooltiparrow {
  right: -4px;
}

.notexttooltip[data-popper-placement^="right"] > #notexttooltiparrow {
  left: -4px;
}

.accdropdownapplybutton {
  top: 167px;
  position: relative;
  right: 76px;
  height: 47px;
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: bold;
  transition: all 0.25s;
  border-radius: 8px;
  color: #55f8a6;
  width: 0px;
  padding: 8px 20px 8px 0px;
  margin: -22px 0px 8px 0px;
  font-size: 26px;
  display: none;
}
.accdropdownapplybutton:hover {
  outline-style: none;
  border: none;
  -webkit-filter: drop-shadow(0 0 2px #a2ffd1);
  -moz-filter: drop-shadow(0 0 2px #a2ffd1);
  -ms-filter: drop-shadow(0 0 2px #a2ffd1);
  filter: drop-shadow(0 0 2px #a2ffd1);
}
.accdropdownapplybutton:focus {
  outline: none;
}
.accdropdowncancelbutton {
  display: none;
  top: 165px;
  position: relative;
  left: -205px;
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: bold;
  transition: all 0.25s;
  border-radius: 8px;
  color: rgb(238, 73, 73);
  width: 0px;
  padding: 8px 20px 8px 0px;
  margin: -22px 0px 8px 0px;
  font-size: 30px;
  height: 50px;
}
.accdropdowncancelbutton:hover {
  outline-style: none;
  border: none;
  -webkit-filter: drop-shadow(0 0 2px #fcc6c5);
  -moz-filter: drop-shadow(0 0 2px #fcc6c5); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #fcc6c5);
  filter: drop-shadow(0 0 2px #fcc6c5);
}
.accdropdowncancelbutton:focus {
  outline: none;
}
.editdeleteportsrightajdust1 {
  margin: -32px 0px 0px 100px !important;
}
.small-icons-OC7-sub {
  margin-top: 1px !important;
}

.logout-dropdown {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  display: none;
  list-style: none;
  margin: 0;
  overflow: visible;
  justify-content: center;
  align-items: center;
  top: 55px;
  font-size: 1em;
  z-index: 10000000002;
  right: 14px;
  height: 74px;
  width: 200px;
  border-radius: 10px;
  padding: 0px;
  position: absolute;
  color: #deebf7;
  background-color: #222a35;
  -webkit-filter: drop-shadow(0 0 6px #14181e);
  -moz-filter: drop-shadow(0 0 6px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 6px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 6px #14181e);
  /*   -moz-box-shadow: inset 0 0 4px #556171;
  -webkit-box-shadow: inset 0 0 4px #556171;
  box-shadow: inset 0 0 4px #556171; */
}
.account-dropdownadjust {
}
.account-dropdown {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  display: none;
  list-style: none;
  margin: 0;
  overflow: visible;
  justify-content: center;
  align-items: center;
  top: 55px;
  font-size: 1em;
  z-index: 10000000002;
  /*   right: 14px; */
  height: 356px;
  width: 270px;
  border-radius: 10px;
  padding: 0px;
  position: absolute;
  color: #deebf7;
  background-color: #222a35;
  -webkit-filter: drop-shadow(0 0 6px #14181e);
  -moz-filter: drop-shadow(0 0 6px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 6px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 6px #14181e);
  /*   -moz-box-shadow: inset 0 0 4px #556171;
  -webkit-box-shadow: inset 0 0 4px #556171;
  box-shadow: inset 0 0 4px #556171; */
}
.portfolio-dropdown {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  display: none;
  list-style: none;
  margin: 0;
  overflow: visible;
  justify-content: center;
  align-items: center;
  top: 55px;
  font-size: 1em;
  z-index: 10000000002;
  //right: 305px; with shared trades button
  height: 380px;
  width: 320px;
  border-radius: 14px;
  padding: 0px;
  position: absolute;
  color: #deebf7;
  background-color: #222a35;
  -webkit-filter: drop-shadow(0 0 6px #14181e);
  -moz-filter: drop-shadow(0 0 6px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 6px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 6px #14181e);
  /*   -moz-box-shadow: inset 0 0 4px #556171;
  -webkit-box-shadow: inset 0 0 4px #556171;
  box-shadow: inset 0 0 4px #556171; */
}

.createnewportfoliobutton {
  background-color: #fbd665;
  position: absolute;
  display: flex;
  color: #1a2028;
  font-family: "Roboto";
  font-weight: 400;
  border: none;
  text-align: center;
  z-index: 200 !important;
  cursor: pointer;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  font-size: 1em;
  font-weight: 500;
  border-radius: 10px;
  padding-top: 10px;
  top: 4px;
  margin-top: 24px;
  padding-left: 14px;
  margin-bottom: 20px;
  margin-right: auto !important;
  height: 44px;
  width: 80%;
  right: 30px;
  &:hover {
    outline-style: none;
    border: none;
    color: #222a35;
    background-color: #ffe593;
  }
}
.createnewportfoliobutton:disabled {
  transition: none;
  background-color: #e4d5ae;
  color: #394453;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
.profileuserName-free {
  display: flex;
  height: 74px;
  width: auto;
  text-align: center;
  top: 104px;
  position: absolute;
  padding: 3px;
  color: #deebf7;
  font-size: 1.5em;
  font-weight: 600;
}
.profileuserName-pro {
  display: flex;
  flex-flow: row;
  height: 74px;
  width: auto;
  margin-left: 0px;
  text-align: center;
  top: 104px;
  position: absolute;
  padding: 3px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to bottom right, #ffeba8, #fbd665);
  font-size: 1.5em;
  font-weight: 600;
}
.editdeleteportsrightajdust2 {
  margin-left: 166px !important;
}
.editdeleteportsrightajdust12 {
  left: 48px;
}
.profileuserName-master {
  display: flex;
  flex-flow: row;
  height: 74px;
  width: auto;
  margin-left: 0px;
  text-align: center;
  top: 104px;
  position: absolute;
  padding: 3px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to bottom right, #a28848, #fbd665);
  font-size: 1.5em;
  font-weight: 600;
}
.profileuserName-ultimate {
  display: flex;
  flex-flow: row;
  height: 74px;
  width: auto;
  margin-left: 0px;
  text-align: center;
  top: 104px;
  position: absolute;
  padding: 3px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to bottom right, #a28848, #fbd665);
  font-size: 1.5em;
  font-weight: 600;
}
.profileName {
  display: block;
  height: 74px;
  width: 100%;
  text-align: center;
  top: 150px;
  position: absolute;
  overflow: hidden;
  padding: 3px;
  color: #deebf7;
  font-weight: 300;
}
.profileEmail {
  display: block;
  height: 74px;
  width: 100%;
  text-align: center;
  color: #8193b3;
  top: 178px;
  position: absolute;
  overflow: hidden;
  padding: 3px;
  font-size: 14px;
}
.profileLine {
  position: absolute;
  width: 100%;
  border-bottom: 0.05em solid #2e3a48;
  height: 1px;
  bottom: 72px;
}
.profilechange {
  display: block;
  height: 74px;
  width: 74px;
  top: 22px;
  position: absolute;
  border-radius: 100%;
  border: 3px solid #808080;
  overflow: hidden;
  padding: 3px;
  &:hover {
    cursor: pointer;
  }
}
.portfolio-linkinnerunchecked {
  border-radius: 0px;
  border: none;
  border-top: 1px solid #1a2028;

  width: 100%;
  background-color: transparent;
  transition: background-color 0.1s ease-in-out;
  display: flex;
  height: 52px;
  padding: 13px 10px 5px 16px;
  &:hover {
    background-color: #1a2028;
    .editdeleteportfolios {
      display: inline-block;
      transition: all 0.1s ease-in-out;
      opacity: 1;
      margin-left: 207px;
    }
    .editdeleteportfoliosun {
      display: inline-block;
      transition: all 0.1s ease-in-out;
      opacity: 1;
      margin-left: 207px;
    }
  }
}
.portfolio-linkinnerchecked {
  border-radius: 10px;
  width: 100%;
  border: 1px solid #fbd665;
  background-color: #1a2028;
  height: 52px;
  transition: background-color 0.1s ease-in-out;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  padding: 13px 10px 5px 16px;
  -moz-box-shadow: inset 0 0 4px #ffe593;
  -webkit-box-shadow: inset 0 0 4px #ffe593;
  box-shadow: inset 0 0 4px #ffe593;
  &:hover {
    background-color: #1a2028;
    .editdeleteportfolios {
      display: inline-block;
      transition: all 0.1s ease-in-out;
      opacity: 1;
      width: 100px;
      margin-left: 176px;
    }
    .editdeleteportfoliosun {
      display: inline-block;
      transition: all 0.1s ease-in-out;
      opacity: 1;
      width: 100px;
      margin-left: 176px;
    }
  }
}
.manage-account-link {
  position: absolute;
  width: 200px;
  font-family: "Roboto";
  font-weight: 300;
  display: flex;
  flex-direction: row;
  font-size: 1em;
  margin: 0px 0 0 0;
  padding: 6px 0 7px 0px;
  color: #deebf7;
  background-color: #222a35;
  border-radius: 20px;
  border: 1px solid #1a2028;
  height: 36px;
  bottom: 94px;
}
.manage-account-link:hover {
  background-color: #2e3a48;
}
.manage-account-link-gold {
  position: absolute;
  width: 200px;
  font-family: "Roboto";
  font-weight: 300;
  display: flex;
  flex-direction: row;
  font-size: 1em;
  margin: 0px 0 0 0;
  padding: 6px 0 7px 0px;
  color: #fbd665;
  background-color: #222a35;
  border-radius: 20px;
  border: 1px solid #1a2028;
  height: 36px;
  bottom: 94px;
}
.manage-account-link-gold:hover {
  background-color: #2e3a48;
}

.account-settings-link,
.logbook-settings-link {
  font-family: "Roboto";
  font-weight: 300;
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 1em;
  margin: 0px 0 0 0;
  padding: 6px 0 7px 0px;
  color: #deebf7;
  background-color: #222a35;
  border-radius: 20px;
  border: 1px solid #1a2028;
  height: 36px;
  width: 210px;
  top: -74px;
  transition: all 0.1s ease-in-out;
}
#addnewport-link {
  width: 100%;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  display: flex;
  padding: 10px 10px 10px 0px;
  &:hover {
    background-color: #1a2028;
  }
}
.small-icons-OC4 {
  height: 28px;
  width: 28px;
  top: 64px;
  position: absolute;
  color: #deebf7;
  background-color: #222a35;
  border-radius: 100%;
  left: 164px;
  padding: 2px 0px 0px 6px;
  margin: 0px;
  z-index: 10000000000;
  &:hover {
    transition: color 0.1s ease-in-out;
    cursor: pointer;
    color: #fbd665;
  }
}
.choose-portfolios {
  width: 100%;
  margin-top: -142px;
  margin-left: 0px;
}

#portfolio-display {
  width: 100%;
  height: 210px;
  background-color: #222a35;
  position: absolute;
  margin: -18px 0px 0px -3px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-top: 1px solid #1a2028;
  border-bottom: 1px solid #1a2028;
}
#portfolio-display::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
#portfolio-display::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
#portfolio-display::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.portfolio-linkinner {
  width: 100%;
  transition: all 0.1s ease-in-out;
  display: flex;
  padding: 10px 10px 3px 16px;
  &:hover {
    background-color: #1a2028;
    .editdeleteportfolios {
      display: block;
      transition: all 0.1s ease-in-out;
      opacity: 1;
    }
    .editdeleteportfoliosun {
      display: block;
      transition: all 0.1s ease-in-out;
      opacity: 1;
    }
  }
}
.portfolio-linkinnerhover {
  background-color: #1a2028;
}
.text-input-boxes-addport53 {
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 1px solid #14181e;
  border-radius: 8px;
  height: 38px;
  color: #fbd665;
  margin-bottom: 10px;
  background-color: #171c24;
  outline-style: none;
  font-weight: 400;
  width: 100%;
}
.text-input-boxes-addport53:focus {
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: all 0.1s;
  border-radius: 8px;
}
.text-input-boxes-addport532 {
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 1px solid #14181e;
  border-radius: 8px;
  height: 38px;
  color: #fbd665;
  margin-bottom: 10px;
  background-color: #171c24;
  outline-style: none;
  font-weight: 400;
  width: 100%;
}
.text-input-boxes-addport532:focus {
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: all 0.1s;
  border-radius: 8px;
}
.text-input-boxes-addport5 {
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 1px solid #14181e;
  border-radius: 8px;
  height: 38px;
  color: #fbd665;
  margin-bottom: 6px;
  background-color: #1a2028;
  outline-style: none;
  font-weight: 400;
  width: 100%;
}
.text-input-boxes-addport5:focus {
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: all 0.1s;
  border-radius: 8px;
}
.text-input-boxes-addport {
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 1px solid #14181e;
  border-radius: 8px;
  height: 38px;
  color: #fbd665;
  background-color: #1a2028;
  outline-style: none;
  font-weight: 400;
  width: 100%;
}
.text-input-boxes-addport:focus {
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: all 0.1s;
  border-radius: 8px;
}
.text-input-boxes-acccname {
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 1px solid #14181e;
  border-radius: 8px;
  height: 38px;
  color: #fbd665;
  background-color: #1a2028;
  outline-style: none;
  font-weight: 400;
  width: calc(100% + 24px);
}
.text-input-boxes-acccname:focus {
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: all 0.1s;
  border-radius: 8px;
}
.text-input-boxes-acccnickname {
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 1px solid #14181e;
  border-radius: 8px;
  height: 38px;
  color: #fbd665;
  background-color: #1a2028;
  outline-style: none;
  font-weight: 400;
  width: calc(100% - 2px);
}
.text-input-boxes-acccnickname:focus {
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: all 0.1s;
  border-radius: 8px;
}
.portaddinputs {
  position: absolute;
  top: 12px;
  padding: 0px 22px 0px 22px;
  color: #fff7cb;
}
.portaddinputsaccnickname {
  position: absolute;
  top: 90px;
  left: 2px;
  padding: 0px 22px 0px 22px;
}
.portaddinputsaccname {
  position: absolute;
  top: 12px;
  left: 2px;
  padding: 0px 22px 0px 22px;
}
.portaddinputs2 {
  position: absolute;
  top: 86px;
  padding: 0px 22px 0px 22px;
}
#portfolio-linkselected {
  width: 100%;
  transition: all 0.1s ease-in-out;
  background-color: #1a2028;
  cursor: pointer;
  display: flex;
  padding: 10px 10px 10px 20px;
  -moz-box-shadow: inset 0 0 4px #14181e;
  -webkit-box-shadow: inset 0 0 4px #14181e;
  box-shadow: inset 0 0 4px #14181e;
  &:hover {
    background-color: #1a2028;
  }
}
#portaddbutton {
  position: absolute;
  background-color: #fbd665;
  z-index: 100000000 !important;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  color: #14181e;
  padding: 8px 0px 8px 0px;
  right: 0px;
  width: 100px;
  height: 40px;
  top: 318px !important;
  margin: 0px 28px 8px 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
#portaddbutton:hover {
  outline-style: none;
  border: none;
  background-color: #ffe593;
}
#portaddbutton:focus {
  outline: none;
}
#portaddbutton:disabled {
  transition: none;
  background-color: #e4d5ae;
  color: #394453;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
.reputationpointaccdrop {
  color: #fff7cb;
  position: absolute;
  font-weight: 300;
  font-size: 0.9em;
  top: 6px;
  left: 10px;
}
.bothbalances {
  position: absolute;
  text-align: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 54px;
  left: 0px;
  top: 172px;
  border-top: 1px solid #1a2028;
  border-bottom: 1px solid #1a2028;
  -moz-box-shadow: inset 0 0 4px #1a2028;
  -webkit-box-shadow: inset 0 0 4px #1a2028;
  box-shadow: inset 0 0 4px #1a2028;
}

/* use this for selected portfolio!!!   -moz-box-shadow: inset 0 0 4px #fbd665;
  -webkit-box-shadow: inset 0 0 4px #fbd665;
  box-shadow: inset 0 0 4px #fbd665; */
.tokenBalance {
  color: #fbd665;
  position: absolute;
  font-weight: 400;
  font-size: 1.5em;
  top: 8px;
}
.numportsselected {
  position: absolute;
  top: 210px;
  font-size: 0.7em;
  left: 8px;
}
.usdBalance {
  color: #deebf7;
  position: absolute;
  font-weight: 300;
  font-size: 0.8em;
  top: 37px;
}

.leftadjustmanageintbutton2wrap {
  position: relative;
  left: 262px !important;
  margin-top: 8px;
}
#portmanageintbuttonlabel {
  color: #fbd665;
  position: absolute;
  top: 212px !important;
}
#portmanageintbutton2label {
  color: #98b5eb;
  position: absolute;
  top: 280px !important;
}
#portmanageintbutton {
  text-align: center;
  position: absolute;
  transition: all 0.15s;
  left: 26px;
  padding: 2px 18px 0px 18px;
  width: 184px;
  display: inline-block !important;
  margin: 82px 16px 0px 42px;
  border: 1px solid #fbd665;
  border-radius: 10px;
  outline: none;
  height: 30px;
  color: #14181e;
  top: 130px;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  text-overflow: ellipsis;
  z-index: 100000000;
  font-size: 1em;
  background-color: transparent;
  background-color: #fbd665;
  &:hover {
    cursor: pointer;
    outline-style: none;
    background-color: #ffe593;
    border: 1px solid #ffe593;
    color: #14181e;
  }
}
#portmanageintbuttonsettings {
  text-align: center;
  position: absolute;
  left: 220px;
  padding: 2px 18px 0px 18px;
  width: 184px;
  display: inline-block !important;
  margin: 82px 16px 0px 42px;
  border: 1px solid #fbd665;
  border-radius: 10px;
  outline: none;
  height: 30px;
  color: #14181e;
  top: 126px;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  text-overflow: ellipsis;
  z-index: 0;
  font-size: 1em;
  background-color: transparent;
  background-color: #fbd665;
  transition: all 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
    outline-style: none;
    background-color: #ffe593;
    border: 1px solid #ffe593;
    color: #14181e;
  }
}
@media screen and (max-width: 481px) {
  #portmanageintbutton {
    left: 0px;
    top: 106px;
  }
  #portmanageintbutton2 {
    left: 270px !important;
    display: inline-block !important;
    top: -36px !important;
    line-height: 20px !important;

  }
  #portmanageintbutton2settings {
    left: auto !important;
    right: 290px !important;
  }
  #portmanageintbuttonsettings {
    left: auto !important;
    right: 30px !important;
  }
}
#portmanageintbutton2settings {
  text-align: center;
  position: absolute;
  transition: all 0.15s;
  left: -40px;
  padding: 2px 18px 0px 18px;
  width: 184px;
  display: inline-block !important;
  margin: 82px 16px 0px 42px;
  border: 1px solid #98b5eb;
  border-radius: 10px;
  outline: none;
  height: 30px;
  color: #14181e;
  overflow: hidden;
  white-space: nowrap;
  top: 183px;
  font-weight: 500;
  text-overflow: ellipsis;
  z-index: 0;
  font-size: 1em;
  background-color: transparent;
  background-color: #98b5eb;
  &:hover {
    cursor: pointer;
    outline-style: none;
    background-color: #a7bee8;
    border: 1px solid #a7bee8;
    color: #14181e;
  }
}
#portmanageintbutton2 {
  text-align: center;
  position: absolute;
  transition: all 0.15s;
  left: 104px;
  line-height: 24px;
  padding: 0px 9px 0px 9px;
  display: inline-block !important;
  margin: 2px 16px 0px 56px;
  border: 1px solid #98b5eb;
  border-radius: 8px;
  outline: none;
  height: fit-content;
  width: fit-content;
  color: #14181e;
  overflow: hidden;
  white-space: nowrap;
  top: -14px;
  font-weight: 500;
  text-overflow: ellipsis;
  z-index: 0;
  font-size: 0.9em;
  background-color: transparent;
  background-color: #98b5eb;
  &:hover {
    cursor: pointer;
    outline-style: none;
    background-color: #a7bee8;
    border: 1px solid #a7bee8;
    color: #14181e;
  }
}
#portnobutton {
  position: absolute;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  background-color: #bbbbbb;
  color: #14181e;
  width: 100px;
  height: 40px;
  top: 318px !important;
  cursor: pointer;
  padding: 8px 0px 8px 0px;
  left: 64px;
  margin: 0px 0px 8px 0px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
#portnobutton:hover {
  outline-style: none;
  border: none;
  background-color: #d6d6d6;
}
#portnobutton:focus {
  outline: none;
}
.logout-link {
  font-family: "Roboto";
  font-weight: 300;
  display: flex;
  flex-direction: row;
  position: absolute;
  font-size: 1em;
  margin: 0px 0 14px 0;
  bottom: 4px;
  padding: 6px 0 8px 4px;
  color: #deebf7;
  background-color: #222a35;
  border-radius: 20px;
  border: none;
  height: 36px;
  width: 120px;
  border: 1px solid #14181e;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(20deg);
  }
  20% {
    transform: rotate(-16deg);
  }
  30% {
    transform: rotate(12deg);
  }
  40% {
    transform: rotate(-9deg);
  }
  50% {
    transform: rotate(6deg);
  }
  60% {
    transform: rotate(-4deg);
  }
  70% {
    transform: rotate(2deg);
  }
  85% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(20deg);
  }
  20% {
    -webkit-transform: rotate(-16deg);
  }
  30% {
    -webkit-transform: rotate(12deg);
  }
  40% {
    -webkit-transform: rotate(-9deg);
  }
  50% {
    -webkit-transform: rotate(6deg);
  }
  60% {
    -webkit-transform: rotate(-4deg);
  }
  70% {
    -webkit-transform: rotate(2deg);
  }
  85% {
    -webkit-transform: rotate(-2deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
.logout-link:hover {
  background-color: #2e3a48;
}
.account-settings-link:hover,
.logbook-settings-link:hover {
  background-color: #2e3a48;
}
.small-icons-OC {
  color: #deebf7;
  margin: -2px 12px 0px 8px;
  padding: 0;
  width: 20px;
  font-size: 1.1em;
}
.small-icons-OC2-wrapper {
  padding: 0;
  border: none;
  background-color: transparent;
  position: absolute;
  width: auto;
  top: 9px;
  right: 86px;
  cursor: pointer;
}
.small-icons-OC2 {
  color: #deebf7;
  padding: 0;
  font-size: 1.8em;
  border: none;
  background-color: transparent;
  position: relative;
  width: auto;
  height: auto;
  top: 0px;
  right: 0px;
  cursor: pointer;
  &:hover,
  &:focus {
    -webkit-filter: drop-shadow(0 0 5px #deebf7);
    -moz-filter: drop-shadow(0 0 5px #deebf7); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 5px #deebf7);
    filter: drop-shadow(0 0 5px #deebf7);
    -webkit-animation: spin 1s ease-in-out;
    -moz-animation: spin 1s ease-in-out;
    animation: spin 1s ease-in-out;
    animation-iteration-count: 1;
  }
}
.navbar[max-width~="481px"] .small-icons-OC2 {
  color: #deebf7;
  padding: 0;
  font-size: 1.4em;
  border: none;
  background-color: transparent;
  position: relative;
  width: auto;
  height: auto;
  top: -1px;
  right: -24px;
  cursor: pointer;
  transition: none;
  &:hover,
  &:focus {
    -webkit-filter: drop-shadow(0 0 5px #deebf7);
    -moz-filter: drop-shadow(0 0 5px #deebf7); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 5px #deebf7);
    filter: drop-shadow(0 0 5px #deebf7);
    -webkit-animation: spin 1s ease-in-out;
    -moz-animation: spin 1s ease-in-out;
    animation: spin 1s ease-in-out;
    animation-iteration-count: 1;
  }
}
.small-icons-OC2gold {
  color: #fbd665;
  padding: 0;
  font-size: 1.8em;
  border: none;
  background-color: transparent;
  position: relative;
  width: auto;
  height: auto;
  top: 0px;
  right: 0px;
  cursor: pointer;
  &:hover,
  &:focus {
    -webkit-filter: drop-shadow(0 0 5px #fbd665);
    -moz-filter: drop-shadow(0 0 5px #fbd665); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 5px #fbd665);
    filter: drop-shadow(0 0 5px #fbd665);
    -webkit-animation: spin 1s ease-in-out;
    -moz-animation: spin 1s ease-in-out;
    animation: spin 1s ease-in-out;
    animation-iteration-count: 1;
  }
}
.navbar[max-width~="481px"] .small-icons-OC2gold {
  color: #fbd665;
  padding: 0;
  font-size: 1.4em;
  border: none;
  background-color: transparent;
  position: relative;
  width: auto;
  height: auto;
  top: -1px;
  transition: none;
  right: -26px;
  cursor: pointer;
  &:hover,
  &:focus {
    -webkit-filter: drop-shadow(0 0 5px #fbd665);
    -moz-filter: drop-shadow(0 0 5px #fbd665); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 5px #fbd665);
    filter: drop-shadow(0 0 5px #fbd665);
    -webkit-animation: spin 1s ease-in-out;
    -moz-animation: spin 1s ease-in-out;
    animation: spin 1s ease-in-out;
    animation-iteration-count: 1;
  }
}
.small-icons-OC3 {
  color: #deebf7;
  margin: -2px 18px 0 18px;
  padding: 0;
  width: 20px;
  font-size: 1.1em;
}
.small-icons-OC3-gold {
  color: #fbd665;
  margin: -2px 18px 0 18px;
  padding: 0;
  width: 20px;
  font-size: 1.1em;
}
.small-icons-OC7 {
  color: #1a2028;
  margin: 0px 13px 10px 13px;
  padding: 0;
  width: 24px;
}
.small-icons-OC5 {
  color: #deebf7;
  margin: 0px 14px 0 8px;
  padding: 0;
  width: 20px;
  font-size: 1.1em;
}
.small-icons-OC6-red {
  color: #fa798b;
  margin: 0px 5px 0 5px;
  padding: 0;
  width: 20px;
  font-size: 1em;
  &:hover {
    color: #ff9daa;
    cursor: pointer;
  }
}
.small-icons-OC6g {
  position: relative;
  color: #556171;
  top: -1px;
  margin: 0px 5px 0 5px;
  padding: 0;
  width: 20px;
  font-size: 1em;
}
.small-icons-OC6 {
  color: #deebf7;
  margin: 0px 5px 0 5px;
  padding: 0;
  width: 20px;
  font-size: 1em;

  &:hover {
    color: #fff7cb;
    cursor: pointer;
  }
}
.small-icons-OC6-gold {
  color: #fbd665;
  margin: 0px 5px 0 5px;
  padding: 0;
  width: 20px;
  font-size: 1em;
  &:hover {
    color: #fff7cb;
    cursor: pointer;
  }
}
.small-icons-OC6-s {
  color: #deebf7;
  margin: 3px 5px 0 5px;
  padding: 2px 0px 0px 0px;
  width: 21px;
  font-size: 1.05em;
  position: relative;
  top: 1px;
  &:hover {
    color: #fff7cb;
    cursor: pointer;
  }
}
.small-icons-OC6-gold-s {
  color: #fbd665;
  margin: 3px 5px 0 5px;
  padding: 2px 0px 0px 0px;
  width: 21px;
  font-size: 1.05em;
  position: relative;
  top: 1px;
  &:hover {
    color: #fff7cb;
    cursor: pointer;
  }
}
.editdeleteportfolios {
  display: none;
  opacity: 0;
  position: absolute;
  margin-top: -8px;
  left: 36px;
  transition: all 0.1s ease-in-out;
}
.editdeleteportfoliosun {
  display: none;
  opacity: 0;
  position: absolute;
  margin-top: -7px;
  left: 37px;
  transition: all 0.1s ease-in-out;
}
.deletedemodatabutton2adjust {
  position: absolute !important;
  left: 158px !important;
}
.deletedemodataheader1 {
  margin-left: 66px;
  margin-top: -10px;
}
.deletedemodatasubheader1 {
  color: #fff7cb;
}
@media screen and (max-width: 481px) {
  .manage-account-link-gold {
    bottom: 75px !important;
  }
  .account-dropdown {
    font-size: 0.85em !important;
    width: 230px !important;
    height: 310px !important;
  }
  .profileLine {
    bottom: 62px !important;
  }
  .deletedemodataheader1 {
    z-index: 10000000 !important;
    margin-left: 244px !important;
    left: 0px !important;
    margin-top: -32px !important;
  }
  .profileName {
    top: 140px !important;
  }
  .profileEmail {
    top: 168px !important;
  }
  .manage-account-link {
    width: 176px !important;
    top: 200px;
    padding: 6px 0 6px 0px !important;
    height: 34px !important;
  }
  .logout-link {
    padding: 4px 0px 2px 4px !important;
    bottom: 1px !important;
    height: 32px !important;
    width: 112px !important;
  }
  .portfolio-dropdown {
    font-size: 0.85em !important;
    width: 270px !important;
    left: 12px !important;
    top: 46px !important;
    height: 340px !important;
  }
  .deletedemodatabutton2adjust {
    position: absolute !important;
    left: 324px !important;
    top: -35px !important;
    border-radius: 8px !important;
    line-height: 24px !important;
  }
  #porttext445 {
    position: absolute;
    color: #98b5eb;
    right: 90px;
    left: auto;
  }
  .profilechange {
    display: block;
    height: 64px !important;
    width: 64px !important;
    top: 25px !important;
    margin-left: 3px !important;
  }
  .createnewportfoliobutton {
    z-index: 200 !important;
    padding: 7px 0px 0px 10px !important;
    width: 212px !important;
    top: -2px !important;
    margin-right: auto !important;
    height: 36px !important;
    right: 29px !important;
  }
  #portfolio-linkinner-name {
    margin-top: -18px !important;
    max-width: 100px !important;
  }
  .portfolio-linkinnerchecked {
    height: 48px !important;
    padding: 11px 0px 5px 16px !important;
  }
  .portfolio-linkinnerunchecked {
    height: 48px !important;
    padding: 11px 0px 5px 16px !important;
  }
  .editdeleteportfolios {
    width: 76px !important;
    left: 0px !important;
    margin-top: -6px !important;
  }
  .editdeleteportfoliosun {
    width: 76px !important;
    left: 38px !important;
    margin-top: -4px !important;
  }
  .small-icons-OC6 {
    margin: 0px 3px 0 3px !important;
  }
  #portfolio-display {
    width: 100%;
    height: 196px !important;
    background-color: #222a35;
    position: absolute;
    margin: -18px 0px 0px -3px;
    overflow-y: scroll;
    border-top: 1px solid #1a2028;
    border-bottom: 1px solid #1a2028;
  }
  .checksss {
    width: 144px !important;
  }
  .accdropdowncancelbutton {
    position: absolute !important;
    top: 300px !important;
    right: 198px !important;
    left: auto !important;
  }
  .accdropdownapplybutton {
    position: absolute !important;
    top: 304px !important;
    right: 60px !important;
    left: auto !important;
  }
  .small-icons-OC7 {
    margin: 1px 13px 0px 13px !important;
    width: 20px !important;
  }
  .small-icons-OC7-sub {
    margin-left: -6px !important;
  }
  #portnobutton {
    width: 80px !important;
    padding: 8px 0px 8px 0px;
    left: 64px !important;
    height: 36px !important;
    top: 286px !important;
    margin: 0px 0px 8px 0px;
  }
  #portaddbutton {
    width: 80px !important;
    height: 36px !important;
    padding: 8px 0px 8px 0px;
    right: 22px !important;
    top: 286px !important;
    margin: 0px 0px 8px 0px;
  }
  .checksssg3 {
    top: -66px !important;
    margin-right: 20px !important;
  }
  .checksssg4 {
    top: -66px !important;
    margin-right: 20px !important;
  }
  .checksssg6 {
    top: -66px !important;
    margin-right: 20px !important;
  }
  .checksssg2 {
    top: 1px !important;
    margin-right: 180px !important;
  }
  .checksssg1 {
    top: 2px !important;
    margin-right: 180px !important;
  }
  .checksssg5 {
    top: 1px !important;
    margin-right: 180px !important;
  }
  .dropdown-list-insights {
    left: 12px !important;
    margin-top: 28px !important;
  }
  .dropdown-list-tools {
    left: 12px !important;
    margin-top: 28px !important;
  }
  .icon-caret-insights {
    rotate: 90deg;
    padding-top: 7px !important;
  }
  .icon-caret-tools {
    rotate: 90deg;
    padding-top: 7px !important;
  }
  .dropdown-list-import {
    left: 12px !important;
    margin-top: 28px !important;
  }
  .icon-caret-import {
    rotate: 90deg;
    padding-top: 7px !important;
  }
}
