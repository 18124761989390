.performancemetricwrapper {
  padding: 0;
  margin: 0px -14px 0px 0px;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}
.performance-metrics-table > div > div {
  width: 100%;
  height: 6.3%;
  display: flex;
  border-bottom: 1px solid #28303c;
}
.performance-metrics-table > div > div > div {
  width: 100%;
}
.performance-metrics-table > div {
  width: 100%;
  height: 100%;
  display: table;
  justify-content: center;
}
.performance-metrics-table > div > div:last-child {
  border-bottom: none;
}
.performancemetricomponentwrapper {
  position: relative;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 100%;
  width: 100%;
  padding: 28px 26px 2px 26px;
}
.performancemetricgraphswrapper {
  overflow-x: hidden;
  overflow-y: visible;

  width: calc(70%);
  height: 100%;
  margin-right: 2px;
  padding-top: 8px;
  margin-top: -6px;
  margin-left: -2px;
}
#invisible {
  position: relative;
  bottom: 0px;
}
.performancemetricgraphswrapper::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.performancemetricgraphswrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.performancemetricgraphswrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.performancemetricgraphswrapper::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.performance-metrics-tablewrapper {
  width: 30%;
  height: 100%;
  white-space: nowrap;
}
#perfoutersettings {
  position: relative;
  background-color: transparent;
  width: 100%;
  right: 0px;
}
.perfsettingsouter {
  position: relative;
  margin-left: auto;
  height: auto;
}

.select-calculation-type-perf {
  position: absolute;
  margin-left: auto;
  right: 18px;
  z-index: 2147483647;
}
#scrollbuttonsPM {
  position: relative;
  margin-left: auto;
  margin-right: 280px;
  z-index: 2147483647;
}
#scrollupbuttonPM {
  position: fixed;
  margin-top: -20px;
  margin-left: 6px;
  font-size: 3.2em;
  font-weight: 100 !important;
  z-index: 2147483646;
  padding: 0px;
  height: 0px;
  cursor: pointer;
  display: none;
  color: #fbd665;
  filter: drop-shadow(0 0 2px #fbd665);
  -webkit-filter: drop-shadow(0 0 2px #fbd665);
}
.fadeindown {
  -webkit-animation: fadeindown2PM 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeindown2PM 1s; /* Firefox < 16 */
  animation: fadeindown2PM 1s;
  @keyframes fadeindown2PM {
    from {
      opacity: 0;
      transform: translate(0px, -104px);
    }
    to {
      opacity: 1;
      transform: translate(0px, -96px);
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadeindown2PM {
    from {
      opacity: 0;
      transform: translate(0px, -104px);
    }
    to {
      opacity: 1;
      transform: translate(0px, -96px);
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadeindown2PM {
    from {
      opacity: 0;
      transform: translate(0px, -104px);
    }
    to {
      opacity: 1;
      transform: translate(0px, -96px);
    }
  }
}
#scrolldownbuttonPM {
  position: fixed;
  margin-left: 6px;
  margin-top: 4px;
  font-size: 3.2em;
  font-weight: 100 !important;
  z-index: 2147483646;
  top: 100px;
  padding: 0px;
  height: 0px;
  cursor: pointer;
  color: #fbd665;
  filter: drop-shadow(0 0 2px #fbd665);
  -webkit-filter: drop-shadow(0 0 2px #fbd665);
}
.select-calculation-airgeo-perf {
  position: absolute;
  margin-left: auto;
  right: 30%;
  z-index: 2147483647;
}

.tradersiqindexwrapper {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  display: block;
  align-items: center;
  justify-content: center;
  padding: 0px 14px 44px 24px;
  margin: -6px 0px 0px 0px;
  border: 2px solid #fbd665;
  -moz-box-shadow: inset 0 0 4px #ffe593;
  -webkit-box-shadow: inset 0 0 4px #ffe593;
  box-shadow: inset 0 0 4px #ffe593;
  background-color: #1f2831;
  -webkit-filter: drop-shadow(0 0 4px #171c23);
  -moz-filter: drop-shadow(0 0 4px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 4px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 4px #171c23);
  border-radius: 10px;
  width: calc(100% - 30px);
  height: 100%;
  display: flex;
}
.tradersiqindexcalculation {
  color: #fbd665;
  font-size: 1.8em;
  font-weight: 300;
  margin-top: 3vh;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 15px;
  z-index: 2147483646;
  display: flex;
  filter: drop-shadow(0 0 1px #fbd665);
  -webkit-filter: drop-shadow(0 0 1px #fbd665);
}
.tradersiqindextext {
  display: flex;
  flex-direction: row;
  font-size: 1.3em;
  font-weight: bold;
  align-items: left;
  position: relative;
  left: 12px;
  margin-top: 28px;
}
.indextext2 {
  display: flex;
  flex-direction: row;
  color: #deebf7;
  font-size: 1em;
  font-weight: 300;
  align-items: left;
  position: relative;
  left: 10px;
}
.indextext {
  position: relative;
  color: #fff7cb;
  font-size: 1.3em;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 26px;
  width: 130px;
  left: 12px;
  z-index: 0 !important;
  display: block;
}
.indexcalculation {
  color: #fbd665;
  font-size: 1.8em;
  font-weight: 300;
  margin-top: 3vh;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  display: flex;
  filter: drop-shadow(0 0 1px #fbd665);
  -webkit-filter: drop-shadow(0 0 1px #fbd665);
  left: 12px;
  position: relative;
}
.tradersiqindexChoosetext {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 26px;
  width: 180px;
  z-index: 2147483647 !important;
  display: block;
  position: relative;
  left: 16px;
  top: 10px;
}
.TIQmetricWrapper {
  position: relative;
  display: block;
  align-items: center;
  width: 154px;
  justify-content: center;
  z-index: 2147483647 !important;
  text-align: center;
}
.metricWrapper {
  position: relative;
  display: block;
  align-items: center;
  justify-content: center;
  z-index: 2147483647 !important;
  text-align: center;
}
.performance-metrics-table {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border-top: 50px solid #28303c;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  position: relative;
  padding: 14px;
  border-radius: 10px;
  background-color: #1f2831;
  align-items: center;
  border-spacing: 0px;
  box-shadow: 0 0 4pt 2pt #171c23;
  display: block;
  justify-content: left;
  color: #deebf7;
  top: -42px;
  height: 100%;
  width: 100%;
  margin: 28px 32px 0px 0px;
}
.performance-metrics-label {
  position: relative;
  margin-bottom: -29px;
  padding: 0;
  color: #fbd665;
  width: fit-content;
  font-size: 1.6em;
  top: 0px;
  left: 20px;
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */

  font-weight: 400;
  z-index: 10000;
}
.performancemetricwrapper[min-width~="1312px"] {
  padding: 0;
  margin: 0px -14px 0px 0px;
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
}
.performancemetricwrapper[min-width~="1312px"]
  .performancemetricomponentwrapper {
  margin-bottom: 0px;
}
.performancemetricwrapper[max-width~="1260px"] {
  height: auto;
}
.performancemetricwrapper[max-width~="1260px"]
  .performancemetricomponentwrapper {
  position: relative;
  display: flex;
  flex-direction: column !important;
  margin-bottom: 0px;
  height: 100%;
  width: 100%;
  padding: 28px 26px 2px 26px;
}

.performancemetricwrapper[max-width~="1260px"]
  .performance-metrics-tablewrapper {
  width: 100%;
  height: 100%;
  min-height: 700px;
  white-space: nowrap;
}
.performancemetricwrapper[max-width~="1260px"] .performancemetricgraphswrapper {
  overflow-x: hidden;
  overflow-y: visible;
  width: calc(100% + 28px);
  height: auto;
  margin-right: 2px;
  padding-top: 8px;
  margin-top: -6px;
  margin-left: -2px;
  min-height: 700px;
}
.performancemetricwrapper[max-width~="900px"] .performancemetricol1 {
  height: 50%;
}
.performancemetricwrapper[max-width~="900px"] .performancemetricol2 {
  height: 50%;
}

.performancemetricwrapper[min-width~="1312px"] .performance-metrics-table {
  margin: 28px 32px 10px 0px;
}
.performancemetricwrapper[max-width~="900px"] .performance-metrics-table {
  flex-flow: column;
  margin-bottom: 46px;
  height: calc(100% - 26px);
}
.performancemetricwrapper[max-width~="400px"] .outermetriccell {
  font-size: 12px;
}
.performancemetricwrapper[max-width~="900px"]
  .performancemetricomponentwrapper {
  height: 100%;
}
.performancemetricwrapper[max-width~="900px"]
  .performance-metrics-table
  > div
  > div {
  width: 100%;
  height: 25%;
  display: flex;
  border-bottom: 1px solid #28303c;
}

.sliderperf {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #14181e;
}

.sliderperf:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: #fbd665;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .sliderperf {
  background-color: #14181e;
}

input:focus + .sliderperf {
  box-shadow: 0 0 2px #556171;
}

input:checked + .sliderperf:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.sliderperf.roundperf {
  border-radius: 24px;
}

.sliderperf.roundperf:before {
  border-radius: 50%;
  display: block;
}
.sliderperf.round2perf:before {
  display: block;
}
.sliderperf.round3perf:before {
  display: none;
}
.sliderperf.round2perf:before {
  display: block;
}
.sliderperf.round3perf:before {
  display: none;
}
.TIQperftopwrapper {
  height: 31%;
  margin: 0;
  margin-bottom: -34px;
  padding-left: 4px;
  margin-right: -4px;
}
.perftopwrapper {
  height: 31%;
  margin: 0;
  margin-top: 36px;
  padding-left: 4px;
  margin-right: -4px;
}
.indexgraph {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  min-width: 240px;
  width: calc(100% - 34px);
  display: block;
  height: calc(100%);
}
.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.indexwrapper {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  display: block;
  align-items: center;
  justify-content: center;
  padding: 0px 14px 44px 24px;
  margin: -6px 0px 0px 0px;
  border: 2px solid #14181e;
  background-color: #1f2831;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  box-shadow: 0 0 4pt 2pt #171c23;
  border-radius: 10px;
  width: calc(100% - 30px);
  height: calc(100% + 6px);
  display: flex;
}

.TIQindexgraph {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  min-width: 240px;
  width: calc(100% - 34px);
  display: block;
  height: calc(100%);
}
.performancemetricwrapper[min-width~="1312px"] .TIQindexgraph {
  margin: 28px 32px 0 0;
}
.performancemetricwrapper[max-width~="900px"] .TIQindexgraph {
  margin: 0px 32px 0 32px;
}
.TIQindexgraphwrapper {
  display: block;
  transition: none;
  width: calc(100% - 54px);
  height: 100%;
  margin: 30px 0px -30px 50px;
  padding-bottom: 0px;
  padding-left: 0px;
}
.indexgraphwrapper {
  display: block;
  transition: none;
  width: calc(100% - 34px);
  height: 100%;
  margin: 30px 0px -30px 30px;
  padding-bottom: 0px;
  padding-left: 0px;
}
//
.indextext {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
}
.TIQindexgraphactual {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  background-color: #06080a;
  width: 100%;
  height: 100%;
}
.indexgraphactual {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  background-color: #06080a;
  width: 100%;
  height: 100%;
}
.indexgraph-label {
  position: absolute;
  display: block;
  padding: 0;
  color: #fbd665;
  top: 10px;
  font-weight: 300;
  z-index: 10000;
  height: 40px;
  width: 180px;
  color: #fff7cb;
  font-size: 1em;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: block;
  left: 43%;
}
/* .TIQindexgraph-label {
  position: absolute;
  display: block;a
  padding: 0;
  color: #fbd665;
  top: 10px;
  font-weight: 300;
  z-index: 10000;
  height: 40px;
  width: 180px;
  color: #fff7cb;
  font-size: 1em;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: block;
  left: 52%;
} */
.indexgraphwrapperblank {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  display: block;
  align-items: center;
  justify-content: center;
  padding: 0px 14px 44px 24px;
  margin: 56px 0px 0px 6px;
  position: relative;
  z-index: 0;
  border: 2px solid #808080;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  box-shadow: 0 0 4pt 2pt #171c23;
  border-radius: 10px;
  width: calc(100% - 34px);
  height: 31%;
  display: flex;
  transition: border 0.25s ease-in-out;
  &:hover {
    border: 2px solid #deebf7;
    transition: border 0.25s ease-in-out;
    cursor: pointer;
    .indexgraphicon {
      color: #deebf7;
    }
  }
}
.indexgraphicon {
  font-size: 100px;
  font-weight: 300;
  margin-top: 34px;
  color: #808080;
  transition: color 0.25s ease-in-out;
  &:hover {
    cursor: pointer;
    color: #deebf7;
  }
}
.TIQIndexgraphsuperwrapper {
  position: relative;
  z-index: 1;
  width: calc(100% - 140px);
  height: 100%;
  transition: all 0.25s ease-in-out;
}
.innermetriccell1PM:disabled {
  cursor: default;
  color: #deebf7;
  border: none;
}
.innermetriccell1PM {
  font-weight: 300;
  text-align: left;
  padding-left: 10px;
  cursor: default;
  background-color: transparent;
  color: #deebf7;
  border: none;
}

.closemetricgraphbutton {
  background-color: #1a2028;
  transition: none;
  position: relative;
  padding: 0px 0px 0px 8.5px;
  margin-left: auto;
  margin-top: -5px;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  border: 1px solid #3a424d;
  top: 18px;
  right: 24px;
  color: #deebf7;
  font-weight: 200;
  font-size: 1.6em;
  z-index: 10000;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  cursor: pointer;
}
.closemetricgraphbutton:after {
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
  position: absolute;
  transition: none;
  top: -9px;
  left: 5px;
  color: #7689a1;
  font-size: 1.1em;
  height: 8px;
  width: 10px;
}
.closemetricgraphbutton:hover:after {
  color: #94aac7;
}
.selectGraphselectionfields {
  position: relative;
  width: 100%;
}
.inner-metric-cell1PM2 {
  color: #fbd665;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background-color: #222a35;
  padding: 1px 0px 1px 1px;
  margin-top: 3px;
  margin-left: -2px;
  margin-right: 4px;
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: #fff7cb;
    color: #edc240;
    box-shadow: inset 0 0 2px #fbd665;
    cursor: pointer;
  }
}
//react-bootstrap classnames
.popover-basic {
  position: relative;
}
.popover {
  background-color: #14181e;
  width: max-content;
  border-radius: 10px !important;
}
.popover-body {
  color: #fff7cb;
}
.popover-header {
  background-color: #28303c;
  border-top-color: #28303c;
  color: #fbd665;
}
.tooltip-arrow {
  display: none !important;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after,
.bs-popover-top > .popover-arrow::after {
  border-top-color: #14181e;
  display: none !important;
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
.bs-popover-bottom > .popover-arrow::after {
  border-bottom-color: #28303c;
  display: none !important;
}
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  border-bottom-color: #28303c;
  display: none !important;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after,
.bs-popover-end > .popover-arrow::after {
  border-right-color: #14181e;
  display: none !important;
}
