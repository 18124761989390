.cumulativereturngraph,
.plhistorygraph,
.plbytimegraph {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 2px solid #14181e;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  border-radius: 10px;
  background-color: #1f2831;
  box-shadow: 0 0 4pt 2pt #171c23;
  min-width: 240px;
  justify-content: center;
  display: flex;
  height: calc(100%);
  width: calc(100%);
}
.tooptip-i-style90 {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  color: #fbd665 !important;
  height: 14px;
  width: 14px;
  line-height: 14px;
  padding: 0px 5px 0px 5px;
  border-radius: 4px;
  background-color: #1a2028;
  z-index: 0;
  font-weight: 400;
  cursor: default;
  transition: all 0.1s ease-in-out;
  font-size: 0.75em;
  &:hover {
    background-color: #1f2831;
    color: #fbd665;
    cursor: pointer;
  }
  top: 0px;
  position: relative;
  margin-left: 6px;

  -webkit-text-fill-color: #fbd665;
  -moz-text-fill-color: #fbd665; /* Old versions of Firefox */
  -ms-text-fill-color: #fbd665; /* Internet Explorer/Edge */
  margin-right: 4px;
}
.innerdashboardtoppinnedhoverclick {
  display: none;
  width: 100%;
}
.selectEditdahsboardmodewrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  top: 60px;
  margin-left: 6px;
  position: absolute;
}
.selectEditdahsboardmode {
  border-radius: 50px;
  background-color: #14181e;
  border: 1px solid #44546a;
  color: #808080;
  padding: 1px 10px 1px 10px;
  margin: 6px;
  &:hover {
    background-color: #222a35;
    cursor: pointer;
  }
}
.selectEditdahsboardmodeselected {
  border-radius: 50px;
  background-color: #222a35;
  border: 1px solid #fbd665;
  color: #b7b7b7;
  padding: 1px 10px 1px 10px;
  margin: 6px;
  &:hover {
    background-color: #222a35;
    cursor: pointer;
  }
}
.editdashboardcomponenntwrapper {
  border-left: 2px solid #2e3a48;
  width: 340px;
  height: 100%;
  background-color: #1a2028;
  position: absolute;
  right: 0px;
  z-index: 10;
  overflow: hidden;
  top: 0px;
}
#dashimportantmetricgraphwraper {
  z-index: 1000000;
  top: calc(50% - 8px);
  display: flex;
  flex: row nowrap;
  position: relative;
  margin: -2px 10px 0px 0px;
  padding: 0px 10px 0px 0px;
  width: 100%;
}
#editdashimportantmetricgraphwraper {
  z-index: 1000000;
  top: calc(50% - 8px);
  display: flex;
  flex: row nowrap;
  height: fit-content;

  position: absolute;
  margin: -2px 10px 0px 0px;
  padding: 0px 10px 0px 0px;
  width: 100%;
}
.rotate180degrees {
  -webkit-transform: rotate(180deg) translate(-3px, -1px);
  -moz-transform: rotate(180deg) translate(-3px, -1px);
  -o-transform: rotate(180deg) translate(-3px, -1px);
  -ms-transform: rotate(180deg) translate(-3px, -1px);
  transform: rotate(180deg) translate(-3px, -1px);
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -o-transform-origin: center;
  transform-origin: center;
}
.superdashboardeditbuttonswrapper {
  position: relative;
  margin-left: auto;
  margin-right: 34px;
  display: flex;
  transition: all 0.35s ease-in-out !important;
}
.dashboardeditbuttonswrapper {
  position: fixed;
  background-color: #1f2831;
  box-shadow: 0 0 4pt 2pt #171c23;
  width: 200px;
  border-radius: 50px;
  height: 48px;
  margin-top: 10px;
  display: flex;
  flex-flow: row;
  z-index: 120;
  opacity: 0.8;
}
.closeeditdashboardbutton {
  background-color: #1a2028;
  transition: none;
  position: relative;
  padding: 0px 0px 0px 8.5px;
  margin-left: auto;
  margin-top: -5px;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  border: 1px solid #3a424d;
  top: 14px;
  right: 8px;
  color: #deebf7;
  font-weight: 200;
  font-size: 1.6em;
  z-index: 10000;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  cursor: pointer;
}
.closeeditdashboardbutton:after {
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
  position: absolute;
  transition: none;
  top: -9px;
  left: 5px;
  color: #7689a1;
  font-size: 1.1em;
  height: 8px;
  width: 10px;
}
.closeeditdashboardbutton:hover:after {
  color: #94aac7;
}
.closeeditdashboardbuttonmini {
  background-color: #1a2028;
  transition: none;
  position: absolute;
  padding: 0px 0px 0px 8.5px;
  margin-left: auto;
  margin-top: -5px;
  height: 20px;
  display: none;
  width: 20px;
  border-radius: 30px;
  border: 1px solid #3a424d;
  top: 0px;
  right: -5px;
  color: #deebf7;
  font-weight: 200;
  font-size: 1.6em;
  z-index: 10000;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  cursor: pointer;
}
.closeeditdashboardbuttonmini:after {
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
  position: absolute;
  transition: none;
  top: -9px;
  left: 2px;
  color: #7689a1;
  font-size: 0.85em;
  height: 8px;
  width: 10px;
}
.closeeditdashboardbuttonmini:hover:after {
  color: #94aac7;
}
.dashboardcaretbutton {
  position: relative;
  outline: none;
  border: none;
  z-index: 10000000000;
  right: 4px;
  top: -4px;
  height: 54px;
  width: 54px;
  outline: none;
  border: none;
  font-size: 28px;
  transition: all 0.25s ease-in-out;
  border-radius: 100px;
  color: #deebf7;
  bottom: 0px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    -webkit-filter: drop-shadow(0 0 3px #deebf7);
    -moz-filter: drop-shadow(0 0 3px #deebf7); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 3px #deebf7); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 3px #deebf7);
  }
}
.toggleadjust4 {
  top: 2px !important;
  margin: 0px 16px 0px 16px !important;
}
.select-toggler-editdashticker-wrapper {
  display: flex;
  flex-direction: column;
}
.select-toggler-editdashticker {
  position: relative;
  z-index: 2147483647;
  margin-top: 0px;
  color: #fff7cb;
  background-color: #1f2831;
  font-size: 1.1em;
  width: 192px;
  top: 0px;
  left: 68px;
  padding: 7px 17px 7px 17px;
  -moz-box-shadow: inset 0 0 4px #ffe593;
  -webkit-box-shadow: inset 0 0 4px #ffe593;
  box-shadow: inset 0 0 4px #ffe593;
  border: 1px solid #fbd665;
  border-radius: 100px;
  -webkit-filter: drop-shadow(0 0 8px #14181e);
  -moz-filter: drop-shadow(0 0 8px #14181e);
  -ms-filter: drop-shadow(0 0 8px #14181e);
  filter: drop-shadow(0 0 8px #14181e);
}
.editdashtickersymbolswrapper2 {
  position: relative;
  top: 24px;
  color: #deebf7;
  padding: 0px 26px;
}
.editdashtickersymbolswrapper {
  position: relative;
  z-index: 2147483647;
  background-color: #14181e;
  height: fit-content;
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  left: 16px;
  justify-content: left;
  min-height: 150px;
  max-height: 240px;
  margin-top: 30px;
  width: 90%;
  border-radius: 12px;
  padding: 10px;
  overflow-y: scroll;
}
.editdashtickersymbolswrapper::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.editdashtickersymbolswrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.editdashtickersymbolswrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.editdashtickersymbolswrapper::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.editdashtickersproname {
  font-weight: 400;
  font-size: 0.7em;
  margin-top: 1px;
}
.editdashtickersddesc {
  font-weight: 500;
  font-size: 1em;
  margin-top: -2px;
}
.editdashtickers {
  position: relative;
  z-index: 2147483647;
  margin: 5px 5px 5px 5px;
  width: fit-content;
  color: #14181e;
  background-color: #ffe593;
  cursor: pointer;
  top: 0px;
  left: 0px;
  height: 43px;
  padding: 2px 12px 2px 12px;
  transition: all 0.15s;
  border-radius: 10px;
  -webkit-filter: drop-shadow(0 0 8px #14181e);
  -moz-filter: drop-shadow(0 0 8px #14181e);
  -ms-filter: drop-shadow(0 0 8px #14181e);
  filter: drop-shadow(0 0 8px #14181e);
  &:hover {
    color: #14181e;
    background-color: #fff7cb;
    .closeeditdashboardbuttonmini {
      display: block;
    }
  }
}
.editdashtickersymbolsaddbutton3 {
  position: absolute;
  top: 144px;
  left: 79px;
  margin: 5px 5px 5px 5px;
  width: fit-content;
  color: #14181e;
  background-color: #fbd665;
  cursor: pointer;
  padding: 6px 15px 6px 15px;
  transition: all 0.15s;
  border-radius: 10px;
  outline: none;
  border: none;
  -webkit-filter: drop-shadow(0 0 8px #14181e);
  -moz-filter: drop-shadow(0 0 8px #14181e);
  -ms-filter: drop-shadow(0 0 8px #14181e);
  filter: drop-shadow(0 0 8px #14181e);
  &:hover {
    color: #14181e;
    background-color: #ffe593;
    .closeeditdashboardbuttonmini {
      display: block;
    }
  }
}
.editdashtickersymbolsaddbutton4 {
  position: absolute;
  top: 144px;
  right: 30px;
  margin: 5px 5px 5px 5px;
  width: fit-content;
  color: #14181e;
  background-color: #fbd665;
  cursor: pointer;
  padding: 6px 15px 6px 15px;
  transition: all 0.15s;
  border-radius: 10px;
  outline: none;
  border: none;
  -webkit-filter: drop-shadow(0 0 8px #14181e);
  -moz-filter: drop-shadow(0 0 8px #14181e);
  -ms-filter: drop-shadow(0 0 8px #14181e);
  filter: drop-shadow(0 0 8px #14181e);
  &:hover {
    color: #14181e;
    background-color: #ffe593;
    .closeeditdashboardbuttonmini {
      display: block;
    }
  }
}
.editdashtickersymbolsaddbutton4:disabled {
  background-color: #e4d5ae;
  color: #394453;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  display: inline-block;
  cursor: not-allowed;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
.editdashtickersymbolsaddbutton {
  position: relative;
  left: calc(25%);
  top: 0px;
  margin: 28px 5px 5px 2px;
  width: fit-content;
  color: #14181e;
  background-color: #fbd665;
  cursor: pointer;
  padding: 6px 15px 6px 15px;
  transition: all 0.15s;
  border-radius: 12px;
  outline: none;
  border: none;
  -webkit-filter: drop-shadow(0 0 8px #14181e);
  -moz-filter: drop-shadow(0 0 8px #14181e);
  -ms-filter: drop-shadow(0 0 8px #14181e);
  filter: drop-shadow(0 0 8px #14181e);
  &:hover {
    color: #14181e;
    background-color: #ffe593;
    .closeeditdashboardbuttonmini {
      display: block;
    }
  }
}
.chartjs-render-monitor {
  overflow: visible;
}
.riskmetriclabel2 {
  position: fixed;
  font-size: 1em;
  font-weight: 400;
  bottom: 10px;
  width: 100%;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  display: flex;
}
.editdashtickersymbolsaddbutton7 {
  position: relative;
  left: calc(25%);
  top: 0px;
  margin: 26px 5px 5px 2px;
  width: fit-content;
  color: #14181e;
  background-color: #fbd665;
  cursor: pointer;
  padding: 6px 15px 6px 15px;
  transition: all 0.15s;
  border-radius: 12px;
  outline: none;
  border: none;
  -webkit-filter: drop-shadow(0 0 8px #14181e);
  -moz-filter: drop-shadow(0 0 8px #14181e);
  -ms-filter: drop-shadow(0 0 8px #14181e);
  filter: drop-shadow(0 0 8px #14181e);
  &:hover {
    color: #14181e;
    background-color: #ffe593;
    .closeeditdashboardbuttonmini {
      display: block;
    }
  }
}
.dashboardeditbutton {
  position: relative;
  outline: none;
  border: none;
  z-index: 10000000000;
  right: 100px;
  top: -4px;
  height: 40px;
  width: 40px;
  outline: none;
  border: none;
  font-size: 22px;
  transform: scaleX(-1);
  transition: all 0.25s;
  border-radius: 100px;
  color: #fbd665;
  bottom: 0px;
  padding: 10px 0px 10px 0px;
  margin: 0px 0px 5px auto;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    -webkit-filter: drop-shadow(0 0 3px #deebf7);
    -moz-filter: drop-shadow(0 0 3px #deebf7); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 3px #deebf7); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 3px #deebf7);
  }
}
.cumulativereturngraph {
  padding: 0px 26px 0px 20px;
  margin: 0px 0px 0px 0px;
}
.plhistorygraph {
  margin: 0px 0px 0px 0px !important;
  padding: 0px 26px 0px 20px !important;
}
.plbytimegraph {
  margin: 0px 0px 0px 0px !important;
  padding: 0px 26px 0px 20px !important;
}

.currentEquityval2inner {
  position: relative;
  top: 2px;
}
.innerdashboardcolumnequitywrap {
  display: flex;
  flex-flow: row wrap;
  width: 60%;
}
#perfmetertexttooltipinner {
  width: 150px;
  margin-top: -2px;
  color: #fff7cb;
}
#perfmetertexttooltipinner2 {
  width: 110px;
  color: #fff7cb;
}
// Performance meter stuff
#perfmeterwrapper {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: block;
  background-color: #1f2831;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  box-shadow: 0 0 4pt 2pt #171c23;
  padding: 10px 18px 4px 18px;
  margin: 0px;
  border-radius: 10px;
  height: 100%;
}
#perfmetertext {
  color: #fbd665;
  font-size: 1.2em;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  margin: 0px 0px 30px 4px;
}

#perfmeterlabelBE {
  color: #f6f969;
  position: relative;
  font-size: 0.8em;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 0px;
  width: 62px;
  top: 4px;
  left: calc(50% - 33px);
}
#perfmeterlabelPoor {
  color: #fab570;
  font-size: 0.8em;
  font-weight: 300;
  left: 3%;
  width: 54px;
  margin-top: 10px;
  position: absolute;
}
#perfmeterlabelAbysmal {
  color: #fa798b;
  font-size: 0.8em;
  font-weight: 300;
  width: 54px;
  margin-top: 10px;
  position: absolute;
}
#perfmeterlabelExcellent {
  color: #55f8a6;
  font-size: 0.8em;
  font-weight: 300;
  width: 54px;
  margin-top: 10px;
  position: absolute;
  left: calc(100% - 68px);
}
#perfmeterlabelGood {
  color: #81f95f;
  font-size: 0.8em;
  font-weight: 300;
  right: 3%;
  width: 54px;
  margin-top: 10px;
  position: absolute;
}

#perfmeterwrappertooltip {
  display: flex;
  flex-flow: row;
  margin-left: calc(50% - 100px);
}

#perfmeterwrappertooltipquestionmark {
  margin: 17px 0px 0px 6px;
  background-color: #1a2028;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  padding: 0px 0px 10px 0px;
  vertical-align: text-top;
}
#perfmeterwrappertooltipquestionmarkinner {
  margin-top: -2px;
}

.perfmeterwrappermain {
  width: 37%;
  margin: 0px 0px 0px 32px;
  height: calc(100% - 4px);
  right: 0px;
  position: relative;
  margin-left: auto;
  min-height: 228px;
}
// -------------------
.dashboardwrapper {
  padding: 0px 0px 0px 0px;
  font-size: 1em;
  margin: 0;
  display: flex;
  flex-flow: column;
  width: calc(100%);
  height: calc(100%);
  margin-bottom: -200px;
}

.gaugechartinner {
  display: flex;
  flex-flow: column;
  width: calc(100%);
  height: 100%;
  margin: 0px -80px 0px 0px;
  padding: 0px 10px 0px 10px;
  align-items: center;
  justify-content: center;
}
.gaugechartwrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 76%;
  margin: 18px 0px 0px 0px;
}
.gaugechartwrapperinner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 12px;
  height: 100%;
  width: 100%;
  margin: 0px 0px -14px 0px;
}
.percentchangenumber {
  margin: 2px 0px 0px 10px;
  font-size: 0.85em;
  display: flex;
  flex-direction: row nowrap;
}
.gaugechartinnerheaderwrapper {
  display: flex;
  flex-flow: row nowrap;
  margin-top: -26px;
}
.gaugechartinnerheader {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  color: #deebf7;
  position: relative;
  white-space: nowrap;
  font-weight: 400;
  margin-top: -8px;
  margin-bottom: -16px;
}
.gaugechartinnerheader2 {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  color: #deebf7;
  position: relative;
  white-space: nowrap;
  font-weight: 400;
  margin-top: -11px;
  margin-bottom: -16px;
}
#perfmeterlabelIndex {
  position: relative;
  color: #fff7cb;
  font-size: 1em;
  font-weight: 300;
  background-color: #1f2831;
  box-shadow: inset 0 0 4pt 2pt #1a2028;
  border-radius: 10px;
  width: 74px;
  margin-bottom: 6px;
}
#perfmeterlabelIndex2 {
  position: relative;
  color: #fff7cb;
  font-size: 1em;
  font-weight: 300;
  background-color: #1f2831;
  box-shadow: inset 0 0 4pt 2pt #1a2028;
  border-radius: 10px;
  width: 74px;
}
.dashboardbuttonwrapper {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px auto;
  float: right;
  display: flex;
  flex-flow: row;
  right: 0px;
  position: relative;
  width: 100px;
  height: 42px;
}
.togglerecenttradesbutton {
  text-align: center;
  position: absolute;
  transition: all 0.15s;
  left: 0px;
  padding: 3px 8px 0px 8px;
  display: inline-block !important;
  margin: 17px 16px 0px 42px;
  border: 1px solid #fbd665;
  max-width: 240px;
  border-radius: 8px;
  outline: none;
  height: 28px;
  line-height: 19px;
  color: #fff7cb;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 400;
  text-overflow: ellipsis;
  z-index: 100000000;
  font-size: 0.9em;
  background-color: transparent;
  background-color: #1a2028;
  -moz-box-shadow: inset 0 0 4px #ffe593;
  -webkit-box-shadow: inset 0 0 4px #ffe593;
  box-shadow: inset 0 0 4px #ffe593;
  &:hover {
    cursor: pointer;
    outline-style: none;
    background-color: #ffe593;
    border: 1px solid #ffe593;
    color: #14181e;
  }
}
.dashboardeditnotifwrapper {
  padding: 12px 0px 8px 27px;
  margin: 24px 26px 2px 26px;
  display: flex;
  flex-flow: row;
  left: 0px;
  position: relative;
  width: calc(100% - 52px);
  height: fit-content;
  border-radius: 8px;
  background-color: #222a35;
  color: #fff7cb;
}
.firstdashboardwrapper {
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 60%;
  min-height: 546px;
}
.firstpoint5dashboardwrapper {
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 60%;
  min-height: 546px;
}
.cummulativereturn-label {
  position: absolute;
  display: block;
  padding: 0;
  color: #fbd665;
  font-size: 1.6em;
  left: 35%;
  font-weight: 300;
  z-index: 10000;
  height: 40px;
  width: 300px;
}
.cummulativereturn-toggleindi {
  position: absolute;
  color: #808080;
  font-size: 14px;
  right: calc(43% + 100px);
}

.superdashboardwrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
}
.sidedashboardwrapper {
  position: relative;
  left: 200px;
  width: 260px;
  height: 100%;
  overflow-y: scroll;
}
.sidedashboardwrapper::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.sidedashboardwrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.sidedashboardwrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.sidedashboardwrapper::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.dashboardeditbuttondostuff {
  transform: scaleX(-1);
  margin-right: -14px;
}
.dashboardredobutton {
  position: relative;
  outline: none;
  border: none;
  z-index: 1;
  right: 76px;
  top: -8px;
  height: 44px;
  outline: none;
  border: none;
  font-weight: bold;
  font-size: 34px;
  transform: scaleX(-1);
  transition: all 0.25s;
  border-radius: 8px;
  color: #a6afc2;
  bottom: 0px;
  padding: 10px 0px 10px 0px;
  width: 50px;
  margin: 0px 0px 5px auto;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    -webkit-filter: drop-shadow(0 0 3px #98b5eb);
    -moz-filter: drop-shadow(0 0 3px #98b5eb); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 3px #98b5eb); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 3px #98b5eb);
    .radio-label-buy-tooltip {
      z-index: 10000000000 !important;
      height: auto;
      width: 72px;
      position: absolute;
      display: block;
      transform: scaleX(-1);
      background-color: #14181e;
      border-radius: 8px;
      padding: 0px 10px 1px 11px;
      top: 70px;
      font-size: 17px;
      font-weight: 300;
    }
  }
}
.openpositionssubheaderfirst {
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  font-weight: 400;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */
}
.openpositionsheader {
  position: absolute;
  display: block;
  white-space: nowrap;
  padding: 0;
  color: #fbd665;
  font-size: 1.6em;
  font-weight: 300;
  z-index: 0;
  height: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0px 0px 0px 26px;
}
.openpositionssubheader {
  position: relative;
  display: block;
  white-space: nowrap;
  padding: 0;
  color: #98b5eb;
  font-size: 0.5em !important;
  font-weight: 300;
  z-index: 10000;
  height: 40px;
  width: 100%;
}
.dashboard-graphs-label {
  position: absolute;
  display: block;
  white-space: nowrap;
  padding: 0;
  color: #fbd665;
  font-size: 1.6em;
  font-weight: 400;
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */

  z-index: 10000;
  height: 40px;
  width: auto;
  margin-left: 6px;
}

.cummulativereturn-label3 {
  position: absolute;
  display: block;
  padding: 0;
  color: #98b5eb;
  font-size: 0.9em;
  left: 8px;
  top: 2px;
  font-weight: 300;
  z-index: 10000;
  height: 40px;
  width: 150px;
}

.cummulativereturn-label4 {
  position: absolute;
  display: block;
  white-space: nowrap;
  padding: 0;
  color: #fbd665;
  font-size: 1.6em;
  left: 35%;
  font-weight: 300;
  z-index: 10000;
  height: 40px;
  width: 160px;
}
.seconddashboardwrapper {
  padding: 0;
  position: relative;
  margin: 8px 0px 0px 0px;
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 30%;
  min-height: 300px;
}
.currentEquityheader {
  color: #deebf7;
  font-size: 1em;
  font-weight: 400;
  width: 50%;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 1px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.currentEquitybottomwrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  height: 53%;
  margin-top: -2px;
  width: 100%;
}
.currentEquityheader2 {
  color: #deebf7;
  font-size: 1em;
  font-weight: 400;
  width: 50%;
  white-space: nowrap;
  align-items: left;
  justify-content: left;
  display: flex;
  margin: 4px 0px 0px 0px;
  padding: 0px 0px 0px 12%;
}
.selectfrequencydropdownbutton {
  height: 28px;
  cursor: pointer;
  border: 1px solid #2e3a48;
  border-radius: 8px;
  padding: 0px 6px 0px 6px;
  background-color: #1a2028;
  color: #fff7cb;
  &:hover {
    background-color: #fff7cb;
    color: #14181e;
  }
}
.selectfrequencydropdownbuttonselected {
  cursor: pointer;
  border: 1px solid #2e3a48;
  border-radius: 8px;
  padding: 0px 6px 0px 6px;
  background-color: #1a2028;
  color: #fff7cb;
}
.selectfrequencydropdownitem {
  padding: 2px 8px 2px 8px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #14181e;
    color: #fff7cb;
  }
}
.selectfrequencydropdown {
  -webkit-filter: drop-shadow(0 0 8px #171c23);
  -moz-filter: drop-shadow(0 0 8px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 8px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 8px #171c23);
  box-shadow: 0 0 4pt 2pt #171c23;
  position: fixed;
  background-color: #1a2028;
  border-radius: 10px;
  right: 40px;
  width: auto;
  padding: 4px;
  z-index: 1000000000;
}
.currentEquityval4 {
  color: #fff7cb;
  font-size: 1.1em;
  font-weight: 400;
  align-items: right;
  justify-content: right;
  width: 50%;
  margin: 1px 12% 0px 0px;
  display: flex;
  white-space: nowrap;
}
.currentEquityval {
  color: #fbd665;
  font-size: 1.2em;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  width: 50%;
  display: flex;
}
.radio-label-buy-tooltip-text {
  margin-top: -6px;
}
.chooseDashColumnsButtonWrapper {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  &:hover {
    .radio-label-buy-tooltip {
      z-index: 10000000000 !important;
      height: auto;
      width: 150px;
      text-transform: none !important;
      margin-left: -100px;
      position: absolute;
      color: #deebf7;
      display: block;
      background-color: #14181e;
      border-radius: 8px;
      padding: 0px 10px 4px 11px;
      top: 46px;
      font-size: 17px;
      font-weight: 300;
    }
    .radio-label-buy-tooltip-tip {
      left: 92px;
    }
  }
}
.cumulativereturngraphsortbutton {
  outline: none;
  border: none;
  background-color: #fff7cb;
  border-radius: 8px;
  position: absolute;
  left: calc(43% - 120px);
  top: 6px;
  color: #14181e;
  font-style: italic;
}
.winrategraphactual {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  padding: 0;
  margin: 0;
}
.winrategraphwrapper {
  /*   color: #55f8a6;
  font-size: 1.4em;
  font-weight: 500;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  display: flex;
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
  width: 270px;
  margin-top: -84px;
  margin-left: -70px; */
  padding: 0px 6px 0px 6px;
  margin: 0px 0px 0px 0px;
  height: 100%;
  width: calc(100%);
  font-size: 1.4em;
  font-weight: 500;
  top: -40px;
  white-space: nowrap;
  overflow: visible;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 100000000;
  display: flex;
}
.tiqgolddash {
  color: #fbd665;
}
.tiqpurple {
  color: #c7b8ff;
}
.tiqcoolblue {
  color: #98b5eb;
}
.logbook-text-input-boxes-wrapper2 {
  display: block;
  position: absolute;
  text-align: right;
  padding-right: 0px;
  width: 100%;
  margin: -4px 0px 4px 0px !important;
}
.logbook-select-input-boxes2 {
  font-family: "Roboto", sans-serif;
  position: relative;
  border-radius: 8px;
  color: #fbd665;
  background-color: #1a2028;
  outline-style: none;
  font-weight: 400;
  width: calc(100% - 60px);
  margin: -6px 14px -6px auto;
}
.logbook-select-input-boxes2 > div {
  min-height: 38px;
  height: 38px;
}
#logbook-select-input-boxes2
  > div:nth-of-type(1)
  > div:nth-of-type(1)
  > div:first-child {
  margin-left: -2px;
}
#logbook-select-input-boxes2 > div:nth-of-type(1) {
  min-height: 26px;
}
#logbook-select-input-boxes2 > div:nth-of-type(1) > div:nth-of-type(1) {
  overflow-x: hidden;
  overflow-y: hidden;
  word-wrap: none;
  flex-flow: row wrap;
  align-items: left !important;
  justify-content: left !important;
}
#logbook-select-input-boxes2 > div:nth-of-type(1) > div:nth-of-type(1) > div {
  flex: none;
}
#pinnedmetricvalue {
  font-size: 0.8em;
  z-index: -1;
  position: absolute;
  font-weight: 400;
  width: 100%;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: -1px;
}
.winratevalue {
  font-size: 1em;
  position: absolute;
  font-weight: 300;
  width: 100%;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  display: flex;
}
.winrategraphdivtextwrapper {
  width: 100%;
  height: 50%;
  margin-top: 70%;
  position: absolute;
  align-items: center;
  justify-content: center;
  z-index: 100000000;
}
#choose-dashcolumns-class {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  display: none;
  width: 700px;
  text-align: center;
  z-index: 100000001;
  margin: -255px 0px 0px -350px;
  top: 50%;
  font-size: 1em;
  left: 50%;
  height: 510px;
  border-radius: 8px;
  color: #deebf7;
  background-color: #222a35;
  -webkit-filter: drop-shadow(0 0 5px #556171);
  -moz-filter: drop-shadow(0 0 5px #556171); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 5px #556171); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 5px #556171);
  outline-style: none;
  border: none;
  flex-flow: row wrap;
  flex: auto;
  align-items: center;
  justify-content: center;
  border-top: 50px solid #1a2028;
}
.chooseDashColumnsButtonWrapper {
  position: relative;
  display: block;
  white-space: nowrap;
  width: 0px;
  margin: 5px 0px 0px 0px;
  right: calc(-100% + 48px);
  padding: 0;
  font-size: 1.6em;
  text-align: left;
  justify-content: left;
  font-weight: 300;
  z-index: 10000;
}
.dropdown-text2 {
  padding: 0;
  margin: 0 0 0 4px;
  left: -15px;
  border-radius: 8px;
  width: 140px;
  height: 43px;
  font-size: 1em;
  display: flex;
  color: #fff7cb;
}
.dropdown-text2:hover {
  background-color: #2e3a48;
  cursor: pointer;
}
.dropdown-items2 {
  font-size: 1.3em;
  padding: 6px 0 0 20px;
  margin: 0;
  width: 150px;
  height: 43px;
}
.dropdown-list-dashboardeditelem {
  width: 150px;
  top: 0px;
  height: 96px;
  display: none;
  position: relative;
  font-size: 14px;
  margin-left: -170px;
  background-color: #1a2028;
  border: 1px solid #14181e;
  padding: 4px 0 4px 0;
  border-radius: 8px;
}
.dropdown-list-dashboardeditelem2 {
  width: 150px;
  top: 0px;
  height: 53px;
  display: none;
  position: relative;
  font-size: 14px;
  margin-left: -170px;
  background-color: #1a2028;
  border: 1px solid #14181e;
  padding: 4px 0 4px 0;
  border-radius: 8px;
}
.handledashmove {
  z-index: 100;
  position: relative;
  margin-left: -94px;
  font-size: 24px;
  cursor: grabbing;
  height: 16px;
}
.handledashdostuff {
  z-index: 100;
  position: relative;
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: -52px;
  font-size: 24px;
  cursor: grabbing;
  color: #fff7cb;
  height: 16px;
  transition: all 0.25s;
  &:hover {
    -webkit-filter: drop-shadow(0 0 2px #fff7cb);
    -moz-filter: drop-shadow(0 0 2px #fff7cb); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 2px #fff7cb); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 2px #fff7cb);
  }
}
.pinnedMetricsWrapper {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  bottom: 0px;
  width: 100%;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  margin: 22px 0px 0px 0px;
  height: calc(51%);
  gap: 0px 12px;
}

.maingraphsdashboardwrapper {
  display: flex;
  flex-flow: row wrap;
  height: 134%;
  gap: 24px;
  margin: 0px 0px 0px 0px;
  padding: 24px 26px 0px 26px;
  position: relative;
}

.maingraphsdashboardinner {
  display: flex;
  width: calc(50% - 13px);
  position: relative;
  height: 50%;
  margin: 0px 0px 0px 0px;
  /*   padding: 6px;
  border-style: dashed;
  border: 1px dashed #808080;
  border-radius: 8px; */
}

.editmodeborders {
  padding: 6px;
  border-style: dashed;
  border: 1px dashed #808080;
  border-radius: 8px;
}
.topmetricgraphwrapper {
  height: 100%;
  width: 50%;
  position: relative;
  left: calc(50% - 16px);
  margin: -10px 10px 0px 0px;
}
.superToppinnedMetricsWrapper {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  bottom: 0px;
  width: calc(100% - 28px);
  padding: 0px;
  height: 13vh;
  margin: 24px 16px 2px 26px;
}
.editsuperToppinnedMetricsWrapper {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  bottom: 0px;
  width: calc(100% - 28px);
  padding: 0px;
  height: fit-content;
  margin: 0px 16px 2px 16px;
}
.editdashboardcomponenntheader {
  position: fixed;
  @import url("https: //fonts.googleapis.com/css2?family=Varela+Round&display=swap");
  top: 14px;
  right: 88px;
  margin: 0;
  font-size: 1.5em;
  text-align: center;
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -ms-text-fill-color: transparent;
}
.superToppinnedMetricsWrappereditablehov {
  display: flex;
  flex-flow: row nowrap;
  position: absolute;
  bottom: 0px;
  width: calc(100%);
  background-color: #14181e;
  height: calc(100%);
}
/* .superToppinnedMetricsWrappereditable {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  bottom: 0px;
  width: calc(100% - 52px);
  border: 2px dashed #98b5eb;
  border-radius: 12px;
  padding: 16px 0px 16px 20px;
  height: 17vh;
  margin: 24px 16px 2px 26px;
  &:hover {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    bottom: 0px;
    width: calc(100% - 52px);
    border: 2px dashed #98b5eb;
    //background-color: #14181e;
    border-radius: 12px;
    padding: 16px 0px 16px 20px;
    height: 17vh;
    margin: 24px 16px 2px 26px;
  }
} */

#doneditingbutton {
  font-size: 1em;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 8px;
  border: none;
  text-align: center;
  background-color: #fbd665;
  color: #1a2028;
  padding: 0px 8px 0px 8px;
  margin-top: -4px;
  margin-left: 20px;
  z-index: 2147483647 !important;
  cursor: pointer;
  width: 74px;
  height: 34px;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
#doneditingbutton:hover {
  outline-style: none;
  border: none;
  color: #222a35;
  background-color: #ffe593;
}
.text-input-boxes-searchbar {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 1px solid #14181e;
  border-radius: 8px !important;
  height: 38px;
  color: #fbd665;
  background-color: #1a2028;
  position: relative;
  outline-style: none;
  padding-left: 10px;
  top: 130px;
  left: 5%;
  font-weight: 400;
  width: 90%;
}
.text-input-boxes-searchbar:focus {
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: all 0.1s;
  border-radius: 8px;
}
#psuedodoneditingbutton {
  font-size: 1em;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 8px;
  border: none;
  text-align: center;
  background-color: #ffe593;
  color: #1a2028;
  padding: 0px 8px 0px 8px;
  margin-top: -4px;
  margin-left: 24px;
  z-index: 2147483647 !important;
  cursor: pointer;
  width: 136px;
  height: 34px;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
#psuedodoneditingbutton:hover {
  outline-style: none;
  border: none;
  color: #222a35;
  background-color: #fff7cb;
}
.taketologbookbuttoninner {
  width: 30px;
  height: 30px;
  transform: scale(0.025);
  &:hover {
    outline-style: none;
    border: none;
  }
}
.taketologbookbutton {
  font-size: 1em;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 8px;
  border: none;
  text-align: center;
  position: absolute;
  transition: all 0.15s;
  color: #1a2028;
  padding: 0px 8px 0px 8px;
  margin: 0px 0px 0px 0px;
  background-color: transparent;
  z-index: 2147483647 !important;
  cursor: pointer;
  top: 0px;
  width: 0px;
  height: 34px;
  outline-style: none;
  &:hover {
    outline-style: none;
    border: none;
    -webkit-filter: drop-shadow(0 0 2px #deebf7);
    -moz-filter: drop-shadow(0 0 2px #deebf7); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 2px #deebf7);
    filter: drop-shadow(0 0 2px #deebf7);
  }
}
/* .innerdashboardportfolios[max-width~="900px"] .taketologbookbutton {
  right: 272px !important;
} */
.deletepicbutton2 {
  transition: height 0.25s;
  background-color: #ff4081;
  position: relative;
  padding: 0px 0px 0px 9px;
  height: 32px;
  opacity: 0;
  width: 32px;
  top: -28px;
  right: -10px;
  float: right;
  margin: 0px 0px 0px -24px;
  border-radius: 30px;
  z-index: 1000000001;
  color: #deebf7;
  font-weight: 500;
  font-size: 1.3em;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
/* .deletepicbutton2:hover {
  cursor: pointer;
} */
.winrategraph {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  display: block;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 16px 0px 0px 0px;
  background-color: #1f2831;
  border-radius: 10px;
  width: calc(20% - 10px);
  //max-width: 220px;
  height: calc(100% - 34px);
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  box-shadow: 0 0 4pt 2pt #171c23;
}
.toppinnedmetricwrapper {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  display: block;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 16px 0px 0px 8px;
  margin: 0px 24px 0px 0px;
  background-color: #1f2831;
  border-radius: 10px;
  width: calc(20% - 12px);
  height: 100%;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  box-shadow: 0 0 4pt 2pt #171c23;
}
.editdashboardpanelscrollwrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 196px);
  width: 100%;
  position: absolute;
  top: 206px;
  padding-bottom: 20px;
}
.editdashboardpanelscrollwrapper::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.editdashboardpanelscrollwrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.editdashboardpanelscrollwrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.editdashboardpanelscrollwrapper::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.editdashboardpanelscrollwrapper2 {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 162px);
  width: 100%;
  position: absolute;
  top: 162px;
}
.editdashboardpanelscrollwrapper2::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.editdashboardpanelscrollwrapper2::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.editdashboardpanelscrollwrapper2::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.editdashboardpanelscrollwrapper2::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.superedittoppinnedmetricwrapper {
  display: flex;
  align-items: left;
  justify-content: left;
  height: fit-content;
}

.addeditdahsboardcomponenettextwrapper {
  position: absolute;
  margin: 0px 0px 0px 50%;
}
.addeditdahsboardcomponenettexttitle {
  color: #fbd665;
  font-weight: 500;
  font-size: 0.88em;
}
.addeditdahsboardcomponenettext {
  color: #fff7cb;
  font-size: 0.82em;
}
.addeditdahsboardcomponenetbutton {
  display: block;
  background-color: #fbd665;
  color: #14181e;
  position: absolute;
  border-radius: 7px;
  margin: 78px 0px 0px 30px;
  padding: 2px 20px 2px 21px;
  cursor: pointer;
  width: 70px;

  height: fit-content;
}
.addeditdahsboardcomponenetbutton:hover {
  outline-style: none;
  border: none;
  color: #222a35;
  background-color: #ffe593;
}
.removeeditdahsboardcomponenetbutton {
  display: block;
  background-color: #bbbbbb;
  color: #14181e;
  position: absolute;
  border-radius: 7px;
  margin: 78px 0px 0px 30px;
  padding: 1px 20px 3px 7px;
  cursor: pointer;
  width: 70px;
  height: fit-content;
}
.removeeditdahsboardcomponenetbutton:hover {
  outline-style: none;
  border: none;
  background-color: #d6d6d6;
}
.extramargin {
  margin: -26px 0px 52px -4px !important;
}
.edittoppinnedmetricwrapper {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  display: block;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 16px 0px 0px 8px;
  transform: scale(0.48);
  //margin: -26px 0px 40px -8px;
  left: calc(-25%);
  width: 100%;
  background-color: #1f2831;
  border-radius: 10px;
  max-height: 120px;
  min-height: 120px;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  box-shadow: 0 0 4pt 2pt #171c23;
}
.edittopmetricgraphwrapper {
  max-height: 110px;
  min-height: 110px;
  width: 50%;
  vertical-align: middle;
  position: relative;
  left: calc(50% - 16px);
  margin: -10px 10px 0px 0px;
}
.my-handle {
  cursor: move;
  cursor: -webkit-grabbing;
}
#editimportantmetricgraphwraper2 {
  z-index: 1000000;
  top: 50%;
  display: flex;
  flex: row nowrap;
  position: relative;
  margin: 32% 10px 0px 0px;
  padding: 0px 10px 0px 0px;
  width: 100%;
}
#editimportantmetricgraphwraper3 {
  z-index: 1000000;
  top: 0%;
  display: flex;
  flex: row nowrap;
  position: relative;
  margin: 45px 10px 0px 0px;
  padding: 0px 10px 0px 0px;
  width: 100%;
}
.toppinnedmetricwrappereditmode {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  display: block;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 16px 0px 0px 8px;
  margin: 0px 24px 0px 0px;
  background-color: #1f2831;
  border-radius: 10px;
  width: calc(20% - 12px);
  height: 100%;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  box-shadow: 0 0 4pt 2pt #171c23;
  cursor: grab;
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
}
.grabbingActive {
  cursor: grab;
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
}
.innerdashboardcolumnwrapper {
  display: flex;
  flex-flow: row-reverse;
  width: calc(100% - 26px);
  margin-top: 24px;
  height: 60vh;
}
.innerdashboardcolumnwrapper1 {
  display: flex;
  width: calc(100% - 26px);
  margin-top: 24px;
  height: 100vh;
  flex-flow: row wrap;
  width: 42%;
  height: 60vh;
  position: relative;
  margin: 0px 0px 0px 94px;
}

.innerdashboardcolumn {
  display: flex;
  flex-flow: row wrap;
  min-width: 590px;
  width: 42%;
  height: 60vh;
  position: relative;
  margin: 0px 0px 0px 24px;
}

.innerdashboardcolumnequitywrapover {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  height: calc(50% + 5px);
  margin: 0px;
  max-height: 430px;
  z-index: 100;
}

.topmetricheaderwrapper {
  width: 30%;
}
.winrateheader {
  color: #deebf7;
  font-size: 1.1em;
  font-weight: 400;
  margin-top: -4px;
  width: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
}
.gaugechartinnerheaderwrapper {
  height: 0px;
  display: flex;
  flex-direction: row nowrap;
}

.topmetricheader {
  color: #deebf7;
  font-size: 1em;
  font-weight: 400;
  margin-top: -6px;
  white-space: nowrap;
  align-items: left;
  justify-content: left;
  padding-left: 8px;
  display: flex;
}
.topmetricvalue {
  font-size: 1.2em;
  position: absolute;
  font-weight: 400;
  width: 30%;
  padding-left: 8px;
  margin-top: 6px;
  white-space: nowrap;
  align-items: left;
  justify-content: left;
  display: flex;
  color: #fff7cb;
}
.rewardsondash {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  display: block;
  align-items: center;
  justify-content: center;
  padding: 14px 24px 34px 24px;
  margin: 2px 0px 24px 0px;
  border: 4px solid #28303c;
  background-color: #1f2831;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  box-shadow: 0 0 4pt 2pt #171c23;
  border-radius: 10px;
  width: calc(100% - 16px);
  height: calc(40% - 8px);
  min-width: 180px;
}
.flushtext2 {
  display: block;
  text-align: center;
  padding-right: 50px;
  margin-left: 20px;
  font-weight: 400;
  width: 100%;
  height: max-content;
  min-height: 36px;
  margin-top: -20px;
  color: #fbd665;
  white-space: nowrap;
  cursor: text;
}
.logbook-text-input-boxes-wrapper2 {
  display: block;
  text-align: right;
  padding-right: 0px;
  right: calc(50% - 114px);
  width: 260px;
  margin: -18px 0px 4px 0px !important;
}
.logbook-text-input-boxes2 {
  border: 1px solid #14181e;
  height: 32px;
}
.logbook-text-input-boxes2 {
  font-family: "Roboto", sans-serif;
  position: relative;
  border-radius: 8px;
  color: #fbd665;
  background-color: #1a2028;
  outline-style: none;
  font-weight: 400;
  width: calc(100% - 60px);
  margin: -6px 14px -6px auto;
}
.ceborderleft {
  border-left: 1px solid #fbd665;
  height: 100%;
}
.currentEquityval2 {
  color: #fbd665;
  font-size: 1em;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  width: 50%;
  display: flex;
}
.currentEquityinnerWrap {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  height: 26%;
  border-radius: 12px;
  margin: 32px 34px 3.5vh 34px;
  background-image: linear-gradient(to bottom right, #28303c, #1f2831);
  -webkit-filter: drop-shadow(0 0 4px #171c23);
  -moz-filter: drop-shadow(0 0 4px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 4px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 4px #171c23);
}
.currentEquityinnerWrapBelow {
  width: 100%;
  margin-top: -30px;
  height: 70%;
  display: flex;
  flex-flow: row;
  justify-content: left;
  align-items: left;
}
.currentEquitycoltext {
  color: #fff7cb;
  font-size: 1.1em;
  font-weight: 400;
  align-items: right;
  justify-content: right;
  width: 50%;
  margin: 0px 12% 0px 0px;
  display: flex;
}
.currentEquityinnerWrapAbove {
  width: 100%;
  display: flex;
  flex-flow: row;
  position: relative;
  max-height: 30px;
  height: auto;
}
.currentEquity {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 0px 0px;
  background-color: #1f2831;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  box-shadow: 0 0 4pt 2pt #171c23;
  border-radius: 10px;
  width: calc(63% - 26px);
  height: calc(100% - 4px);
  min-width: 180px;
  background-image: linear-gradient(to bottom right, #1f2831, #1f2831);
}
.currentEquitycoltextkeepsameline {
  display: inline-block;
  white-space: nowrap;
}
.cumulativereturngraphwrapper {
  display: flex;
  transition: none;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  margin-left: -6px;
  margin-bottom: -30px;
  padding-bottom: 40px;
}
.cumulativereturngraphactual {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  background-color: #06080a;
}
.recent-trades-label {
  position: relative;
  display: block;
  white-space: nowrap;
  width: 0px;
  left: 26px;
  margin: -2px 0px 0px 0px;
  padding: 0;
  color: #fbd665;
  font-size: 1.6em;
  text-align: left;
  justify-content: left;
  font-weight: 300;
  z-index: 10000;
}

.containrecenttrades {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: 28px 2px 0px 2px;
  border-top: 50px solid #28303c;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  position: relative;
  padding: 0px 0px 25px 0px;
  border-radius: 8px;
  background-color: #1f2831;
  align-items: center;
  justify-content: center;
  border-collapse: separate;
  border-spacing: 0px;
  box-shadow: 0 0 4pt 2pt #171c23;
  color: #deebf7;
  top: -34px;
  width: 100%;
  height: calc(100% - 20px);
  overflow: hidden;
}
.recent-trades-table::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.recent-trades-table::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.recent-trades-table::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #1a2028;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.recent-trades-table::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.recent-trades-table > table > tbody > tr {
  padding: 8px 22px 8px 6px;
}
.recent-trades-table > table > tbody > tr > td {
  color: #deebf7;
  border-bottom: 1px solid #44546a;
  overflow: hidden;
  white-space: nowrap;
  padding: 8px 22px 8px 6px;
}
.recent-trades-table > table > thead > tr > th:nth-child(2),
.recent-trades-table > table > tbody > tr > td:nth-child(2) {
  display: none;
  width: -1;
}
.recent-trades-table > table > thead > tr > th:first-child,
.recent-trades-table > table > tbody > tr > td:first-child {
  margin-right: 0px;
  padding-right: 0px;
}
.recent-trades-table > table > thead > tr > th:nth-child(3),
.recent-trades-table > table > tbody > tr > td:nth-child(3) {
  margin-left: 0px;
  padding-left: 0px;
}

.recent-trades-table > table > thead > tr {
  color: #fff7cb;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.recent-trades-table > table > thead > tr > th {
  white-space: nowrap;
  padding: 12px 22px 12px 6px;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0px;
  z-index: 10000;
  font-weight: 400;
  background-color: #1a2028;
  &:hover {
    color: #fbd665;
    cursor: pointer;
  }
}

.recent-trades-table > table > tbody > tr:first-child > td {
  color: #deebf7;
  border-top: 1px solid #44546a;
  z-index: -1;
  overflow: hidden;
  white-space: nowrap;
  padding: 8px 22px 8px 6px;
}
.recent-trades-table > table > tbody > tr:hover {
  box-shadow: 0px 0px 8px 3px #556171;
  background-color: #1a2028;
  cursor: pointer;
}

// open trades
.displayDonutGraph {
  display: flex !important;
}
.OpenPositionDonutGraph {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 2px solid #14181e;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  border-radius: 10px;
  box-shadow: 0 0 4pt 2pt #171c23;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px 0px 0px 0px;
  display: none;
  margin: 0px 0px 0px 24px;
  height: calc(100% + 68px);
  width: calc(30%);
  min-width: 200px;
  display: flex;
  top: -2px;
  position: relative;
}
.OpenPositionDonutGraph-label {
  position: absolute;
  padding: 0;
  text-align: center;
  color: #fbd665;
  font-size: 1.4em;
  left: 15%;
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */

  top: 20px;
  font-weight: 400;
  z-index: 10000;
  height: 60px;
  width: 70%;
}
.OpenPositionDonutGraph-exp {
  position: absolute;
  display: flex;
  flex-direction: row;
  padding: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #fff7cb;
  font-size: 1em;
  top: 16%;
  font-weight: 300;
  z-index: 10000;
  height: 40px;
  width: 100%;
}
.OpenPositionDonutGraph-expval {
  font-weight: 400;
  color: #98b5eb;
  margin-left: 6px;
}
.OpenPositionDonutGraphwrapper {
  padding: 0px;
  width: calc(100% - 36px);
  min-width: calc(100% - 26px);
  height: 50%;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 20%;
  margin-top: 36px;
}
.OpenPositionDonutGraphLegendwrapper {
  padding: 0px;
  width: calc(100% - 36px);
  min-width: calc(100% - 26px);
  height: 25%;
  position: absolute;
  align-items: center;
  justify-content: center;
  bottom: 26px;
  margin-top: 36px;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: hidden;
}
.OpenPositionDonutGraphLegendwrapper::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
  border-radius: 10px;
}
.OpenPositionDonutGraphLegendwrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.OpenPositionDonutGraphLegendwrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.OpenPositionDonutGraphLegendwrapper::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.openpositionstablewrapper {
  width: 75%;
}
.openpositionswrapper {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  border: 1px solid #14181e;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  box-shadow: 0 0 4pt 2pt #171c23;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  margin: 64px 0px 0px 26px;
}
.openpositionswrappersuper {
  padding: 0px;
  width: calc(100% + 68px);
  height: 100%;
  display: flex;
  flex-direction: row;
}
.openpositionswrapperinner {
  padding-bottom: 0;
  height: auto;
  display: table;
  width: 100%;
  height: auto;
}

.openpositionswrapper::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
  border-radius: 10px;
}
.openpositionswrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.openpositionswrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.openpositionswrapper::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.openpositionswrapper > table > thead > tr {
  background-color: #14181e;
  color: #fff7cb;
  overflow: hidden;
  justify-content: left;
  align-items: left;
}

.openpositionswrapper > table > thead > tr > th:nth-child(1),
.openpositionswrapper > table > tbody > tr > td:nth-child(1) {
  display: none;
  width: -1;
}
.openpositionswrapper > table > thead > tr > th:not(:nth-child(1)),
.openpositionswrapper > table > tbody > tr > td:not(:nth-child(1)) {
  margin-right: 0px;
  padding-left: 14px;
  white-space: nowrap;
}

.openpositionswrapper > table > thead {
  font-weight: 400;
}
.openpositionswrapper > table > thead > tr > th {
  white-space: nowrap;
  padding: 12px 26px 12px 0px;
  align-items: left;
  justify-content: left;
  position: sticky;
  top: 0px;
  z-index: 10000;
  font-weight: 400;
  background-color: #181c23; //lighter dark
  &:hover {
    color: #fbd665;
    cursor: pointer;
  }
}
.openpositionswrapper > table > tbody > tr:hover {
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #9e8a4e, #222a35);
  cursor: pointer;
  td:nth-child(2) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
.openpositionssuperwrapper {
  width: 100%;
  height: calc(60vh - 64px);
  display: flex;
  flex-direction: row;
}
.pulsestyling {
  animation: pulseit 2s ease-in-out 0s normal;
  /*   -webkit-transition: pulseit 2s ease-in-out 0s normal;
  /*-moz-transition: background-color 5000ms ease-in-out;
  -o-transition: background-color 5000ms ease-in-out;
  -ms-transition: background-color 5000ms ease-in-out;
  transition: background-color 5000ms ease-in-out; */
}
@keyframes pulseit {
  0% {
    background-color: #222a35;
    color: #fff7cb;
    /*     filter: drop-shadow(0 0 1px);
    -webkit-filter: drop-shadow(0 0 1px); */
  }
  70% {
    /*     filter: "none";
    -webkit-filter: "none"; */
    background-color: none;
    color: #deebf7;
  }
  100% {
    /*     filter: "none";
    -webkit-filter: "none"; */
    background-color: none;
    color: #deebf7;
  }
}
@-webkit-keyframes pulseit {
  0% {
    background-color: #222a35;
    color: #fff7cb;
    /*     filter: drop-shadow(0 0 1px);
    -webkit-filter: drop-shadow(0 0 1px); */
  }
  70% {
    /*     filter: "none";
    -webkit-filter: "none"; */
    background-color: none;
    color: #deebf7;
  }
  100% {
    /*     filter: "none";
    -webkit-filter: "none"; */
    background-color: none;
    color: #deebf7;
  }
}

.sortingcaret2 {
  margin-left: 8px;
  margin-top: -2px;
  font-size: 1.3em;
  color: #a4a9b1;
}

// ------------------ REPONSIVE DESIGN ------------------
.dashboardwrapper[max-width~="529px"] .maingraphsdashboardwrapper {
  display: flex;
  flex-flow: row wrap;
  height: 134%;
  gap: 24px !important;
  margin: 0px 0px -24px 0px;
  padding: 12px 24px 8384px 24px;
}
.dashboardwrapper[max-width~="942px"] .maingraphsdashboardwrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 60vh;
  position: relative;
  margin-left: 0px;
  margin: 0px 0px -24px 0px !important;
}
.dashboardwrapper[max-width~="942px"] .maingraphsdashboardinner {
  display: flex;
  width: calc(100%) !important;
  height: 450px;
  min-height: 450px;
  margin: 0px 0px 0px 0px;
  /*   padding: 6px;
  border-style: dashed;
  border: 1px dashed #808080;
  border-radius: 8px; */
}
.dashboardwrapper[max-width~="514px"] .maingraphsdashboardinner {
  display: flex;
  width: calc(100% - 12px);
  height: 360px;
  min-height: 360px;
  margin: 6px 6px 0px 6px;
  /*   padding: 6px;
  border-style: dashed;
  border: 1px dashed #808080;
  border-radius: 8px; */
}

// For screen sizes larger than 1680w and 900h
@media screen and (min-width: 1680px) and (min-height: 900px) {
  .dashboardwrapper[max-width~="1266px"] .innerdashboardcolumnwrapper {
    display: flex;
    position: relative;
    flex-flow: column;
    margin-top: 6px;
    width: 100%;
    height: max-content;
  }
  .dashboardwrapper[max-width~="1266px"] .openpositionssuperwrapper {
    width: 100%;
    height: calc(100% + 150px);
    margin-top: 72px;
  }
  .dashboardwrapper[max-width~="1266px"] .innerdashboardcolumnwrapper1 {
    display: flex;
    position: relative;
    flex-flow: column;
    height: max-content;
    width: 100%;
    padding: 22px 26px 0px 26px;
    top: 6px;
    margin: 0px 0px -64px 0px;
  }
  .dashboardwrapper[max-width~="1266px"] .innerdashboardportfolios {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% - 28px);
    height: 100%;
    min-height: 750px;
    position: relative;
    //margin: -14px 0px 0px 0px;
  }
  .dashboardwrapper[max-width~="1013px"] .innerdashboardportfolios {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% - 28px);
    height: 100%;
    min-height: 640px;
    position: relative;
    //margin: -14px 0px 0px 0px;
  }
  .dashboardwrapper[max-width~="1266px"] .maingraphsdashboardinner {
    display: flex;
    width: calc(50% - 13px);
    height: 660px;
    margin: 0px 0px 0px 0px;
  }
  .dashboardwrapper[max-width~="1266px"] .openpositionswrapper {
    height: calc(100% - 352px);
    width: calc(100% + 38px);
    margin-bottom: -128px;
  }
  .dashboardwrapper[max-width~="1266px"] .maingraphsdashboardwrapper {
    margin: -68px 0px 0px 0px !important;
  }
  .dashboardwrapper[max-width~="1266px"] .superToppinnedMetricsWrapper {
    color: #fbd665;
    font-weight: 300;
    white-space: nowrap;
    margin: 18px 0px -6px 21px;
    padding: 5px 0px 6px 5px;
    height: 15vh;
    min-height: 140px;
    width: calc(100% - 52px);
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .dashboardwrapper[max-width~="1266px"] .taketologbookbutton {
    margin: 72px 0px 0px 0px;
  }
  .dashboardwrapper[max-width~="1266px"] .toppinnedmetricwrappereditmode {
    min-width: 300px;
  }
  .dashboardwrapper[max-width~="1266px"] .toppinnedmetricwrapper {
    min-width: 300px;
  }
  .dashboardwrapper[max-width~="1266px"] .pinnedMetricsWrapper {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    bottom: 0px;
    width: 100%;
    margin-bottom: 0px;
    margin-right: 0px;
    padding: 0px;
    margin: 22px 0px 0px 0px;
    height: calc(76% + 6px);
    min-height: 340px;
    max-height: 380px;
    gap: 0px 12px;
  }
  .dashboardwrapper[max-width~="1266px"] .innerdashboardcolumn {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% - 52px);
    height: max-content;
    max-height: 856px;
    position: relative;
    margin: 20px 0px 0px 26px;
  }
  .dashboardwrapper[max-width~="1266px"]
    .superToppinnedMetricsWrapper::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    color: #1f2831;
    background-color: #1f2831;
  }
  .dashboardwrapper[max-width~="1266px"]
    .superToppinnedMetricsWrapper::-webkit-scrollbar-track {
    border-radius: 10px;
    border: #a4a9b1;
    color: #1f2831;
    background-color: #1f2831;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .dashboardwrapper[max-width~="1266px"]
    .superToppinnedMetricsWrapper::-webkit-scrollbar-thumb {
    border-radius: 8px;
    color: #14181e;
    background-color: #14181e;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  .dashboardwrapper[max-width~="1266px"]
    .superToppinnedMetricsWrapper::-webkit-scrollbar-corner {
    background-color: #1f2831;
    border-bottom-right-radius: 5px;
  }
  .dashboardwrapper[max-width~="1266px"] .OpenPositionDonutGraph {
    @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    border: 2px solid #14181e;
    -webkit-filter: drop-shadow(0 0 2px #171c23);
    -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 2px #171c23);
    border-radius: 10px;
    box-shadow: 0 0 4pt 2pt #171c23;
    padding: 8px 0px 0px 0px;
    margin: 0px 0px 0px 24px;
    left: 0px;
    display: flex;
    height: calc(100% - 292px);
    width: calc(30%);
    top: 4px;
    position: relative;
  }
  .dashboardwrapper[max-width~="1266px"] .OpenPositionDonutGraph {
    @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    border: 2px solid #14181e;
    -webkit-filter: drop-shadow(0 0 2px #171c23);
    -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 2px #171c23);
    border-radius: 10px;
    box-shadow: 0 0 4pt 2pt #171c23;
    padding: 8px 0px 0px 0px;
    margin: 0px 0px 0px 24px;
    left: 0px;
    display: flex;
    height: calc(100% - 292px);
    width: calc(30%);
    top: 4px;
    position: relative;
  }
  .dashboardwrapper[max-width~="1266px"] .innerdashboardcolumnequitywrapover {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    height: calc(84% + 5px);
    margin: 0px;
    min-height: 390px;
  }
  .dashboardwrapper[max-width~="1012px"] .openpositionssuperwrapper {
    width: 100%;
    height: calc(100% + 250px);
    margin: 72px 0px 50px 0px !important;
  }
  .dashboardwrapper[max-width~="1012px"] .maingraphsdashboardwrapper {
    margin: 30px 0px 0px 0px !important;
  }
  .dashboardwrapper[max-width~="932px"] .innerdashboardportfolios {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% - 24px);
    height: 100%;
    min-height: 640px;
    position: relative;
  }
  .dashboardwrapper[max-width~="529px"] .openpositionssuperwrapper {
    width: 100%;
    height: calc(100% + 400px) !important;
    margin: 18px 0px 50px 0px !important;
    display: flex !important;
    flex-flow: column !important;
    min-height: max-content;
  }
  .dashboardwrapper[max-width~="529px"] .maingraphsdashboardwrapper {
    margin: -16px 0px 0px 0px !important;
  }
  .dashboardwrapper[max-width~="529px"] .OpenPositionDonutGraph {
    font-weight: 300 !important;
    border: 2px solid #14181e !important;
    -moz-filter: drop-shadow(0 0 2px #171c23) !important;
    -ms-filter: drop-shadow(0 0 2px #171c23) !important;
    filter: drop-shadow(0 0 2px #171c23) !important;
    border-radius: 10px !important;
    box-shadow: 0 0 4pt 2pt #171c23 !important;
    padding: 8px 0px 0px 0px !important;
    margin: 14px 0px -16px 26px !important;
    left: 0px !important;
    min-width: 200px !important;
    display: flex !important;
    width: calc(100% - 24px) !important;
    top: 10px !important;
    position: relative !important;
  }
  .dashboardwrapper[max-width~="800px"] .pinnedMetricsWrapper {
    min-height: 260px;
  }
  .dashboardwrapper[max-width~="360px"] .pinnedMetricsWrapper {
    font-size: 0.75em;
    margin: -4px 0px 86px 0px !important;
    grid-gap: 0px 8px;
    gap: 0px 8px;
    width: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
  }
  .dashboardwrapper[max-width~="529px"] .pinnedMetricsWrapper {
    font-size: 0.75em;
    height: 210px;
    min-height: 100px;
    padding-left: 2px;
    margin: 24px 0px 92px 0px;
    grid-gap: 0px 12px;
    gap: 0px 12px;
    width: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
  }
  .dashboardwrapper[max-width~="529px"]
    .pinnedMetricsWrapper::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    color: #1f2831;
    background-color: #1f2831;
    border-radius: 10px;
  }
  .dashboardwrapper[max-width~="529px"]
    .pinnedMetricsWrapper::-webkit-scrollbar-track {
    border-radius: 10px;
    border: #a4a9b1;
    color: #1f2831;
    background-color: #1f2831;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .dashboardwrapper[max-width~="529px"]
    .pinnedMetricsWrapper::-webkit-scrollbar-thumb {
    border-radius: 8px;
    color: #14181e;
    background-color: #14181e;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  .dashboardwrapper[max-width~="529px"]
    .pinnedMetricsWrapper::-webkit-scrollbar-corner {
    background-color: #1f2831;
    border-bottom-right-radius: 5px;
  }
  .dashboardwrapper[max-width~="529px"] .taketologbookbutton {
    right: 52px !important;
    margin: 18px 0px 0px 0px;
  }
}

.dashboardwrapper[max-width~="529px"] .innerdashboardcolumnwrapper {
  display: flex;
  position: relative;
  flex-flow: column;
  height: 170vh;
  margin-top: 76px;
  margin-bottom: 0px;

  width: 100%;
}

.innerdashboardportfolios {
  display: flex;
  width: 0px;
  flex: 1;
  flex-flow: row;
  height: calc(100%);
  //min-height: 590px;
  position: relative;
  margin: 0px 0px 0px 0px;
}
/* .dashboardwrapper[max-width~="880px"] .innerdashboardportfolios {
  display: flex;
  flex-flow: row wrap;
  width: calc(100% - 90px);
  height: auto;
  min-height: 600px;
  position: relative;
  margin: -14px 0px -4px 0px;
} */
.dashboardwrapper[max-width~="932px"] .openpositionswrapper {
  height: calc(100% - 354px) !important;
  width: calc(100% + 40px) !important;
  margin-left: 28px !important;
  margin-right: 0px !important;
  margin-bottom: -200px !important;
}
.dashboardwrapper[max-width~="529px"] .openpositionswrapper {
  height: calc(100% - 368px) !important;
  min-height: 500px;
  width: calc(100% - 26px) !important;
  margin-bottom: 0px !important;
}
.dashboardwrapper[max-width~="360px"] .openpositionswrapper {
  height: calc(100% - 194px);
  margin: 63px 0px 0px 36px;
  width: calc(100% + 14px);
}

.dashboardwrapper[max-width~="782px"] .innerdashboardcolumn {
  display: flex;
  flex-flow: row wrap;
  width: calc(100% - 52px);
  height: 75vh;
  max-height: 736px;
  position: relative;
  margin: 20px 0px 0px 26px;
}
.dashboardwrapper[max-width~="782px"] .innerdashboardcolumnequitywrapover {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  height: calc(50% + 5px);
  margin: 0px;
}
.dashboardwrapper[max-width~="529px"] .innerdashboardcolumn {
  width: 100%;
  margin: -48px 0px -48px 32px;
  height: 70vh;
  right: 0px;
  position: relative;
  margin-left: auto;
  min-height: 560px;
}
.dashboardwrapper[max-width~="364px"] .innerdashboardcolumn {
  width: 100%;
  margin: -48px 0px 168px 32px;
  height: 30vh;
  right: 0px;
  position: relative;
  margin-left: auto;
  min-height: 460px;
}
.dashboardwrapper[max-width~="529px"] .innerdashboardportfolios {
  display: flex !important;
  flex-flow: column !important;
  width: calc(100% - 24px) !important;
  height: max-content;
  //min-height: 400px;
  position: relative !important;
  margin: -28px 0px 36px -6px;
}
.dashboardwrapper[max-width~="529px"] .innerdashboardcolumnequitywrapover {
  display: flex;
  flex-flow: column !important;
  width: 100%;
  height: max-content;
  max-height: 680px;
  margin: 2px 0px 112px 0px;
}
.dashboardwrapper[max-width~="360px"] .innerdashboardcolumnequitywrapover {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: max-content;
  max-height: 700000px;
  margin: 2px 0px 24px 0px;
}
.dashboardeditnotifwrapper[max-width~="534px"] {
  padding: 6px 0px 8px 27px;
  #doneditingbutton {
    font-size: 1em;
    font-family: "Roboto";
    font-weight: 400;
    border-radius: 8px;
    border: none;
    text-align: center;
    background-color: #fbd665;
    transition: none;
    color: #1a2028;
    padding: 0px 8px 0px 8px;
    margin-top: 8px;
    margin-left: 10px;
    margin-right: 16px;
    z-index: 1 !important;
    cursor: pointer;
    width: 74px;
    height: 34px;
    outline-style: none;
    -moz-filter: drop-shadow(0 0 10px #14181e);
    -ms-filter: drop-shadow(0 0 10px #14181e);
    filter: drop-shadow(0 0 10px #14181e);
  }
  #doneditingbutton:hover {
    outline-style: none;
    border: none;
    color: #222a35;
    background-color: #ffe593;
  }
}
/* .winrategraph[max-width~="232px"] .winrategraphdivtextwrapper {
  margin-top: 58%;
}
.winrategraph[max-width~="180px"] .winrategraphdivtextwrapper {
  margin-top: 72%;
}
.winrategraph[max-width~="134px"] .winrategraphdivtextwrapper {
  margin-top: 82%;
}
.winrategraph[max-width~="83px"] .winrategraphdivtextwrapper {
  margin-top: 100%;
}
.winrategraph[max-width~="56px"] .winrategraphdivtextwrapper {
  margin-top: calc(100% + 26px);
} */
@for $i from 232 through 56 {
  $width: $i;
  .winrategraph[max-width~="#{$width}"] .winrategraphdivtextwrapper {
    $margin-top: (-0.3182 * $width + 131.82) * 0.55%;
    margin-top: calc(#{$margin-top} + 25%);
  }
}
.dashboardwrapper[max-width~="794px"] .toppinnedmetricwrappereditmode {
  min-width: 260px;
}
.dashboardwrapper[max-width~="794px"] .toppinnedmetricwrapper {
  min-width: 260px;
}
.dashboardwrapper[max-width~="529px"] .superToppinnedMetricsWrapper {
  color: #fbd665;
  font-weight: 300;
  white-space: nowrap;
  margin: 16px 0px -2px 18px;
  padding: 5px 0px 6px 5px;
  height: 13vh;
  min-height: 126px;
  width: calc(100% - 42px);
  overflow-x: scroll;
  overflow-y: hidden;
  //font-size: 0.85em;
}
.dashboardwrapper[max-width~="529px"] .toppinnedmetricwrappereditmode {
  margin: 0px 18px 0px 0px;
}
.dashboardwrapper[max-width~="529px"] .toppinnedmetricwrapper {
  margin: 0px 18px 0px 0px;
}
.dashboardwrapper[max-width~="529px"] .perfmeterwrappermain {
  width: 100% !important;
  margin: 0px 0px 6px 32px !important;
  height: calc(100%) !important;
  right: 0px !important;
  position: relative !important;
  margin-left: auto !important;
  min-height: 410px !important;
}
/* @media screen and (min-width: 2561px) {
  .dashboardwrapper {
    padding: 0px 0px 0px 0px;
    font-size: 0.8em;
    margin: 0;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: calc(100%);
  }
  .superToppinnedMetricsWrapper {
    color: #55f8a6;
    font-weight: 300;
    overflow: hidden;
    white-space: nowrap;
    margin: 10px 0px 0 4px;
    height: 12vh;
    min-height: 104px;
    display: none;
  }
} */

// For screen sizes smaller than 1680w or 900h
@media screen and (max-width: 1680px), (max-height: 900px) {
  .innerdashboardcolumnwrapper {
    display: flex !important;
    flex-flow: row-reverse !important;
    width: calc(100% - 26px);
    margin-top: 24px;
    margin-bottom: 26px;
    min-height: 570px !important;
    //height: max-content !important;
  }
  .dashboardwrapper[max-width~="1013px"] .innerdashboardcolumnwrapper {
    display: flex !important;
    position: relative !important;
    flex-flow: column !important;
    margin-top: 6px !important;
    width: 100% !important;
    height: max-content !important;
    min-height: max-content !important;
  }
  .dashboardwrapper[max-width~="1013px"] .openpositionssuperwrapper {
    width: 100% !important;
    height: calc(100% + 150px) !important;
    margin-top: 4px !important;
    margin-bottom: -4px !important;
    min-height: 690px;
    max-height: 640px;
  }
  .dashboardwrapper[max-width~="1013px"] .innerdashboardcolumnwrapper1 {
    display: flex !important;
    position: relative !important;
    flex-flow: column !important;
    height: max-content !important;
    width: 100% !important;
    padding: 22px 26px 0px 26px !important;
    top: 6px !important;
    margin: 0px 0px 0px 0px !important;
  }
  .dashboardwrapper[max-width~="1013px"] .innerdashboardportfolios {
    display: flex !important;
    flex-flow: row wrap !important;
    width: calc(100% - 28px) !important;
    height: calc(100% - 32px) !important;
    //min-height: 640px !important;
    position: relative !important;
    min-height: 590px;
    max-height: 590px;
    margin: 56px 0px -74px 0px;
  }
  .dashboardwrapper[max-width~="1013px"] .maingraphsdashboardinner {
    display: flex !important;
    width: calc(50% - 13px) !important;
    height: 550px !important;
    margin: 0px 0px 0px 0px !important;
  }
  .maingraphsdashboardinner {
    display: flex;
    width: calc(50% - 13px);
    height: 50%;
    max-height: 560px !important;
    margin: 0px 0px 0px 0px;
  }
  .dashboardwrapper[max-width~="942px"] .innerdashboardportfolios {
    display: flex !important;
    flex-flow: row wrap !important;
    width: calc(100% - 28px) !important;
    height: calc(100% - 32px) !important;
    //min-height: 640px !important;
    position: relative !important;
    min-height: 590px;
    max-height: 590px;
    margin: 56px 0px -74px 0px;
  }
  .dashboardwrapper[max-width~="810px"] .innerdashboardportfolios {
    display: flex !important;
    flex-flow: row wrap !important;
    width: calc(100% - 28px) !important;
    height: calc(100% - 32px) !important;
    //min-height: 640px !important;
    position: relative !important;
    min-height: 540px;
    max-height: 540px;
    margin: -14px 0px 0px 0px;
  }
  .dashboardwrapper[max-width~="942px"] .maingraphsdashboardwrapper {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: 60vh;
    position: relative;
    margin-left: 0px;
    margin: 0px 0px -24px 0px;
  }
  .dashboardwrapper[max-width~="942px"] .maingraphsdashboardinner {
    display: flex;
    width: calc(100%);
    height: 450px;
    min-height: 450px;
    margin: 0px 0px 0px 0px;
    /*   padding: 6px;
    border-style: dashed;
    border: 1px dashed #808080;
    border-radius: 8px; */
  }
  .dashboardwrapper[max-width~="1013px"] .openpositionswrapper {
    height: calc(100% - 216px) !important;
    width: calc(100% + 38px) !important;
  }
  .dashboardwrapper[max-width~="1013px"] .superToppinnedMetricsWrapper {
    color: #fbd665 !important;
    font-weight: 300 !important;
    white-space: nowrap !important;
    margin: 18px 0px -6px 21px !important;
    padding: 5px 0px 6px 5px !important;
    height: 17vh !important;
    min-height: 154px !important;
    width: calc(100% - 52px) !important;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
  }
  .dashboardwrapper[max-width~="1013px"] .taketologbookbutton {
    margin: 0px 0px 0px 0px !important;
  }
  .dashboardwrapper[max-width~="1013px"] .toppinnedmetricwrappereditmode {
    min-width: 300px !important;
  }
  .dashboardwrapper[max-width~="1013px"] .toppinnedmetricwrapper {
    min-width: 300px !important;
  }
  .dashboardwrapper[max-width~="1013px"] .pinnedMetricsWrapper {
    display: flex !important;
    flex-flow: row wrap !important;
    position: relative !important;
    bottom: 0px !important;
    width: 100% !important;
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    padding: 0px !important;
    margin: 22px 0px 0px 0px !important;
    height: calc(76% + 6px) !important;
    min-height: 340px !important;
    max-height: 380px !important;
    gap: 0px 12px !important;
  }
  .dashboardwrapper[max-width~="1013px"] .innerdashboardcolumn {
    display: flex !important;
    flex-flow: row wrap !important;
    width: calc(100% - 52px) !important;
    height: max-content !important;
    max-height: 856px !important;
    position: relative !important;
    margin: 20px 0px 0px 26px !important;
  }
  .dashboardwrapper[max-width~="1013px"]
    .superToppinnedMetricsWrapper::-webkit-scrollbar {
    height: 10px !important;
    width: 10px !important;
    color: #1f2831 !important;
    background-color: #1f2831 !important;
  }
  .dashboardwrapper[max-width~="1013px"]
    .superToppinnedMetricsWrapper::-webkit-scrollbar-track {
    border-radius: 10px !important;
    border: #a4a9b1 !important;
    color: #1f2831 !important;
    background-color: #1f2831 !important;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  }
  .dashboardwrapper[max-width~="1013px"]
    .superToppinnedMetricsWrapper::-webkit-scrollbar-thumb {
    border-radius: 8px !important;
    color: #14181e !important;
    background-color: #14181e !important;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;
  }
  .dashboardwrapper[max-width~="1013px"]
    .superToppinnedMetricsWrapper::-webkit-scrollbar-corner {
    background-color: #1f2831 !important;
    border-bottom-right-radius: 5px !important;
  }
  .dashboardwrapper[max-width~="1013px"] .OpenPositionDonutGraph {
    font-weight: 300 !important;
    border: 2px solid #14181e !important;
    -webkit-filter: drop-shadow(0 0 2px #171c23) !important;
    -moz-filter: drop-shadow(
      0 0 2px #171c23
    ) !important; /* Old versions of Firefox */
    -ms-filter: drop-shadow(
      0 0 2px #171c23
    ) !important; /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 2px #171c23) !important;
    border-radius: 10px !important;
    box-shadow: 0 0 4pt 2pt #171c23 !important;
    padding: 8px 0px 0px 0px !important;
    margin: 0px 0px 0px 26px !important;
    left: 0px !important;
    min-width: 200px !important;
    display: flex !important;
    height: calc(100% - 162px) !important;
    width: calc(30%) !important;
    top: 10px !important;
    position: relative !important;
  }
  .dashboardwrapper[max-width~="1013px"] .innerdashboardcolumnequitywrapover {
    display: flex !important;
    flex-flow: column wrap !important;
    width: 100% !important;
    height: calc(84% + 5px) !important;
    margin: 0px !important;
    min-height: 390px !important;
  }
  .dashboardwrapper[max-width~="529px"] .innerdashboardcolumnequitywrapover {
    font-size: 1em !important;
  }
  .dashboardwrapper[max-width~="529px"] .innerdashboardcolumnwrapper1 {
    display: flex !important;
    position: relative !important;
    flex-flow: column !important;
    height: max-content !important;
    width: 100% !important;
    padding: 24px 24px 0px 24px !important;
    margin: 0px 0px 0px 0px !important;
  }
  .dashboardwrapper[max-width~="529px"] .innerdashboardportfolios {
    display: flex;
    flex-flow: column !important;
    width: calc(100% - 24px) !important;
    height: max-content !important;
    //min-height: 1000px !important;
    position: relative;
    //margin: -20px 0px 2px -2px !important;
  }

  .dashboardwrapper[max-width~="529px"] .openpositionssuperwrapper {
    display: flex !important;
    flex-flow: column !important;
    min-height: max-content;
  }
  .dashboardwrapper[max-width~="360px"] .innerdashboardportfolios {
    display: flex;
    flex-flow: column !important;
    width: calc(100% - 24px) !important;
    height: 64vh !important;
    //min-height: 1000px !important;
    position: relative;
    margin: -20px 0px 48px -2px !important;
  }
  .dashboardwrapper[max-width~="360px"] .taketologbookbutton {
    top: 28px !important;
  }

  .innerdashboardcolumn {
    display: flex;
    flex-flow: row wrap;
    min-width: 590px;
    width: 42%;
    height: calc(100% + 26px);
    position: relative;
    margin: 0px 0px 0px 24px;
  }
  #perfmeterwrappertooltip {
    display: flex;
    flex-flow: row;
    margin-left: calc(50% - 78px);
  }
  .openpositionsheader {
    margin: -2px 0px 0px 26px !important;
  }

  .openpositionssubheader {
    position: relative;
    display: block;
    white-space: nowrap;
    padding: 0;
    color: #98b5eb;
    font-size: 0.5em !important;
    font-weight: 300;
    z-index: 10000;
    height: 40px;
    width: 100%;
  }
  .openpositionssuperwrapper {
    width: 100%;
    height: calc(100% - 64px);
  }
  .dashboardwrapper {
    padding: 0px 0px 0px 0px;
    //font-size: 0.85em;
    margin: 0;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: calc(100%);
  }
  .superToppinnedMetricsWrapper {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    bottom: 0px;
    width: calc(100% - 28px);
    padding: 0px;
    height: 15vh;
    min-height: 125px;
    margin: 24px 16px 2px 26px;
    display: flex !important;
  }
  .togglerecenttradesbutton {
    text-align: center;
    position: absolute;
    transition: all 0.15s;
    z-index: 100000000;
    left: 0px;
    padding: 4px 8px 0px 8px !important;
    display: inline-block !important;
    margin: 17px 16px 0px 42px;
    line-height: 18px;
    border: 1px solid #fbd665;
    max-width: 240px;
    border-radius: 8px;
    outline: none;
    height: 28px;
    color: #fff7cb;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 400;
    text-overflow: ellipsis;
    font-size: 0.9em;
    background-color: transparent;
    background-color: #1a2028;
    -moz-box-shadow: inset 0 0 4px #ffe593;
    -webkit-box-shadow: inset 0 0 4px #ffe593;
    box-shadow: inset 0 0 4px #ffe593;
    &:hover {
      cursor: pointer;
      outline-style: none;
      background-color: #ffe593;
      border: 1px solid #ffe593;
      color: #14181e;
    }
  }
  .dashboardwrapper[max-width~="743px"] .maingraphsdashboardwrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 60vh;
    position: relative;
    margin-left: 0px;
    margin: 0px 0px -24px 0px;
  }
  .dashboardwrapper[max-width~="743px"] .maingraphsdashboardinner {
    display: flex;
    width: calc(100%) !important;
    height: 500px;
    min-height: 500px;
    margin: 0px 0px 0px 0px;
    /*   padding: 6px;
    border-style: dashed;
    border: 1px dashed #808080;
    border-radius: 8px; */
  }
  .dashboardwrapper[max-width~="529px"] .pinnedMetricsWrapper {
    font-size: 0.75em !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    height: 240px !important;
    min-height: 100px !important;
    margin: 80px 0px 34px 0px !important;
    grid-gap: 0px 12px !important;
    gap: 0px 12px !important;
  }
  .dashboardwrapper[max-width~="529px"]
    .pinnedMetricsWrapper::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    color: #1f2831;
    background-color: #1f2831;
    border-radius: 10px;
  }
  .dashboardwrapper[max-width~="529px"] .openpositionswrapper {
    height: calc(100% - 230px) !important;
    max-height: 400px;
    margin: 63px 0px -2px 26px !important;
    width: calc(100% - 24px) !important;
  }
  .dashboardwrapper[max-width~="529px"] .OpenPositionDonutGraph {
    font-weight: 300 !important;
    border: 2px solid #14181e !important;
    -moz-filter: drop-shadow(0 0 2px #171c23) !important;
    -ms-filter: drop-shadow(0 0 2px #171c23) !important;
    filter: drop-shadow(0 0 2px #171c23) !important;
    border-radius: 10px !important;
    box-shadow: 0 0 4pt 2pt #171c23 !important;
    padding: 8px 0px 0px 0px !important;
    margin: 16px 0px 26px 26px !important;
    left: 0px !important;
    min-width: 200px !important;
    display: flex !important;
    height: calc(100% - 160px) !important;
    min-height: 400px !important;
    max-height: 500px !important;
    width: calc(100% - 24px) !important;
    top: 10px !important;
    position: relative !important;
  }
  .dashboardwrapper[max-width~="360px"] .OpenPositionDonutGraph {
    height: calc(400px) !important;
    min-height: 420px !important;
    margin-bottom: 1260px !important;
  }
  .dashboardwrapper[max-width~="529px"] .innerdashboardcolumnequitywrapover {
    margin: 2px 0px 54px 0px !important;
    display: flex !important;
    flex-flow: column !important;
    //font-size: 0.85em;
  }
  .dashboardwrapper[max-width~="529px"] .openpositionsheader {
    margin: -2px 0px 0px 12.5% !important;
    width: calc(100% - 62px) !important;
  }
  .dashboardwrapper[max-width~="529px"] .perfmeterwrappermain {
    width: 100% !important;
    margin: 0px 0px 6px 32px !important;
    height: calc(100%) !important;
    right: 0px !important;
    position: relative !important;
    margin-left: auto !important;
    min-height: 410px !important;
  }
  .dashboardwrapper[max-width~="450px"] .perfmeterwrappermain {
    width: 100% !important;
    margin: 0px 0px 6px 32px !important;
    height: 100% !important;
    right: 0px !important;
    position: relative !important;
    margin-left: auto !important;
    min-height: 390px !important;
  }
  .dashboardwrapper[max-width~="450px"] .pinnedMetricsWrapper {
    font-size: 0.75em !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    height: 240px !important;
    min-height: 100px !important;
    margin: 62px 0px 34px 0px !important;
    grid-gap: 0px 12px !important;
    grid-gap: 0px 12px !important;
    gap: 0px 12px !important;
  }
  .dashboardwrapper[max-width~="360px"] .openpositionssuperwrapper {
    width: 100% !important;
    height: calc(100% + 80px) !important;
    margin-top: 30px !important;
  }
  .dashboardwrapper[max-width~="360px"] .maingraphsdashboardwrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 60vh;
    position: relative;
    margin-left: 0px;
    margin: -42px 0px 0px 0px;
  }
  .innerdashboardportfolios {
    display: flex;
    flex: 1;
    flex-flow: row;
    height: calc(100% + 26px);
    width: 0%;
    //min-height: 590px;
    position: relative;
    margin: 0px 0px 0px 0px;
  }
  .OpenPositionDonutGraphwrapper {
    padding: 0px;
    width: calc(100% - 36px);
    min-width: calc(100% - 8px);
    height: 50%;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 20%;
    margin-top: 36px;
  }
  .dashboardwrapper[max-width~="529px"] .taketologbookbutton {
    right: 52px !important;
  }
  .dashboardwrapper[max-width~="800px"] .pinnedMetricsWrapper {
    min-height: 260px;
  }
  .dashboardwrapper[max-width~="360px"] .pinnedMetricsWrapper {
    font-size: 0.75em;
    margin: -34px 0px 16px 0px !important;
    grid-gap: 0px 8px;
    gap: 0px 8px;
    width: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
  }
  .dashboardwrapper[max-width~="529px"] .pinnedMetricsWrapper {
    font-size: 0.75em;
    height: 210px;
    min-height: 100px;
    padding-left: 2px;
    margin: 24px 0px 92px 0px;
    grid-gap: 0px 12px;
    gap: 0px 12px;
    width: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
  }
  .dashboardwrapper[max-width~="529px"]
    .pinnedMetricsWrapper::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    color: #1f2831;
    background-color: #1f2831;
    border-radius: 10px;
  }
  .dashboardwrapper[max-width~="529px"]
    .pinnedMetricsWrapper::-webkit-scrollbar-track {
    border-radius: 10px;
    border: #a4a9b1;
    color: #1f2831;
    background-color: #1f2831;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .dashboardwrapper[max-width~="529px"]
    .pinnedMetricsWrapper::-webkit-scrollbar-thumb {
    border-radius: 8px;
    color: #14181e;
    background-color: #14181e;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  .dashboardwrapper[max-width~="529px"]
    .pinnedMetricsWrapper::-webkit-scrollbar-corner {
    background-color: #1f2831;
    border-bottom-right-radius: 5px;
  }
}
/* @media screen and (max-width: 1130px) and (max-height: 800px) {
  .innerdashboardcolumnequitywrapover {
    min-height: 345px !important;
  }
  .dashboardwrapper[max-width~="529px"] .innerdashboardportfolios {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% - 78px);
    height: 44vh;
    min-height: 400px;
    position: relative;
    margin: -20px 0px 40px -8px !important;
  }
  .dashboardwrapper[max-width~="529px"] .maingraphsdashboardwrapper {
    display: flex;
    flex-flow: row wrap;
    height: 134%;
    gap: 18px;
    margin: 0px 0px -24px 0px;
    padding: 52px 18px 5382px 18px !important;
  }
}

@media screen and (max-width: 950px) and (max-height: 800px) {
  .innerdashboardcolumnequitywrapover {
    min-height: 300px !important;
  }
  .dashboardwrapper[max-width~="529px"] .gaugechartwrapper {
    height: 80%;
  }
  .dashboardwrapper[max-width~="360px"] .innerdashboardcolumnequitywrapover {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: max-content;
    max-height: 680px;
    margin: 2px 0px 24px 0px !important;
  }
  .dashboardwrapper[max-width~="360px"] .openpositionsheader {
    margin: 12px 0px 0px 0px !important;
    font-size: 1.3em !important;
  }
  .dashboardwrapper[max-width~="360px"] .openpositionssubheader {
    font-size: 0.7em !important;
    margin: 2px 0px 0px 16px !important;
  }
  .dashboardwrapper[max-width~="360px"] .openpositionswrapper {
    height: calc(100% - 194px);
    margin: 63px 0px 0px 32px !important;
    width: calc(100% + 14px);
  }
  .dashboardwrapper[max-width~="529px"] .innerdashboardportfolios {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% - 62px);
    height: 44vh;
    min-height: 400px;
    position: relative;
    top: -8px;
    margin: -16px 0px -6px -8px !important;
  }
}
@media screen and (max-width: 376px) and (max-height: 800px) {
  .dashboardwrapper[max-width~="529px"] .innerdashboardportfolios {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% - 62px);
    height: 44vh;
    min-height: 400px;
    position: relative;
    top: -8px;
    margin: -22px 0px -20px -8px !important;
  }
}
@media screen and (max-width: 950px) and (max-height: 900px) {
  .dashboardwrapper[max-width~="529px"] .innerdashboardcolumnequitywrapover {
    margin: 2px 0px 112px 0px !important;
    display: flex !important;
    flex-flow: column !important;
    //font-size: 0.85em;
  }
  .dashboardwrapper[max-width~="418px"] .innerdashboardcolumnequitywrapover {
    margin: 2px 0px 78px 0px !important;
    //font-size: 0.85em;
  }
  .dashboardwrapper[max-width~="529px"] .openpositionsheader {
    margin-left: 34px !important;
  }
  .dashboardwrapper[max-width~="529px"] .openpositionswrapper {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 390px) and (max-height: 900px) {
  .dashboardwrapper[max-width~="529px"] .innerdashboardcolumnequitywrapover {
    margin: 2px 0px 52px 0px !important;
  }
} */

.dashboardwrapper[max-width~="529px"] .plbytimegraph {
  margin: 0px 0px 0px 0px !important;
  padding: 14px 26px 0px 20px !important;
}
.dashboardwrapper[max-width~="529px"] .dashboard-graphs-label {
  position: absolute;
  display: block;
  white-space: nowrap;
  padding: 0;
  color: #fbd665;
  font-size: 1.1em;
  font-weight: 300;
  z-index: 10000;
  height: 30px;
  margin-top: -10px;
  width: auto;
  margin-left: 6px;
}
.dashboardwrapper[max-width~="529px"] .cumulativereturngraphwrapper {
  display: flex;
  transition: none;
  width: 100%;
  height: calc(100% - 20px);
  margin-top: 10px;
  margin-left: -6px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.perfmeterwrappermain[max-width~="223px"] #perfmeterwrappertooltip {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.perfmeterwrappermain[max-width~="223px"] #perfmetertext {
  color: #fbd665;
  font-size: 1em;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  margin: 0px 0px 14px 4px;
}
.perfmeterwrappermain[max-width~="223px"] .gaugechartwrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 70%;
  margin: 24px 0px 0px 0px;
}
.perfmeterwrappermain[max-width~="223px"] #perfmeterwrappertooltipquestionmark {
  margin: 2px 0px -2px 6px;
}
.dashboardwrapper[max-width~="529px"] .cummulativereturn-label3 {
  position: absolute;
  display: block;
  padding: 0;
  color: #98b5eb;
  font-size: 0.9em;
  left: 8px;
  font-weight: 300;
  margin-top: 0px;
  z-index: 10000;
  height: 40px;
  width: 150px;
}

.dashboardwrapper[max-width~="529px"] .currentEquity {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin: -4px 0px 24px 0px;
  background-color: #1f2831;
  -webkit-filter: drop-shadow(0 0 2px #171c23);
  -moz-filter: drop-shadow(0 0 2px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #171c23);
  box-shadow: 0 0 4pt 2pt #171c23;
  border-radius: 10px;
  width: 100%;
  height: calc(100% - 4px);
  z-index: 100;
  min-width: 180px;
  min-height: 360px;
  background-image: linear-gradient(to bottom right, #1f2831, #1f2831);
}
.dashboardwrapper[max-width~="529px"] .currentEquitybottomwrapper {
  height: 57%;
}
.dashboardwrapper[max-width~="529px"] .currentEquityinnerWrap {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  height: 24%;
  border-radius: 10px;
  margin: 28px 34px 4vh 34px;
  background-image: linear-gradient(to bottom right, #28303c, #1f2831);
  -webkit-filter: drop-shadow(0 0 4px #171c23);
  -moz-filter: drop-shadow(0 0 4px #171c23); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 4px #171c23); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 4px #171c23);
}

@media screen and (max-width: 430px) {
  .perfmeterwrappermain {
    width: 100%;
    margin: 0px 0px -12px 0px !important;
    height: calc(100%);
    right: 0px;
    position: relative;
    margin-left: auto;
    min-height: 346px !important;
  }
  @media screen and (max-width: 392px) {
    .perfmeterwrappermain {
      width: 100%;
      margin: 0px 0px 8px 0px !important;
      height: calc(100%);
      right: 0px;
      position: relative;
      margin-left: auto;
      min-height: 320px !important;
    }
    @media screen and (max-width: 276px) {
      .perfmeterwrappermain {
        width: 100%;
        margin: 0px 0px 0px 32px;
        height: calc(100%);
        right: 0px;
        position: relative;
        margin-left: auto;
        min-height: 290px !important;
      }
    }
  }
}
.dashboardwrapper[max-width~="529px"] .winrategraph {
  height: calc(100%);
  border-radius: 8px;
  min-width: 110px;
  width: calc(25% - 7px);
}
.dashboardwrapper[max-width~="360px"] .winrategraph {
  height: calc(100%);
  border-radius: 8px;
  min-width: 90px;
  width: calc(25% - 7px);
}
.dashboardwrapper[max-width~="529px"] .winrategraphdivtextwrapper {
  margin-top: 80%;
}
.containrecenttrades[max-width~="1130px"] .recent-trades-table {
  top: -14px;
  overflow: hidden;
}
.currentEquity[max-width~="330px"] .currentEquityinnerWrap {
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
.currentEquity[max-width~="330px"] .currentEquityheader {
  margin: 1px 0px 8px 0px;
  padding: 0px 0px 0px 0px;
}
.currentEquity[max-width~="330px"] .currentEquitycoltext {
  flex-flow: column;
  align-items: right;
  justify-content: right;
  width: 50px;
  margin: -14px 0px 0px 24px;
  display: flex;
}
.OpenPositionDonutGraph[max-width~="161px"] .OpenPositionDonutGraph-label {
  width: 100%; /* Adjust this value based on your desired width */
  line-height: 1.5; /* Adjust this value based on your desired line height */
  white-space: pre-wrap; /* Makes sure the text wraps to the next line */
  word-wrap: break-word; /* Breaks the word if it's too long for the given width */
  font-size: 1.1em;
  left: 0% !important;
  top: 20px;
}
