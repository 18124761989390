.rightpanelclosebutton {
  position: absolute;
  background-color: rgb(238, 73, 73);
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 8px;
  color: #14181e;
  padding: 3px 9px 2px 10px;
  top: 72px;
  right: 11px;
  transition: all 0.25s;
}
.right-panel-scroll::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.right-panel-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.right-panel-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #14181e;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.right-panel-scroll::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.rightpanelclosebutton:hover {
  outline-style: none;
  border: none;
  -webkit-filter: drop-shadow(0 0 4px #fcc6c5);
  -moz-filter: drop-shadow(0 0 4px #fcc6c5); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 4px #fcc6c5);
  filter: drop-shadow(0 0 4px #fcc6c5);
}
.rightpanelclosebutton:focus {
  outline: none;
}
.rightpanelheader, .rightpanelheader2 {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
       user-select: none; 
  position: absolute;
  font-size: 1.4em;
  text-align: center;
  justify-content: center;
  z-index: 20000;
  font-weight: 400;
  top: 71px;
  margin-right: -10px;
  @import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */
}
.rightpanelheader2{
  display: none;
}
.rightpanelclickable {
  overflow: hidden;
  height: calc(100% - 121px);
  top: 112px;
  position: absolute;
  padding: 0;
  background-color: #1a2028;
  border-right: 1px solid #1a2028;
  margin: 0;
  z-index: 10000;
  justify-content: center;
}
.vellisepaneltoggler{
  color:#556171;
  position: absolute;
  top: 50%;
  height: 14px;
  margin-top: -12px;
}
.mandatory3 {
  color: #fa798b;
  font-weight: bold;
  font-size: 15px;
  position: absolute;
  right: 40px;
  margin-top: 4px;
}
.mandatory4 {
  color: #fa798b;
  font-weight: 400;
  font-size: 15px;
  position: absolute;
  right: 40px;
  margin-top: 2px;
}

// MFA CODE SUBMIT
/* Add your custom CSS here */
:root {
  --check-size: 200px;
  --check-border-width: 5px;
  --checkmark-width: calc(var(--check-size) / 2);
  --checkmark-height: calc(var(--checkmark-width) / 2);
  --checkmark-left: calc(var(--checkmark-width) / 2);
  --checkmark-top: 50%;
  --checkmark-color: #19b8a2;
}

.checkmark-wrapper {
  width: var(--check-size);
  height: var(--check-size);
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.checkmark-wrapper::after {
  content: "";
  position: absolute;
  inset: 0;
  border: var(--check-border-width) solid #f8f8f8;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: block;
  z-index: 0;
}

.checkmark-wrapper::before {
  content: "";
  position: absolute;
  inset: 0;
  border: var(--check-border-width) solid transparent;
  border-left-color: var(--checkmark-color);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: block;
  z-index: 1;
  animation: circle linear forwards .75s;
}

.checkmarkz {
height: var(--checkmark-height);
width: var(--checkmark-width);
position: absolute;
opacity: 0;
left: var(--checkmark-left);
top: var(--checkmark-top);
display: block;
border-left: var(--check-border-width) solid var(--checkmark-color);
border-bottom: var(--check-border-width) solid var(--checkmark-color);
transform-origin: left top;
transform: rotate(-45deg);
animation: checkmark linear both 1s;
}

@keyframes checkmark {
  0% {
      height: 0;
      width: 0;
      opacity: 0;
    }
    80% {
      height: 0;
      width: 0;
      opacity: 0;  
    }
    90% {
      height: var(--checkmark-height);
      width: 0;
      opacity: 1;
    }
    100% {
      height: var(--checkmark-height);
      width: var(--checkmark-width);
      opacity: 1;
    }
}

@keyframes circle {
  0% {
      border-color: transparent;
      border-left-color: var(--checkmark-color);
  }
  90% {
      transform: rotate(-360deg);
      border-color: transparent;
      border-left-color: var(--checkmark-color);
  }
  100% {
      transform: rotate(-360deg);
      border-color: var(--checkmark-color);
  }
}