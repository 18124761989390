.instructstepwrapper {
  width: 100%;
  position: relative;
  display: block;
  margin: 0px 0px 40px 0px;
  padding: 0px 32px 0px 32px;
}
.showinstructionsprompt {
  font-size: 1.3em;
  padding: 0px 32px 0px 32px;
  color: #808080;
}
.showInstructionElem {
  right: 0px;
  border-left: 1px solid #303a49;
  height: calc(100% - 60px);
  position: absolute;
  display: flex;
  top: 32px;
  width: calc(100% - 500px);
  animation: delaycss 0.5s;
  -webkit-animation: delaycss 0.5s;
  -moz-animation: delaycss 0.5s;
  -o-animation: delaycss 0.5s;
  -ms-animation: delaycss 0.5s;
}
.portaddinputs-accountexistserror {
  color: #fa798b;
  margin-left: 6px;
  margin-top: -2px;
  margin-bottom: 2px;
  font-size: 0.85em;
}
.showinstructionswrapper {
  display: flex;
  flex-flow: column;
  position: absolute;
  padding: 0px 0px 0px 0px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}
.portaddinputs-linkbroker332 > input::placeholder {
  color: #fa798b !important; /* Replace #yourColorHere with your desired color */
  opacity: 1 !important; /* CSS standard requires setting the opacity for the color to take full effect */
}
.portaddinputs-linkbroker2 > input::placeholder {
  color: #fa798b !important; /* Replace #yourColorHere with your desired color */
  opacity: 1 !important; /* CSS standard requires setting the opacity for the color to take full effect */
}
.portaddinputs-linkbroker > input::placeholder {
  color: #fa798b !important; /* Replace #yourColorHere with your desired color */
  opacity: 1 !important; /* CSS standard requires setting the opacity for the color to take full effect */
}
.portaddinputs-linkbroker3 > input::placeholder {
  color: #fa798b !important; /* Replace #yourColorHere with your desired color */
  opacity: 1 !important; /* CSS standard requires setting the opacity for the color to take full effect */
}
.showinstructionswrapper::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.showinstructionswrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.showinstructionswrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #1a2028;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.showinstructionswrapper::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.linklogowrapperautoimportonfreewrapper {
  margin-top: 0px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  align-items: center;
}
.linklogowrapperautoimportonnotfreewrapper {
  position: relative;
  margin-top: 0px;
  font-size: 0.95em;
  display: block;
}
#promocodeelement6 {
  position: relative;
  display: flex;
  left: calc(50% - 88px);
  flex-direction: column;
  width: 166px;
  height: 32px;
  font-size: 16px;
  font-weight: 500;
  top: 12px;
  cursor: pointer;
  color: #14181e;
  text-align: center;
  vertical-align: middle;
  padding: 0px 2px 0px 4px;
  border-radius: 10px;
  line-height: 10px;
  transition: 0.25s all ease-in-out !important;
  overflow: hidden; /* hide overflow */
  background: linear-gradient(to bottom right, #ffe694, #6a5c34);
  &:hover {
    margin-top: -3px;
  }
}
/* Apply the animation */
#promocodeelement6::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 222px;
  height: 18px;
  background-color: #ffefbc;
  transform: skew(-20deg) rotate(-16.26deg) translateY(-120%); /* adjust the transform property */
  opacity: 0.5;
  filter: blur(12px);
  animation: somestripe 6s linear infinite; /* adjust the animation duration */
}
@keyframes somestripe {
  0% {
    transform: skew(-20deg) rotate(-16.26deg) translateY(-62px)
      translateX(-100%); /* adjust the transform property */
  }
  52% {
    transform: skew(-20deg) rotate(-16.26deg) translateY(-62px)
      translateX(-100%); /* adjust the transform property */
  }
  76% {
    transform: skew(-20deg) rotate(-16.26deg) translateY(110px) translateX(100%);
  }
  100% {
    transform: skew(-20deg) rotate(-16.26deg) translateY(110px) translateX(100%);
  }
}
@keyframes delaycss {
  0% {
    -webkit-filter: none;
    opacity: 0;
  }
  50% {
    -webkit-filter: none;
    opacity: 0;
  }
  100% {
    -webkit-filter: none;
    opacity: 1;
  }
}
.showintructionbuttonwrapper {
  color: #deebf7;
  width: 100%;
  text-align: right;
  height: 16px;
}
.showintructionbutton {
  outline: none;
  background-color: transparent;
  border: none;
  color: #98b5eb;
  margin-top: 8px;
  position: relative;
  height: 16px;
  transition: all 0.25s ease-in-out;
  &:hover {
    text-decoration: underline;
  }
}
.instructstepstep {
  color: #fbd665;
  font-size: 1.4em;
  margin-bottom: 12px;
}
.importCardLeft {
  flex-flow: column;
  display: flex;
  width: 100%;
}
.IMPORTselectionfields {
  width: calc(100% - 0px);
  padding-right: 22px;
  left: 0px;
  position: relative;
  margin: -40px 0px 0px 16px;
  z-index: 3;
  display: inline-block;
}
.IMPORTselectionfields > div > input::placeholder {
  color: #fbd665;
  opacity: 0.7;
}
.IMPORTselectionfields > .required::placeholder {
  color: #fa798b !important; /* Replace #yourColorHere with your desired color */
  opacity: 1 !important; /* CSS standard requires setting the opacity for the color to take full effect */
}
.moveright {
  left: 28px !important;
}
.instructstepimage {
  width: calc(100% - 64px);
  max-width: 700px;
  border-radius: 10px;
  margin: 24px 0px 0px 32px;
  border: 3px solid #44546a;
}
#importwrongpreview {
  position: absolute;
  outline: none;
  color: #fa798b;
  border: none;
  right: 12px;
  top: 110px;
  font-style: italic;
  background-color: transparent;
  font-weight: 300;
}
#importwrongpreview3 {
  position: absolute;
  outline: none;
  color: #fa798b;
  border: none;
  right: 12px;
  top: 12px;
  font-style: italic;
  background-color: transparent;
  font-weight: 300;
}
#importpreview {
  position: relative;
  outline: none;
  color: #98b5eb;
  border: none;
  left: -32px;
  text-align: right;
  justify-content: right;
  align-items: end;
  height: 0px;
  font-style: italic;
  background-color: transparent;
  font-weight: 300;
  z-index: 2;
}

.importtypewrapper {
  position: relative;
  display: inline-block;
  margin: 0px 0px 30px 0px;
}
#importfromcsvchoose {
  position: relative;
  background-color: #bbbbbb;
  outline: none;
  border: none;
  font-weight: bold;
  z-index: 2;
  display: inline-block;
  border-radius: 10px;
  font-size: 1em;
  color: #14181e;
  padding: 8px 0px 8px 0px;
  width: calc(100% - 22px);
  margin: 38px 0px 0px 16px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
#importfromcsvchoose:hover {
  outline-style: none;
  border: none;
  background-color: #d6d6d6;
}
#importfromcsvchoose:focus {
  outline: none;
}
#importfromcsvchoose:disabled {
  background-color: #686c75;
  color: #394453;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  &:hover {
    background-color: #686c75;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
.defaultButtonText {
  opacity: 0.7;
}
#importfromcsvchoose2 {
  position: relative;
  background-color: #bbbbbb;
  outline: none;
  border: none;
  font-weight: bold;
  z-index: 2;
  display: inline-block;
  border-radius: 10px;
  font-size: 1em;
  color: #14181e;
  padding: 8px 0px 8px 0px;
  width: calc(100% - 22px);
  margin: 38px 0px 0px 16px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
#importfromcsvchoose2:hover {
  outline-style: none;
  border: none;
  background-color: #d6d6d6;
}
#importfromcsvchoose2:focus {
  outline: none;
}
.deleteFileButton {
  position: absolute;
  color: rgb(238, 73, 73);
  outline: none;
  font-weight: bold;
  border-radius: 8px;
  transition: all 0.25s;
  height: 14px;
  width: 14px;
  margin-left: 10px;
  font-size: 18px;
  transform: rotate(45deg);
  margin-top: 0px;
  cursor: pointer;
  padding: 0px 0px 0px 0px;
}
.deleteFileButton:hover {
  outline-style: none;
  border: none;
  -webkit-filter: drop-shadow(0 0 3px #fcc6c5);
  -moz-filter: drop-shadow(0 0 3px #fcc6c5); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 3px #fcc6c5);
  filter: drop-shadow(0 0 3px #fcc6c5);
}
#importfromcsvchoose2:disabled {
  background-color: #686c75;
  color: #394453;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  &:hover {
    background-color: #686c75;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
#importfromcsvimport:disabled {
  background-color: #e4d5ae;
  color: #394453;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
.radio-label-overwrite {
  position: absolute;
  top: 0px;
  left: -90px;
  z-index: 9;
  color: #fbd665;
  -webkit-filter: drop-shadow(0 0 2px #fbd665);
  -moz-filter: drop-shadow(0 0 2px #fbd665); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #fbd665); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #fbd665);
}
.radio-label-nongold {
  position: absolute;
  top: 0px;
  left: -90px;
  z-index: 9;
  color: #deebf7;
}
.overwrite-text {
  position: absolute;
  left: 16px;
  width: 100%;
  padding: 0px 0px 0px 0px;
  top: 32px;
}
.checksygf {
  width: 64px;
  position: relative;
  left: 0px !important;
}
.checksygf2 {
  width: 94px;
  left: 0px !important;
  position: relative;
}
.checksygf3 {
  width: 123px;
  left: 0px !important;
  position: relative;
}
.overwrite-container {
  display: block !important;
  width: fit-content;
  height: 20px;
  z-index: 10000000;
  position: relative;
  padding: 0px 0px 0px 0px;
  margin: 0px 20px 0px 0px;
}
.tiqgold {
  color: #fbd665 !important;
  -webkit-filter: drop-shadow(0 0 2px #fbd665) !important;
  -moz-filter: drop-shadow(
    0 0 2px #fbd665
  ) !important; /* Old versions of Firefox */
  -ms-filter: drop-shadow(
    0 0 2px #fbd665
  ) !important; /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #fbd665) !important;
}
.radio-label-buy-tooltip {
  display: none;
  color: #deebf7;
  -webkit-filter: drop-shadow(0 0 2px #deebf7);
  -moz-filter: drop-shadow(0 0 2px #deebf7); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 2px #deebf7); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 2px #deebf7);
}
.radio-label-buy-tooltip-tip {
  width: 10px;
  position: relative;
  height: 10px;
  transform: rotate(45deg);
  background-color: #14181e;
  top: -5px;
  left: 10px;
}
.overwrite-container2 {
  display: block !important;
  width: 94px;
  z-index: 10000000;
  position: relative;
  padding: 0px 0px 0px 6px;
  margin: 0px 0px 6px -6px;
  height: 20px;
  &:hover {
    .radio-label-buy-tooltip {
      z-index: 10000000000 !important;
      height: auto;
      width: 150px;
      position: absolute;
      display: block;
      background-color: #14181e;
      border-radius: 8px;
      padding: 0px 10px 10px 10px;
      top: 30px;
    }
  }
}
.addedwidthoverwrite {
  width: 94px !important;
}
#overwriteselectwrapper {
  position: relative;
  z-index: 1;
  left: 20px;
  top: 24px;
  display: flex;
  width: 100%;
  height: 20px;
  flex-flow: row wrap;
  padding: 10px 0px 10px 0px;
  margin: 0px 0px -4px 0px;
}
#importfromcsvimport {
  position: relative;
  background-color: #fbd665;
  font-size: 1em;
  outline: none;
  border: none;
  font-weight: bold;
  z-index: 0;
  border-radius: 10px;
  color: #14181e;
  padding: 8px 0px 8px 0px;
  width: calc(100% - 22px);
  margin: 40px 0px 0px 16px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
#importfromcsvimport:hover {
  outline-style: none;
  border: none;
  background-color: #ffe593;
}
#importfromcsvimport:focus {
  outline: none;
}
.importCardErrorMsg {
  z-index: 1000000000;
  top: 28px;
  float: right;
  position: absolute;
}

// INTEGRATIONS
.integrationCardShow {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: relative;
  padding: 25px;
  display: flex;
  flex-flow: column;
  padding-left: 20px;
  padding-right: 30px;
  border-radius: 10px;
  width: 100%;
  height: auto;
  margin: 36px 0px 0px 0px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.Integrations-heading {
  position: relative;
  color: #fbd665;
  font-size: 1.6em;
  padding-bottom: 4px;
  text-align: left;
  top: -68px;
  font-weight: 300;
  margin-left: 4px;
}
// For screen sizes larger than 1680w and 900h
@media screen and (min-width: 1680px) and (min-height: 900px) {
  .integrationCardShow[max-width~="1192px"] .Integrations-component {
    width: 33%; /* Set the width of the children elements to 33% for 3 columns */
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 18px 18px 18px 18px;
    height: 420px;
  }
  .integrationCardShow[max-width~="870px"] .Integrations-component {
    width: 50%; /* Set the width of the children elements to 50% for 2 columns */
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 18px 18px 18px 18px;
    height: 420px;
  }
  .integrationCardShow[max-width~="870px"] .Integrations-wrapper {
    width: calc(100% + 10px);
  }
  .integrationCardShow[max-width~="565px"] .Integrations-component {
    width: 100%; /* Set the width of the children elements to 100% for 1 column */
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 18px 18px 18px 18px;
    height: 420px;
  }
}
// For screen sizes smaller than 1680w or 900h
@media screen and (max-width: 1680px), (max-height: 900px) {
  .integrationCardShow[max-width~="954px"] .Integrations-component {
    width: 33%; /* Set the width of the children elements to 33% for 3 columns */
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 18px 18px 18px 18px;
    height: 420px;
  }
  .integrationCardShow[max-width~="696px"] .Integrations-component {
    width: 50%; /* Set the width of the children elements to 50% for 2 columns */
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 18px 18px 18px 18px;
    height: 420px;
  }
  .integrationCardShow[max-width~="452px"] .Integrations-component {
    width: 100%; /* Set the width of the children elements to 100% for 1 column */
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 18px 18px 18px 18px;
    height: 420px;
  }
  .integrationCardShow[max-width~="452px"] .Integrations-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + 20px);
    height: -moz-fit-content;
    height: fit-content;
    padding: 0px 0px 0px 0px;
    margin: -46px 0px 0px -4px;
  }
}
.Integrations-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% + 12px);
  height: fit-content;
  padding: 0px 0px 16px 0px;
  margin: -46px 0px 0px 0px;
}
.Integrations-component {
  position: relative;
  width: 25%; /* Set the width of the children elements to 25% for 4 columns */
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 18px;
  height: 420px;
}
.linklogowrapperimgtext {
  color: #8a93b2;
  font-weight: 400;
  position: relative;
  top: -1px;
  right: -10px;
  font-size: min(2vw, 2em);
}
.linklogowrapperimgsubheader1 {
  color: #fbd665;
  font-weight: 400;
  margin: 20px 0px 0px 0px;
}
.linklogowrapperimgsubheaderBETA {
  color: #fbd665;
  background: linear-gradient(to bottom right, #171c23, #14181e);
  line-height: 12px;
  position: absolute;
  top: 20px;
  right: calc(20px);
  font-size: 0.8em;
  width: fit-content;
  padding: 4px 10px 4px 10px;
  border-radius: 6px;
}
.linklogowrapperimg {
  width: 100%;
  image-rendering: optimizeSpeed; /*                     */
  image-rendering: -moz-crisp-edges; /* Firefox             */
  image-rendering: -o-crisp-edges; /* Opera               */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
  image-rendering: optimize-contrast; /* CSS3 Proposed       */
  -ms-interpolation-mode: nearest-neighbor;
  filter: blur(0);
  -webkit-filter: blur(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.linklogowrapperimgwrapper {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif;
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  top: 22px;
  height: 48px;
  max-height: 48px;
  width: 100%;
  right: calc(-50% + 141px);
}
.Integrations-inner-component,
.Integrations-inner-component-highlight {
  position: relative;
  background-color: #1f2831;
  width: 100%;
  height: 100%;
  min-width: 248px;
  border-radius: 20px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  box-shadow: 0 0 4pt 2pt #171c23;
  &:hover {
    background-color: #28313d;
  }
}
.Integrations-inner-component-highlight {
  border: 1px solid #fbd665;
  box-shadow: inset 0 0 4px #ffe593;
  -moz-box-shadow: inset 0 0 4px #ffe593;
}
// auto import button
.linklogowrapperautoimportbutton {
  background-color: #fbd665;
  color: #1a2028;
  font-family: "Roboto";
  font-weight: 400;
  border: none;
  text-align: center;
  z-index: 200 !important;
  cursor: pointer;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  font-size: 1em;
  font-weight: 400;
  border-radius: 10px;
  bottom: 66px;
  position: absolute;
  padding: 2px 3px 0px 0px;
  width: 228px !important;
  right: calc(50% - 114px) !important;
  top: 126px;
  font-weight: 600 !important;
  height: 40px;
  margin-left: 24px;
}
.linklogowrapperautoimportbutton:disabled {
  background-color: #e4d5ae;
  color: #394453;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
.linklogowrapperautoimportbutton:hover {
  border: none;
  background-color: #ffe593;
}
// import BUTTON
.linklogowrapperimportbutton {
  background-color: #ffeba8;
  padding: 2px 2px 0px 0px;
  color: #1a2028;
  font-family: "Roboto";
  font-weight: 400;
  border: none;
  text-align: center;
  z-index: 200 !important;
  cursor: pointer;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
  font-size: 1em;
  font-weight: 400;
  border-radius: 10px;
  padding-top: 2px;
  bottom: 66px;
  position: absolute;
  width: 168px !important;
  right: calc(50% - 84px) !important;
  bottom: 26px;
  font-weight: 600 !important;
  height: 40px;
  margin-left: 24px;
}
.linklogowrapperimportbutton:disabled {
  background-color: #e4d5ae;
  color: #394453;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
.linklogowrapperimportbutton:hover {
  border: none;
  background-color: rgb(255, 237, 177);
}
.modalSymbolHeader47 {
  z-index: 1000;
  width: 100%;
  margin-top: -39px;
  margin-left: -16px;
  margin-bottom: 20px;
  position: relative;
  color: #fbd665;
  font-size: 1.6em;
  padding-bottom: 4px;
  text-align: center;
  font-weight: 300;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.importChoiceModal {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  width: 460px;
  text-align: center;
  z-index: 100000001 !important;
  margin: -100px 0px 0px -230px;
  top: 50%;
  left: 50%;
  height: 260px;
  border-radius: 14px;
  color: #fbd665;
  transition: all 0.5s;
  background-color: #222a35;
  outline-style: none;
  border: none;
  display: flex;
  flex-flow: column;
  flex: auto;
  padding: 0px 0px 0px 36px;
  border-top: 50px solid #1a2028;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.IntegrationImportModal,
.IntegrationImportModal2,
.IntegrationImportModal3 {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  text-align: center;
  z-index: 100000001 !important;
  top: 50%;
  left: 50%;
  border-radius: 14px;
  color: #fbd665;
  transition: all 0.5s;
  background-color: #222a35;
  outline-style: none;
  border: none;
  display: flex;
  flex-flow: column;
  flex: auto;
  padding: 0px 0px 0px 36px;
  border-top: 50px solid #1a2028;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.IntegrationImportModal4 {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  text-align: center;
  z-index: 100000001 !important;
  top: 50%;
  left: 50%;
  border-radius: 14px;
  color: #fbd665;
  transition: all 0.5s;
  background-color: #222a35;
  outline-style: none;
  border: none;
  display: flex;
  flex-flow: column;
  flex: auto;
  padding: 0px 0px 0px 36px;
  border-top: 50px solid #1a2028;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  width: 398px;
  margin: -110px 0px 0px -199px;
  height: 220px;
}
.IntegrationImportModal {
  width: 460px;
  margin: -325px 0px 0px -230px;
  height: 650px;
}
.IntegrationImportModal2 {
  width: 420px;
  margin: -220px 0px 0px -210px;
  height: 440px;
}
.IntegrationImportModal3 {
  width: 398px;
  margin: -110px 0px 0px -199px;
  height: 220px;
}
.IntegrationImportModalShow {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: absolute;
  width: 750px;
  text-align: center;
  z-index: 100000001 !important;
  margin: -250px 0px 0px -375px;
  top: 50%;
  left: 50%;
  height: fit-content;
  border-radius: 14px;
  color: #fbd665;
  transition: all 0.5s;
  background-color: #222a35;
  outline-style: none;
  border: none;
  display: flex;
  flex-flow: column;
  flex: auto;
  padding: 0px 0px 0px 36px;
  border-top: 50px solid #1a2028;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.importCard {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: relative;
  padding: 0px;
  display: flex;
  flex-flow: column;
  padding-left: 20px;
  padding-right: 0px;
  border-radius: 10px;
  width: calc(100% + 8px);
  height: 400px;
  margin: 0px 28px 80px -36px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.importCard[max-width~="400px"] .importCardLeft {
  width: 100%;
}
.importCardShow {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  position: relative;
  padding: 0px;
  display: flex;
  flex-flow: column;
  padding-left: 20px;
  padding-right: 0px;
  border-radius: 10px;
  width: calc(100% + 8px);
  height: 400px;
  margin: 0px 28px 80px -36px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.contentLB[min-width~="880px"] .importCardShow {
  display: none;
  flex-flow: column wrap;
  width: 50%;
}
.contentLB2[min-width~="880px"] .importCardShow {
  display: none;
  flex-flow: column wrap;
  width: 50%;
}
.fileimportintegrationinfo {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 86px;
}
.Integrations-inner-line {
  width: calc(100% - 48px);
  left: 24px;
  border-top: 1px solid #556171;
  position: absolute;
  bottom: 178px;
}
.linklogowrapperautoimportbuttonimg {
  font-size: 1em;
  height: 20px;
  margin-top: -5px;
}
.linklogowrapperautoimportbuttonimg2 {
  font-size: 1em;
  height: 19px;
  margin-top: -4px;
  margin-right: 3px;
}
#autoimportunlinkbrokerbutton {
  position: absolute;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #ff8080;
  color: #1a2028;
  padding: 8px 0px 8px 0px;
  margin: 0px 0px 32px 0px;
  font-weight: 500;
  font-size: 1em;
  z-index: 20000;
  height: 40px;
  cursor: pointer;
  width: 232px !important;
  right: calc(50% - 116px) !important;
  top: 147px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
#autoimportunlinkbrokerbutton:hover {
  color: #222a35;
  background-color: #ffa09f;
}
.linklogowrapperautoimportbuttonactionneed {
  outline: none;
  position: relative;
  height: 20px;
  width: 20px;
  font-size: 0.8em;
  font-weight: 500;
  margin-left: 8px;
  top: -2px;
  border: 1px solid #e67443;
  padding: 0px 0px 16px 0px;
  border-radius: 100px;
  color: #e67443;
  background-color: transparent;
  &:hover {
    background-color: #e67443;
    color: #14181e;
  }
}
#importwrongpreview2 {
  position: absolute;
  outline: none;
  color: #fa798b;
  border: none;
  width: calc(100% - 26px);
  top: 170px;
  right: 12px;
  background-color: transparent;
  font-weight: 300;
  font-size: 0.85em;
}
@media screen and (max-width: 1680px), (max-height: 900px) {
  .Integrations-inner-component {
    position: relative;
    background-color: #1f2831;
    width: 100%;
    height: 100%;
    min-width: 248px !important;
    border-radius: 16px;
    -webkit-filter: drop-shadow(0 0 10px #14181e);
    -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 10px #14181e);
    box-shadow: 0 0 4pt 2pt #171c23;
    &:hover {
      background-color: #28313d;
    }
  }
  .Integrations-inner-component-highlight {
    position: relative;
    background-color: #1f2831;
    width: 100%;
    height: 100%;
    min-width: 248px !important;
    border-radius: 14px;
    -webkit-filter: drop-shadow(0 0 10px #14181e);
    -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 10px #14181e);
    box-shadow: 0 0 4pt 2pt #171c23;
    &:hover {
      background-color: #28313d;
    }
  }
}
.contactuslink {
  color: #98b5eb;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #98b5eb;
  }
}
.brokerserverimage {
  width: 300px;
  max-width: 700px;
  border-radius: 10px;
  margin: 24px 0px 0px -2px;
  border: 3px solid #44546a;
}
.serverimageTooltipSuperWrapper > .tooltip-inner {
  max-width: calc(100% + 32px);
  display: flex;
  overflow: hidden;
  margin-left: -32px;
}
.serverimageTooltipSuperWrapper {
  z-index: 10000000001;
}
.accountNumberSuperWrapper > .tooltip-inner {
  max-width: calc(100%);
  display: flex;
  overflow: hidden;
}
.accountNumberSuperWrapper {
  z-index: 10000000001;
}
.accountNumberSuperWrapper2 {
  z-index: 10000000001;
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
  transform: none !important;
}
.accountNumberSuperWrapper3 {
  z-index: 10000000001;
  position: absolute !important;
  top: 100px !important;
  right: 0px !important;
  transform: none !important;
}
.investorpasswordtextwrapper {
  width: 360px;
  margin-bottom: 14px;
}
.investorpasswordtextwrapperlast {
  width: 360px;
}
// BROKER REQUESTS STUFF
.brokerrequestwrapper {
  position: fixed;
  height: 50px;
  z-index: 100000000;
  background-color: #1a2028;
  width: calc(100% - 18px);
  margin: -40px 0px 62px 18px;
  padding: 0px 0px 40px 0px;
  border-bottom: 1px solid #556171;
}
