.DW-heading {
  position: relative;
  color: #fbd665;
  font-size: 1.6em;
  padding-bottom: 4px;
  text-align: left;
  white-space: nowrap;
  top: -68px;
  font-weight: 400;
  margin-left: 10px;
  width: fit-content;
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */
}
.hide {
  display: none;
}
.show {
  display: inline;
}
.addTopMargin {
  margin-top: 10px !important;
}
.mandatoryDW {
  color: #fa798b;
  font-size: 14px;
  position: absolute;
  right: 40px;
}
.main-labels-DW {
  position: relative;
  width: 284px;
  z-index: 2147483647;
  margin-top: 6px;
  margin-bottom: 6px;
}
.amountDW-input {
  border: 1px solid #14181e;
  border-radius: 8px;
  height: 38px;
  color: #fbd665;
  background-color: #1a2028;
  outline-style: none;
}
.columnDW {
  width: 100%;
}
.amountDW-input:focus {
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: all 0.1s;
  border-radius: 8px;
}
.DWinputs {
  width: 424px;
  margin-right: 0px;
}
.DWinputs2 {
  width: 410px;
  margin-right: 34px;
}
.DWCard {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border-top: 50px solid #28303c;
  position: relative;
  padding: 25px;
  padding-left: 14px;
  padding-right: 24px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  background-color: #1f2831;
  height: fit-content;
  margin: 24px 28px 0px 28px;
  box-shadow: 0 0 4pt 2pt #171c23;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.DWCard[max-width~="682px"] {
  width: 100%;
  min-width: 0px;
}

.DWCard[max-width~="682px"] .DWinputs {
  display: flex;
  min-width: 0px;
}
.DWCard[max-width~="682px"] .DWinputs2 {
  display: flex;
  min-width: 0px;
}
.DWinputwrapper {
  margin-top: 0px;
  display: flex;
  flex-flow: row wrap;
}
.DWinputwrapper33notes {
  position: relative;
  top: -39px;
}
.DWinputwrapper33 {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 52px !important;
  margin-bottom: 0px;
  width: calc(100% - 38px);
}
.DWinputwrapper33 > div:last-child > div > div:last-child {
  min-height: 170px;
}
.DWCard[max-width~="682px"] .DWinputwrapper {
  width: 100%;
  display: flex;
  min-width: 0px;
  padding-right: 6px;
}
.DWCard[max-width~="682px"] .DWinputwrapper2 {
  width: calc(100% - 32px);
  display: flex;
  min-width: 0px;
  padding-right: 6px;
}
.DWCard[max-width~="682px"] .logtradebuttonDW {
  margin-left: auto;
  margin-right: 42px;
  left: 0;
}
.text-input-boxes-DW {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  border: 1px solid #14181e;
  border-radius: 8px;
  height: 38px;
  color: #fbd665;
  background-color: #1a2028;
  outline-style: none;
}
.commentsboxdw {
  word-break: break-all;
  border: 1px solid #14181e;
  border-radius: 8px;
  color: #fbd665;
  background-color: #1a2028;
  outline-style: none;
  position: relative;
  height: 120px;
  top: 0px;
  margin: 0px 32px 0px 0px;
  width: 100%;
  left: 0px;
  padding: 6px 10px 0px 10px;
}
.commentsboxdw::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  color: #1f2831;
  background-color: #1f2831;
}
.commentsboxdw::-webkit-scrollbar-track {
  border-radius: 10px;
  border: #a4a9b1;
  color: #1f2831;
  background-color: #1f2831;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.commentsboxdw::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #14181e;
  background-color: #1a2028;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.commentsboxdw::-webkit-scrollbar-corner {
  background-color: #1f2831;
  border-bottom-right-radius: 5px;
}
.commentsboxdw:focus {
  word-break: break-all;
  outline: 1px solid #deebf7;
  border: 1px solid #deebf7;
  transition: 0.1s;
  border-radius: 8px;
}
.logtradebuttonDW {
  position: absolute;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  text-align: center;
  float: right;
  background-color: #fbd665;
  color: #1a2028;
  padding: 8px 0px 8px 0px;
  font-size: 1em;
  z-index: 20000;
  cursor: pointer;
  width: calc(100% - 38px);
  bottom: -74px;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.logtradebuttonDW:hover {
  color: #222a35;
  background-color: #ffe593;
}
.logtradebuttonDW:disabled {
  background-color: #e4d5ae;
  color: #394453;
  cursor: not-allowed;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  &:hover {
    background-color: #e4d5ae;
    color: #394453;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
  }
}
.order-type-style-DW {
  padding-bottom: 8px;
}
.container-DW-d {
  cursor: pointer;
  width: 76px;
}
.container-DW-w {
  cursor: pointer;
  width: 101px;
}
.radiocircle-DW {
  position: absolute;
  top: 3px;
  left: -2px;
  border-radius: 2px;
  height: 20px;
  width: 20px;
  background-color: #1a2028;
  border: 2px solid #14181e;
  border-radius: 100%;
}
.buy-container-DW {
  position: absolute;
  top: 0px;
  left: 56px;
}
.sell-container-DW {
  position: absolute;
  top: 0px;
  left: 160px;
}
.radio-label-buy-DW,
.radio-label-sell-DW {
  position: absolute;
  top: 0px;
  left: 25px;
  z-index: -10000;
}
.container-DW-d:hover input ~ .radiocircle-DW,
.container-DW-w:hover input ~ .radiocircle-DW {
  background-color: #2e3a48;
}

.container-DW-d
  input:checked
  ~ .radiocircle-DW
  .container-DW-w
  input:checked
  ~ .radiocircle-DW {
  filter: drop-shadow(0 0 3px #171c23);
  -webkit-filter: drop-shadow(0 0 3px #171c23);
  background-color: #2e3a48;
}

.radiocircle-DW:after {
  content: "";
  position: absolute;
  display: none;
}

.container-DW-d input:checked ~ .radiocircle-DW:after,
.container-DW-w input:checked ~ .radiocircle-DW:after {
  display: block;
}

.container-DW-d .radiocircle-DW:after,
.container-DW-w .radiocircle-DW:after {
  left: 5px;
  top: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fbd665;
}
.requiredordertypeDW {
  color: #fa798b !important; /* Replace #yourColorHere with your desired color */
  opacity: 1 !important; /* CSS standard requires setting the opacity for the color to take full effect */
  position: relative;
  left: 250px;
}
.DWinputwrapper > input::placeholder,
.DWinputwrapper > div > input::placeholder {
  color: #fa798b !important; /* Replace #yourColorHere with your desired color */
  opacity: 1 !important; /* CSS standard requires setting the opacity for the color to take full effect */
}
