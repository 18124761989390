.navbar-tabname {
  @import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
  position: absolute;
  top: 11px;
  margin: 0;
  left: 150px;
  font-size: 1.5em;
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */
  @media only screen and (max-width: 481px) {
    display: none;
  }
}
.navbar-tabname-morpheus {
  @import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
  position: absolute;
  top: 11px;
  margin: 0;
  left: 250px;
  font-size: 1.5em;
  background: linear-gradient(to bottom right, #797357, #fbd665);
  -webkit-background-clip: text;
  -moz-background-clip: text; /* Old versions of Firefox */
  -ms-background-clip: text; /* Internet Explorer/Edge */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; /* Old versions of Firefox */
  -ms-text-fill-color: transparent; /* Internet Explorer/Edge */
  @media only screen and (max-width: 481px) {
    display: none;
  }
}
.settingTooltip {
  position: relative;
}
.viewingporttesenvtextr {
  position: relative;
  height: 36px;
  margin-left: auto;
  display: flex;
  flex-flow: row;
  margin-right: 222px;
  margin-top: 0px;
  font-size: 1.4em;
  padding: 2px 0px 0px 0px;
  width: auto;
  color: #deebf7;
}
@media screen and (max-width: 481px) {
  .portfolio-icon-notification {
    font-size: 0.57em;
    font-weight: 600;
    position: absolute;
    border: 1px solid #222a35;
    color: #222a35;
    top: 5px !important;
    right: 95px !important;
    border-radius: 100px;
    cursor: pointer;
    padding: 0px 5px 0px 5px;
    background-color: #fbd665;
    display: none;
    z-index: 100000000;
  }
}
@media screen and (max-width: 1440px) and (max-height: 800px) {
  /*   .navbar {
    padding-left: 0.2%;
    padding-top: 0.1;
    z-index: 10000;
    height: 50px !important;
  }
  #profile-icon {
    padding: 0px 0px 4px 2px !important;
    border-radius: 100% !important;
    top: 9px !important;
    right: 10px !important;
    height: 32px !important;
    width: 32px !important;
  }
  #profile-icon-gold {
    font-size: 1em;
    padding: 3px !important;
    border-radius: 100% !important;
    height: 32px !important;
    width: 32px !important;
    top: 8px !important;
    right: 10px !important;
  }
  .hamburger {
    left: 4px !important;
    top: 8px !important;
    font-size: 0.85em !important;
    padding: 7px 12px 8px 12px !important;
    border-radius: 100% !important;
  }
  .viewingportwrapper {
    height: 24px !important;
    //margin-left: auto !important;
    display: flex !important;
    flex-flow: row !important;
    margin-right: 130px !important;
    margin-top: 2px !important;
    padding: 2px 0px 0px 0px !important;
    width: auto !important;
  }
  .viewingportbutton {
    padding: 3px 9px 9px 9px !important;
    display: inline-block !important;
    margin: -6px 20px 10px 0px !important;
    max-width: 240px !important;
    border-radius: 8px !important;
    height: 30px !important;
    font-size: 0.95em !important;
  }
  .viewingportviewing {
    margin-top: -2px !important;
  }
  .navbar-tabname {
    position: absolute;
    top: 9px;
    margin: 0;
    left: 136px;
    font-size: 1.3em;
  }
  .tradersIQlogo {
    width: 28px !important;
    height: 28px !important;
    margin-left: -10px !important;
    margin-top: -6px !important;
  }
  .tradersIQlogoStrike {
    width: 28px !important;
    height: 28px !important;
    margin-left: -10px !important;
    margin-top: 0px !important;
  }
  .filter-icon {
    font-size: 1.3em !important;
    top: 10px !important;
    right: 98px !important;
    padding: 0px 6px 0px 6px !important;
  }
  .portfolio-icon-notification {
    font-size: 0.45em !important;
    font-weight: 600;
    position: absolute;
    border: 1px solid #222a35;
    color: #222a35;
    top: 7px !important;
    right: 97px !important;
    border-radius: 100px;
    cursor: pointer;
    padding: 0px 4px 0px 4px !important;
    background-color: #fbd665;
    display: none;
    z-index: 100000000;
  }
  .small-icons-OC2gold {
    color: #fbd665;
    font-size: 1.46em !important;
    top: -1px !important;
    right: -26px !important;
  }
  .small-icons-OC2 {
    color: #deebf7;
    padding: 0;
    font-size: 1.46em !important;
    border: none;
    background-color: transparent;
    position: relative;
    width: auto;
    height: auto;
    top: -1px !important;
    right: -26px !important;
    cursor: pointer;
    transition: none;
  }
  .beta-topnav-text {
    @import url("https: //fonts.googleapis.com/css2?family=Varela+Round&display=swap");
    position: absolute;
    color: #fff8b9;
    font-size: 0.5em;
    top: 28px !important;
    left: 92px !important;
    height: 0px;
    width: 0px;
    cursor: default;
  } */
}
.navbar {
  display: flex;
  background-color: #14181e;
  padding-left: 0.2%;
  padding-top: 0.1;
  z-index: 10000;
  height: 62px;
  border-bottom: 1px solid #262f3a;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.navbar[max-width~="481px"] {
  display: flex;
  background-color: #14181e;
  padding-left: 0.2%;
  padding-top: 0.1;
  z-index: 10000;
  height: 50px;
  border-bottom: 1px solid #262f3a;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
a,
.navbar-nav,
.navbar-light .nav-link {
  color: #deebf7;
  text-decoration: none;
}
.navbar-text:hover {
  cursor: default;
}
.navbar-brand {
  position: absolute;
  left: 80px;
  color: #deebf7;
}
.navbar[max-width~="481px"] .tradersIQlogo {
  transition: none;
  width: 28px !important;
  height: 28px !important;
  margin-left: -28px !important;
  margin-top: -6px !important;
}
.navbar[max-width~="481px"] .tradersIQlogoStrike {
  width: 28px !important;
  height: 28px !important;
  margin-left: -28px !important;
  margin-top: 0px !important;
  transition: none;
}
#profile-icon {
  font-size: 1em;
  position: absolute;
  padding: 3px;
  border: 2px solid #808080;
  border-radius: 100%;
  height: 45px;
  width: 45px;
  color: #deebf7;
  top: 8px;
  right: 10px;
  background-color: #1a2028;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.navbar[max-width~="481px"] #profile-icon {
  font-size: 1em;
  position: absolute;
  padding: 0px 0px 4px 2px;
  border: 2px solid #808080;
  border-radius: 100%;
  color: #deebf7;
  top: 8px;
  right: 10px;
  background-color: #1a2028;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  height: 32px;
  width: 32px;
}

#profile-icon-gold {
  font-size: 1em;
  position: absolute;
  padding: 3px;
  border: 2px solid #fbd665;
  border-radius: 100%;
  height: 45px;
  width: 45px;
  color: #deebf7;
  top: 8px;
  right: 10px;
  background-color: #1a2028;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.navbar[max-width~="481px"] #profile-icon-gold {
  font-size: 1em;
  position: absolute;
  padding: 0px 0px 4px 2px;
  border: 2px solid #fbd665;
  border-radius: 100%;
  height: 32px;
  width: 32px;
  color: #deebf7;
  top: 8px;
  right: 10px;
  background-color: #1a2028;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.forum-icon {
  font-size: 1.7em;
  position: absolute;
  border: none;
  color: #deebf7;
  top: 10px;
  right: 206px;
  border-radius: 15%;
  cursor: pointer;
  padding: 0 6px 1px 6px;
  transition: all 0.15s;
}
.forum-icon:hover {
  background-color: #1f2831;
  -webkit-filter: drop-shadow(0 1px #556171);
  -moz-filter: drop-shadow(0 0 1px #556171); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 1px #556171); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 1px #556171);
}
.portfolio-icon {
  font-size: 1.7em;
  position: absolute;
  border: none;
  color: #deebf7;
  top: 10px;
  right: 220px;
  border-radius: 15%;
  cursor: pointer;
  padding: 1px 10px 1px 10px;
}
.portfolio-icon:hover {
  background-color: #1f2831;
}

.search-bar {
  font-family: "Roboto";
  transition: width 0.25s ease-in-out;
  position: absolute;
  padding-left: 10px;
  right: 300px;
  width: 37px;
  border: 1px solid #1f2831;
  border-radius: 8px;
  height: 36px;
  font-size: 0;
  color: #fbd665;
  top: 13px;
  background-color: #222a35;
  ::-webkit-input-placeholder {
    font-style: italic;
    color: #808080;
  }
  :-moz-placeholder {
    font-style: italic;
    color: #808080;
  }
  ::-moz-placeholder {
    font-style: italic;
    color: #808080;
  }
  :-ms-input-placeholder {
    font-style: italic;
    color: #808080;
  }
}
.search-bar:focus {
  border: 2px solid #deebf7;
  transition: all 0.1s;
  border-radius: 8px;
  outline-style: none;
  font-size: 1em;
}
.search-icon {
  font-size: 1em;
  position: absolute;
  border: none;
  color: #999999;
  z-index: 80003;
  top: 19px;
  right: 302px;
  cursor: pointer;
  padding: 0 8px 0.5px 8.5px;
  border-radius: 15%;
}
.hamburger {
  position: absolute;
  left: 2px;
  top: 8px;
  font-size: 1em;
  padding: 11.2px 16.7px 11.2px 16.7px;
  border-radius: 100%;
  border: none;
  color: #deebf7;
  background-color: #14181e;
  margin: 0;
  &:hover {
    transition: all 0.15s ease-in;
    cursor: pointer;
    background-color: #1f2831;
  }
}
.navbar[max-width~="481px"] .hamburger {
  position: absolute;
  left: 4px;
  top: 8px;
  font-size: 0.85em;
  padding: 7px 12px 8px 12px;
  border-radius: 100%;
  border: none;
  color: #deebf7;
  background-color: #14181e;
  margin: 0;
  &:hover {
    transition: all 0.15s ease-in;
    cursor: pointer;
    background-color: #1f2831;
  }
}
.tip {
  transform: rotate(-45deg);
  background: #222a35;
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 10px;
  left: 200px;
  top: -10px;
  z-index: 80000;
}
.tooltip-inner {
  background-color: #0f1217;
  border-radius: 10px;
  -webkit-filter: drop-shadow(0 0 5px #14181e);
  -moz-filter: drop-shadow(0 0 5px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 5px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 5px #14181e);
  -moz-box-shadow: 0 0 4px #14181e;
  -webkit-box-shadow: 0 0 4px #14181e;
  box-shadow: 0 0 4px #14181e;
}
.tooltip-bg {
  display: none;
  background-color: #222a35;
  -webkit-filter: drop-shadow(0 0 5px #556171);
  -moz-filter: drop-shadow(0 0 5px #556171); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 5px #556171); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 5px #556171);
  position: absolute;
  width: 250px;
  height: 60px;
  right: 68px;
  top: 60px;
  border-radius: 8px;
  z-index: 80001;
  text-align: center;
}
.messagebox-text {
  font-size: 0.9em;
  padding-top: 20px;
  font-style: italic;
  color: #deebf7;
}
.feedbackbutton {
  position: relative;
  font-size: 1em;
  font-family: "Roboto";
  font-weight: 400;
  border-radius: 8px;
  border: none;
  text-align: center;
  background-color: #fbd665;
  color: #1a2028;
  padding: 5px;
  z-index: 2147483647 !important;
  cursor: pointer;
  width: 100px;
  left: -20px;
  outline-style: none;
  -webkit-filter: drop-shadow(0 0 10px #14181e);
  -moz-filter: drop-shadow(0 0 10px #14181e); /* Old versions of Firefox */
  -ms-filter: drop-shadow(0 0 10px #14181e); /* Internet Explorer/Edge */
  filter: drop-shadow(0 0 10px #14181e);
}
.feedbackbutton:hover {
  outline-style: none;
  border: none;
  color: #222a35;
  background-color: #ffe593;
}
.morpheuslogo {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  transition: 0.2s all linear;
}
.tradersIQlogo {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  transition: 0.2s all linear;
}
.tradersIQlogo:hover {
  transition: 0.2s all linear;
  filter: drop-shadow(0 -2px 5px #deebf7);
}
.navbar-brand:hover .tradersIQlogo {
  display: none;
}
.navbar[max-width~="506px"] .beta-topnav-text {
  display: none;
}
.navbar[max-width~="506px"] .beta-topnav-text-morpheus {
  display: none;
}
.navbar-brand:hover .tradersIQlogoStrike {
  display: block;
}
.tradersIQlogoStrike {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  transition: 0.2s all linear;
  display: none;
}
.tradersIQlogoStrike:hover {
  transition: 0.2s all linear;
  filter: drop-shadow(0 -1px 3px #deebf7);
}
.navbar[max-width~="481px"] .viewingportwrapper {
  position: relative;
  height: 24px;
  margin-left: auto;
  display: flex;
  flex-flow: row;
  //margin-right: 280px; this is the margin when the share trades button is there
  margin-left: 56px;
  margin-top: 2px;
  padding: 2px 0px 0px 0px;
  width: auto;
  border-right: none;
}
.viewingportwrapper {
  position: relative;
  height: 36px;
  margin-left: auto;
  display: flex;
  flex-flow: row;
  //margin-right: 280px; this is the margin when the share trades button is there
  margin-right: 254px;
  margin-top: 1px;
  padding: 2px 0px 0px 0px;
  width: auto;
}
.viewingportviewing {
  color: #98b5eb;
  margin-right: 18px;
  margin-top: 4px;
  font-style: italic;
}
.viewingportbuttontext {
  max-width: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.deletedemodatabutton {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
  font-family: "Roboto", sans-serif;
  position: relative;
  border-radius: 8px;
  left: -20px;
  top: 4px;
  font-size: 0.85em;
  font-weight: 500;
  color: #14181e;
  padding: 3px 8px 0px 8px;
  height: 24px;
  border: none;
  background-color: #dd273c;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: #ff3149;
  }
}
.navbar[max-width~="632px"] .deletedemodatabutton {
  display: none;
}
.navbar[max-width~="481px"] .deletedemodatabutton {
  left: 250px;
  border-radius: 8px;
  font-size: 0.85em;
  height: 24px;
  border: none;
  background-color: #fa798b;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: #fa798b;
    -webkit-filter: drop-shadow(0 0 3px #fa798b);
    -moz-filter: drop-shadow(0 0 3px #fa798b); /* Old versions of Firefox */
    -ms-filter: drop-shadow(0 0 3px #fa798b); /* Internet Explorer/Edge */
    filter: drop-shadow(0 0 3px #fa798b);
  }
}

.viewingportbutton {
  padding: 5px 13px 6px 13px;
  display: inline-block !important;
  margin: -3px 30px 10px 0px;
  border: 1px solid #fbd665;
  max-width: 240px;
  border-radius: 10px;
  outline: none;
  height: 38px;
  color: #fff7cb;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 400;
  text-overflow: ellipsis;
  font-size: 1.1em;
  background-color: transparent;
  background-color: #1a2028;
  -moz-box-shadow: inset 0 0 4px #ffe593;
  -webkit-box-shadow: inset 0 0 4px #ffe593;
  box-shadow: inset 0 0 4px #ffe593;
  &:hover {
    cursor: pointer;
    outline-style: none;
    background-color: #ffe593;
    border: 1px solid #ffe593;
    color: #14181e;

  }
}
.navbar[max-width~="481px"] .viewingportbutton {
  padding: 3px 9px 9px 9px;
  display: inline-block !important;
  margin: -6px 20px 10px 48px !important;
  border: 1px solid #fbd665;
  max-width: 160px;
  border-radius: 8px;
  outline: none;
  height: 30px;
  color: #fff7cb;
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 400;
  text-overflow: ellipsis;
  font-size: 0.95em;
  background-color: transparent;
  background-color: #1a2028;
  transition: none;
  -moz-box-shadow: inset 0 0 4px #ffe593;
  -webkit-box-shadow: inset 0 0 4px #ffe593;
  box-shadow: inset 0 0 4px #ffe593;
  &:hover {
    cursor: pointer;
    outline-style: none;
    background-color: #ffe593;
    border: 1px solid #ffe593;
    color: #14181e;

  }
}
.viewingportbuttonhovered {
  padding: 5px 13px 6px 13px;
  display: inline-block !important;
  margin: -3px 30px 10px 0px;
  max-width: 240px;
  border-radius: 10px;
  outline: none;
  height: 38px;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 400;
  text-overflow: ellipsis;
  font-size: 1.1em;
  background-color: #ffe593;
  border: 1px solid #ffe593;
  color: #14181e;
  -moz-box-shadow: inset 0 0 4px #ffe593;
  -webkit-box-shadow: inset 0 0 4px #ffe593;
  box-shadow: inset 0 0 4px #ffe593;
  &:hover {
    cursor: pointer;
    outline-style: none;
    background-color: #ffe593;
    border: 1px solid #ffe593;
    color: #14181e;
  }
}
.beta-topnav-text {
  @import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
  position: absolute;
  color: #fff8b9;
  font-size: 0.5em;
  top: 37px;
  left: 98px;
  height: 0px;
  width: 0px;
  cursor: default;
}
.beta-topnav-text-morpheus {
  @import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
  position: absolute;
  color: #fff8b9;
  font-size: 0.5em;
  top: 40px;
  left: 169px;
  height: 0px;
  width: 0px;
  cursor: default;
}
.portfolio-icon-notification {
  font-size: 0.57em;
  font-weight: 600;
  position: absolute;
  border: 1px solid #222a35;
  color: #222a35;
  top: 8px;
  right: 142px;
  border-radius: 100px;
  cursor: pointer;
  padding: 0px 5px 0px 5px;
  background-color: #fbd665;
  display: none;
  z-index: 100000000;
}
